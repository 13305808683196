import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row, Spinner } from "reactstrap";

interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}

interface Step7Props {
    toggleArrowTab: () => void;
    inputs: SignUpState;
    setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
    onCreate: () => void;
    OnUpdate: (object: any) => void;
    t: (key: string) => string;
    loading: boolean;
    isInitialValue: boolean;
}

const Step7: React.FC<Step7Props> = ({ toggleArrowTab, inputs, setInputs, t, onCreate, OnUpdate, loading, isInitialValue }: Step7Props) => {
    const [isValidWebsite, setIsValidWebsite] = useState<boolean>(false);

    const onClickUpdate = () => {
        let website = inputs.company_website.trim();
        if (!website.match(/^https?:\/\//i)) {
            website = `http://${website}`;
        }
        const attributes = [
            {
                "key": "website",
                "value": website
            }
        ]
        OnUpdate(attributes)
    }
    const validateWebsite = (url: string) => {
        const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}([\/\w-]+)?$/;
        const isValid = websiteRegex.test(url);
        setIsValidWebsite(isValid);
    };
    const handleWebsiteChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { value } = e.target;
        setInputs({ ...inputs, company_website: value });
        validateWebsite(value);
    };
    
    useEffect(() => {
        const websiteRegex = /^(https?:\/\/)?([a-zA-Z0-9-]+\.){1,}[a-zA-Z]{2,}([\/\w-]+)?$/;
        const isValid = websiteRegex.test(inputs.company_website);
        setIsValidWebsite(isValid);
    }, [inputs.company_website]);

    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12}>
                    <div className="hstack fs-32 fw-bold justify-content-center">
                        <h5 className="fw-bold">{t("signup.What_is_your_companys_website")}</h5>
                    </div>
                </Col>
                <Col lg={3}>
                    <div className="mb-3">
                        <Label htmlFor="dts_website" className="form-label">
                            {t("signup.company_website")}
                        </Label>
                        <Input
                            type="text"
                            value={inputs.company_website}
                            className="form-control"
                            id="dts_website"
                            onChange={handleWebsiteChange}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                }
                              }}
                            placeholder={t("signup.enter_company_website")}
                        />
                    </div>
                    {isInitialValue ? <p className="fs-14">{t("signup.company_disclaimer")}.</p> : null}
                </Col>
                <Col lg={3}>
                    <button type="button" className="w-100 btn btn-primary btn-icon waves-effect waves-light" onClick={onClickUpdate} disabled={!isValidWebsite}>
                        {loading ? <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                                Loading...
                            </span>
                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                        </span> : t("signup.finish")}
                    </button>{" "}
                </Col>
            </Row>
        </div>
    );
};

export default Step7;