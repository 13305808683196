// src/components/QuickSightEmbed.js
import React from 'react';
import GenerativeQnA from './GenrateURL'
import QuickSightEmbedContext from './QuickSightEmbedContext';
import { Container, Row } from 'reactstrap';
import GenrateURL from './GenrateURL';

const QuickSightEmbed = () => {
  return (
    <div>  
       <React.Fragment>
            <div className="page-content">
                <Container fluid>    
                    <Row className='h-100 w-100'>
                      <div style={{position: "absolute", margin: "0 auto"}} className='h-100'>
                          <div style={{position:"fixed", top:132, zIndex:99, width:"88%"}} className='h-100'>
                            <GenrateURL />                       
                          </div>
                          <div style={{position:"relative", top:50, zIndex:9, width:"90%"}} className='h-100'>
                            <QuickSightEmbedContext initialDashboardId="fd22fb3b-86a2-4d7b-a533-8758c7abcdb5" />
                          </div>
                      </div>
                    </Row>      
                </Container>
            </div>
        </React.Fragment>
      
    
        {/* <QuickSightEmbedContext /> */}
      {/* <iframe
            width="960"
            height="720"
            src="https://eu-west-2.quicksight.aws.amazon.com/sn/embed/share/accounts/200024303214/topics/fuiVMynUS92of1yFtwV7HDrlZFxXNLOy?directory_alias=zilter-technologies">
        </iframe> */}
        </div>
  );
};

export default QuickSightEmbed;
