import { L } from "@fullcalendar/list/internal-common";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button,
} from "reactstrap";
import SimpleBar from "simplebar-react";
import { SetInvoceData } from "store/auth/action";
import { isJson } from "utils";

const StudentsList: React.FC<any> = ({ disabled, handleselect,mainList, show, onCloseClick, selectedlist, ChannelDetail }: any) => {  
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const { id } = useParams();
    const navigate = useNavigate();

    const isChecked = (id: any) => {
        if (selectedlist && selectedlist.length) {
            return selectedlist.some((course: any) => {
                let valuesJson = course.valuesJson && isJson(course.valuesJson) ? JSON.parse(course.valuesJson) : {}
                return valuesJson.course_name === id;
            });
        }
    };
    const createStudents = () => {
        let details = ChannelDetail && ChannelDetail.valuesJson && isJson(ChannelDetail.valuesJson) ? JSON.parse(ChannelDetail.valuesJson) : {};
        let channel = {ChannelDetail, ...details, id: id}       
        dispatch(SetInvoceData(selectedlist, channel))
        navigate("/channels/invoices/create-invoice")
    }
    
    return (<>
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="lg"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
                Select Students For Invoice
            </ModalHeader>
            <ModalBody>
            {mainList && mainList.length ?
                <div className="table-responsive">
                    <table className="table align-middle position-relative table-nowrap">
                        <thead className="cursor-pointer table-active">
                            <tr>
                                <th>
                                    #
                                </th>
                                <th>
                                    Student
                                </th>
                                <th>
                                    University
                                </th>
                                <th>
                                    Course
                                </th>
                                <th>
                                    Commission
                                </th>
                            </tr>
                        </thead>                        
                        <tbody id="task-list">
                            {mainList && mainList.length ? mainList.map((item: any, key: any) => {
                                let result = isChecked(item.id);
                                let itemdetails = {...item.valuesJson, ...item};
                                return <tr key={key}>
                                    <td>
                                        <div className="d-flex align-items-start">
                                            <div className="flex-shrink-0 me-0">
                                            </div>
                                            <div className="flex-grow-1">
                                                <div className="form-check">
                                                    <input
                                                        className="form-check-input me-0"
                                                        type="checkbox"
                                                        value={item.id}
                                                        id={"todo" + item.id}
                                                        // checked={result ? true : false}
                                                        onChange={(e) => handleselect(item, e.target.checked)}
                                                    />
                                                    {/* <label className="px-4 form-check-label" htmlFor={"todo" + item.id}>{item.student} </label> */}
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                    <td> 
                                        <label >{itemdetails["student"] && itemdetails["student"]["name"] ? itemdetails["student"]["name"] : 'NA'}</label>
                                    </td>
                                    <td><label >{itemdetails["institution_name"] && itemdetails["institution_name"]["name"] ? itemdetails["institution_name"]["name"] : 'NA'}</label></td>
                                    <td><label >{itemdetails["courses"] && itemdetails["courses"]["name"] ? itemdetails["courses"]["name"] : 'NA'}</label></td>
                                     
                                    <td>
                                        {item?.channels?.commission}
                                    </td>
                                </tr>
                            }) : null}
                            {/* </SimpleBar> */}
                        </tbody>
                    </table>
                </div> 
                :<div className="hstack align-items-center justify-content-center" 
                style={{minHeight: 200}}>
                <h5 className="fw-bold">No Students to create Invoice</h5>
            </div>}               
            </ModalBody>
            {mainList && mainList.length ?
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    <Button
                        className="btn btn-primary w-auto"
                        onClick={() => createStudents()}
                        disabled={disabled}
                    >
                        Create
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        Cancel
                    </Button>
                </div>
                : null}
        </Modal>
    </>
    );
};

export default StudentsList;
