import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import { sourceGetRequest, sourcesMetadataGetRequest, sourceUpdateRequest } from 'store/sources/action';

const Details = (props: any) => {
    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>       
                <Section 
                    detailsGetAction={sourceGetRequest}
                    metaDatagetRequest={sourcesMetadataGetRequest}
                    updateAction={sourceUpdateRequest}
                    model={"sources"}
                    reducerState={"sources"}
                    translater={"source"}
                    label={"Sources"}
                />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Details);