import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/UpdateForm";
import { toast } from "react-toastify";
import { Button, Card, Col, Modal, ModalBody, ModalHeader, OffcanvasHeader, Row } from "reactstrap";
import { useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { propertyListGetRequest } from "store/properties/action";
import { financeChannelListGetRequest, financeGetRequest, financeUpdateRequest } from "store/finance/action";
// Define types or interfaces
interface FormField {
    name: string;
    label: string;
    type: string;
    options?: { value: string; label: string }[];
}

interface FormData {
    fields: FormField[];
}

const FinanceInfo =({ props, show, onCloseClick, dataFields , record}: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const finance: any = useSelector((state: ApplicationState) => state.finance.details);

    const onUpdate = (formValues: any) => {
        let data = {
            "valuesJson": formValues,
            "channelId": id,
          }
        const handleSuccess = (body: any) => {
            dispatch(financeChannelListGetRequest(id));
            dispatch(financeGetRequest(finance.id))
        }
        dispatch(financeUpdateRequest(finance.id, data, handleSuccess, () => { }))
    }
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(propertyListGetRequest("channels", filters));
    }, [])

    return (
        <>
        {finance && finance.id && 
        <Card>
            <Modal  backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
                {/* <ModalHeader toggle={onCloseClick} >
                    Update Details
                </ModalHeader> */}
            <OffcanvasHeader className="bg-light fw-bold p-3" toggle={() =>{onCloseClick(!show)}}>
               <span className="fw-bold"> Update Details</span>
               
            </OffcanvasHeader>
            <hr />
            <ModalBody className='p-1'>
                    <Col md={12} lg={12}>                
                        <Form dataFields={dataFields} onUpdate={onUpdate} showbtn={false} data={finance} formfor={"commission"} />
                    </Col>
            </ModalBody>
            </Modal> 
        </Card>
        }
        </>

    );
};

export default FinanceInfo;
