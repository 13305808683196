
const currency = [
  {
    name: "Afghanistan",
    code: "AF",
    currency: "Afghan afghani",
    currency_code: "AFN",
    currency_symbol: "؋",
    flag: "🇦🇫",
    flamobile_codeg: "+93"
  },
  {
    name: "Albania",
    code: "AL",
    currency: "Albanian lek",
    currency_code: "ALL",
    currency_symbol: "L",
    flag: "🇦🇱",
    flamobile_codeg: "+355"
  },
  {
    name: "Algeria",
    code: "DZ",
    currency: "Algerian dinar",
    currency_code: "DZD",
    currency_symbol: "دج",
    flag: "🇩🇿",
    flamobile_codeg: "+213"
  },
  {
    name: "Andorra",
    code: "AD",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇦🇩",
    flamobile_codeg: "+376"
  },
  {
    name: "Angola",
    code: "AO",
    currency: "Angolan kwanza",
    currency_code: "AOA",
    currency_symbol: "Kz",
    flag: "🇦🇴",
    flamobile_codeg: "+244"
  },
  {
    name: "Antigua and Barbuda",
    code: "AG",
    currency: "East Caribbean dollar",
    currency_code: "XCD",
    currency_symbol: "$",
    flag: "🇦🇬",
    flamobile_codeg: "+1-268"
  },
  {
    name: "Argentina",
    code: "AR",
    currency: "Argentine peso",
    currency_code: "ARS",
    currency_symbol: "$",
    flag: "🇦🇷",
    flamobile_codeg: "+54"
  },
  {
    name: "Armenia",
    code: "AM",
    currency: "Armenian dram",
    currency_code: "AMD",
    currency_symbol: "֏",
    flag: "🇦🇲",
    flamobile_codeg: "+374"
  },
  {
    name: "Australia",
    code: "AU",
    currency: "Australian dollar",
    currency_code: "AUD",
    currency_symbol: "$",
    flag: "🇦🇺",
    flamobile_codeg: "+61"
  },
  {
    name: "Austria",
    code: "AT",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇦🇹",
    flamobile_codeg: "+43"
  },
  {
    name: "Azerbaijan",
    code: "AZ",
    currency: "Azerbaijani manat",
    currency_code: "AZN",
    currency_symbol: "₼",
    flag: "🇦🇿",
    flamobile_codeg: "+994"
  },
  {
    name: "Bahamas",
    code: "BS",
    currency: "Bahamian dollar",
    currency_code: "BSD",
    currency_symbol: "$",
    flag: "🇧🇸",
    flamobile_codeg: "+1-242"
  },
  {
    name: "Bahrain",
    code: "BH",
    currency: "Bahraini dinar",
    currency_code: "BHD",
    currency_symbol: ".د.ب",
    flag: "🇧🇭",
    flamobile_codeg: "+973"
  },
  {
    name: "Bangladesh",
    code: "BD",
    currency: "Bangladeshi taka",
    currency_code: "BDT",
    currency_symbol: "৳",
    flag: "🇧🇩",
    flamobile_codeg: "+880"
  },
  {
    name: "Barbados",
    code: "BB",
    currency: "Barbadian dollar",
    currency_code: "BBD",
    currency_symbol: "$",
    flag: "🇧🇧",
    flamobile_codeg: "+1-246"
  },
  {
    name: "Belarus",
    code: "BY",
    currency: "Belarusian ruble",
    currency_code: "BYN",
    currency_symbol: "Br",
    flag: "🇧🇾",
    flamobile_codeg: "+375"
  },
  {
    name: "Belgium",
    code: "BE",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇧🇪",
    flamobile_codeg: "+32"
  },
  {
    name: "Belize",
    code: "BZ",
    currency: "Belize dollar",
    currency_code: "BZD",
    currency_symbol: "$",
    flag: "🇧🇿",
    flamobile_codeg: "+501"
  },
  {
    name: "Benin",
    code: "BJ",
    currency: "West African CFA franc",
    currency_code: "XOF",
    currency_symbol: "Fr",
    flag: "🇧🇯",
    flamobile_codeg: "+229"
  },
  {
    name: "Bhutan",
    code: "BT",
    currency: "Bhutanese ngultrum",
    currency_code: "BTN",
    currency_symbol: "Nu.",
    flag: "🇧🇹",
    flamobile_codeg: "+975"
  },
  {
    name: "Bolivia",
    code: "BO",
    currency: "Bolivian boliviano",
    currency_code: "BOB",
    currency_symbol: "Bs.",
    flag: "🇧🇴",
    flamobile_codeg: "+591"
  },
  {
    name: "Bosnia and Herzegovina",
    code: "BA",
    currency: "Bosnia and Herzegovina convertible mark",
    currency_code: "BAM",
    currency_symbol: "KM",
    flag: "🇧🇦",
    flamobile_codeg: "+387"
  },
  {
    name: "Botswana",
    code: "BW",
    currency: "Botswana pula",
    currency_code: "BWP",
    currency_symbol: "P",
    flag: "🇧🇼",
    flamobile_codeg: "+267"
  },
  {
    name: "Brazil",
    code: "BR",
    currency: "Brazilian real",
    currency_code: "BRL",
    currency_symbol: "R$",
    flag: "🇧🇷",
    flamobile_codeg: "+55"
  },
  {
    name: "Brunei",
    code: "BN",
    currency: "Brunei dollar",
    currency_code: "BND",
    currency_symbol: "$",
    flag: "🇧🇳",
    flamobile_codeg: "+673"
  },
  {
    name: "Bulgaria",
    code: "BG",
    currency: "Bulgarian lev",
    currency_code: "BGN",
    currency_symbol: "лв",
    flag: "🇧🇬",
    flamobile_codeg: "+359"
  },
  {
    name: "Burkina Faso",
    code: "BF",
    currency: "West African CFA franc",
    currency_code: "XOF",
    currency_symbol: "Fr",
    flag: "🇧🇫",
    flamobile_codeg: "+226"
  },
  {
    name: "Burundi",
    code: "BI",
    currency: "Burundian franc",
    currency_code: "BIF",
    currency_symbol: "Fr",
    flag: "🇧🇮",
    flamobile_codeg: "+257"
  },
  {
    name: "Cabo Verde",
    code: "CV",
    currency: "Cape Verdean escudo",
    currency_code: "CVE",
    currency_symbol: "$",
    flag: "🇨🇻",
    flamobile_codeg: "+238"
  },
  {
    name: "Cambodia",
    code: "KH",
    currency: "Cambodian riel",
    currency_code: "KHR",
    currency_symbol: "៛",
    flag: "🇰🇭",
    flamobile_codeg: "+855"
  },
  {
    name: "Cameroon",
    code: "CM",
    currency: "Central African CFA franc",
    currency_code: "XAF",
    currency_symbol: "Fr",
    flag: "🇨🇲",
    flamobile_codeg: "+237"
  },
  {
    name: "Canada",
    code: "CA",
    currency: "Canadian dollar",
    currency_code: "CAD",
    currency_symbol: "$",
    flag: "🇨🇦",
    flamobile_codeg: "+1"
  },
  {
    name: "Central African Republic",
    code: "CF",
    currency: "Central African CFA franc",
    currency_code: "XAF",
    currency_symbol: "Fr",
    flag: "🇨🇫",
    flamobile_codeg: "+236"
  },
  {
    name: "Chad",
    code: "TD",
    currency: "Chadian CFA franc",
    currency_code: "XAF",
    currency_symbol: "Fr",
    flag: "🇹🇩",
    flamobile_codeg: "+235"
  },
  {
    name: "Chile",
    code: "CL",
    currency: "Chilean peso",
    currency_code: "CLP",
    currency_symbol: "$",
    flag: "🇨🇱",
    flamobile_codeg: "+56"
  },
  {
    name: "China",
    code: "CN",
    currency: "Renminbi",
    currency_code: "CNY",
    currency_symbol: "¥",
    flag: "🇨🇳",
    flamobile_codeg: "+86"
  },
  {
    name: "Colombia",
    code: "CO",
    currency: "Colombian peso",
    currency_code: "COP",
    currency_symbol: "$",
    flag: "🇨🇴",
    flamobile_codeg: "+57"
  },
  {
    name: "Comoros",
    code: "KM",
    currency: "Comorian franc",
    currency_code: "KMF",
    currency_symbol: "Fr",
    flag: "🇰🇲",
    flamobile_codeg: "+269"
  },
  {
    name: "Congo (Congo-Brazzaville)",
    code: "CG",
    currency: "Central African CFA franc",
    currency_code: "XAF",
    currency_symbol: "Fr",
    flag: "🇨🇬",
    flamobile_codeg: "+242"
  },
  {
    name: "Costa Rica",
    code: "CR",
    currency: "Costa Rican colón",
    currency_code: "CRC",
    currency_symbol: "₡",
    flag: "🇨🇷",
    flamobile_codeg: "+506"
  },
  {
    name: "Croatia",
    code: "HR",
    currency: "Croatian kuna",
    currency_code: "HRK",
    currency_symbol: "kn",
    flag: "🇭🇷",
    flamobile_codeg: "+385"
  },
  {
    name: "Cuba",
    code: "CU",
    currency: "Cuban peso",
    currency_code: "CUP",
    currency_symbol: "$",
    flag: "🇨🇺",
    flamobile_codeg: "+53"
  },
  {
    name: "Cyprus",
    code: "CY",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇨🇾",
    flamobile_codeg: "+357"
  },
  {
    name: "Czech Republic",
    code: "CZ",
    currency: "Czech koruna",
    currency_code: "CZK",
    currency_symbol: "Kč",
    flag: "🇨🇿",
    flamobile_codeg: "+420"
  },
  {
    name: "Democratic Republic of the Congo",
    code: "CD",
    currency: "Congolese franc",
    currency_code: "CDF",
    currency_symbol: "Fr",
    flag: "🇨🇩",
    flamobile_codeg: "+243"
  },
  {
    name: "Denmark",
    code: "DK",
    currency: "Danish krone",
    currency_code: "DKK",
    currency_symbol: "kr",
    flag: "🇩🇰",
    flamobile_codeg: "+45"
  },
  {
    name: "Djibouti",
    code: "DJ",
    currency: "Djiboutian franc",
    currency_code: "DJF",
    currency_symbol: "Fr",
    flag: "🇩🇯",
    flamobile_codeg: "+253"
  },
  {
    name: "Dominica",
    code: "DM",
    currency: "East Caribbean dollar",
    currency_code: "XCD",
    currency_symbol: "$",
    flag: "🇩🇲",
    flamobile_codeg: "+1-767"
  },
  {
    name: "Dominican Republic",
    code: "DO",
    currency: "Dominican peso",
    currency_code: "DOP",
    currency_symbol: "$",
    flag: "🇩🇴",
    flamobile_codeg: "+1-809"
  },
  {
    name: "Ecuador",
    code: "EC",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇪🇨",
    flamobile_codeg: "+593"
  },
  {
    name: "Egypt",
    code: "EG",
    currency: "Egyptian pound",
    currency_code: "EGP",
    currency_symbol: "ج.م",
    flag: "🇪🇬",
    flamobile_codeg: "+20"
  },
  {
    name: "El Salvador",
    code: "SV",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇸🇻",
    flamobile_codeg: "+503"
  },
  {
    name: "Equatorial Guinea",
    code: "GQ",
    currency: "Central African CFA franc",
    currency_code: "XAF",
    currency_symbol: "Fr",
    flag: "🇬🇶",
    flamobile_codeg: "+240"
  },
  {
    name: "Eritrea",
    code: "ER",
    currency: "Eritrean nakfa",
    currency_code: "ERN",
    currency_symbol: "Nfk",
    flag: "🇪🇷",
    flamobile_codeg: "+291"
  },
  {
    name: "Estonia",
    code: "EE",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇪🇪",
    flamobile_codeg: "+372"
  },
  {
    name: "Eswatini",
    code: "SZ",
    currency: "Swazi lilangeni",
    currency_code: "SZL",
    currency_symbol: "E",
    flag: "🇸🇿",
    flamobile_codeg: "+268"
  },
  {
    name: "Ethiopia",
    code: "ET",
    currency: "Ethiopian birr",
    currency_code: "ETB",
    currency_symbol: "Br",
    flag: "🇪🇹",
    flamobile_codeg: "+251"
  },
  {
    name: "Fiji",
    code: "FJ",
    currency: "Fijian dollar",
    currency_code: "FJD",
    currency_symbol: "$",
    flag: "🇫🇯",
    flamobile_codeg: "+679"
  },
  {
    name: "Finland",
    code: "FI",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇫🇮",
    flamobile_codeg: "+358"
  },
  {
    name: "France",
    code: "FR",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇫🇷",
    flamobile_codeg: "+33"
  },
  {
    name: "Gabon",
    code: "GA",
    currency: "Central African CFA franc",
    currency_code: "XAF",
    currency_symbol: "Fr",
    flag: "🇬🇦",
    flamobile_codeg: "+241"
  },
  {
    name: "Gambia",
    code: "GM",
    currency: "Gambian dalasi",
    currency_code: "GMD",
    currency_symbol: "D",
    flag: "🇬🇲",
    flamobile_codeg: "+220"
  },
  {
    name: "Georgia",
    code: "GE",
    currency: "Georgian lari",
    currency_code: "GEL",
    currency_symbol: "₾",
    flag: "🇬🇪",
    flamobile_codeg: "+995"
  },
  {
    name: "Germany",
    code: "DE",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇩🇪",
    flamobile_codeg: "+49"
  },
  {
    name: "Ghana",
    code: "GH",
    currency: "Ghanaian cedi",
    currency_code: "GHS",
    currency_symbol: "₵",
    flag: "🇬🇭",
    flamobile_codeg: "+233"
  },
  {
    name: "Greece",
    code: "GR",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇬🇷",
    flamobile_codeg: "+30"
  },
  {
    name: "Grenada",
    code: "GD",
    currency: "East Caribbean dollar",
    currency_code: "XCD",
    currency_symbol: "$",
    flag: "🇬🇩",
    flamobile_codeg: "+1-473"
  },
  {
    name: "Guatemala",
    code: "GT",
    currency: "Guatemalan quetzal",
    currency_code: "GTQ",
    currency_symbol: "Q",
    flag: "🇬🇹",
    flamobile_codeg: "+502"
  },
  {
    name: "Guinea",
    code: "GN",
    currency: "Guinean franc",
    currency_code: "GNF",
    currency_symbol: "Fr",
    flag: "🇬🇳",
    flamobile_codeg: "+224"
  },
  {
    name: "Guinea-Bissau",
    code: "GW",
    currency: "West African CFA franc",
    currency_code: "XOF",
    currency_symbol: "Fr",
    flag: "🇬🇼",
    flamobile_codeg: "+245"
  },
  {
    name: "Guyana",
    code: "GY",
    currency: "Guyanese dollar",
    currency_code: "GYD",
    currency_symbol: "$",
    flag: "🇬🇾",
    flamobile_codeg: "+592"
  },
  {
    name: "Haiti",
    code: "HT",
    currency: "Haitian gourde",
    currency_code: "HTG",
    currency_symbol: "G",
    flag: "🇭🇹",
    flamobile_codeg: "+509"
  },
  {
    name: "Honduras",
    code: "HN",
    currency: "Honduran lempira",
    currency_code: "HNL",
    currency_symbol: "L",
    flag: "🇭🇳",
    flamobile_codeg: "+504"
  },
  {
    name: "Hungary",
    code: "HU",
    currency: "Hungarian forint",
    currency_code: "HUF",
    currency_symbol: "Ft",
    flag: "🇭🇺",
    flamobile_codeg: "+36"
  },
  {
    name: "Iceland",
    code: "IS",
    currency: "Icelandic króna",
    currency_code: "ISK",
    currency_symbol: "kr",
    flag: "🇮🇸",
    flamobile_codeg: "+354"
  },
  {
    name: "India",
    code: "IN",
    currency: "Indian rupee",
    currency_code: "INR",
    currency_symbol: "₹",
    flag: "🇮🇳",
    flamobile_codeg: "+91"
  },
  {
    name: "Indonesia",
    code: "ID",
    currency: "Indonesian rupiah",
    currency_code: "IDR",
    currency_symbol: "Rp",
    flag: "🇮🇩",
    flamobile_codeg: "+62"
  },
  {
    name: "Iran",
    code: "IR",
    currency: "Iranian rial",
    currency_code: "IRR",
    currency_symbol: "﷼",
    flag: "🇮🇷",
    flamobile_codeg: "+98"
  },
  {
    name: "Iraq",
    code: "IQ",
    currency: "Iraqi dinar",
    currency_code: "IQD",
    currency_symbol: "ع.د",
    flag: "🇮🇶",
    flamobile_codeg: "+964"
  },
  {
    name: "Ireland",
    code: "IE",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇮🇪",
    flamobile_codeg: "+353"
  },
  {
    name: "Israel",
    code: "IL",
    currency: "Israeli new shekel",
    currency_code: "ILS",
    currency_symbol: "₪",
    flag: "🇮🇱",
    flamobile_codeg: "+972"
  },
  {
    name: "Italy",
    code: "IT",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇮🇹",
    flamobile_codeg: "+39"
  },
  {
    name: "Jamaica",
    code: "JM",
    currency: "Jamaican dollar",
    currency_code: "JMD",
    currency_symbol: "$",
    flag: "🇯🇲",
    flamobile_codeg: "+1-876"
  },
  {
    name: "Japan",
    code: "JP",
    currency: "Japanese yen",
    currency_code: "JPY",
    currency_symbol: "¥",
    flag: "🇯🇵",
    flamobile_codeg: "+81"
  },
  {
    name: "Jordan",
    code: "JO",
    currency: "Jordanian dinar",
    currency_code: "JOD",
    currency_symbol: "د.أ",
    flag: "🇯🇴",
    flamobile_codeg: "+962"
  },
  {
    name: "Kazakhstan",
    code: "KZ",
    currency: "Kazakhstani tenge",
    currency_code: "KZT",
    currency_symbol: "₸",
    flag: "🇰🇿",
    flamobile_codeg: "+7"
  },
  {
    name: "Kenya",
    code: "KE",
    currency: "Kenyan shilling",
    currency_code: "KES",
    currency_symbol: "Ksh",
    flag: "🇰🇪",
    flamobile_codeg: "+254"
  },
  {
    name: "Kiribati",
    code: "KI",
    currency: "Australian dollar",
    currency_code: "AUD",
    currency_symbol: "$",
    flag: "🇰🇷",
    flamobile_codeg: "+686"
  },
  {
    name: "Kuwait",
    code: "KW",
    currency: "Kuwaiti dinar",
    currency_code: "KWD",
    currency_symbol: "د.ك",
    flag: "🇰🇼",
    flamobile_codeg: "+965"
  },
  {
    name: "Kyrgyzstan",
    code: "KG",
    currency: "Kyrgyzstani som",
    currency_code: "KGS",
    currency_symbol: "с",
    flag: "🇰🇬",
    flamobile_codeg: "+996"
  },
  {
    name: "Laos",
    code: "LA",
    currency: "Lao kip",
    currency_code: "LAK",
    currency_symbol: "₭",
    flag: "🇱🇦",
    flamobile_codeg: "+856"
  },
  {
    name: "Latvia",
    code: "LV",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇱🇻",
    flamobile_codeg: "+371"
  },
  {
    name: "Lebanon",
    code: "LB",
    currency: "Lebanese pound",
    currency_code: "LBP",
    currency_symbol: "ل.ل",
    flag: "🇱🇧",
    flamobile_codeg: "+961"
  },
  {
    name: "Lesotho",
    code: "LS",
    currency: "Lesotho loti",
    currency_code: "LSL",
    currency_symbol: "L",
    flag: "🇱🇸",
    flamobile_codeg: "+266"
  },
  {
    name: "Liberia",
    code: "LR",
    currency: "Liberian dollar",
    currency_code: "LRD",
    currency_symbol: "$",
    flag: "🇱🇷",
    flamobile_codeg: "+231"
  },
  {
    name: "Libya",
    code: "LY",
    currency: "Libyan dinar",
    currency_code: "LYD",
    currency_symbol: "ل.د",
    flag: "🇱🇾",
    flamobile_codeg: "+218"
  },
  {
    name: "Liechtenstein",
    code: "LI",
    currency: "Swiss franc",
    currency_code: "CHF",
    currency_symbol: "CHF",
    flag: "🇱🇮",
    flamobile_codeg: "+423"
  },
  {
    name: "Lithuania",
    code: "LT",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇱🇹",
    flamobile_codeg: "+370"
  },
  {
    name: "Luxembourg",
    code: "LU",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇱🇺",
    flamobile_codeg: "+352"
  },
  {
    name: "Madagascar",
    code: "MG",
    currency: "Malagasy ariary",
    currency_code: "MGA",
    currency_symbol: "Ar",
    flag: "🇲🇬",
    flamobile_codeg: "+261"
  },
  {
    name: "Malawi",
    code: "MW",
    currency: "Malawian kwacha",
    currency_code: "MWK",
    currency_symbol: "K",
    flag: "🇲🇼",
    flamobile_codeg: "+265"
  },
  {
    name: "Malaysia",
    code: "MY",
    currency: "Malaysian ringgit",
    currency_code: "MYR",
    currency_symbol: "RM",
    flag: "🇲🇾",
    flamobile_codeg: "+60"
  },
  {
    name: "Maldives",
    code: "MV",
    currency: "Maldivian rufiyaa",
    currency_code: "MVR",
    currency_symbol: "Rf",
    flag: "🇲🇻",
    flamobile_codeg: "+960"
  },
  {
    name: "Mali",
    code: "ML",
    currency: "West African CFA franc",
    currency_code: "XOF",
    currency_symbol: "Fr",
    flag: "🇲🇱",
    flamobile_codeg: "+223"
  },
  {
    name: "Malta",
    code: "MT",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇲🇹",
    flamobile_codeg: "+356"
  },
  {
    name: "Marshall Islands",
    code: "MH",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇲🇭",
    flamobile_codeg: "+692"
  },
  {
    name: "Mauritania",
    code: "MR",
    currency: "Mauritanian ouguiya",
    currency_code: "MRU",
    currency_symbol: "UM",
    flag: "🇲🇦",
    flamobile_codeg: "+222"
  },
  {
    name: "Mauritius",
    code: "MU",
    currency: "Mauritian rupee",
    currency_code: "MUR",
    currency_symbol: "₨",
    flag: "🇲🇺",
    flamobile_codeg: "+230"
  },
  {
    name: "Mexico",
    code: "MX",
    currency: "Mexican peso",
    currency_code: "MXN",
    currency_symbol: "$",
    flag: "🇲🇽",
    flamobile_codeg: "+52"
  },
  {
    name: "Micronesia",
    code: "FM",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇫🇲",
    flamobile_codeg: "+691"
  },
  {
    name: "Moldova",
    code: "MD",
    currency: "Moldovan leu",
    currency_code: "MDL",
    currency_symbol: "L",
    flag: "🇲🇹",
    flamobile_codeg: "+373"
  },
  {
    name: "Monaco",
    code: "MC",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇲🇨",
    flamobile_codeg: "+377"
  },
  {
    name: "Mongolia",
    code: "MN",
    currency: "Mongolian tögrög",
    currency_code: "MNT",
    currency_symbol: "₮",
    flag: "🇲🇳",
    flamobile_codeg: "+976"
  },
  {
    name: "Montenegro",
    code: "ME",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇲🇪",
    flamobile_codeg: "+382"
  },
  {
    name: "Morocco",
    code: "MA",
    currency: "Moroccan dirham",
    currency_code: "MAD",
    currency_symbol: "د.م.",
    flag: "🇲🇦",
    flamobile_codeg: "+212"
  },
  {
    name: "Mozambique",
    code: "MZ",
    currency: "Mozambican metical",
    currency_code: "MZN",
    currency_symbol: "MT",
    flag: "🇲🇿",
    flamobile_codeg: "+258"
  },
  {
    name: "Myanmar (formerly Burma)",
    code: "MM",
    currency: "Myanmar kyat",
    currency_code: "MMK",
    currency_symbol: "K",
    flag: "🇲🇲",
    flamobile_codeg: "+95"
  },
  {
    name: "Namibia",
    code: "NA",
    currency: "Namibian dollar",
    currency_code: "NAD",
    currency_symbol: "$",
    flag: "🇳🇦",
    flamobile_codeg: "+264"
  },
  {
    name: "Nauru",
    code: "NR",
    currency: "Australian dollar",
    currency_code: "AUD",
    currency_symbol: "$",
    flag: "🇳🇷",
    flamobile_codeg: "+674"
  },
  {
    name: "Nepal",
    code: "NP",
    currency: "Nepalese rupee",
    currency_code: "NPR",
    currency_symbol: "Rs",
    flag: "🇳🇵",
    flamobile_codeg: "+977"
  },
  {
    name: "Netherlands",
    code: "NL",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇳🇱",
    flamobile_codeg: "+31"
  },
  {
    name: "New Zealand",
    code: "NZ",
    currency: "New Zealand dollar",
    currency_code: "NZD",
    currency_symbol: "$",
    flag: "🇳🇿",
    flamobile_codeg: "+64"
  },
  {
    name: "Nicaragua",
    code: "NI",
    currency: "Nicaraguan córdoba",
    currency_code: "NIO",
    currency_symbol: "C$",
    flag: "🇳🇮",
    flamobile_codeg: "+505"
  },
  {
    name: "Niger",
    code: "NE",
    currency: "West African CFA franc",
    currency_code: "XOF",
    currency_symbol: "Fr",
    flag: "🇳🇪",
    flamobile_codeg: "+227"
  },
  {
    name: "Nigeria",
    code: "NG",
    currency: "Nigerian naira",
    currency_code: "NGN",
    currency_symbol: "₦",
    flag: "🇳🇬",
    flamobile_codeg: "+234"
  },
  {
    name: "North Macedonia",
    code: "MK",
    currency: "Macedonian denar",
    currency_code: "MKD",
    currency_symbol: "ден",
    flag: "🇲🇰",
    flamobile_codeg: "+389"
  },
  {
    name: "Norway",
    code: "NO",
    currency: "Norwegian krone",
    currency_code: "NOK",
    currency_symbol: "kr",
    flag: "🇳🇴",
    flamobile_codeg: "+47"
  },
  {
    name: "Oman",
    code: "OM",
    currency: "Omani rial",
    currency_code: "OMR",
    currency_symbol: "ر.ع.",
    flag: "🇴🇲",
    flamobile_codeg: "+968"
  },
  {
    name: "Pakistan",
    code: "PK",
    currency: "Pakistani rupee",
    currency_code: "PKR",
    currency_symbol: "Rs",
    flag: "🇵🇰",
    flamobile_codeg: "+92"
  },
  {
    name: "Palau",
    code: "PW",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇵🇼",
    flamobile_codeg: "+680"
  },
  {
    name: "Palestine, State of",
    code: "PS",
    currency: "Israeli new shekel",
    currency_code: "ILS",
    currency_symbol: "₪",
    flag: "🇵🇸",
    flamobile_codeg: "+970"
  },
  {
    name: "Panama",
    code: "PA",
    currency: "Panamanian balboa",
    currency_code: "PAB",
    currency_symbol: "B/.",
    flag: "🇵🇦",
    flamobile_codeg: "+507"
  },
  {
    name: "Papua New Guinea",
    code: "PG",
    currency: "Papua New Guinean kina",
    currency_code: "PGK",
    currency_symbol: "K",
    flag: "🇵🇬",
    flamobile_codeg: "+675"
  },
  {
    name: "Paraguay",
    code: "PY",
    currency: "Paraguayan guaraní",
    currency_code: "PYG",
    currency_symbol: "₲",
    flag: "🇵🇾",
    flamobile_codeg: "+595"
  },
  {
    name: "Peru",
    code: "PE",
    currency: "Peruvian sol",
    currency_code: "PEN",
    currency_symbol: "S/.",
    flag: "🇵🇪",
    flamobile_codeg: "+51"
  },
  {
    name: "Philippines",
    code: "PH",
    currency: "Philippine peso",
    currency_code: "PHP",
    currency_symbol: "₱",
    flag: "🇵🇭",
    flamobile_codeg: "+63"
  },
  {
    name: "Poland",
    code: "PL",
    currency: "Polish złoty",
    currency_code: "PLN",
    currency_symbol: "zł",
    flag: "🇵🇱",
    flamobile_codeg: "+48"
  },
  {
    name: "Portugal",
    code: "PT",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇵🇹",
    flamobile_codeg: "+351"
  },
  {
    name: "Qatar",
    code: "QA",
    currency: "Qatari riyal",
    currency_code: "QAR",
    currency_symbol: "ر.ق",
    flag: "🇶🇦",
    flamobile_codeg: "+974"
  },
  {
    name: "Romania",
    code: "RO",
    currency: "Romanian leu",
    currency_code: "RON",
    currency_symbol: "lei",
    flag: "🇷🇴",
    flamobile_codeg: "+40"
  },
  {
    name: "Russia",
    code: "RU",
    currency: "Russian ruble",
    currency_code: "RUB",
    currency_symbol: "₽",
    flag: "🇷🇺",
    flamobile_codeg: "+7"
  },
  {
    name: "Rwanda",
    code: "RW",
    currency: "Rwandan franc",
    currency_code: "RWF",
    currency_symbol: "Fr",
    flag: "🇷🇼",
    flamobile_codeg: "+250"
  },
  {
    name: "Saint Kitts and Nevis",
    code: "KN",
    currency: "East Caribbean dollar",
    currency_code: "XCD",
    currency_symbol: "$",
    flag: "🇰🇳",
    flamobile_codeg: "+1-869"
  },
  {
    name: "Saint Lucia",
    code: "LC",
    currency: "East Caribbean dollar",
    currency_code: "XCD",
    currency_symbol: "$",
    flag: "🇱🇨",
    flamobile_codeg: "+1-758"
  },
  {
    name: "Saint Vincent and the Grenadines",
    code: "VC",
    currency: "East Caribbean dollar",
    currency_code: "XCD",
    currency_symbol: "$",
    flag: "🇻🇨",
    flamobile_codeg: "+1-784"
  },
  {
    name: "Samoa",
    code: "WS",
    currency: "Samoan tala",
    currency_code: "WST",
    currency_symbol: "T",
    flag: "🇼🇸",
    flamobile_codeg: "+685"
  },
  {
    name: "San Marino",
    code: "SM",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇸🇲",
    flamobile_codeg: "+378"
  },
  {
    name: "Sao Tome and Principe",
    code: "ST",
    currency: "Sao Tome and Principe dobra",
    currency_code: "STN",
    currency_symbol: "Db",
    flag: "🇸🇹",
    flamobile_codeg: "+239"
  },
  {
    name: "Saudi Arabia",
    code: "SA",
    currency: "Saudi riyal",
    currency_code: "SAR",
    currency_symbol: "ر.س",
    flag: "🇸🇦",
    flamobile_codeg: "+966"
  },
  {
    name: "Senegal",
    code: "SN",
    currency: "West African CFA franc",
    currency_code: "XOF",
    currency_symbol: "Fr",
    flag: "🇸🇳",
    flamobile_codeg: "+221"
  },
  {
    name: "Serbia",
    code: "RS",
    currency: "Serbian dinar",
    currency_code: "RSD",
    currency_symbol: "дин.",
    flag: "🇷🇸",
    flamobile_codeg: "+381"
  },
  {
    name: "Seychelles",
    code: "SC",
    currency: "Seychellois rupee",
    currency_code: "SCR",
    currency_symbol: "₨",
    flag: "🇸🇨",
    flamobile_codeg: "+248"
  },
  {
    name: "Sierra Leone",
    code: "SL",
    currency: "Sierra Leonean leone",
    currency_code: "SLL",
    currency_symbol: "Le",
    flag: "🇸🇱",
    flamobile_codeg: "+232"
  },
  {
    name: "Singapore",
    code: "SG",
    currency: "Singapore dollar",
    currency_code: "SGD",
    currency_symbol: "$",
    flag: "🇸🇬",
    flamobile_codeg: "+65"
  },
  {
    name: "Slovakia",
    code: "SK",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇸🇰",
    flamobile_codeg: "+421"
  },
  {
    name: "Slovenia",
    code: "SI",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇸🇮",
    flamobile_codeg: "+386"
  },
  {
    name: "Solomon Islands",
    code: "SB",
    currency: "Solomon Islands dollar",
    currency_code: "AUD",
    currency_symbol: "$",
    flag: "🇸🇧",
    flamobile_codeg: "+677"
  },
  {
    name: "Somalia",
    code: "SO",
    currency: "Somali shilling",
    currency_code: "SOS",
    currency_symbol: "S",
    flag: "🇸🇴",
    flamobile_codeg: "+252"
  },
  {
    name: "South Africa",
    code: "ZA",
    currency: "South African rand",
    currency_code: "ZAR",
    currency_symbol: "R",
    flag: "🇿🇦",
    flamobile_codeg: "+27"
  },
  {
    name: "South Korea",
    code: "KR",
    currency: "South Korean won",
    currency_code: "KRW",
    currency_symbol: "₩",
    flag: "🇰🇷",
    flamobile_codeg: "+82"
  },
  {
    name: "South Sudan",
    code: "SS",
    currency: "South Sudanese pound",
    currency_code: "SSP",
    currency_symbol: "£",
    flag: "🇸🇸",
    flamobile_codeg: "+211"
  },
  {
    name: "Spain",
    code: "ES",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇪🇸",
    flamobile_codeg: "+34"
  },
  {
    name: "Sri Lanka",
    code: "LK",
    currency: "Sri Lankan rupee",
    currency_code: "LKR",
    currency_symbol: "Rs",
    flag: "🇱🇰",
    flamobile_codeg: "+94"
  },
  {
    name: "Sudan",
    code: "SD",
    currency: "Sudanese pound",
    currency_code: "SDG",
    currency_symbol: "£",
    flag: "🇸🇩",
    flamobile_codeg: "+249"
  },
  {
    name: "Suriname",
    code: "SR",
    currency: "Surinamese dollar",
    currency_code: "SRD",
    currency_symbol: "$",
    flag: "🇸🇷",
    flamobile_codeg: "+597"
  },
  {
    name: "Sweden",
    code: "SE",
    currency: "Swedish krona",
    currency_code: "SEK",
    currency_symbol: "kr",
    flag: "🇸🇪",
    flamobile_codeg: "+46"
  },
  {
    name: "Switzerland",
    code: "CH",
    currency: "Swiss franc",
    currency_code: "CHF",
    currency_symbol: "CHF",
    flag: "🇨🇭",
    flamobile_codeg: "+41"
  },
  {
    name: "Syria",
    code: "SY",
    currency: "Syrian pound",
    currency_code: "SYP",
    currency_symbol: "£",
    flag: "🇸🇾",
    flamobile_codeg: "+963"
  },
  {
    name: "Taiwan",
    code: "TW",
    currency: "New Taiwan dollar",
    currency_code: "TWD",
    currency_symbol: "NT$",
    flag: "🇹🇼",
    flamobile_codeg: "+886"
  },
  {
    name: "Tajikistan",
    code: "TJ",
    currency: "Tajikistani somoni",
    currency_code: "TJS",
    currency_symbol: "SM",
    flag: "🇹🇯",
    flamobile_codeg: "+992"
  },
  {
    name: "Tanzania",
    code: "TZ",
    currency: "Tanzanian shilling",
    currency_code: "TZS",
    currency_symbol: "TSh",
    flag: "🇹🇿",
    flamobile_codeg: "+255"
  },
  {
    name: "Thailand",
    code: "TH",
    currency: "Thai baht",
    currency_code: "THB",
    currency_symbol: "฿",
    flag: "🇹🇭",
    flamobile_codeg: "+66"
  },
  {
    name: "Timor-Leste",
    code: "TL",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇹🇱",
    flamobile_codeg: "+670"
  },
  {
    name: "Togo",
    code: "TG",
    currency: "West African CFA franc",
    currency_code: "XOF",
    currency_symbol: "Fr",
    flag: "🇹🇬",
    flamobile_codeg: "+228"
  },
  {
    name: "Tonga",
    code: "TO",
    currency: "Tongan paʻanga",
    currency_code: "TOP",
    currency_symbol: "T$",
    flag: "🇹🇴",
    flamobile_codeg: "+676"
  },
  {
    name: "Trinidad and Tobago",
    code: "TT",
    currency: "Trinidad and Tobago dollar",
    currency_code: "TTD",
    currency_symbol: "$",
    flag: "🇹🇹",
    flamobile_codeg: "+1-868"
  },
  {
    name: "Tunisia",
    code: "TN",
    currency: "Tunisian dinar",
    currency_code: "TND",
    currency_symbol: "د.ت",
    flag: "🇹🇳",
    flamobile_codeg: "+216"
  },
  {
    name: "Turkey",
    code: "TR",
    currency: "Turkish lira",
    currency_code: "TRY",
    currency_symbol: "₺",
    flag: "🇹🇷",
    flamobile_codeg: "+90"
  },
  {
    name: "Turkmenistan",
    code: "TM",
    currency: "Turkmenistani manat",
    currency_code: "TMT",
    currency_symbol: "m",
    flag: "🇹🇲",
    flamobile_codeg: "+993"
  },
  {
    name: "Tuvalu",
    code: "TV",
    currency: "Australian dollar",
    currency_code: "AUD",
    currency_symbol: "$",
    flag: "🇹🇻",
    flamobile_codeg: "+688"
  },
  {
    name: "Uganda",
    code: "UG",
    currency: "Ugandan shilling",
    currency_code: "UGX",
    currency_symbol: "USh",
    flag: "🇺🇬",
    flamobile_codeg: "+256"
  },
  {
    name: "Ukraine",
    code: "UA",
    currency: "Ukrainian hryvnia",
    currency_code: "UAH",
    currency_symbol: "₴",
    flag: "🇺🇦",
    flamobile_codeg: "+380"
  },
  {
    name: "United Arab Emirates",
    code: "AE",
    currency: "United Arab Emirates dirham",
    currency_code: "AED",
    currency_symbol: "د.إ",
    flag: "🇦🇪",
    flamobile_codeg: "+971"
  },
  {
    name: "United Kingdom",
    code: "GB",
    currency: "British pound sterling",
    currency_code: "GBP",
    currency_symbol: "£",
    flag: "🇬🇧",
    flamobile_codeg: "+44"
  },
  {
    name: "United States",
    code: "US",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇺🇸",
    flamobile_codeg: "+1"
  },
  {
    name: "Uruguay",
    code: "UY",
    currency: "Uruguayan peso",
    currency_code: "UYU",
    currency_symbol: "$U",
    flag: "🇺🇾",
    flamobile_codeg: "+598"
  },
  {
    name: "Uzbekistan",
    code: "UZ",
    currency: "Uzbekistani som",
    currency_code: "UZS",
    currency_symbol: "лв",
    flag: "🇺🇿",
    flamobile_codeg: "+998"
  },
  {
    name: "Vanuatu",
    code: "VU",
    currency: "Vanuatu vatu",
    currency_code: "VUV",
    currency_symbol: "Vt",
    flag: "🇻🇺",
    flamobile_codeg: "+678"
  },
  {
    name: "Vatican City",
    code: "VA",
    currency: "Euro",
    currency_code: "EUR",
    currency_symbol: "€",
    flag: "🇻🇦",
    flamobile_codeg: "+39"
  },
  {
    name: "Venezuela",
    code: "VE",
    currency: "Venezuelan bolívar",
    currency_code: "VES",
    currency_symbol: "Bs.",
    flag: "🇻🇪",
    flamobile_codeg: "+58"
  },
  {
    name: "Vietnam",
    code: "VN",
    currency: "Vietnamese đồng",
    currency_code: "VND",
    currency_symbol: "₫",
    flag: "🇻🇳",
    flamobile_codeg: "+84"
  },
  {
    name: "Yemen",
    code: "YE",
    currency: "Yemeni rial",
    currency_code: "YER",
    currency_symbol: "﷼",
    flag: "🇾🇪",
    flamobile_codeg: "+967"
  },
  {
    name: "Zambia",
    code: "ZM",
    currency: "Zambian kwacha",
    currency_code: "ZMW",
    currency_symbol: "K",
    flag: "🇿🇲",
    flamobile_codeg: "+260"
  },
  {
    name: "Zimbabwe",
    code: "ZW",
    currency: "United States dollar",
    currency_code: "USD",
    currency_symbol: "$",
    flag: "🇿🇼",
    flamobile_codeg: "+263"
  }
]
;

export default currency;
