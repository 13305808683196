import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import "amazon-connect-streams";

interface CCPContextProps {
  showCCP: boolean;
  initializeCCP: () => void;
}

const CCPContext = createContext<CCPContextProps | undefined>(undefined);

export const useCCP = (): CCPContextProps => {
  const context = useContext(CCPContext);
  if (!context) {
    throw new Error("useCCP must be used within a CCPProvider");
  }
  return context;
};

export const CCPProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [showCCP, setShowCCP] = useState(false);

  const initializeCCP = () => {
    setShowCCP(true);
  };

  useEffect(() => {
    if (showCCP) {
      const ccpContainer = document.getElementById("ccp-container");

      if (ccpContainer) {
        connect.core.initCCP(ccpContainer, {
          ccpUrl: "https://demo-zatest.my.connect.aws/ccp-v2/softphone",            // REQUIRED
          loginPopup: true,               // optional, defaults to `true`
          loginPopupAutoClose: true,      // optional, defaults to `false`
          loginOptions: {                 // optional, if provided opens login in new window
            autoClose: true,              // optional, defaults to `false`
            height: 600,                  // optional, defaults to 578
            width: 400,                   // optional, defaults to 433
            top: 0,                       // optional, defaults to 0
            left: 0                       // optional, defaults to 0
          },
          region: "eu-west-2",         // REQUIRED for `CHAT`, optional otherwise
          softphone: {                    // optional, defaults below apply if not provided
            allowFramedSoftphone: true,   // optional, defaults to false
            disableRingtone: false,       // optional, defaults to false
            ringtoneUrl: "", // optional, defaults to CCP’s default ringtone if a falsy value is set
            allowFramedVideoCall: true,    // optional, default to false
            allowEarlyGum: true    //optional, default to true
          },
          task: {
            disableRingtone: false, // optional, defaults to false
            ringtoneUrl: "", // optional, defaults to CCP's default ringtone if a falsy value is set
          },
          pageOptions: { //optional
            enableAudioDeviceSettings: false, //optional, defaults to 'false'
            enableVideoDeviceSettings: false, //optional, defaults to 'false'
            enablePhoneTypeSettings: true //optional, defaults to 'true' 
          },
          ccpAckTimeout: 3000, //optional, defaults to 3000 (ms)
          ccpSynTimeout: 1000, //optional, defaults to 1000 (ms)
          ccpLoadTimeout: 500 //optional, defaults to 5000 (ms)
         });

        connect.contact((contact) => {
          if (contact.getType() === connect.ContactType.VOICE) {
            contact.onConnected(() => {
            });

            contact.onEnded(() => {
            });
          }
        });
      } else {
        console.error("CCP container element not found");
      }
    }
  }, [showCCP]);

  return (
    <CCPContext.Provider value={{ showCCP, initializeCCP }}>
      {children}
      {showCCP && (
        <div id="ccp-container" style={{display: "none"}}>
          
        </div>
      )}
    </CCPContext.Provider>
  );
};
