import React, { useEffect, useState } from "react";
import {
    Col,
    Input,
    Row,
    Spinner
} from "reactstrap";

import FeatherIcon from 'feather-icons-react';

interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}

interface Step3Props {
    toggleArrowTab: () => void;
    inputs: SignUpState;
    setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
    onCreate: () => void;
    t: (key: string) => string;
    OnUpdate: (object: any) => void;
    loading: boolean;
}

const Step3: React.FC<Step3Props> = ({ toggleArrowTab, inputs, setInputs, props, onCreate, t, OnUpdate, loading }: any) => {
    const [passwordShow, setPasswordShow] = useState<boolean>(false);
    const [disabled, setDisabled] = useState<boolean>(false);
    const islen = () => {
        let str = inputs.password;
        if (str && str.length >= 8) return true
        else return false
    }

    useEffect(() => {
        setDisabled(islen() && inputs.password !== "" && /[a-z]/.test(inputs.password) &&
            inputs.password !== "" && /[A-Z]/.test(inputs.password) &&
            inputs.password !== "" && /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(inputs.password))
    }, [inputs.password])

    const onClickUpdate = () => {
        const attributes = [
            {
                "key": "password",
                "value": inputs.password
            }
        ]
        OnUpdate(attributes)
    }

    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex flex-column justify-content-center">
                    <div className="hstack fs-32 mb-1 mt-2 fw-bold justify-content-center">
                        <h1 className="fw-bold">{ t("signup.create_your_password")}</h1>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="hstack justify-content-center">
                        <Input
                            type={passwordShow ? "text" : "password"}
                            className="form-control w-100"
                            id="steparrow-gen-info-email-input"
                            placeholder={ t("signup.enter_password")}
                            onKeyDown={(e) => {
                                if (e.key === 'Enter') {
                                  e.preventDefault();
                                }
                              }}
                            onChange={(e) => setInputs({ ...inputs, password: e.target.value })}
                        />
                        {passwordShow ? <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye" className="m-1 icon-xs icon-dual-info" /></button> :
                            <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><FeatherIcon icon="eye-off" className="m-1 icon-xs icon-dual-info" /></button>}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="fs-14 p-1">
                        {islen() ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                        {" "}{ t("signup.at_least_8_characters")}.
                    </div>
                    <div className="fs-14 p-1">
                        {inputs.password !== "" && /[a-z]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                        {" "}{ t("signup.one_lower_case")}.</div>
                    <div className="fs-14 p-1">
                        {inputs.password !== "" && /[A-Z]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                        {" "}{ t("signup.one_upper_case")}.
                    </div>
                    <div className="fs-14 p-1">
                        {inputs.password !== "" && /[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/? ]/.test(inputs.password) ? <FeatherIcon icon="check-circle" className="icon-xs icon-dual-success" /> : <FeatherIcon icon="x-circle" className="icon-xs icon-dual-danger" />}
                        {" "}{ t("signup.one_number_symbols_character")}.
                    </div>
                </Col>
                <Col lg={4}>
                    <button type="button" className="w-100 btn btn-primary btn-icon waves-effect waves-light" onClick={() =>{onClickUpdate();}} disabled={!disabled} >{loading ?  <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                                Loading...
                            </span>
                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                        </span> :  t("signup.next")}</button>{" "}
                </Col>
            </Row>
        </div>
    );
};

export default Step3;
