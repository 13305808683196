import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import Form from "Components/Common/FormBuilder/QuickForm";
import { productsMetadataGetRequest } from "store/products/action";
import SimpleBar from "simplebar-react";
import { isJson } from "utils";


const ProductInfo = ({ fields, product,  OnAddEntry, disabledBtn }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [disabled, setDisabled] = useState<any>(false)
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [statusMetaData, setStatusMetaData] = useState<any>([]);
    const [edit, setEdit] = useState<any>(false);
    const [data, setData] = useState<any>({});
   
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(productsMetadataGetRequest(filters));
    }, [])

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setEdit(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.products;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(false) : setEdit(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const handleViewAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    }
    
    const handleEditAccess = (permissionJson: any) => {
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;
        const permissions = permissionJson?.permissions;
        if (userRole === "Owner") return true
        else if (permissions) {
            switch (permissions) {
                case "Owner":
                case "owner": if (userRole === "Owner") return true;
                else return false;
                case "view": return false;
                case "view_edit": return true;
                case "role_based": const items = permissionJson.item || [];
                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)
                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view_edit"));
                    return isview && isview.length;
                default: return false;
            }

        }
    };

    useEffect(() => {
        let newstatusMetaData: any = []
        fields && fields.map((field: any) => {
            let permissionsJson = field?.permissionsJson && isJson(field?.permissionsJson) ? JSON.parse(field?.permissionsJson) : {}
            let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleViewAccess(permissionsJson) : true;
            let isFieldEditaccess = permissionsJson && permissionsJson.permissions ? handleEditAccess(permissionsJson) : true;
            if (isFieldviewaccess) {
                if (isFieldEditaccess) {
                    let newField = { ...field, isEditAccess: true }
                    return newstatusMetaData.push(newField)
                }
                return newstatusMetaData.push(field)
            }
        })
        setStatusMetaData(newstatusMetaData)
    }, [fields])

    const handleselectValue = (value: any, options: any) => {
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
    }

    useEffect(() => {
        if(product && product.id) {
            let productDetails = product.valuesJson;
            let neewData: any = {}
            statusMetaData && statusMetaData.map((item: any) => {
                let field = item?.valuesJson && isJson(item?.valuesJson) ? JSON.parse(item?.valuesJson) : {}
                let rendervalue = productDetails[field.key];
                if (field.type == 'select' || field.type == 'radio' || field.type == 'checboxes') {
                    rendervalue = handleselectValue(productDetails?.[field.key], field.values);
                }
                else if (field.optionLabel) {
                    rendervalue = productDetails?.[field.key] && productDetails?.[field.key]?.[field.optionLabel] ? productDetails?.[field.key]?.[field.optionLabel] : 'NA'
                }
                neewData[field.key]= rendervalue;
            })
            setData({...neewData,id:product.id, productId:product.id})
        }
    }, [product, statusMetaData])
    
    return (
        <div>
			<div className="live-preview vstack gap-2">
				<div className="live-preview vstack gap-2">
					<SimpleBar autoHide={false} className="simplebar-track-dark pe-2" style={{ maxHeight: "500px" }}>
						<Form btn_text={"Add Entry"} dataFields={statusMetaData} btnAction={OnAddEntry} showbtn={false} data={data} formfor={"products"} fields={fields} isEditAccess={edit}  btndisabled={disabledBtn}/>
					</SimpleBar>
				</div>
			</div>
        </div>

    );
};

export default ProductInfo;
