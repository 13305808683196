import React, { useState, FormEvent, ChangeEvent } from "react";
import { Row, Col, Alert, Card, CardBody, Container, Input, Label, Form } from "reactstrap";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import ParticlesAuth from "./ParticlesAuth";
import logo from "../../assets/images/dtsimg/svg/zilter_logo_white.svg";
import { withTranslation, WithTranslation } from 'react-i18next';
import { forgotRequest } from "store/auth/action";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { useNavigate } from 'react-router-dom';
import ForgetSuccess from "./ForgetSuccess";
import { ToastContainer, toast } from "react-toastify";

interface FormData {
    email: string;
}

const ForgetPasswordPage: React.FC<WithTranslation> = (props) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [isConfirm, setIsConfirm] = useState<boolean>(false);

    const [inputs, setInputs] = useState<FormData>({
        email: ''
    });

    const handleInputChange = (e: ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setInputs((prevData) => ({ ...prevData, [name]: value }));
    };


    const onSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (inputs.email) {
            const data = {
                "attributes": [
                    {
                        "key": "email",
                        "value": inputs.email
                    }
                ]
            }
            const handleSuccess = (body: any): void => {
                setIsConfirm(true)
                const message = () => toast(`Reset Link Sent Successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
            }
            const handleError = (body: any): void => {
                const message = () => toast(`Reset Link Sent Successfully.`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
            }
            dispatch(forgotRequest(data, handleSuccess, handleError));
        }
    };


    document.title = "Forgot Password | Zilter";
    return (
        <ParticlesAuth>
            <ToastContainer />
            <div className="auth-page-content">
                {
                    isConfirm ?
                        <ForgetSuccess onSubmit={onSubmit} inputs={inputs} />
                        :
                        <Container>
                            <Row>
                                <Col lg={12}>
                                    <div className="text-center mt-sm-5 mb-4 text-white-50">
                                        <div>
                                            <Link to="/" className="d-inline-block auth-logo">
                                                <img src={logo} alt="" height="50" />
                                            </Link>
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row className="justify-content-center">
                                <Col md={8} lg={6} xl={5}>
                                    <Card className="mt-4">
                                        <CardBody className="p-4">
                                            <div className="text-center mt-2">
                                                <h5 className="text-primary">{props.t("forgot.forgot_password")}</h5>
                                                <p className="text-muted">{props.t("forgot.reset_password_with_zilter")}</p>
                                                <i className="ri-mail-send-line display-5 text-success mb-3"></i>
                                            </div>
                                            <Alert className="border-0 alert-warning text-center mb-2 mx-2" role="alert">
                                                {props.t("forgot.enter_your_email_and_instructions_will_be_sent_to_you")}
                                            </Alert>
                                            <div className="p-2">
                                                <Form onSubmit={onSubmit}>
                                                    <div className="mb-4">
                                                        <Label className="form-label">{props.t("forgot.email")}</Label>
                                                        <Input
                                                            name="email"
                                                            className="form-control"
                                                            placeholder={props.t("forgot.enter_email")}
                                                            type="email"
                                                            value={inputs.email}
                                                            onChange={handleInputChange}
                                                        />
                                                        {inputs.email !== "" && !/^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(inputs.email) ? (
                                                            <div className="text-danger fs-14 fw-medium">{props.t("signup.please_enter_valid_email_address")}</div>
                                                        ) : null}
                                                    </div>
                                                    <div className="text-center mt-4">
                                                        <button className="btn btn-success w-100" type="submit" disabled={!inputs.email}>{props.t("forgot.send_reset_link")}</button>
                                                    </div>
                                                </Form>
                                            </div>
                                        </CardBody>
                                    </Card>
                                    <div className="mt-4 text-center">
                                        <p className="mb-0">{props.t("forgot.comment")} <Link to="/signin" className="fw-semibold text-primary text-decoration-underline">{props.t("forgot.click_here")}</Link> </p>
                                    </div>
                                </Col>
                            </Row>
                        </Container>
                }
            </div>
        </ParticlesAuth>
    );
};

export default withTranslation()(ForgetPasswordPage);
