import React from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import logoLight from "../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';

interface JoinErrorProps {
    t: (key: string) => string;
}

const JoinError: React.FC<JoinErrorProps> = ({ t }) => {
    document.title = "Create New Password | Zilter";

    return (
        <div className="auth-page-content mt-4 pt-5">
            <Container className="mt-4">
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">
                            <CardBody className="p-4">
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                                            <div>
                                                <Link to="/#" className="d-inline-block auth-logo">
                                                    <img src={logoLight} alt="" height="50" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-2">
                                    <FeatherIcon icon="x-circle" className="icon-xxl icon-dual-danger" />
                                    <h5 className="text-danger fw-bold fs-28 mb-2 mt-4">Sorry, your link is expired!</h5>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default withTranslation()(JoinError);