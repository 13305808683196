import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import FeatherIcon from "feather-icons-react";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const DeleteModal = ({ props, show, onCloseClick, data, onDelete, record, t }: any) => {
    const [files, setFiles] = useState<any>([]);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    function handleAcceptedFiles(files: any) {
        files.map((file: any) => {
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        }
        );
        setselectedFiles(files);
    }
    /**
     * Formats the size
     */
    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    return (
        <Modal id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>        
            <ModalBody className='text-center p-5'>
                <div className="mt-2 text-center">
                    <i className="ri-delete-bin-5-line display-5 text-danger"></i>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>{t("roles.delete_disclaimer1")}</h4>
                        <p className="text-muted mx-4 mb-0">
                            {t("roles.delete_disclaimer2")}                            
                        </p>
                    </div>
                </div>
                <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        type="button"
                        className="btn w-sm btn-light"
                        onClick={() => onCloseClick()}
                    >
                        {t("roles.close")}   
                    </button>
                    <button type="button" className="btn w-sm btn-danger" onClick={() => onDelete()}>
                        {t("roles.delete")}   
                    </button>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default DeleteModal;