import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
    ReactFlow,
    Controls,
    Background,
    addEdge,
    applyEdgeChanges,
    applyNodeChanges,
} from '@xyflow/react';
import { withTranslation } from "react-i18next";
import { Card, CardBody, Input, Label } from "reactstrap";
import Node from "./Node";
import { COLORS } from "helpers/colors";



interface WorkflowProps {
    statuses: any,
    t: (key: string) => string;
    selectedProcessFlow: any,
    onUpdateProcessFlow: (processFlow: any, timeOut?: number) => void;
}

const Workflow = ({ t, statuses, selectedProcessFlow, onUpdateProcessFlow }: WorkflowProps) => {
    const [active, setActive] = useState<boolean>(false)
    const [nodes, setNodes] = useState<any[]>([])
    const [edges, setEdges] = useState<any[]>([])
    useEffect(() => {
        const nodes = statuses.map((status: any, index: number) => {
            const nodes = selectedProcessFlow?.configuration?.workflow?.flow?.nodes ?? [];
            const selectedNode = nodes.find((node: any) => node.id === status.value) ?? null;
            if(selectedNode) {
                return {...selectedNode, data: { ...status, color: COLORS[index] }};
            } else {
                return {
                    id: status.value,
                    type: 'statusUpdater',
                    position: { x: 500, y: index * 100 },
                    data: { ...status, color: COLORS[index] }
                }
            }
        })
        setNodes(nodes)
        const edges = selectedProcessFlow?.configuration?.workflow?.flow?.edges ?? [];
        setEdges(edges)
        setActive(selectedProcessFlow?.configuration?.workflow?.status || false)
    }, [statuses, selectedProcessFlow])
    const onSetActive = () => {
        setActive(prev => {
            selectedProcessFlow.configuration.workflow.status = !prev;
            onUpdateProcessFlow(selectedProcessFlow)
            return !prev
        })
        setActive(!active)
    }
    const onNodesChange = useCallback(
        (changes: any) => setNodes((nds) => {
            const nodes = applyNodeChanges(changes, nds);
            selectedProcessFlow.configuration.workflow.flow.nodes = nodes;
            onUpdateProcessFlow(selectedProcessFlow, 1200);
            return nodes;
        }),
        [setNodes, selectedProcessFlow]
    );
    const onEdgesChange = useCallback(
        (changes: any) => setEdges((eds) => {
            const edges = applyEdgeChanges(changes, eds);
            selectedProcessFlow.configuration.workflow.flow.edges = edges;
            onUpdateProcessFlow(selectedProcessFlow, 1200);
            return edges;
        }),
        [setEdges, selectedProcessFlow],
    );
    const onConnect = useCallback(
        (connection: any) => setEdges((eds: any) => {
            const edges = addEdge({ ...connection, animated: true }, eds)
            selectedProcessFlow.configuration.workflow.flow.edges = edges;
            onUpdateProcessFlow(selectedProcessFlow, 1200);
            return edges;
        }),
        [setEdges, selectedProcessFlow],
    );
    const nodeTypes = useMemo(() => ({ statusUpdater: Node }), []);
    return (
        <Card>
            <CardBody className="border border-dashed border-end-0 border-start-0 vstack gap-2">
                <h5>
                    {t('properties.process_flow.common.configuration.workflow.workflow_configuration')}
                </h5>
                <p>{t('properties.process_flow.common.configuration.workflow.heading_line')}</p>
                <div className="form-check form-switch form-switch-md" dir="ltr">
                    <Input
                        type="checkbox"
                        className="form-check-input"
                        checked={active}
                        onChange={() => onSetActive()}
                    />
                    <div className="d-flex gap-2">
                        <Label className="form-check-label fw-bold m-0">
                            {t('properties.process_flow.common.configuration.workflow.activate_workflow')}
                        </Label>
                        <span className="text-muted small mark">
                            <abbr title={t('properties.process_flow.common.configuration.workflow.activate_workflow_helper')}>
                                {t('properties.process_flow.common.configuration.workflow.activate_workflow_helper')}
                            </abbr>
                        </span>
                    </div>
                </div>
                <div className="position-relative" style={{ height: '600px' }}>
                    <ReactFlow
                        nodes={nodes}
                        edges={edges}
                        panOnScroll
                        nodeTypes={nodeTypes}
                        onNodesChange={onNodesChange}
                        onEdgesChange={onEdgesChange}
                        onConnect={onConnect}
                        fitView
                        colorMode="dark"
                    >
                        <Background className="bg-light" />
                        <Controls />
                    </ReactFlow>
                    {active ? null : <div className="position-absolute bg-dark bg-opacity-10 h-100 w-100 top-0"></div>}
                </div>
            </CardBody>
        </Card>
    )
};

export default withTranslation()(Workflow);