// filterUtil.ts

export function validateFilterString(filterString: string, maxNumber: number): string | null {
    // Function to check if parentheses are balanced
    const isBalanced = (str: string): boolean => {
        let balance = 0;
        for (const char of str) {
            if (char === '(') balance++;
            if (char === ')') balance--;
            if (balance < 0) return false;
        }
        return balance === 0;
    };

    // Function to tokenize the filter string
    const tokenize = (str: string): string[] => {
        // Replace spaces around parentheses and split the string into tokens
        return str
            .replace(/\s*\(\s*/g, ' ( ')
            .replace(/\s*\)\s*/g, ' ) ')
            .split(/\s+/)
            .filter(token => token.trim() !== '');
    };

    // Function to validate tokens
    const validateTokens = (tokens: string[]): string | null => {
        let lastToken = '';
        for (const token of tokens) {
            const upperToken = token.toUpperCase();
            if (['AND', 'OR'].includes(upperToken)) {
                if (['AND', 'OR'].includes(lastToken) || lastToken === '') {
                    return `Invalid: '${lastToken} ${token}' is not allowed.`;
                }
            } else if (token === '(' || token === ')') {
                // Parentheses are valid, just check they are balanced separately
                continue;
            } else if (!/^\d+$/.test(token)) {
                return `Invalid: '${token}' is not a valid number.`;
            } else {
                const number = parseInt(token, 10);
                if (number < 1 || number > maxNumber) {
                    return `Invalid: Number ${number} is out of the valid range (1 to ${maxNumber}).`;
                }
            }
            lastToken = upperToken;
        }
        if (['AND', 'OR'].includes(lastToken)) {
            return `Invalid: Expression ends with '${lastToken}'.`;
        }
        return null;
    };

    filterString = filterString.trim();

    // Check for balanced parentheses
    if (!isBalanced(filterString)) {
        return "Invalid: Parentheses are not balanced.";
    }

    // Tokenize the filter string
    const tokens = tokenize(filterString);

    // Validate the tokens and expression
    return validateTokens(tokens);
}
