import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Label,
} from 'reactstrap';
import avtarImage2 from '../../assets/images/users/avatar-1.jpg';
import avtarImage3 from '../../assets/images/users/user-dummy-img.jpg';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import TableContainer from './TableComponent';
import FormBuilder from 'Components/Common/FormBuilder/FormBuilder';


const Profile = ({setUserInputs, userInputs}: any) => {

    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    const CreateRole = () => {
        // Implement your logic for creating a role
    };
    const columns = [
        {
            Header:"Email",
            accessor: "email",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        },
        {
            Header:"Status",
            accessor: "status",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        }
    ]

    const calendarcolumns = [
        {
            Header:"Account",
            accessor: "account",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        },
        {
            Header:"Status",
            accessor: "status",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        }
    ]

    return (
        <React.Fragment>
            <div className="hstack step-arrow-nav mt-4 mb-4">
                <Row className="w-100 hstack gap-3 justify-content-center">
                    <Col lg={12} className="vstack justify-content-between">                        
                        <div className="vstack gap -1 justify-content-center">
                            <h4>User Preferences</h4>
                            <p>This applies across any HubSpot account they have.</p>
                        </div>
                        <div className="vstack gap-3 align-items-start">
                            <h5>Profile Image</h5>
                            <div className="flex-shrink-0">
                                <img src={avtarImage2} alt="" className="avatar-md rounded-circle" />
                            </div>
                            <div className="w-100">
                                <FormBuilder 
                                    label={"First Name"}
                                    type={"text"}
                                    value={userInputs.first_name}
                                    name={"first_name"}
                                    placeholder={`Enter first name`}
                                    className="w-100 h-100 text-start vstack gap-2"
                                    onChange={(value: any) => {
                                        setUserInputs({ ...userInputs, first_name: value })
                                    } }
                                    Editable={true}
                                />
                            </div>
                            <div className="w-100">
                                <FormBuilder 
                                    label={"Last Name"}
                                    type={"text"}
                                    value={userInputs.last_name}
                                    name={"last_name"}
                                    placeholder={`Enter last name`}
                                    className="w-100 h-100 text-start"
                                    onChange={(value: any) => {
                                        setUserInputs({ ...userInputs, last_name: value })
                                    } }
                                    Editable={true}
                                />
                            </div>
                            <div>
                                <p>
                                    This phone number may be used to contact them about security events, sending workflows SMS, and for owner property values.
                                </p>
                                <FormBuilder 
                                    label={"Main Phone"}
                                    type={"phone"}
                                    value={userInputs.main_phone}
                                    name={"main_phone"}
                                    placeholder={`Enter Main number`}
                                    className="w-100 h-100 text-start"
                                    onChange={(value: any) => {
                                        setUserInputs({ ...userInputs, main_phone: value })
                                    }}
                                />
                            </div>   
                            <div>
                                <p>
                                    This phone number may be used to contact them about security events, sending workflows SMS, and for owner property values.
                                </p>
                                <FormBuilder 
                                    label={"Additional Phone"}
                                    type={"phone"}
                                    value={userInputs.alt_phone_number}
                                    name={"alt_phone_number"}
                                    placeholder={`Enter Main number`}
                                    className="w-100 h-100 text-start"
                                    onChange={(value: any) => {
                                        setUserInputs({ ...userInputs, alt_phone_number: value })
                                    }}
                                />
                            </div>    
                            <div className="w-100">
                                <FormBuilder 
                                    label={"Job Title"}
                                    type={"text"}
                                    value={userInputs.job_title}
                                    name={"job_title"}
                                    placeholder={`Enter Job Title`}
                                    className="w-100 h-100 text-start vstack gap-2"
                                    onChange={(value: any) => {
                                        setUserInputs({ ...userInputs, job_title: value })
                                    }}
                                    Editable={true}
                                />
                            </div>                                        
                        </div>                            
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Profile);