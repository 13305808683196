import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import DashboardComponent from "../../Components/Common/Dashboard/Dashboard"
import { Card, CardHeader, Col, Container, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import { BarLabelChart } from 'pages/Charts/ECharts/ECharts';
import KPIs from './Charts/KPIs';
import { BasicBarChart, StackBarChart } from './Charts/Charts';
import MyTasks from './Charts/MyTasks';
import classnames from "classnames";
import { useState } from 'react';
import QuickSightEmbedContext from 'Components/Common/Dashboard/QuickSightEmbedContext';

const LeadGeneration = (props: any) => { 
    const [arrowNavTab, setarrowNavTab] = useState<any>(1);  
    const KPIsData = [
        {
            id: 1,
            cardColor: "primary",
            label: "Total Students",
            // badge: "ri-arrow-right-up-line",
            badgeClass: "white",
            // percentage: "+16.24",
            counter: 6000,
            link: "View all students",
            bgcolor: "light",
            icon: "ri-list-check",
            decimals: 0,
            // prefix: "$",
            // suffix: "k"
        },
        {
            id: 2,
            cardColor: "secondary",
            label: "Students this week",
            // badge: "ri-arrow-right-down-line",
            badgeClass: "white",
            // percentage: "-3.57",
            counter: 100,
            link: "View all Applications",
            bgcolor: "info",
            icon: "ri-list-check",
            decimals: 0,
            prefix: "",
            separator: ",",
            suffix: ""
        },
        {
            id: 3,
            cardColor: "success",
            label: "Students Today",
            // badge: "ri-arrow-right-up-line",
            badgeClass: "white",
            // percentage: "+29.08",
            counter: 10,
            link: "See details",
            bgcolor: "warning",
            icon: "ri-list-check",
            decimals: 0,
            prefix: "",
            suffix: ""
        }
    ];

    const daydata= ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sat']
    const dayseries = [5, 5, 2, 2, 4, 4, 3]
    
    const Leaddata= ['Source1', 'Source2', 'Source3', 'Source4', 'Source5', 'Source6', 'Source7']
    const Leadseries = [10, 10, 15, 2, 5,15, 13]

    const sourcewiseLeaddata= ['Source1', 'Source2', 'Source3', 'Source4', 'Source5', 'Source6', 'Source7']
    const sourcewiseLeadseries=[
        'Hot Lead',
        'Lead',
        'Ringing',
        'Beyond intake',
        'Other'
    ]

    const arrowNavToggle = (tab:any) => {
        if (arrowNavTab !== tab) {
            setarrowNavTab(tab);
        }
    };

    return (
        <div className="h-100">
            {/* <div className='hstack gap-2'>
                {KPIsData && KPIsData.length ? KPIsData.map((item: any) =>{
                    return <Col xl={3} md={3} lg={3}><KPIs item={item}  /></Col>
                })
                : null
                }
            </div>
            <div className='hstack'>
                    <Col xl={6} md={6} lg={6}>
                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">Day wise Registration</h4>
                            </CardHeader>
                            <div className="card-body">
                                <BasicBarChart dataColors='["--vz-primary"]' data={daydata} series={dayseries} seriesName={'Count'} />
                            </div>
                        </Card>
                    </Col>
                    <Col xl={6} md={6} lg={6}>
                        <Card>
                            <CardHeader>
                                <h4 className="card-title mb-0">Source wise Registration</h4>
                            </CardHeader>
                            <div className="card-body">
                                <BasicBarChart dataColors='["--vz-success"]' data={Leaddata} series={Leadseries}  seriesName={'Count'} />
                            </div>
                        </Card>
                    </Col>
            </div>
            <div className='hstack'>
                <Col xl={6} md={6} lg={6}>
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">Source wise Lead status</h4>
                        </CardHeader>
                        <StackBarChart series={sourcewiseLeadseries} data={sourcewiseLeaddata} />
                    </Card>
                </Col>
                <Col xl={6} md={6} lg={6}>                
                    <Card>
                        <CardHeader>
                            <h4 className="card-title mb-0">My task</h4>
                        </CardHeader>
                        <div className="card-body">
                            <MyTasks />
                        </div>
                    </Card>
                </Col>
            </div> */}

            <Nav pills className="nav nav-pills arrow-navtabs nav-primary bg-light mb-3">
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", fontWeight:600 }}
                        className={classnames({
                            active: arrowNavTab == 1,
                        })}
                        onClick={() => {
                            arrowNavToggle(1);
                        }}
                    >
                        {props.t("dashboard.leads_analysis")}
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        style={{ cursor: "pointer", fontWeight:600 }}
                        className={classnames({
                            active: arrowNavTab == 2,
                        })}
                        onClick={() => {
                            arrowNavToggle(2);
                        }}
                    >
                        {props.t("dashboard.applications_analysis")}
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent
                activeTab={arrowNavTab}
                className="text-muted p-0 h-100"
            >
                <TabPane tabId={1}  className='h-100'>
                    { arrowNavTab == 1 && <Row>        
                            <QuickSightEmbedContext initialDashboardId="fd22fb3b-86a2-4d7b-a533-8758c7abcdb5"  width="100%" height="900" />
                        </Row> 
                    }
                </TabPane>
                <TabPane tabId={2} className='h-100'>
                     { arrowNavTab == 2 && <Row>  
                            <QuickSightEmbedContext initialDashboardId="5ac9f328-c04e-4921-8bc1-e33fe379ee63"  width="100%" height="900" />
                        </Row> 
                    }
                    
                </TabPane>
            </TabContent> 
            
            
      </div>
    );
};
export default withTranslation()(LeadGeneration);


