export interface Task {
  taskAssignments: [{
    assiignedTo: []
  }];
  dueDate: string;
  priority: string;
  status: string;
  createdAt: string,
  createdBy: {
    email: string,
    id: string,
    name: string,
    phoneNumber: string,
  },
  deletedAt: string,
  detailedTask: string,
  id: string,
  parentTask: string,
  replies: {
    createdAt: string,
    createdBy: {
      email: string,
      id: string,
      name: string,
      phoneNumber: string,
    },
    deletedAt: string,
    detailedTask: string,
    id: string,
  },
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
  }

export enum TaskActionTypes {
    POST_TASK_REQUEST = "@@tasks/POST_TASK_REQUEST",
    POST_TASK_SUCCESS = "@@tasks/POST_TASK_SUCCESS",
    POST_TASK_ERROR = "@@tasks/POST_TASK_ERROR",
    GET_TASK_REQUEST = "@@tasks/GET_TASK_REQUEST",
    GET_TASK_SUCCESS = "@@tasks/GET_TASK_SUCCESS",
    GET_TASK_ERROR = "@@tasks/GET_TASK_ERROR",
    GET_TASKS_REQUEST = "@@tasks/GET_TASKS_REQUEST",
    GET_TASKS_SUCCESS = "@@tasks/GET_TASKS_SUCCESS",
    GET_TASKS_ERROR = "@@tasks/GET_TASKS_ERROR",
    DELETE_TASK_REQUEST = "@@tasks/DELETE_TASK_REQUEST",
    DELETE_TASK_SUCCESS = "@@tasks/DELETE_TASK_SUCCESS",
    DELETE_TASK_ERROR = "@@tasks/DELETE_TASK_ERROR",
}

export interface TaskState {
    // readonly loading: boolean;
    readonly tasks: Task[] | null;
    readonly task: Task  | null;
    readonly pagination: Pagination | null;
    readonly loading: boolean;
    readonly dataLoading: boolean;
    // readonly errors?: string;
    // readonly content?:any;
    // readonly sort: any;
    // readonly sortBy: any;
    // readonly sortColumn: string;
    // readonly pageable: any;
    // readonly page: any;
    // readonly size: number;
    // readonly totalPages: number;
    // readonly totalElements: number;
    // readonly number: number;
    // readonly numberOfElements: number;
    // readonly first: boolean;
    // readonly last: boolean;
    // readonly empty: boolean;
    // readonly list: []
}
