import React, { useState } from "react";
import { Link } from "react-router-dom";
import {
  Breadcrumb,
  Button,
  Card,
  CardBody,
  Container,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledDropdown,
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import classnames from "classnames";
import UsersTable from "./UsersTable";
import _ from "lodash";
import InviteUser from "./InviteUser";
import { toast, ToastContainer } from "react-toastify";
import UserDetailsModal from "./User/UserDetailsModal";
import config from "../../config";

const { link : {PRIVACY_POLICY}} = config;
const UsersList = ()  => {

  const [customActiveTab, setcustomActiveTab] = useState<any>("1");
  const [show, setShow] = useState<boolean>(false);
  // const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
  // const [userInputs, setUserInputs] = useState<any>({
  //     first_name: "vikranti",
  //     last_name: "Jadhav",
  //     main_phone: "22555222",        
  //     alt_phone_number: "22555222",
  //     job_title: "XYZ",
  //     email: "asas@gmail.com",
  //     status: "Invite Accepted"
  // });
 

  const toggleCustom = (tab: any) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <>
  
    {/* <div className="page-content">
                <Container fluid> */}
                    <Breadcrumb title="Users" pageTitle="Users" />
      {show ? <ToastContainer /> : null}
      <Card>
        <CardBody className="">
          <Nav
            tabs
            className="nav nav-tabs nav-tabs-custom nav-primary fs-18 mb-3"
          >
            <NavItem>
              <NavLink
                style={{ cursor: "pointer" }}
                className={classnames({
                  active: customActiveTab === "1",
                })}
                onClick={() => {
                  toggleCustom("1");
                }}
              >
                Users
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={customActiveTab}>
            <TabPane tabId="1" id="home1">
              <div>
                <div className="fs-15 fw-medium mb-3">
                  Create new users, customize user permissions, and remove users
                  from your account.{" "}
                  {/* <strong>
                    <Link to={`${PRIVACY_POLICY}`}target="_blank" rel="noopener noreferrer" className="text-info fw-medium">
                      {" "}
                      Learn more about users and permissions
                    </Link>
                  </strong>
                  &nbsp; */}
                  {/* <span>
                    <FeatherIcon icon="arrow-up-right" className="icon-sm" />{" "}
                  </span> */}
                </div>
                {/* <hr></hr> */}
                
              </div>
            </TabPane>
            <UsersTable />
          </TabContent>
        </CardBody>
      </Card>
      {/* </Container>
      </div> */}
    </>
  );
};
export default UsersList;
