import {WhatsappActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;


const createQueryLink = (id: any, filterObject: any, page: any) => {
    const keys = filterObject ? Object.keys(filterObject) : [];
    const values = filterObject ? Object.values(filterObject): [];
    let query = `${DTS_API_STUDENT}/whatsapp?studentId=${id}&`;
    if (page) query += `page=${page}&`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};
export const whatsappsGetRequest: AppThunk = (id, filterObject, page) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {        
        const { auth: { token } } = getState();      
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: WhatsappActionTypes.GET_WHATSAPPS_REQUEST
        });
        let searchQuery = createQueryLink(id, filterObject, page)
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: WhatsappActionTypes.GET_WHATSAPPS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: WhatsappActionTypes.GET_WHATSAPPS_SUCCESS,
                payload: body_1
            });
        }
    };
};