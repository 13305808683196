import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, CardHeader, Badge } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableContainer';
import moment from 'moment';
import DeleteModal from './innerComponent/DeleteModal';
import PreviewModal from './innerComponent/PreviewModal';
import { withTranslation } from 'react-i18next';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Link } from 'react-router-dom';
import isEqual from 'lodash/isEqual';
import { InvoiceListGetRequest, invoiceDelete, invoiceGetRequest, invoicesMetadataGetRequest } from 'store/invoices/action';
import { ToastContainer, toast } from 'react-toastify';
import AccessDenied from '../AccessDenied/Denied';
import { isJson } from 'utils';

const Sources = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const invoiceMetadata = useSelector((state: ApplicationState) => state.invoices.metaDataFields);
    const invoiceList = useSelector((state: ApplicationState) => state.invoices.list);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const paginationDetails = useSelector((state: ApplicationState) => state.invoices.pagination); 
    const loading = useSelector((state: ApplicationState) => state.invoices.loading); 
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null); 
    const [columns, setColumns] = useState<any>([])   
    const [pageSize, setPageSize] = useState<number>(10)
    const [previewModal, setpreview] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [search, setsearch] = useState<string>("");
    const [record, setrecord] = useState<any>({});
    const [open, setOpen] = useState('1');
    const [metaData, setMetaData] = useState(invoiceMetadata);
    const [invoices, setInvoices] = useState([]);
    const [invoiceJson, setinvoiceJson] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")
    const [pagesList, setPagesList] = useState<any>([]);
    const [filterObject, setFilterObject] =  useState<any>({});
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [data, setData] = useState<any>(null);
    const [access, setAccess] = useState<any>([]);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const model = "students"
    const toggle = (id: any) => {
        setOpen(id)
    };


    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(InvoiceListGetRequest(model, filters, 0));
        setinvoiceJson([])
    }

    useEffect(() => {
        const filters = {pageSize: 100}
        dispatch(invoicesMetadataGetRequest("studentsinvoices", filters));
        setMetaData(invoiceMetadata)
    }, [])

    useEffect(() => {
        setMetaData(invoiceMetadata);
    }, [invoiceMetadata]);


    const handleLeadClick = (lead: any) => {
        setrecord(lead)
        setData(lead)
        dispatch(invoiceGetRequest(lead.id))
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
        }
        else if(subLevelPermissionsList) {
            let access = subLevelPermissionsList?.invoices;
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(InvoiceListGetRequest(model, filterObject, newPageNo));
        setinvoiceJson([])
    };

    const onDelete = () => {
        const handleSuccess = (body: any) => {
            dispatch(InvoiceListGetRequest(model, filterObject))
            setisDeleteOpen(false)
        }
        const handleFailure = (body: any) => {
            setisDeleteOpen(false)
        }
        dispatch(invoiceDelete(record.id, handleSuccess, handleFailure))
    }

    const handleEditClick = (data: any) => {

    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])


    const handleAccess = (permissionJson : any) => {        
        let userRole = userProfile?.role?.title;
        let roleid = userProfile?.role?.id;        
        const permissions = permissionJson?.permissions;
        if(userRole === "Owner") return true 
        else if(permissions) {
            switch(permissions) {
                case "Owner": 
                case "owner": if(userRole === "Owner") return true;
                                else return false;
                case "view": return true;
                case "view_edit": return true;
                case "role_based":  const items = permissionJson.item || [];
                                    let filteredId = items.filter((subitem: any) => subitem.role_id.toString() === roleid)     
                                    let isview = filteredId.filter((subitem: any) => (subitem.permission === "view" || subitem.permission === "view_edit"));
                                    return isview && isview.length;
                default: return false;
            }

        }
    }
    
    const handleselectValue = (value: any, options: any) => {  
        if (options && options.length) {
            const foundItem = options.find((item: any) => item.value === value);
            if (foundItem) {
                return foundItem.label;
            }
        }
        return null; // or any default value you prefer
    }

	
    useEffect(() => {
        let columns: any[] = [];
        if (metaData && metaData.length > 0) {
            metaData.map((fieldName: any) => {
                let fields = fieldName.valuesJson && isJson(fieldName.valuesJson) ?  JSON.parse(fieldName.valuesJson) : {}
                let permissionsJson = fieldName?.permissionsJson ? JSON.parse(fieldName?.permissionsJson)  : {}                
                let isFieldviewaccess = permissionsJson && permissionsJson.permissions ? handleAccess(permissionsJson) :  true;           
                if(fields.tablecolumn && fields.key != 'invoice_status') {
                    let newColumn = {
                        Header: _.startCase(fields.label),
                        accessor: fields.key,
                        id: fields.key,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        valueJson: fields,
                        meta: fieldName,
                        show: true,
                        Cell: (cellProps: any) => {
                            let rendervalue = cellProps.row.original[fields.key];
                            if(fields.type=='select' || fields.type=='radio' || fields.type=='checboxes') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                            }
                            if(fields.key == 'email') {
                                rendervalue = cellProps.row.original["student_email"];
                            }
                            if(fields.key == 'payment_status') {
                                rendervalue = handleselectValue(cellProps.row.original[fields.key], fields.values);
                                switch (rendervalue) {
                                    case "Paid":
                                      return <span className="badge text-uppercase bg-success-subtle text-success"> {rendervalue} </span>;
                                    case "Unpaid":
                                      return <span className="badge text-uppercase bg-warning-subtle text-warning"> {rendervalue} </span>;
                                    case "Partially Paid":
                                      return <span className="badge text-uppercase bg-danger-subtle text-danger"> {rendervalue} </span>;
                                      case "Advanced Paid":
                                        return <span className="badge text-uppercase bg-success-subtle text-danger"> {rendervalue} </span>;
                                      case "Cancel":
                                        return <span className="badge text-uppercase bg-danger-subtle text-danger"> {rendervalue} </span>;
                                    default:
                                      return <span className="badge text-uppercase bg-primary-subtle text-primary"> {rendervalue} </span>;
                                  }
                            }
                            if(fields.type == 'datetime') {
                                rendervalue = cellProps.row.original[fields.key] ? moment(cellProps.row.original[fields.key]).format('DD, MMM, YYYY'):
                                    ''
                            }
                            return  <div className="d-flex align-items-center justify-content-between">                                      {
                                            fields.key == "student_name" ? <Row style={{width: 250}} className='justfy-content-start align-items-center'>                                           
                                                <Col md={11} lg={11} className={'gap-1 hstack justify-content-start'}>
                                                    <Link to={""} className="text-decoration-none text-ellipsis" >                                            
                                                        {cellProps.row.original[fields.key]}
                                                    </Link>
                                                    {cellProps.row.original['invoice_status'] == true || cellProps.row.original['invoice_status'] == 'true' ? 
                                                    <Badge color='warning' pill>SENT</Badge>
                                                        : 
                                                        <Badge color='success' pill>SAVED</Badge>}
                                                </Col>                                           
                                            </Row> : 
                                            <div>
                                                {rendervalue}
                                            </div>
                                        }
                                    </div>
                        }
                    }
                    columns.push(newColumn)
                }    
            });
            const nameColumnIndex = columns.findIndex(column => column.accessor === "student_name");
        
            // If "Name" column is found, move it to the beginning
            if (nameColumnIndex !== -1) {
                const nameColumn = columns.splice(nameColumnIndex, 1)[0]; // Remove the "Name" column
                columns.unshift(nameColumn); // Add it to the beginning
            }
            let isExist = false;
			columns && columns.length && columns.map((datafield: any) => {
				if(datafield?.accessor === "paid_amount") isExist = true
			});
            if(!isExist) {
                let newColumn = {
                    Header: "Paid Amount",
                    accessor: "paid_amount",
                    id: "paid_amount",
                    filterable: true,
                    isSortable: false,
                    disableFilters: true,
                    show: true,
                    Cell: (cellProps: any) => {
                        let rendervalue = cellProps.row.original["paid_amount"];
                        return  <div className="d-flex align-items-center justify-content-between">            <div>
                                            {rendervalue}
                                        </div>
                                    
                                </div>
                    }
                }
                columns.push(newColumn)
            }
        }
        setColumns(columns)
    }, [metaData, userProfile]);

    useEffect(() => {
        setMetaData(invoiceMetadata)
    }, [invoiceMetadata != undefined])

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters)
        
        
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(InvoiceListGetRequest(model, filters));
            setinvoiceJson([])
        }, 2000);

        setTypingTimeout(newTimeout);
        
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
          const filters = {...filterObject}
          dispatch(InvoiceListGetRequest(model, filters));
            setinvoiceJson([])
        }
      };
    
    const execute = () => {
        let a= isEqual(invoiceList, invoiceJson)
        return !a
     }

    useEffect(() => {
        const parsedData: any = invoiceList && invoiceList.length ? invoiceList.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): {};
            let values = { ...valuesJson, id: item.id , createdAt: item.createdAt, paid_amount: valuesJson.paid_amount}
            return values
        }) : []
        let invoice = parsedData && parsedData.length && parsedData.reverse() 
        setInvoices(invoice)
        setinvoiceJson(invoiceList)
    }, [execute()])

    
    useEffect(() => {
        dispatch(InvoiceListGetRequest(model, filterObject))
    }, [])

    const applyFilters = (filters: any) => {
        dispatch(InvoiceListGetRequest(model, filters, 0));
        setinvoiceJson([])
    }
    

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          setSortOrder(sort);
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort}   
          setFilterObject(filters)       
          dispatch(InvoiceListGetRequest(model, filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}  
          setFilterObject(filters)            
          dispatch(InvoiceListGetRequest(model, filters, pagination.page));
        }
      };

    document.title = "DTS | Zilter";
    return ( 
        <>
        {
            viewAccess ?
    <React.Fragment>
            <ToastContainer />   
        {previewModal && <PreviewModal
            show={previewModal}
            onCloseClick={() => setpreview(false)}
            record={record}
            ontoggle={toggle}
            open={open}
            setrecord={setrecord}
            metaData={metaData}
            data={data}
        />}
        {isDeleteOpen && <DeleteModal
            show={isDeleteOpen}
            onCloseClick={() => setisDeleteOpen(false)}
            props={props}
            record={record}
            onDelete={onDelete}
        />}
		<Row>
				<Col lg={12}>
					<Card id="invoiceList">
						<CardHeader className="border-0">
							<div className="d-flex align-items-center">
								<h5 className="card-title mb-0 flex-grow-1">{props.t("invoices.student_invoices")}</h5>
							</div>
							<Row className='hstack gap-3 my-2'>
                                <Col md={3} sm={12} xl={3} xxl={3} lg={3}>
                                    <div className="search-box">
                                        <Input
                                            type="text"
                                            size={14}
                                            className="search"
                                            onKeyDown={handleKeyDown}
                                            placeholder={props.t("invoices.search_for")}
                                            onChange={(e) => handleChange(e.target.value)}
                                            value={search}
                                        />
                                        {search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                    </div>
                                </Col>				
                            </Row>
						</CardHeader>
						<CardBody className="pt-0">
							<div>
								<TableContainer
									columns={columns && columns.length ? columns.filter((column: any) => column.show == true) : []}
                                    data={invoices || []}
                                    customPageSize={pageSize}
                                    divClass="table-responsive"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    handleEditClick={handleEditClick}
                                    handleLeadClick={handleLeadClick}
                                    setisDeleteOpen={setisDeleteOpen}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    setPagination={setPagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    pageSize={pageSize}
                                    setpreview={setpreview}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    props={props}
                                    access={access}
                                    loading={loading}
								/>
								<ToastContainer closeButton={false} limit={1} />
							</div>
						</CardBody>
					</Card>
				</Col>
			</Row>
    </React.Fragment>
    : <AccessDenied />
    }
    </>
    );
};
export default withTranslation()(Sources);


