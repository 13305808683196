import React, { useEffect, useState } from "react";
import {
    Form,
    Button,
} from "reactstrap";
import isEqual from 'lodash/isEqual';
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import axios from "axios";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import config from "../../../config"
import { toast } from "react-toastify";
import { handleSelectValue, isJson } from "utils";
const { DTS_API_STUDENT, AWS_API } = config.api;

const DtsForm = ({ dataFields, onUpdate, data, formfor, fields, isEditAccess}: any) => {
    const [isSaveOpen, setisSaveOpen] = useState(false);
    const [file, setFile] = useState(null);
    const [fileKey, setFileKey] = useState(null);
    const [updateField, setupdateField] = useState(null);
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [filterFields, setFilterFields] = useState([]);
       
    
    const [formValues, setFormValues] = useState<any>({});
    const [fieldValues, SetFieldValues] = useState<any>({});   

    const isFile = (file: any) => {
        return typeof file === 'object' && file !== null && 'size' in file && 'type' in file;
    }

    const SubmitUpload = async (selectedFiles: any, key: any) => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : selectedFiles;
        const studentId: any = data.studentId;
        const applicationId: any = data.applicationId ? data.applicationId : "";
        if (file) {
                const formData = new FormData();
                formData.append('documentType', key);
                formData.append('studentId', studentId);
                formData.append('applicationId', applicationId);
                formData.append('file', file);
                const config = {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                await axios.post(`${DTS_API_STUDENT}/documents`, formData, config)
                .then((res: any) => {                    
                    if(res == 'File uploaded successfully.') {
                        const message = () => toast(`File uploaded successfully. Please check in documents section`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                        message();
                    }
                   
                })
        }
    }
    
    const onChange = (option: any, key: any, field: any) => {    
        let feldtoupdate: any = null;  
        if(field.type == "selectboxes" || field.isMulti) {    
            setFormValues({ ...formValues, [key]: option })
            feldtoupdate = {[key]: option}
        } 
        else if(isFile(option)) {
            const file = option && option.length ? option[0] : option;
            setFile(file)
            setFileKey(key)
            // let name = option.name;
            // let newFormValues = {...formValues, [key]: option?.name}
            // setFormValues(newFormValues) 
            // // feldtoupdate = {[key]:  file}           
        }
        else if(isNaN(Date.parse(option)) === false) {
            setFormValues({...formValues, [key]: option})
            feldtoupdate = {[key]: option}
        }
        else if (typeof option == 'object') {
            setFormValues({...formValues, [key]: option?.value})
            feldtoupdate = {[key]:  option?.value}
        }
        else {
            setFormValues({ ...formValues, [key]: option })
            feldtoupdate = {[key]:  option}
        }
        setupdateField(feldtoupdate)
    };
    const onSaveClick = async (type?:any, key?: any, value?: any) => {
        let newUpdateField = {[key]: value}
        if(formfor == "commission") {
            onUpdate(formValues)
        }
        else if(formfor == "students" || formfor == "applications") {
            if(type == "file") {
                await SubmitUpload(file, fileKey)
                onUpdate(newUpdateField, type)
            }
            else onUpdate(newUpdateField)
        }
        else {         
            onUpdate(newUpdateField)
        }
        setisSaveOpen(false)
    }
    
    useEffect(() => {
        setisSaveOpen(!isEqual(fieldValues, formValues))
    }, [formValues])

    useEffect(() => {
        let newfilterFields: any = [];
        fields && fields.length ? fields.map((data: any) => {
            dataFields && dataFields.length && dataFields.map((field: any) => {
                if(data.id == field.id) {
                    let item = field.valuesJson && isJson(field.valuesJson)  ? JSON.parse(field.valuesJson) : {}
                    let newItem = {...item, isEditAccess: field.isEditAccess }
                    if (field.valuesJson) return newfilterFields.push(newItem)
                }
            })
        }) :
        dataFields && dataFields.length && dataFields.map((field: any) => {
                let item = field.valuesJson && isJson(field.valuesJson)  ? JSON.parse(field.valuesJson) : {}
                let newItem = {...item, isEditAccess: field.isEditAccess }
                if (field.valuesJson) return newfilterFields.push(newItem)
        })        
        if (dataFields && dataFields.length > 0 && fields && fields.length) {
            const result = dataFields.filter((item: any) => !fields.some((field: any) => (item.id === field.id)))
            if (result && result.length > 0) {
                result.map((fieldName: any) => {     
                    let item = fieldName.valuesJson && isJson(fieldName.valuesJson)  ? JSON.parse(fieldName.valuesJson) : {}
                    let newItem = {...item, isEditAccess: fieldName.isEditAccess }
                    if (fieldName.valuesJson) return newfilterFields.push(newItem)
                });
            }
        }
        setFilterFields(newfilterFields)
    }, [fields,dataFields])
    
    useEffect(() => {   
        let newData:any =  {};
        if(data) {
            let info = data?.valuesJson || data;
            filterFields && filterFields.length && filterFields.map((item: any, key: any) => {
                let rendervalue = info?.[item.key]; 
                if (fields?.mergeColumns?.columns) {
                    let value = fields?.mergeColumns?.columns
                    let values = value.split(",")
                    let newValue = "";
                    values?.length && values.map((item: any) => {
                        newValue = info?.[item] ? `${newValue ? newValue : ""} ${info?.[item]}${fields.mergeColumns?.separator || " "}` : ""
                    })
                    rendervalue = newValue;
                }                     
                if (item.optionLabel) {
                    rendervalue = info?.[item.key]?.[item.optionLabel] ? info?.[item.key]?.["id"] : null
                }                         
                else if (item.isMulti || item.type == "selectboxes") {
                    rendervalue = info?.[item.key];
                }         
                else if (item.type == 'select' || item.type == 'radio' || item.type == 'checboxes') {
                    rendervalue = handleSelectValue(info?.[item.key], item.values);
                }
                newData[item.key]= rendervalue;
            })
            setFormValues(newData)
            SetFieldValues(newData)
        }
    }, [data, filterFields])
    return (
            <div className="live-preview vstack gap-2">
                
                {filterFields && filterFields.length ? filterFields.map((field: any) => { 
                   let type = field.type == "checkbox" || field.type == "selectboxes" || field.type == "radio" ? "select" : field.type;
                    return <FormBuilder
                        placeholder={field.placeholder}
                        name={field.key}
                        onChange={(e: any) => onChange(e, field.key, field)}
                        options={field.values && field.values.reverse() || []}
                        label={field.label}
                        type={type}
                        value={formValues[field.key]}
                        isEditState={true}
                        actionButtons={true}
                        defaultValue={formValues[field.key]}
                        Editable={true}
                        rest={field}
                        invalid={true}
                        formValues={formValues}
                        dataFields={filterFields}
                        onSaveClick={(type: any) => onSaveClick(type, field.key, formValues[field.key])}
                        resetValue={fieldValues[field.key]}
                        readOnly={formfor == "students" || formfor == "applications"  || formfor == "subagents" || formfor == "channels"  || formfor == "sources" || formfor == "banks" || formfor == "companies" ? !field.isEditAccess || !isEditAccess : false }
                    />
                }): null}
            </div>      
    );
};

export default DtsForm;

