import {TemplateActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const templateGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: TemplateActionTypes.GET_TEMPLATE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/templates/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, TemplateActionTypes.GET_TEMPLATE_SUCCESS, TemplateActionTypes.GET_TEMPLATE_ERROR, null, null, null, "GET");
      
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const templatesMetadataGetRequest: AppThunk = (model,filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: TemplateActionTypes.GET_TEMPLATEMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }           
        if (model) {
            headers.append('Properties-Model', model);
        } 
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, TemplateActionTypes.GET_TEMPLATEMETADATA_SUCCESS, TemplateActionTypes.GET_TEMPLATEMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const templateUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: TemplateActionTypes.PUT_TEMPLATE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/templates/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                          
        const error = {
            204: {
                "message": "Template updated Successfully",
                "success": true
            },
            200: {
                "message": "Template updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, TemplateActionTypes.PUT_TEMPLATE_SUCCESS, TemplateActionTypes.PUT_TEMPLATE_ERROR, error, handleSuccess, handleError);
    
    };
};

export const templateDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: TemplateActionTypes.DELETE_TEMPLATE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/templates/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Template deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Template deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, TemplateActionTypes.DELETE_TEMPLATE_SUCCESS, TemplateActionTypes.DELETE_TEMPLATE_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: TemplateActionTypes.DELETE_TEMPLATE_ERROR
            });
            handleSuccess();
        }
    };
};

export const templateCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: TemplateActionTypes.POST_TEMPLATE_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/models/templates`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token,
                        'Properties-Model': "templates"
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Template already exist",
                    "success": false
                },
                201: {
                    "message": "Template created Successfully",
                    "success": true
                },       
                200: {
                    "message": "Template created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, TemplateActionTypes.POST_TEMPLATE_SUCCESS, TemplateActionTypes.POST_TEMPLATE_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: TemplateActionTypes.POST_TEMPLATE_ERROR, payload: error });
            handleError();
        }
    };
};

export const templateListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: TemplateActionTypes.GET_TEMPLATES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/get/templates?page=${page?page:0}&pageSize=${size?size:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });          
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, TemplateActionTypes.GET_TEMPLATES_SUCCESS, TemplateActionTypes.GET_TEMPLATES_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};



