import {ChannelActionTypes, Channel} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const channelGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ChannelActionTypes.GET_CHANNEL_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/channels/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, ChannelActionTypes.GET_CHANNEL_SUCCESS, ChannelActionTypes.GET_CHANNEL_ERROR, null, null, null, "GET");   
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};


export const applicationsCommissions: AppThunk = (applicationId , handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/channels/commissions/${applicationId}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};


export const channelMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ChannelActionTypes.GET_CHANNELMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }   
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, ChannelActionTypes.GET_CHANNELMETADATA_SUCCESS, ChannelActionTypes.GET_CHANNELMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const channelUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ChannelActionTypes.PUT_CHANNEL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/channels/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
                      
        const error = {
            204: {
                "message": "Channel updated Successfully",
                "success": true
            },
            200: {
                "message": "Channel updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, ChannelActionTypes.PUT_CHANNEL_SUCCESS, ChannelActionTypes.PUT_CHANNEL_ERROR, error, handleSuccess, handleError);
    };
};

export const channelDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ChannelActionTypes.DELETE_CHANNEL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/channels/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Channel deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Channel deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, ChannelActionTypes.DELETE_CHANNEL_SUCCESS, ChannelActionTypes.DELETE_CHANNEL_ERROR, error, handleSuccess, handleError);
       
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: ChannelActionTypes.DELETE_CHANNEL_ERROR
            });
            handleSuccess();
        }
    };
};

export const channelListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ChannelActionTypes.GET_CHANNELS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        const response = await fetch(`${DTS_API_STUDENT}/models/get/channels?page=${page?page:0}&pageSize=${size?size:10}`, {
            method: "POST",
            headers: headers,
            body: JSON.stringify(requestbody),
            signal
        }).catch((error) => {
            return false;
        });
        if(typeof response === 'object' && !Array.isArray(response) && response !== null) {
            const body = await response?.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: ChannelActionTypes.GET_CHANNELS_ERROR,
                    payload: body_1
                });
            } else {
                dispatch({
                    type: ChannelActionTypes.GET_CHANNELS_SUCCESS,
                    payload: body_1
                });
            }
        }
    };
};

export const channelCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: ChannelActionTypes.POST_CHANNEL_REQUEST
        });
        try {
        const response = await fetch(
            `${DTS_API_STUDENT}/channels`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        ); 
        const error = {
            208: {
                "message": "Channel already exist",
                "success": false
            },
            201: {
                "message": "Channel created Successfully",
                "success": true
            },
            200: {
                "message": "Channel created Successfully",
                "success": true
            }
        }
        await responseHandler(response, dispatch, ChannelActionTypes.POST_CHANNEL_SUCCESS, ChannelActionTypes.POST_CHANNEL_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: ChannelActionTypes.POST_CHANNEL_ERROR, payload: error });
            handleError();
        }
    };
};

export const invoicesStudentsList: AppThunk = (channelId , handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/applications/invoices/${channelId}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};




