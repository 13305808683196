import React from "react";
import { withTranslation } from "react-i18next";
import EmailToolbar from "./Emails";

const Email = (props: any) => {
    const {communicate, details, model, to } =props;
    return (
        <React.Fragment>
            <div className="">
                <EmailToolbar communicate={communicate} details={details} model={model} to={to} />
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Email);
