import { v4 as uuidv4 } from 'uuid';
import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { Link } from "react-router-dom";
import Select from 'react-select';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import { ModelListGetRequest } from 'store/auth/action';

interface DataSourceDrawerProps {
    isOpen: boolean;
    initData: any;
    toggle: (value: boolean) => void;
    onDataSource: (primary: any, secondary: any) => void;
    t: (key: string) => string;
}

const DataSourceDrawer: React.FC<DataSourceDrawerProps> = ({
    isOpen,
    toggle,
    onDataSource,
    initData,
    t
}) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const modelList = useSelector((state: ApplicationState) => state.auth.models)
    
    const [models, setModels] = useState([])
    const [secondaryModels, setSecondaryModels] = useState([])
    const [selectedPrimarySource, setSelectedPrimarySource] = useState(null)
    const [selectedSecondarySources, setSelectedSecondarySources] = useState([])

    useEffect(() => {
        dispatch(ModelListGetRequest())
    }, []);

    useEffect(() => {
        if(initData) {
            setSelectedPrimarySource(initData?.primary)
            setSelectedSecondarySources(initData?.secondary)
        }
    }, [initData]);

    useEffect(() => {
        if(modelList?.length) {
            const models = modelList.map((item: any) => {
                return { label: item.charAt(0).toUpperCase() + item.slice(1), value: item}
            })
            setModels(models)
        }
    }, [modelList])
    const onSelectPrimarySource = (selectedModel: any) => {
        setSelectedPrimarySource(selectedModel)
        const secondaryModels = models.filter((model: any) => model.value !== selectedModel.value)
        setSecondaryModels(secondaryModels)
    }
    const onAdd = () => {
        onDataSource(selectedPrimarySource, selectedSecondarySources)
        resetValues()
        toggle(false)
    }
    const resetValues = () => {
        setSecondaryModels([])
        setSelectedPrimarySource(null)
        setSelectedSecondarySources([])
    }
    return (
        <Offcanvas
            direction="end"
            isOpen={isOpen}
            backdrop={false}
            toggle={() => toggle(false)}
        >
            <OffcanvasHeader
                className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary"
                id="processFlowCreateHeader"
                toggle={() => toggle(false)}
            >
                <span className="m-0 me-2 text-white">
                    {t('reports.add.heading')}
                </span>
            </OffcanvasHeader>
            <OffcanvasBody className="bg-light" id="processFlowCreateBody">
                <div className="d-flex flex-column gap-2">
                    <div>
                        <Label className='fw-bold'>{t('reports.add.primary_data_source')}</Label>
                        <p>{t('reports.add.primary_data_source_helper')}</p>
                        <Select
                            isSearchable
                            name="values"
                            options={models}
                            onChange={onSelectPrimarySource}
                            value={selectedPrimarySource}
                        />
                    </div>
                    <div>
                        <Label className='fw-bold'>{t('reports.add.secondary_data_sources')}</Label>
                        <p>{t('reports.add.secondary_data_sources_helper')}</p>
                        <Select
                            closeMenuOnSelect={false}
                            isMulti
                            isClearable
                            isSearchable
                            name="values"
                            options={secondaryModels}
                            onChange={setSelectedSecondarySources}
                            value={selectedSecondarySources}
                        />
                    </div>
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Link
                    to="#"
                    className="text-danger fw-bold w-auto"
                    onClick={() => toggle(false)}
                >
                    {t('reports.add.btn_cancel')}
                </Link>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    onClick={onAdd}
                >
                    {t('reports.add.btn_add')}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(DataSourceDrawer);
