export interface Currency {
    coversion: {
            targetCurrencyJson: string;
},
updateCurrency: boolean;
updatedCurrency: any;
}

export enum CurrencyActionTypes {
    POST_CURRENCY_REQUEST = "@@currency/POST_CURRENCY_REQUEST",
    POST_CURRENCY_SUCCESS = "@@currency/POST_CURRENCY_SUCCESS",
    POST_CURRENCY_ERROR = "@@currency/POST_CURRENCY_ERROR",
    GET_CURRENCY_REQUEST = "@@currency/GET_CURRENCY_REQUEST",
    GET_CURRENCY_SUCCESS = "@@currency/GET_CURRENCY_SUCCESS",
    GET_CURRENCY_ERROR = "@@currency/GET_CURRENCY_ERROR",
    GET_CURRENCIES_REQUEST = "@@currency/GET_CURRENCIES_REQUEST",
    GET_CURRENCIES_SUCCESS = "@@currency/GET_CURRENCIES_SUCCESS",
    GET_CURRENCIES_ERROR = "@@currency/GET_CURRENCIES_ERROR",
    UPDATE_CURRENCY_SUCCESS = "@@currency/UPDATE_CURRENCY_SUCCESS",
    UPDATE_CURRENCY_ERROR = "@@currency/UPDATE_CURRENCY_ERROR"
}

export interface CurrencyState {
    readonly conversion: Currency [] | null;
    readonly currency: Currency  | null;
    readonly loading: boolean;
    readonly updateCurrency: boolean;
    readonly updatedCurrency: any;
}
