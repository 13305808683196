import { Fragment, useEffect } from "react";
import {
    useTable,
    useGlobalFilter,
    useSortBy,
    useFilters,
    useExpanded,
    usePagination,
    useRowSelect
} from "react-table";
import { Table, Row, Button } from "reactstrap";
import { DefaultColumnFilter } from "../../../Components/Common/filters";
import Paginator from "Components/Common/Paginator";
import Select from "react-select";
import { components } from "react-select";
import { createSelector } from "reselect";
import { useSelector } from "react-redux";
interface TableContainerProps {
    columns?: any;
    data?: any;
    tableClass?: any;
    theadClass?: any;
    isPageSizeChange?: any;
    trClass?: any;
    thClass?: any;
    pagination: any; 
    pageSize: any;
    pagesList: any;
    pageChanged: (e: any) => void;
    onChangePageSize: (e: any) => void;
    handleSort: (e: any) => void;
    sortBy:string;
    sortOrder:string;
    props: any;
    rolesPermissions: any;
    onApply: (e:any) => any;
    onCloseClick: any;
    permissionInputs: any;
    checkedRows: any;
    onChange: (e: any, value: any) => void;
    handleCheckboxChange: (e: any, value: any) => void;
}

const TableContainer = ({
    columns,
    data,
    isPageSizeChange,
    tableClass,
    theadClass,
    trClass,
    thClass,
    pageChanged,
    pagesList,
    pagination,
    onChangePageSize,
    pageSize,
    handleSort,
    sortOrder,
    sortBy,
    props,
    rolesPermissions,
    onApply,
    onCloseClick,
    permissionInputs,
    checkedRows,
    onChange,
    handleCheckboxChange
}: TableContainerProps) => {
    const {
        getTableProps,
        getTableBodyProps,
        headerGroups,
        page,
        prepareRow,
        setPageSize,
    } = useTable(
        {
            columns,
            data,
            defaultColumn: { Filter: DefaultColumnFilter }
        },
        useGlobalFilter,
        useFilters,
        useSortBy,
        useExpanded,
        usePagination,
        useRowSelect
    );   

    const onChangeInSelect = (event: any) => {
        setPageSize(Number(event.target.value));
        onChangePageSize(event.target.value);
    };

    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    
    const { layoutModeType } = useSelector(selectDashboardData);
   
    const permissionOptions = [
        { label: 'View and Edit', value: 'view_edit' },
        { label: 'View Only', value: 'view' },
        { label: 'No Access', value: 'none' },
      ];

    const CustomOption = (props: any) => {
        return (
          <components.Option {...props}>
            <div className='hstack justify-content-between'>
                {props.children}
                {props.isSelected && <i className="ri-check-line fs-20" />}
            </div>
          </components.Option>
        );
      };

      const customStyles = {
        // Customize the style of the dropdown menu
        control: (provided: any, state: any) => ({
                    ...provided,
                    width: 200, 
                    // color: '#495057',
                    fontWeight: 900,
                    // border: state.isFocused ? '0.5px solid lightgrey' : '0.5px solid lightgrey',
                    borderRadius: 3,
                    boxShadow: 'none',
                    '&:hover': {
                    // border: '0.5px solid lightgrey',
                    },
                    backgroundColor: 'transparent'
        }),
        menu: (provided: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          borderRadius: 4,
        }),
        // Customize the style of the option in the dropdown menu
        option: (provided: any, state: any) => ({
          ...provided,
          backgroundColor: layoutModeType === 'light'? 'white' : '#495057',
          color: layoutModeType === 'light'? 'black' : 'white',
          '&:hover': {
            backgroundColor: layoutModeType === 'light' ? 'white' : '#495057',
          },
        }),
    }
   
    const handleSelected = (row: any) => {
        let selectedOption;
        permissionInputs && permissionInputs.item && permissionInputs.item.length && permissionInputs.item.map((subitem: any) =>{
            if(subitem.role_id === row.original.id) {                
                permissionOptions.forEach((option: any) =>  {
                    if(option.value === subitem.permission) {
                        selectedOption = option;
                    }
                });
            }
        });
        return selectedOption;
    };

    useEffect(() => {
        permissionInputs && permissionInputs.item && permissionInputs.item.length && permissionInputs.item.map((subitem: any) =>{
            if(subitem.role_id) {                
                permissionOptions.forEach((option: any) =>  {
                    if(option.value === subitem.permission) {
                        handleCheckboxChange(subitem.role_id, option)
                    }
                });
            }
        });
    }, [])
    return (
        <div>
            <Table hover {...getTableProps()} className={tableClass + 'm-0'} size="sm">
                <thead className={theadClass}>
                    {headerGroups.map((headerGroup: any) => (
                        <tr className={trClass} key={headerGroup.id}  {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column: any) => (
                                <th key={column.id} className={thClass + " fw-bold"}
                                    {...column.isSortable ? { ...column.getSortByToggleProps() } : ""}
                                >
                                    <div onClick={() => handleSort(column.id)}>
                                        {column.render("Header")}
                                        {sortBy === 'title' && (
                                            <span>
                                                {sortOrder === 'asc' ? ' ▲' : ' ▼'}
                                            </span>
                                        )}
                                    </div>                                   
                                </th>
                            ))}
                            <th className={thClass + " fw-bold hstack justify-content-center"} >
                                {props.t('roles.access')}
                            </th>
                        </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                        {page.map((row: any) => {
                            prepareRow(row);
                            let selectedValue: any = handleSelected(row);
                            return (
                                <Fragment key={row.getRowProps().key}>
                                    <tr style={{verticalAlign: 'middle'}}>
                                        {row.cells.map((cell: any) => {
                                            let classList = cell.column.className;
                                            return (
                                                <td key={cell.id} style={{verticalAlign: 'middle', width: "50%"}} className='align-items-center justify-content-between' {...cell.getCellProps()}>
                                                    {cell.render("Cell")}
                                                </td>
                                            );
                                        })}
                                        <td style={{verticalAlign: 'middle', width: "50%", justifyContent: "end"}}>
                                            <ul className="list-inline hstack gap-2 mb-0">                                            
                                                <li className="list-inline-item" title="Delete">                                                
                                                    <Select
                                                        styles={customStyles}
                                                        components={{ Option: CustomOption }}
                                                        id={row.id}
                                                        className='form-select-sm p-0'
                                                        placeholder={`Select permission`}
                                                        onChange={(selectedOption: any) => onChange(selectedOption,row)}
                                                        options={permissionOptions} 
                                                        onClick={(e: any) => {e.preventDefault()}}   
                                                        value={checkedRows[row.original.id] !== undefined ? checkedRows[row.original.id]:{ label: 'No Access', value: 'none' } }
                                                     ></Select>    
                                                </li>
                                            </ul>
                                        </td>
                                    </tr>
                                </Fragment>
                            );
                        })}
                </tbody>
            </Table>
            <Row className="align-items-center mt-2 g-3 text-center text-sm-start pb-2">            
                <div className="col-sm">
                    <div className="text-muted">
                        {props.t("applications.showing")}
                        <span className="fw-semibold ms-1">
                        {data.length > 0 ? `${pagination.offset + 1}-${Math.min(pagination.offset + pagination.size, pagination.elements)} of ${pagination.elements} results` : `0-0 of 0 results`}
                        </span>
                    </div>
                </div>       
                <div className="col-sm-auto">
                    <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                        currentPage={pagination?.page}
                        pageChanged={(e:any) => pageChanged(e)}
                        pageSize={pageSize} 
                        isPageSizeChange={isPageSizeChange}
                        onChangeInSelect={(e:any) => onChangeInSelect(e)}
                        pagesList={pagesList}
                        pagination={pagination}
                        props={props}
                     />
                </div>
            </Row>
            <Row>
                <div className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-primary" onClick={() => onApply(rolesPermissions)} disabled={!rolesPermissions.length}>
                        {props.t("properties.save")}
                    </Button>
                    <Button color="danger" onClick={() => onCloseClick()} outline>
                        {props.t("properties.cancel")}
                    </Button>
                </div>
            </Row>
        </div>
    );
};

export default TableContainer;