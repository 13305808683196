import React, { useEffect } from 'react';
import { Container, Row } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import ViewsTab from 'Components/Common/Views/ViewsTab';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { ThunkDispatch } from '@reduxjs/toolkit';
import { Action } from 'redux';
import Student from './Students';
import { studentsMetadataGetRequest } from 'store/student/action';
import { downloadStudentsBulkTemplate } from 'store/documents/action';

const Students = (props: any) => {    
    const metaData = useSelector((state: ApplicationState) => state.student.metaDataFields);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();

    useEffect(() => {
        const filters = { pageSize: 500 }
        dispatch(studentsMetadataGetRequest("students", filters));
    }, [])
    
    document.title="DTS | Zilter";
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>                           
                    <Row>
                        <ViewsTab 
                            component={Student}
                            metadata={metaData}
                            model={"students"}
                            title={props.t("student.students")} 
                            isBulkUpload={true}
                            downloadBulkTemplate={downloadStudentsBulkTemplate}
                        />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Students);

