import React, { useEffect, useState } from 'react';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import FeatherIcon from 'feather-icons-react';
import { Button, Card, CardBody, Col, Container, Input, Label, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import classnames from "classnames";
import { loadStripe } from '@stripe/stripe-js';
import { useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { userGetRequest } from 'store/auth/action';
import config from 'config';

const { stripe, link } = config;
const SUCCESS = stripe.SUCCESS;
const CANCEL = stripe.CANCEL;
const TERMS_AND_CONDITIONS = link.TERMS_AND_CONDITIONS

const stripePromise = loadStripe('pk_live_ArIL6Hir0nziPOALApHefpLg00VjhF2HXb');

const ANNUAL = [
    {
        id: 1,
        type: "Annual Subscription",
        planIcon: "ri-book-mark-line",
        supportClass: "danger",
        supportClassSymbol: "close",
        storageClass: "danger",
        storageClassSymbol: "close",
        domainClass: "danger",
        domainClassSymbol: "close",
        ribbon: false,
        planButtonClassname: "soft-success",
        icon: "ri-book-mark-line",
        btntxt: "Subscribe",
        rateYear: "29",
        delrateYear: "37",
        priceIdYear: "price_1QAmwtKBdQtb1E4JgD6I2zKg",
        priceIdMonth: "price_1PzsKxKBdQtb1E4JtVztNLoV",
        billingCapped: "1000",
        forCompanies: "Under 50 Enrollments",
        idealFor: "Upto 5 users",
        url:"https://buy.stripe.com/cN2bJw9aB40w8sE9AL"
    }]
const PRICING = [
    {
        id: 1,
        type: "Starter",
        purpose: "For Startup",
        planIcon: "ri-book-mark-line",
        rate: 37,
        projects: 3,
        Customers: 299,
        FTP: 5,
        supportClass: "danger",
        supportClassSymbol: "close",
        storageClass: "danger",
        storageClassSymbol: "close",
        domainClass: "danger",
        domainClassSymbol: "close",
        ribbon: false,
        planButtonClassname: "soft-success",
        icon: "ri-book-mark-line",
        btntxt: "Subscribe",
        rateYear: "29",
        delrateYear: "37",
        priceIdYear: "price_1QAmOCKBdQtb1E4Jydxf0GX1",
        priceIdMonth: "price_1PzsKxKBdQtb1E4JtVztNLoV",
        billingCapped: "2000",
        forCompanies: "Under 50 Enrollments",
        idealFor: "Ideal for 10 users",
        url: "https://buy.stripe.com/eVaeVI4UleFa38k4go", 
    },
    {
        id: 2,
        type: "Growth",
        purpose: "For Startup",
        planIcon: "ri-book-mark-line",
        rate: 43,
        projects: 3,
        Customers: 299,
        FTP: 5,
        supportClass: "danger",
        supportClassSymbol: "close",
        storageClass: "danger",
        storageClassSymbol: "close",
        domainClass: "danger",
        domainClassSymbol: "close",
        ribbon: true,
        planButtonClassname: "soft-success",
        icon: "ri-book-mark-line",
        btntxt: "Subscribe",
        rateYear: "34",
        delrateYear: "43",
        priceIdYear: "price_1QAmOnKBdQtb1E4JUtg3cfhH",
        priceIdMonth: "price_1PzsKxKBdQtb1E4JtVztNLoV",
        billingCapped: "3500",
        forCompanies: "51 to 150 Enrollments",
        idealFor: "Ideal for 20 users",
        url: "https://buy.stripe.com/3csaFsfyZ8gMgZa14d"
    },
    {
        id: 3,
        type: "Enterprise",
        purpose: "For Startup",
        planIcon: "ri-book-mark-line",
        rate: 74,
        projects: 3,
        Customers: 299,
        FTP: 5,
        supportClass: "danger",
        supportClassSymbol: "close",
        storageClass: "danger",
        storageClassSymbol: "close",
        domainClass: "danger",
        domainClassSymbol: "close",
        ribbon: false,
        planButtonClassname: "soft-success",
        icon: "ri-book-mark-line",
        btntxt: "Subscribe",
        rateYear: "59",
        delrateYear: "74",
        priceIdYear: "price_1QAmPGKBdQtb1E4JKUDAjdmP",
        priceIdMonth: "price_1PzsKxKBdQtb1E4JtVztNLoV",
        billingCapped: "6500",
        forCompanies: "150+ Enrollments",
        idealFor: "Unlimited users",
        url: "https://buy.stripe.com/3cs3d0euVbsY8sE14e", 
    },
];

const PricingModel = () => {
    document.title = "Pricing | Velzon - React Admin & Dashboard Template";
    const navigate = useNavigate();
    const auth = useSelector((state: ApplicationState) => state.auth);
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [isChecked, setIsChecked] = useState<any>("");
    const [activeTab, setActiveTab] = useState<string>('2');
    const toggleTab = (tab: any) => {
        if (activeTab !== tab) {
            setActiveTab(tab);
        }
    };

    const filteredPricing = activeTab === '1' ? ANNUAL : PRICING;

    useEffect(() => {
        dispatch(userGetRequest());
    }, [])

    const handleCheckout = async (product: any) => {
        window.open(product.url, '_blank');
        // const stripe = await stripePromise;
        // const price = product.priceIdYear
        // const { error } = await stripe!.redirectToCheckout({
        //     lineItems: [{ price: price, quantity: 1 }], // Replace with your price ID from Stripe
        //     mode: 'subscription', // or 'subscription' for recurring
        //     successUrl: `${SUCCESS}`,
        //     cancelUrl: `${CANCEL}`,
        // });
        // if (error) {
        //     console.error('Stripe Checkout error:', error);
        // }
    };
    
    return (
        <React.Fragment>
            <div className="page-content pt-4">
                <Container fluid>
                    <Row className="justify-content-center mt-4">
                        <Col lg={5}>
                            <div className="text-center mb-4">
                                <h4 className="fw-bold fs-22">Plans & Pricing</h4>
                                <p className="text-muted mb-4 fs-15">Simple pricing. No hidden fees. Advanced features for your business.</p>

                                <div className="d-inline-flex">
                                    <Nav className="nav-pills arrow-navtabs plan-nav rounded mb-3 p-1" id="pills-tab" role="tablist">
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '2' }, 'fw-semibold')}
                                                onClick={() => { toggleTab('2'); }}
                                            >Prepaid
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                href="#"
                                                className={classnames({ active: activeTab === '1' }, 'fw-semibold')}
                                                onClick={() => { toggleTab('1'); }}
                                            >Postpaid
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row className="justify-content-center">
                        <Col xl={9}>
                            <Row className='justify-content-center'>
                                {(filteredPricing || []).map((price, key) => (
                                    <Col lg={4} key={key}>
                                        <Card className="pricing-box ribbon-box right">
                                            {price.ribbon === true ? <div className="ribbon-two ribbon-two-danger"><span>Popular</span></div> : ""}
                                            <CardBody className="p-4 m-2">
                                                <div className="d-flex align-items-center">
                                                    <div className="flex-grow-1">
                                                        <h5 className="mb-1 fw-bold">{price.type}</h5>
                                                        {/* <p className="text-muted mb-0">{price.purpose}</p> */}
                                                    </div>
                                                    <div className="avatar-sm">
                                                        <div className="avatar-title bg-light rounded-circle text-primary">
                                                            <i className={"fs-20 " + price.icon}></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="pt-4">
                                                    <div className="ms-auto">
                                                        <TabContent activeTab={activeTab}>
                                                            <TabPane tabId="1">
                                                                <h2 className="month mb-0">&#163;{price.billingCapped} <small className="fs-13 text-muted">/Year</small></h2>
                                                            </TabPane>
                                                            <TabPane tabId="2">
                                                                <h2 className="annual mb-0"><small className="fs-16"></small> &#163;{price.billingCapped} <small className="fs-13 text-muted">/Year</small></h2>
                                                            </TabPane>
                                                        </TabContent>
                                                    </div>
                                                </div>
                                                <hr className="my-4 text-muted" />
                                                <div>
                                                    <ul className="list-unstyled text-muted vstack gap-3">
                                                        {activeTab === '1' ? (
                                                            <>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            Billing done of &#163;10 per applicant <span>{`(Billed monthly)`}</span>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            All Zilter features
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            Billing capped at <b>&#163;{price.billingCapped}</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            For companies <b>{price.forCompanies}</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            <b>{price.idealFor}</b>
                                                                        </div>
                                                                    </div>
                                                                </li>
                                                                <li>
                                                                    <div className="d-flex">
                                                                        <div className="flex-shrink-0 text-success me-1">
                                                                            <i className="ri-checkbox-circle-fill fs-15 align-middle"></i>
                                                                        </div>
                                                                        <div className="flex-grow-1">
                                                                            All Zilter features
                                                                        </div>
                                                                    </div>
                                                                </li>

                                                            </>
                                                        )
                                                        }
                                                    </ul>
                                                    {/* <div>
                                                        <Label>
                                                            Please make sure to review our <span>
                                                                <Link to={`${TERMS_AND_CONDITIONS}`} className="link-primary fw-bold" target="_blank" rel="noopener noreferrer">
                                                                    &nbsp;<strong>Terms & Conditions</strong>&nbsp;
                                                                </Link>
                                                            </span> before completing your subscription.
                                                        </Label>
                                                    </div> */}
                                                    <div className="form-check mb-3">
                                                    <Input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={`formCheck${price.id}`}
                                                        checked={(isChecked === price.id)}
                                                        onChange={() => setIsChecked(price?.id)}
                                                    />
                                                    <Label className="form-check-label" htmlFor={`formCheck${price.id}`}>
                                                        I agree and accept all <Link to={`${TERMS_AND_CONDITIONS}`} className="link-primary fw-bold" target="_blank" rel="noopener noreferrer">
                                                                    &nbsp;<strong>Terms & Conditions</strong>&nbsp;
                                                                </Link>.
                                                    </Label>
                                                </div>
                                                <div className="mt-4">
                                                    <Button
                                                        disabled={!(isChecked === price.id)}
                                                        onClick={() => handleCheckout(price)}
                                                        className={`btn btn-${price.planButtonClassname} w-100 waves-effect waves-light`}
                                                    >
                                                        {price.btntxt}
                                                    </Button>
                                                </div>
                                                </div>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))}
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default PricingModel;