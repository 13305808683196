import React, { useEffect, useState } from "react";
import {
  Label,
  Input,
  Form,
  FormGroup,
  FormFeedback,
} from "reactstrap";

interface DtsCheckboxProps {
  label: string;
  value: boolean;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  className?: string;
  checked?: boolean;
  name?: any;
  rest?: any;
  actionButtons?: any;
  setdisabled?: any;
  setError?: any;
  formerror?: any;
  resetvalue?: any;
}

const DtsCheckbox: React.FC<DtsCheckboxProps> = ({ setdisabled, rest, label, value, onChange, name }) => {
    const [errormsg, setErrormsg] = useState();
    const onhandleChange = (value: any) => {
        onChange(value)
        validateInput(value);
    }
    const validateInput = (value: any) => { 
      if(rest && rest.validate && rest.validate.required) {
          if(value === "") {
             let error: any =  `Please enter ${label}`;
              setErrormsg(error)
              return false
          }
      }
      setErrormsg(undefined)
      return true
  }
  useEffect(() => {
      if(rest && rest.validate) {           
          if(errormsg)
          { 
              if (typeof setdisabled === 'function') {
                  setdisabled(true)
              }
              const inputdiv: any = document.getElementById(`${name}feedback`);
              if(inputdiv) inputdiv.classList.add('d-block');
          } 
          else {
              if (typeof setdisabled === 'function') {
                  setdisabled(false)
              }
              const inputdiv: any = document.getElementById(`${name}feedback`);
              if(inputdiv) inputdiv.classList.remove('d-block');
          }            
      }
  },[value])
    useEffect(() => {
      if(rest && rest.validate && rest.validate.required) {           
          if(value === false)
          { 
              const inputdiv: any = document.getElementById(`${name}input`);
              if(inputdiv) inputdiv.classList.add('d-block');
          } 
          else {
              const inputdiv: any = document.getElementById(`${name}input`);
              if(inputdiv) inputdiv.classList.remove('d-block');
          }            
      }
  },[value])

  useEffect(() => {
    if(rest && rest.validate && rest.validate.required) { 
        const feedback: any = document.getElementById(`starred${name}`);
        if(feedback) feedback.classList.remove('d-none');
        }
    },[rest])

  useEffect(() => {
      if(rest && rest.validate && rest.validate.required) { 
          const feedback: any = document.getElementById(`${name}`);
          if(feedback) feedback.classList.remove('d-none');
      }
  },[rest])
  return (
    <Form>
      <div className="form-check">
          <Input
            className={"form-check-input"}
            type={"checkbox"}
            checked={value}
            onChange={(e:any) => onhandleChange(e.target.checked)}
          />
          <Label htmlFor={name} className="form-label">{label}<span id={`starred${name}`} className="d-none fw-bold text-danger">{" "}*</span></Label>
          {<FormFeedback id={`${name}input`}>{errormsg}</FormFeedback>}
        </div>
    </Form>
  );
};

export default DtsCheckbox;