import DtsTablePlaceholder from "Components/Common/FormBuilder/DtsTablePlaceholder";
import React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    Button
} from "reactstrap";
import FeatherIcon from "feather-icons-react";
import { handleSelectValue } from "utils";

const PreviewModal: React.FC<any> = ({recommendLoading, onRemove,disabled, handleButtonClick, props,link, createRecommend,show, onCloseClick, selectedlist, shortlistFields }: any) => {  
    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    };  
   return (<>
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="lg"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
                Review and Send
            </ModalHeader>
            <ModalBody>
                {
                        link ? 
                        <div className="p-5 ms-4 text-center">
                            <h5>
                                Shortlisted Course Sent Successfully...!
                            </h5>                            
                            <Button className="btn-success" onClick={() => handleButtonClick()}>Copy Link</Button>
                        </div>
                    :<div>
                    {!recommendLoading ? 
                        <div className="table-responsive">
                            <table className="table align-middle position-relative table-nowrap">
                                <thead className="cursor-pointer table-active">
                                    <tr>
                                        <th>
                                            Sr. no
                                        </th>
                                        {shortlistFields?.length && shortlistFields.map((item: any) => {
                                            return <th>
                                                {item.label}
                                            </th>
                                        })}
                                    </tr>
                                </thead>
                                <tbody id="task-list">                                    
                                    {selectedlist && selectedlist.map((record: any, key: any) => {
                                            return <tr>
                                                 <td>
                                                    <button className="btn btn-sm btn-soft-danger remove-list" onClick={() => onRemove(record, false)}
                                                                >
                                                        <i className="ri-delete-bin-fill"></i>
                                                    </button>
                                                </td>
                                                {
                                                shortlistFields && shortlistFields.length && shortlistFields.map((fields: any) => {
                                                    let rendervalue = record[fields.key];          
                                                    if(fields.tableRenderType == 'hyperlink' && record?.[fields.key] && record?.[fields.key] !== "NA") {
                                                        rendervalue = <div className='ms-3'>
                                                                <FeatherIcon icon="link-2" className="icon-xs text-primary cursor-pointer" onClick={() => handleIconClick(record[fields.key])}/>
                                                            </div>
                                                    }                            
                                                    else if(fields.tableRenderType == 'tag') {            
                                                        let value = record?.[fields.key]
                                                        let intakearray = value.split(",")
                                                       rendervalue = <div>
                                                            {intakearray?.length && intakearray.map((item: any) => {                                          
                                                                return <div className='ms-0'>
                                                                    <span className="badge bg-success">{handleSelectValue(item, fields.values)}</span>
                                                                </div>
                                                            })}
                                                        </div>
                                                    }                                                    
                                                    else if (fields?.key && fields?.optionLabel) {
                                                        rendervalue = record?.[fields.key]?.[fields.optionLabel] ? record?.[fields.key]?.[fields.optionLabel] : 'NA'
                                                    }         
                                                    if (fields.type == 'select' || fields.type == 'radio' || fields.type == 'checboxes') {
                                                        rendervalue = handleSelectValue(record[fields.key], fields.values);
                                                    }
                                                    return <td>{rendervalue}</td>
                                                    })
                                                }
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                            
                        </div>: <DtsTablePlaceholder 
                            rows={selectedlist.length} cols={5}                                     
                            tableClass="align-middle table-nowrap"
                            thClass="border-bottom-1 table-soft-primary"
                        />
                    }
                    </div>
                }
            </ModalBody>
            {
                        link ? null :
                <div className="Modal-footer border-top p-3 text-center hstack gap-2 justify-content-center">
                    <Button
                        className="btn btn-primary w-auto"
                        onClick={() => createRecommend()}
                        disabled={disabled}
                    >
                        {props.t("courses.generate_shortlist")}
                    </Button>
                    <Button
                        className="btn btn-light w-auto"
                        onClick={() => onCloseClick()}
                    >
                        {props.t("courses.cancel")}
                    </Button>
                </div>
            }
        </Modal>
    </>
    );
};

export default PreviewModal;
