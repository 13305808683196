import React, { Fragment, useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Table, Row, Col, Button, CardBody, Input, Label, DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";


interface TableContainerProps {
    columns?: any;
    tableClass?: any;
    theadClass?: any;
    thClass?: any;
    rows?: any;
    cols?: any;
}

const TableContainer = ({
    columns,
    tableClass,
    theadClass,
    thClass,
    rows,
    cols
}: TableContainerProps) => {
    let innitialrows= rows || 10 
    let placeholdercolumn = cols || 8;
    return (
        <div>
            <Table responsive={true} hover className={tableClass + 'm-0'}>
                <thead className={theadClass}>
                    <tr>
                    {[...Array(placeholdercolumn)].map((_, index) => {
                            return <th className={thClass + " fw-bold placeholder-glow"}>
                                    <span className="placeholder placeholder-sm col-12"></span>
                            </th>
                        })}</tr>
                </thead>
                <tbody >
                    {[...Array(innitialrows)].map((_, index) => {
                        return <tr>
                         {[...Array(placeholdercolumn)].map((_, index) => {
                            return <td key={index} className="placeholder-glow">
                                <span className="placeholder col-12"></span>
                            </td>
                        })}</tr>}
                    )}
                </tbody>
            </Table>
        </div>
    );
};

export default withTranslation()(TableContainer);