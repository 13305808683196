import React, { useState, useRef } from 'react'
import axios from 'axios'
import New from './New'
import Uploading from './Uploading'
import { ApplicationState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import config from "../../../../config"
import { useParams } from 'react-router'
import { downloadFinanceBulkTemplate, financeChannelListGetRequest } from 'store/finance/action'
const { DTS_API_STUDENT } = config.api;

interface FProps {
    selectedFiles: any;
    setselectedFiles: (event: any) => void;
    setModalFile:(event: boolean) => void;
    fileInfo: any;
    filterObject: any;
    setFileInfo:  (event: any) => void;
}
const Upload: React.FC<FProps> = ({
    selectedFiles,
    setselectedFiles,
    setModalFile,
    fileInfo,
    setFileInfo,
    filterObject
}
) => {
    const { id } = useParams();
    const dispatch: any = useDispatch();
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const [percentage, setPercentage] = useState(0)
    const [uploading, setUploading] = useState('not_started')
    
    const SubmitUpload = async () => {
        const file = selectedFiles[0];
        setFileInfo(file)
        if (file) {
                setUploading('started');
                const formData = new FormData();
                formData.append('file', file);
                setUploading('started');
                const config = {
                    onUploadProgress: function (progressEvent: any) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        setPercentage(percentCompleted - 10)
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                axios.post(`${DTS_API_STUDENT}/commissions/upload/channel/{channelId}?channelId=${id}`, formData, config)
                .then((res) => {
                    setPercentage(100)
                    setUploading('completed')
                    setModalFile(false)
                    // dispatch(financeChannelListGetRequest(filterObject, 0));
                })
        }
    }

    const onClickFileDownload = (file: any) => {
        const handleSuccess = async (body: any) =>{

            try {        
                // Create a Blob from the response data
                const fileBlob = await body.blob();
        
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
        
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", file.documentName); // Set the desired filename for the downloaded file
        
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
        
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () =>{
            
        }
        dispatch(downloadFinanceBulkTemplate(file.documentKey, handleSuccess, handleFailure))
    }

    let component = null;
    switch(uploading) {
        case 'not_started':
            component = <New handleDownloadTemplate={onClickFileDownload} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} SubmitUpload={SubmitUpload} />
            break;
        case 'started':
            component = <Uploading file={fileInfo} percentage={percentage} />
            break;
    }
    return (
        <div>
            {component}
        </div>
    )
}
export default Upload