export interface sourcesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface Source {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum SourceActionTypes {
    GET_SOURCEMETADATA_REQUEST = "@@source/GET_SOURCEMETADATA_REQUEST",
    GET_SOURCEMETADATA_SUCCESS = "@@source/GET_SOURCEMETADATA_SUCCESS",
    GET_SOURCEMETADATA_ERROR = "@@source/GET_SOURCEMETADATA_ERROR",
    GET_SOURCE_REQUEST = "@@source/GET_SOURCE_REQUEST",
    GET_SOURCE_SUCCESS = "@@source/GET_SOURCE_SUCCESS",
    GET_SOURCE_ERROR = "@@source/GET_SOURCE_ERROR",
    GET_SOURCES_REQUEST = "@@source/GET_SOURCES_REQUEST",
    GET_SOURCES_SUCCESS = "@@source/GET_SOURCES_SUCCESS",
    GET_SOURCES_ERROR = "@@source/GET_SOURCES_ERROR",
    PUT_SOURCE_REQUEST = "@@source/PUT_SOURCE_REQUEST",
    PUT_SOURCE_SUCCESS = "@@source/PUT_SOURCE_SUCCESS",
    PUT_SOURCE_ERROR = "@@source/PUT_SOURCE_ERROR",
    DELETE_SOURCE_REQUEST = "@@source/DELETE_SOURCE_REQUEST",
    DELETE_SOURCE_SUCCESS = "@@source/DELETE_SOURCE_SUCCESS",
    DELETE_SOURCE_ERROR = "@@source/DELETE_SOURCE_ERROR",
    POST_SOURCE_REQUEST = "@@source/POST_SOURCE_REQUEST",
    POST_SOURCE_SUCCESS = "@@source/POST_SOURCE_SUCCESS",
    POST_SOURCE_ERROR = "@@source/POST_SOURCE_ERROR",
    GET_SOURCES_HIERARCHY_REQUEST = "@@source/GET_SOURCES_HIERARCHY_REQUEST",
    GET_SOURCES_HIERARCHY_SUCCESS = "@@source/GET_SOURCES_HIERARCHY_SUCCESS",
    GET_SOURCES_HIERARCHY_ERROR = "@@source/GET_SOURCES_HIERARCHY_ERROR",
}

export interface SourceState {
    readonly list: Source[] | null;
    readonly details: Source | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: sourcesMetadata[];
    readonly hierarchysources: any;
    readonly loading: boolean;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
}
