import React, { useEffect, useState } from "react";
import { Col, Input, Label, Row, Spinner } from "reactstrap";

interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}

interface Step5Props {
  toggleArrowTab: () => void;
  inputs: SignUpState;
  setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
  onCreate: () => void;
  t: (key: string) => string;
  OnUpdate: (object: any) => void;
  loading: boolean;
  isInitialValue: boolean;
}

const Step5: React.FC<Step5Props> = ({ toggleArrowTab, inputs, setInputs, onCreate, t, OnUpdate, loading, isInitialValue}: Step5Props) => {
  const onClickUpdate = () => {
    const attributes = [
        {
            "key": "company_name",
            "value": inputs.company_name
        }
    ]
    OnUpdate(attributes)
}
let value = inputs.company_name && inputs.company_name != "" ? inputs.company_name.charAt(0).toUpperCase() + inputs.company_name.slice(1) : "";

  return (
    <div>
      <Row className="hstack gap-3 flex-column justify-content-center">
        <Col lg={12}>
          <div className="hstack fs-32 fw-bold justify-content-center">
            <h5 className="fw-bold">{t("signup.what_is_your_companys_name")}</h5>
          </div>
        </Col>
        <Col lg={3}>
          <div className="mb-3">
            <Label htmlFor="dts_company" className="form-label">{t("signup.company_name")}</Label>
            <Input
              type="text"
              value={value}
              onChange={(e) => setInputs({ ...inputs, company_name: e.target.value })}
              className="form-control"
              id="dts_company"
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                }
              }}
              placeholder={t("signup.enter_company_name")}
            />
          </div>
          {isInitialValue ? <p className="fs-14">{t("signup.company_disclaimer")}.</p> : null }
        </Col>
        <Col lg={3}>
          <button type="button" className="w-100 btn btn-primary btn-icon waves-effect waves-light" onClick={onClickUpdate} disabled={!inputs.company_name}>{loading ?  <span className="d-flex align-items-center">
              <span className="flex-grow-1 me-2">
                  Loading...
              </span>
              <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
          </span> : t("signup.next")}</button>{" "}
        </Col>
      </Row>
    </div>
  );
};

export default Step5;

