import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import FeatherIcon from "feather-icons-react";


const DeleteModal = ({ props, show, onCloseClick, onDelete, record }: any) => {

    return (
        <Modal id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
                {props.t("users.delete_the_user")}
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <div className="mt-1">
                    <FeatherIcon icon="trash-2" className="mb-1 text-danger display-2" style={{ width: 60, height: 60 }} />
                    <h4 className="mb-2 mt-2">{record.name}</h4>
                    <div className="hstack gap-2 justify-content-center">
                        <Button className="btn btn-danger" onClick={() => onDelete()}>
                            {props.t("users.delete")}
                        </Button>
                        <Button color="danger" onClick={() => onCloseClick()} outline>
                            {props.t("users.cancel")}
                        </Button>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default DeleteModal;