import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import config from "../../config";
import {
    Modal,
    ModalHeader,
    ModalBody,
    CardBody,
    Row,
    Col,
    Label,
    Table,
    Card,
} from "reactstrap";
import generatePDF, { Resolution, Margin } from 'react-to-pdf';
import axios from "axios";
import { ApplicationState } from "store";
import { toast } from "react-toastify";
import DtsSpinners from "Components/Common/DtsSpinners";

const { DTS_API_STUDENT } = config.api;

const PreviewModal: React.FC<any> = ({ show, onCloseClick, formValues, record, ontoggle, StudentsList, targetRef}: any) => {    
    const navigate = useNavigate()
    const [percentage, setPercentage] = useState(0)
    const [blobFile, setBlobFile] = useState<any>(null)
    const [uploading, setUploading] = useState('not_started')
    const [loading, setLoading] = useState(false)
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
     // you can use a function to return the target element besides using React refs
     const getTargetElement: any = () => document.getElementById('content-id');  
    // const { toPDF, targetRef } = usePDF({filename: 'page.pdf'});
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    let fileno = formValues?.invoiceNo ? formValues?.invoiceNo : "";
    const options: any = {
        filename: `Inv_${fileno}.pdf`,
        // default is `save`
        method: 'build',
        // default is Resolution.MEDIUM = 3, which should be enough, higher values
        // increases the image quality but also the size of the PDF, so be careful
        // using values higher than 10 when having multiple pages generated, it
        // might cause the page to crash or hang.
        resolution: Resolution.NORMAL,
        page: {
           // margin is in MM, default is Margin.NONE = 0
           margin: Margin.MEDIUM,
           // default is 'A4'
           format: 'A4',
           // default is 'portrait'
           orientation: 'portrait',
        },
        canvas: {
           // default is 'image/jpeg' for better size performance
           mimeType: 'image/png',
           qualityRatio: 1
        },
        // Customize any value passed to the jsPDF instance and html2canvas
        // function. You probably will not need this and things can break, 
        // so use with caution.
        overrides: {
           // see https://artskydj.github.io/jsPDF/docs/jsPDF.html for more options
           pdf: {
              compress: true
           },
           // see https://html2canvas.hertzen.com/configuration for more options
           canvas: {
              useCORS: true
           }
        },
     };
     
     const getpdf = async () => {
        let pdf =  await generatePDF(getTargetElement, options);
        let Url = pdf.output("bloburl");
        const response = await fetch(Url);
        const blob = await response.blob();        
    };
    const handlestatus = (paid: any, amount: any) => {
        let balance = amount - paid;
        if(balance > 0 && paid == 0) return 'unpaid'
        else if(balance > 0 && paid > 0) return 'partially_paid'
        else if(balance == 0) return 'paid';        
		else if (balance < 0) return 'advanced_paid';
        else return 'unpaid';
    }

      const sendBlobAsFormData = async (isSendInvoice: any) => {  
        let payment_status= handlestatus(formValues.paidAmount, formValues.invoice_amount)
        let newFormValues = {...formValues, invoice_status: isSendInvoice, payment_status: payment_status, channels: formValues.channel_id}
        let pdf =  await generatePDF(getTargetElement, options);
        let Url = pdf?.output("bloburl");
        const response = await fetch(Url);
        const blob = await response.blob();
        if (blob) {
            const formData = new FormData();
            formData.append('documentType', "pdf");
            formData.append('isSendInvoice', isSendInvoice);
            formData.append('invoiceJson', JSON.stringify(newFormValues));
            formData.append('model', "channels");
            formData.append('file', blob);
            setUploading('started');
            const config = {
                onUploadProgress: function (progressEvent: any) {
                    const percentCompleted = Math.round(
                        (progressEvent.loaded * 100) / progressEvent.total,
                    )
                    setPercentage(percentCompleted - 10)
                },
                headers: {
                    'Content-Type': 'multipart/form-data',
                    "token": access_token
                },
            }            
            axios.post(`${DTS_API_STUDENT}/invoices`, formData, config)
            .then((res) => {
                const message = () => toast(isSendInvoice ?  `Invoice sent Successfully` : `Invoice saved Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
                message();
                onCloseClick()
                setLoading(false)
                navigate('/channels/invoices')
            })
    }
      }

    const preview = async () => {
		let newoptions = {...options,  method: 'open'}
        let pdf =  await generatePDF(getTargetElement, newoptions);
        let Url = pdf.output("bloburl");
        const response = await fetch(Url);
        const blob = await response.blob();    
        setBlobFile(blob)   
        sendBlobAsFormData(false) 
      };

    return (<>
    {loading ? <DtsSpinners /> :
        <Modal
            direction="end"
            isOpen={show}
            id="ModalExample"
            toggle={onCloseClick}
            size="lg"
        >
            <ModalHeader className="bg-light" toggle={onCloseClick}>
            </ModalHeader>
            <ModalBody>
                <div ref={targetRef} id="content-id" >
                    <CardBody className="border-bottom border-bottom-dashed p-0">
                        <Row className="hstack justify-content-end">
                            <Col lg={12} md={12} xl={12}className="hstack justify-content-end">  
                                <div className="justify-content-end">
                                    <h1>INVOICE</h1>
                                    <span>#{formValues.invoiceNo}</span>
                                </div>
                            </Col>
                        </Row>
                        <Row className="justify-content-center">
                            <Col xxl={12} xl={12} md={12} lg={12}>
                                <Card>
                                    <CardBody className="border-bottom border-bottom-dashed py-2 px-2">
                                        <Row className="hstack justify-content-between">
                                            <Col lg={4} md={4} xl={4}>
                                                <div className="profile-user mx-auto  mb-1 mt-2">
                                                    <Label for="profile-img-file-input" className="d-block">
                                                        {/* <span
                                                            className="border border-dashed d-flex align-items-center justify-content-center rounded"
                                                            style={{ height: "100px", width: "100px" }}
                                                        >{formValues?.company_logo ?
                                                            <img
                                                                src={formValues?.company_logo}
                                                                className="card-logo card-logo-dark user-profile-image img-fluid"
                                                                alt="logo dark"
                                                                width={100}
                                                                height={100}
                                                            />
                                                            : null}
                                                        </span> */}
                                                        <span
                                                            className="border border-dashed d-flex align-items-center justify-content-center rounded"
                                                            
                                                        >{formValues?.company_name ?
                                                        formValues?.company_name
                                                            : null}
                                                        </span>
                                                    </Label>
                                                </div>
                                                <div>
                                                    <div className="vstack">
                                                        <Label for="companyAddress">Address</Label>
                                                        <Label for="companyAddress">{formValues?.company_address}</Label>
                                                    </div>
                                                    <div  className="vstack">
                                                        <Label for="companyAddress">Postal Code</Label>
                                                        <Label for="companyAddress">{formValues?.company_postal_Code}</Label>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col lg={7} md={7} xl={7} className="align-self-start mt-4">
                                                <div className="mb-1 hstack justify-content-between">
                                                    <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Registration Number:</Label>
                                                    <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_registrationNo}</Label>
                                                </div>
                                                <div className="mb-1 hstack justify-content-between">
                                                    <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Email:</Label>
                                                    <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_email}</Label>
                                                </div>
                                                <div className="mb-1 hstack justify-content-between">
                                                    <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Website:</Label>
                                                    <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_website}</Label>
                                                </div>
                                                <div className="mb-1 hstack justify-content-between">
                                                    <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Contact Number:</Label>
                                                    <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.company_phone}</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>                               
                                    <CardBody className="p-4 border-top border-top-dashed px-2">
                                        <Row className="w-100 hstack justify-content-between">
                                            <Col lg={5} sm={12} md={5} xl={5}>
                                                <div>
                                                    <Label
                                                        for="billingName"
                                                        className="text-muted text-uppercase fw-semibold mb-1"
                                                    >
                                                        Billing Address
                                                    </Label>
                                                </div>
                                                <div className="mb-1">
                                                    <Label for="totalamountInput">{formValues?.channel_name}</Label>
                                                </div>
                                                <div className="mb-1">
                                                    <Label for="totalamountInput">{formValues?.channel_address}</Label>
                                                </div>
                                                <div className="mb-1">
                                                    <Label for="totalamountInput">{formValues?.channel_phone}</Label>
                                                </div>
                                                <div className="mb-1">
                                                    <Label for="totalamountInput">{formValues?.channel_email}</Label>
                                                </div>
                                                <div className="mb-1">
                                                    <Label for="totalamountInput">{formValues?.channel_taxNumber}</Label>
                                                </div>
                                            </Col>
                                            <Col lg={5} sm={12} md={5} xl={5} className="align-self-start">                                           
                                                <div className="mb-1 hstack justify-content-between">
                                                    <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Invoice Date:</Label>
                                                    <Label className="text-muted mb-1 hstack justify-content-end w-50">{moment(formValues?.invoiceDate).format('DD-MM-YYYY')}</Label>
                                                </div>
                                                <div className="mb-1 hstack justify-content-between">
                                                    <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Invoice No:</Label>
                                                    <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.invoiceNo}</Label>
                                                </div>
                                                <div className="mb-1 hstack justify-content-between">
                                                    <Label  className="fw-bold mb-1 hstack justify-content-end w-50">Balance Due:</Label>
                                                    <Label className="fw-bold mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.balanceDue}</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </CardBody>
                                    <CardBody className="p-4 px-2">
                                        <div className="table-responsive">
                                            <Table className="invoice-table table-borderless table-nowrap mb-0">
                                                <thead className="align-middle">
                                                    <tr className="table-active">
                                                        <th scope="col" style={{ width: "50px" }}>
                                                            #
                                                        </th>
                                                        <th scope="col">Student Details</th>
                                                        <th scope="col" style={{ width: "120px" }}>
                                                            <div className="d-flex currency-select input-light align-items-center text-center ">
                                                                Rate
                                                            </div>
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-center"
                                                            style={{ width: "200px" }}
                                                        >
                                                            Tax
                                                        </th>
                                                        <th
                                                            scope="col"
                                                            className="text-center"
                                                            style={{ width: "150px" }}
                                                        >
                                                            Amount
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody id="newlink">
                                                    {StudentsList && StudentsList.length ? StudentsList.map((item: any, index: any) => {
                                                        return <tr id="1" className="product">
                                                            <td scope="row" className="product-id">
                                                                {index + 1}
                                                            </td>
                                                            <td className="text-start">
                                                                <div className="mb-2">
                                                                    {item?.student?.name}
                                                                </div>
                                                                {item?.institutes?.name}<br />
                                                                {item.courses.name}
                                                                ({item?.courses?.currency}{item?.courses?.course_fee})<br />
                                                                {item?.taxIncluded ? "Tax is included" : "Tax is excluded"}
                                                                ({item?.taxPercentage}{item?.taxType == "percent" ? "%" : ""})
                                                            </td>
                                                            <td className="justify-content-center">
                                                                <Label for="totalamountInput">{item?.commission}</Label>
                                                            </td>
                                                            <td className="text-end">
                                                            {
                                                                item.taxType == 'percent' ?
                                                                <div className="text-center position-relative w-100">
                                                                    <Label for="totalamountInput">{item?.taxPercentage}%</Label> 
                                                                </div>
                                                                : 
                                                                <div className="text-center position-relative w-100">
                                                                    <Label for="totalamountInput">{formValues?.currency?.currency}{item?.taxPercentage}</Label> 
                                                                </div>
                                                            }
                                                            </td>
                                                            <td className="text-end">
                                                                <div className="text-center position-relative w-100">
                                                                    <Label for="totalamountInput">{formValues?.currency?.currency}{item?.itemAmount}</Label> 
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    }) : null}

                                                </tbody>
                                            </Table>
                                        </div>
                                        <br />
                                        <br />
                                        <br />
                                        <br /><br /><br /><br /><br />
                                        <CardBody>
                                            <Row className="mt-1 justify-content-between">
                                                <Col  lg={5} md={5} xl={5}>
                                                    <Label>NOTES</Label>
                                                    <div className="mb-2">
                                                        <Label>{formValues.bank_name}</Label>
                                                    </div>
                                                    <div>
                                                        <Label>{formValues.account_name}</Label>
                                                    </div>
                                                    <div>
                                                        <Label>{formValues.account_number}</Label>
                                                    </div>
                                                    <div>
                                                        <Label>{formValues.ifsc}</Label>
                                                    </div>
                                                </Col>		
                                                <Col lg={5} className="mt-1">
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Subtotal:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.subtotal}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label className="fw-semibold mb-1 hstack justify-content-end w-50">Tax:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.totaltax}</Label>
                                                 
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Total:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.invoice_amount}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Amount Paid:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.paidAmount ? formValues?.paidAmount : 0}</Label>
                                                    </div>
                                                    <div className="mb-1 hstack justify-content-between">
                                                        <Label  className="fw-semibold mb-1 hstack justify-content-end w-50">Balance Due:</Label>
                                                        <Label className="text-muted mb-1 hstack justify-content-end w-50">{formValues?.currency?.currency}{formValues?.balanceDue}</Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col lg={12}>
                                                    <div className="mt-2 vstack">
                                                        <Label
                                                            for="exampleFormControlTextarea1"
                                                            className="form-label text-muted text-uppercase fw-semibold"
                                                        >
                                                            TERMS
                                                        </Label>
                                                        <Label for="totalamountInput">{formValues?.terms}</Label>                                                
                                                    </div>
                                                </Col>
                                            </Row>
                                        </CardBody>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </CardBody>
                </div>
                <Row className="text-center gap-2">
                    <button
                        onClick={() => {preview(); 
                            setLoading(true)}}
                        className="btn btn-primary w-auto"
                    >
                        <i className="ri-article-line align-bottom me-1"></i> 
                       Draft
                    </button>                    
                    {/* <button type="submit" className="btn btn-success  w-auto" onClick={() => {sendBlobAsFormData("false")}}>
                        <i className="ri-printer-line align-bottom me-1"></i> Save
                    </button> */}
                    <button type="submit" className="btn btn-success  w-auto" onClick={() => {sendBlobAsFormData("true");
        setLoading(true)}}>
                        <i className="ri-printer-line align-bottom me-1"></i> Save and Send Invoice
                    </button>

                </Row>
            </ModalBody>
        </Modal>
    }
    </>
    );
};

export default PreviewModal;
