import { Reducer } from "redux";
import { TemplateActionTypes, TemplatesState } from "./types";


export const initialState: TemplatesState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    metaLoading: false
}
const reducer: Reducer<TemplatesState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case TemplateActionTypes.GET_TEMPLATEMETADATA_REQUEST: {
            return { ...state, metaLoading: true };
        }
        case TemplateActionTypes.GET_TEMPLATEMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case TemplateActionTypes.GET_TEMPLATEMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload };
        }
        case TemplateActionTypes.GET_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.GET_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case TemplateActionTypes.GET_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case TemplateActionTypes.POST_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.POST_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case TemplateActionTypes.POST_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TemplateActionTypes.GET_TEMPLATES_REQUEST: {
            return { ...state, dataLoading: true, list:[] };
        }
        case TemplateActionTypes.GET_TEMPLATES_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case TemplateActionTypes.GET_TEMPLATES_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload, list:[]};
        }
        case TemplateActionTypes.PUT_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.PUT_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case TemplateActionTypes.PUT_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case TemplateActionTypes.DELETE_TEMPLATE_REQUEST: {
            return { ...state, loading: true };
        }
        case TemplateActionTypes.DELETE_TEMPLATE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case TemplateActionTypes.DELETE_TEMPLATE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as TemplatesReducer };
