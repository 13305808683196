import React from 'react';
import { Container, Row, Col } from 'reactstrap';

interface HighlightedLogicProps {
  logic: string;
  onClick: () => void;
}

const HighlightedLogic: React.FC<HighlightedLogicProps> = ({ logic, onClick }) => {
  // Split the logic string on "AND" and "OR" while keeping the delimiters
  const formattedLogic = logic.split(/(AND|OR|and|or)/i);

  return (
    <div onClick={onClick}  className="d-flex p-2 border flex-wrap align-items-center">
        {formattedLogic.map((part, index) => {
          const trimmedPart = part.trim().toUpperCase();
          if (trimmedPart === "AND") {
            return (
              <span key={index} className="text-black-50 mx-1 fw-bold" style={{fontSize: "11px"}}> {/* Green for AND */}
                {trimmedPart}
              </span>
            );
          } else if (trimmedPart === "OR") {
            return (
              <span key={index} className="text-success mx-1 fw-bold" style={{fontSize: "11px"}}> {/* Red for OR */}
                {trimmedPart}
              </span>
            );
          } else {
            return (
              <span key={index} className="fw-semibold"> {/* Bold for conditions */}
                {part.trim()}
              </span>
            );
          }
        })}
    </div>
  );
};

export default HighlightedLogic;
