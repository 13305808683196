import { Reducer } from "redux";
import { UserActionTypes, UserState } from "./types";


export const initialState: UserState =
{
    users: null,
    user: null,
    pagination: null,
    loading: false,
}
const reducer: Reducer<UserState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case UserActionTypes.GET_USER_REQUEST: {
            return { ...state, loading: true };
        }
        case UserActionTypes.GET_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                user: action.payload
            };
        }
        case UserActionTypes.GET_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case UserActionTypes.GET_USERS_REQUEST: {
            return { ...state, loading: true };
        }
        case UserActionTypes.GET_USERS_SUCCESS: {
            // const totalPages = Math.ceil(action.payload.total / action.payload.size);
            const page = action.payload.number || 1; // Set page to 1 if undefined
            const total = action.payload.totalPages;
            const size = action.payload.numberOfElements;
            let pageNumbers: number[] = [];
            let totalPages : number;
            if (typeof total === 'number' && typeof size === 'number' && size !== 0) {
                // totalPages = Math.ceil(total / size);
                totalPages = Math.ceil(total / size);
                pageNumbers = Array.from({ length: total }, (_, index) => index);
            }
            return {
                ...state,
                loading: false,
                users: action.payload.content,
                pagination: {
                    page,
                    total,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    offset: action.payload.pageable.offset

                }
            };
        }
        case UserActionTypes.GET_USERS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case UserActionTypes.PUT_USER_REQUEST: {
            return { ...state, loading: true };
        }
        case UserActionTypes.PUT_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case UserActionTypes.PUT_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case UserActionTypes.DELETE_USER_REQUEST: {
            return { ...state, loading: true };
        }
        case UserActionTypes.DELETE_USER_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case UserActionTypes.DELETE_USER_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case UserActionTypes.POST_INVITE_REQUEST: {
            return { ...state, loading: true };
        }
        case UserActionTypes.POST_INVITE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case UserActionTypes.POST_INVITE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case UserActionTypes.PUT_ROLE_REQUEST: {
            return {...state, loading: true};
        }
        case UserActionTypes.PUT_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }
        case UserActionTypes.PUT_ROLE_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        default: {
            return state;
        }
    }
};

export { reducer as userReducer };
