import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import { productGetRequest, productsMetadataGetRequest, productUpdateRequest } from 'store/products/action';


const Details = (props: any) => {
    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>       
                <Section 
                    detailsGetAction={productGetRequest}
                    metaDatagetRequest={productsMetadataGetRequest}
                    updateAction={productUpdateRequest}
                    model={"products"}
                    reducerState={"products"}
                    translater={"product"}
                    label={"Product"}
                />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Details);