import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const ExportModal = ({ show, onCloseClick, data }: any) => {
    const [files, setFiles] = useState<any>([]);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    function handleAcceptedFiles(files: any) {
        files.map((file: any) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size),
                })
            }
        );
        setselectedFiles(files);
    }
    /**
     * Formats the size
     */
    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    return (
        <Modal  backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader toggle={onCloseClick}>
                <h4>Upload File</h4>
            </ModalHeader>
            <ModalBody className="py-3 px-5">
                <div>
                    <Dropzone
                        onDrop={acceptedFiles => {
                            handleAcceptedFiles(acceptedFiles);
                        }}
                    >
                        {({ getRootProps, getInputProps }) => (
                            <div className="dropzone dz-clickable">
                                <div
                                    className="dz-message needsclick"
                                    {...getRootProps()}
                                >
                                    <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                    {/* <input {...getInputProps()} accept='.csv' />
                                    <p className="fs-18">Click to select file</p> */}
                                    {selectedFiles.map((f: any, i: any) => {
                                           return <div className="fs-18">{f && f.name ? <span className="fs-16">Selected File: {f.name}</span> : <em>(Only *.csv file type will be accepted)</em>}</div>
                                    })}
                                </div>
                            </div>
                        )}
                    </Dropzone>
                    {/* <div className="list-unstyled mb-0" id="file-previews">
                        {selectedFiles.map((f: any, i: any) => {
                            return (
                                <Card
                                    className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                    key={i + "-file"}
                                >
                                    <div className="p-2">
                                        <Row className="align-items-center">
                                            <Col className="col-auto">
                                                <span className="hstack align-items-center">
                                                    <i className="ri-file-line rounded bg-light fs-18"></i>
                                                    {f.name}
                                                </span>
                                            </Col>
                                            <Col>
                                                <Link
                                                    to="#"
                                                    className="text-muted font-weight-bold"
                                                >
                                                    {f.name}
                                                </Link>
                                                <p className="mb-0">
                                                    <strong>{f.formattedSize}</strong>
                                                </p>
                                            </Col>
                                        </Row>
                                    </div>
                                </Card>
                            );
                        })}
                    </div> */}
                </div>
            </ModalBody>
            <ModalFooter>
                <Button onClick={() => onCloseClick()} outline={true}>
                    Upload
                </Button>
                <Button onClick={() => onCloseClick()}>
                    Cancel
                </Button>
            </ModalFooter>
        </Modal>
    ) as unknown as JSX.Element;
};

export default ExportModal;