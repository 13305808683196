import {TaskActionTypes, Task} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const taskGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: TaskActionTypes.GET_TASK_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/tasks/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: TaskActionTypes.GET_TASK_ERROR,
                    payload: body
                });
                // handleError(body);
            } else {
                dispatch({
                    type: TaskActionTypes.GET_TASK_SUCCESS,
                    payload: body
                });
                // handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

const createQueryLink = (id: any, filterObject: any, page?: any) => {
    const keys = filterObject ? Object.keys(filterObject) : [];
    const values = filterObject ? Object.values(filterObject): [];
    let query = `${DTS_API_STUDENT}/tasks/${id}?`;
    if (page) query += `page=${page}&`;
    for (let i = 0; i < keys.length; i++) {
        if (values[i]) query += `${keys[i]}=${values[i]}&`;
    }
    return query;
};
export const tasksListGetRequest: AppThunk = (id, queryParams, page) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: TaskActionTypes.GET_TASKS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        let searchQuery = createQueryLink(id, queryParams, page)       
        const response = await fetch(`${searchQuery}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: TaskActionTypes.GET_TASKS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: TaskActionTypes.GET_TASKS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const taskDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: TaskActionTypes.DELETE_TASK_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/tasks/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: TaskActionTypes.DELETE_TASK_ERROR,
            });
            handleError();
        } else {
            dispatch({
                type: TaskActionTypes.DELETE_TASK_SUCCESS,
            });
            handleSuccess();
        }
    };
};

export const taskAddRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: TaskActionTypes.POST_TASK_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/tasks`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: TaskActionTypes.POST_TASK_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: TaskActionTypes.POST_TASK_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};