import React, { useEffect, useState } from 'react';
import { Container, Label, Row } from 'reactstrap';
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from 'store';
import Form from './FormRender';
import { formGetRequest, getMataData } from 'store/customform/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { Link, useParams } from "react-router-dom";
import logoLight from "../../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import { ToastContainer } from 'react-toastify';
import { isJson } from 'utils';

const FormRender = () => { 
    const { tenantId , id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const formDetails = useSelector((state: ApplicationState) => state.customform.customformDetails);
    const metadata = useSelector((state: ApplicationState) => state.customform.metadata);
    const [formData, setFormData] = useState<any>({})    
    const [defaultValues, setDefaultValues] = useState<any>({})    
    const [title, setTile] = useState<any>("")    
    const [col, setColumn] = useState({})

    useEffect(() => {
        dispatch(getMataData(tenantId))
        dispatch(formGetRequest(tenantId,id))
      }, [])

      useEffect(() => {
        let fields: any = [];
        let newFormData = formDetails && formDetails.valuesJson && isJson(formDetails.valuesJson) ? JSON.parse(formDetails.valuesJson) : {}
         newFormData && newFormData?.components && newFormData?.components.length && newFormData.components.map((component: any)=> {
            metadata && metadata.length && metadata.map((field: any) => {                         
                let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
                let newField = {...item, id: field.id}                
                if(component.id == field.id) 
                {
                    fields.push({...newField,  ...component.extras})
                }
            })
        })  
        setFormData(fields)
        setColumn(newFormData.column)
        setDefaultValues(newFormData.values)
        setTile(newFormData.title)
      }, [formDetails, metadata])
    return (
        <React.Fragment>
            <ToastContainer />
            <Container>  
                <div className="navbar-brand-box horizontal-logo">
					<Link to="" className="logo logo-dark">
						<span className="logo-sm">
							<img src={logoLight} alt="" height="30" />
						</span>
						<span className="logo-lg">
							<img src={logoLight} alt="" height="30" />
						</span>
					</Link>

					<Link to="" className="logo logo-light">
						<span className="logo-sm">
							<img src={logoLight} alt="" height="30" />
						</span>
						<span className="logo-lg">
							<img src={logoLight} alt="" height="30" />
						</span>
					</Link>
				</div>  
                <Row>
                    <div className='hstack justify-content-center fw-bold fs-20 text-decoration-underline'>
                        <Label>{title}</Label>
                    </div>
                </Row>                       
                <Form tenantId={tenantId} dataFields={formData || []} md={col} lg={col} xl={col} BtnClassName={"w-auto justify-content-end"}  btn_text={'Submit'} data={defaultValues} />
            </Container>
        </React.Fragment>
    );
}

export default FormRender;