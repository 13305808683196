import React, { useEffect, useState } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Card, Col, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap";
import { FilePond, registerPlugin } from 'react-filepond';
import 'filepond/dist/filepond.min.css';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import Dropzone from "react-dropzone";
import { Link } from "react-router-dom";
import { WithTranslation } from 'react-i18next';
import FeatherIcon from "feather-icons-react";
interface RolesProps extends WithTranslation { }
// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);


const DeleteModal = ({ t, props, show, onCloseClick, data, onDelete, record }: any) => {
    const [files, setFiles] = useState<any>([]);
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    function handleAcceptedFiles(files: any) {
        files.map((file: any) => {
                Object.assign(file, {
                    preview: URL.createObjectURL(file),
                    formattedSize: formatBytes(file.size),
                })
            }
        );
        setselectedFiles(files);
    }
    /**
     * Formats the size
     */
    function formatBytes(bytes: any, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    return (
        <Modal id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
        <ModalHeader className="modal-title fw-bold" id="myModalLabel" toggle={() => { onCloseClick(); }}>
            {props.t("users.delete_the_user")}
        </ModalHeader>
        <ModalBody className='text-center p-5'>
            <div className="mt-1">     
                <FeatherIcon icon="trash-2" className="mb-1 text-danger display-2" style={{width:60, height: 60}} />                                   
                <h4 className="mb-2 mt-2">{record.name}</h4>
               <div className="hstack gap-2 justify-content-center">
                    <Button className="btn btn-danger" onClick={() => onDelete()}>
                        {props.t("users.delete")}
                    </Button>
                    <Button color="danger" onClick={() => onCloseClick()} outline>
                        {props.t("users.cancel")}
                    </Button>
                </div>
            </div>
        </ModalBody>
    </Modal>
    ) as unknown as JSX.Element;
};

export default DeleteModal;