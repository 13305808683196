import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef, useState } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { AccordionBody, Button, ButtonGroup, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledDropdown } from 'reactstrap';
import aggregationsJson from './aggregations.json'

export interface DNDRowProps {
  accordionId: string;
  id: any;
  column: any;
  index: number;
  moveColumns: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void;
  onColumnUpdated: (newfilter: any) => void
  toggle: (value: string) => void;
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const DNDColumn: FC<DNDRowProps> = ({ accordionId, id, column, index, moveColumns, toggle, onColumnUpdated }) => {
  const [aggregations, setAggregations]: any = useState([])
  const [name, setName] = useState("")
  const [baseName, setBaseName] = useState("")
  const [aggregation, setAggregation] = useState({value: 'none', label: 'None'})
  const ref = useRef<HTMLTableRowElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'tr',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveColumns(dragIndex, hoverIndex, isDragging)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'tr',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const onToggle = (selectedAccordionId: string, column: any) => {
    const aggregations = aggregationsJson[column.type as keyof typeof aggregationsJson]
    setAggregations(aggregations);
    setName(column?.label)
    setBaseName(column?.baseName ? column?.baseName : column?.label)
    setAggregation(column?.aggregation ? column?.aggregation : {value: 'none', label: 'None'})
    toggle(selectedAccordionId)
  }

  const onNameChange = (name: string) => {
    setName(name)
    setBaseName(name)
  }

  const onSelectAggregation = (aggregation: any) => {
    const updatedName = aggregation.value === 'none' ? baseName : `${aggregation.label} (${baseName})`
    setName(updatedName)
    setAggregation(aggregation)
  }

  // Handle filter selection for submission
  const onApplyClicked = (selectedAccordionId: string, column: any) => {
    const newColumn = {
      ...column,
      label: name,
      aggregation,
      baseName
    }
    onColumnUpdated(newColumn)
    toggle('')
  };

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <div ref={ref} key={index} data-handler-id={handlerId} style={{opacity}}  className="accordion-item border border-dark-subtle rounded-1">
        <h6 className="w-100 p-1 ps-2 m-0 bg-light">
            <div className="d-flex justify-content-between align-items-center">
                <div className='d-flex align-items-center gap-2'>
                    <div className="flex-shrink-0">
                        <div className="task-handle bg-primary-subtle rounded text-primary px-2 py-1">: :</div>
                    </div>
                    <div className='d-flex flex-column gap-1'>
                      <div className="text-primary fw-semibold">{column.dataSource.label}</div>
                      <div>{column?.label}</div>
                    </div>
                </div>
                <div className="d-flex">
                    <Button size="sm" color="primary" className="btn btn-ghost-primary waves-effect waves-light p-1"  onClick={() => onToggle(accordionId, column)}>
                        <i className="ri-edit-2-fill fs-5"></i>
                    </Button>
                    <Button size="sm" color="danger" className="btn btn-ghost-danger waves-effect waves-light p-1"  onClick={() => onToggle(accordionId, column)}>
                        <i className="ri-close-line fs-5"></i>
                    </Button>
                </div>
            </div>
        </h6>
        {/* <AccordionHeader targetId="1">How to create a group booking ?</AccordionHeader> */}
        <AccordionBody accordionId={accordionId}>
          <div className='d-flex flex-column align-items-center justify-content-start gap-4'>
            <div className='d-flex flex-column w-100'>
              <Label className="form-label">Name</Label>
              <Input
                draggable
                type="text"
                className="form-control"
                value={name}
                onChange={(e) => onNameChange(e.target.value)}
                onDragStart={e => {
                  e.preventDefault();
                  e.stopPropagation();
                }} 
              />
            </div>
            <div className='d-flex flex-column w-100'>
                <Label>
                    Aggregation
                </Label>
                <ButtonGroup>
                    <UncontrolledDropdown className='w-100'>
                        <DropdownToggle
                            tag="button"
                            className="btn btn-light text-left d-flex align-items-center justify-content-between w-100"
                        >
                            {aggregation.label} <i className="mdi mdi-chevron-down"></i>
                        </DropdownToggle>
                        <DropdownMenu className="dropdownmenu-primary">
                              <DropdownItem
                                onClick={() => onSelectAggregation({value: 'none', label: 'None'})}
                              >
                                  None
                              </DropdownItem>
                            {
                              aggregations.map((aggregation: any, aggregationIndex: number) => {
                                return (
                                  <DropdownItem
                                    key={aggregationIndex}
                                    onClick={() => onSelectAggregation(aggregation)}
                                  >
                                      {aggregation?.label}
                                  </DropdownItem>
                                )
                              })
                            }
                        </DropdownMenu>
                    </UncontrolledDropdown>
                </ButtonGroup>
            </div>
            <div className='d-flex gap-2 justify-content-start w-100'>
              <Button color="primary" onClick={() => onApplyClicked(accordionId, column)}>
                Apply
              </Button>
              <Button color="light" onClick={() => toggle('')}>
                Cancel
              </Button>
            </div>
          </div>
        </AccordionBody>
    </div>
  )
}
