export interface Call {
  callAssignments: [{
    assiignedTo: []
  }];
  dueDate: string;
  priority: string;
  status: string;
  createdAt: string,
  createdBy: {
    email: string,
    id: string,
    name: string,
    phoneNumber: string,
  },
  deletedAt: string,
  detailedCall: string,
  id: string,
  parentCall: string,
  replies: {
    createdAt: string,
    createdBy: {
      email: string,
      id: string,
      name: string,
      phoneNumber: string,
    },
    deletedAt: string,
    detailedCall: string,
    id: string,
  },
  }

  export interface Sentiment {
    sentiment: string
  } 

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
  }

export enum CallActionTypes {
    POST_CALL_REQUEST = "@@calls/POST_CALL_REQUEST",
    POST_CALL_SUCCESS = "@@calls/POST_CALL_SUCCESS",
    POST_CALL_ERROR = "@@calls/POST_CALL_ERROR",
    GET_CALL_REQUEST = "@@calls/GET_CALL_REQUEST",
    GET_CALL_SUCCESS = "@@calls/GET_CALL_SUCCESS",
    GET_CALL_ERROR = "@@calls/GET_CALL_ERROR",
    GET_CALLS_REQUEST = "@@calls/GET_CALLS_REQUEST",
    GET_CALLS_SUCCESS = "@@calls/GET_CALLS_SUCCESS",
    GET_CALLS_ERROR = "@@calls/GET_CALLS_ERROR",
    DELETE_CALL_REQUEST = "@@acalls/DELETE_CALL_REQUEST",
    DELETE_CALL_SUCCESS = "@@calls/DELETE_CALL_SUCCESS",
    DELETE_CALL_ERROR = "@@calls/DELETE_CALL_ERROR",
    GET_ALLCALLS_REQUEST = "@@calls/GET_ALLCALLS_REQUEST",
    GET_ALLCALLS_SUCCESS = "@@/calls/GET_ALLCALLS_SUCCESS",
    GET_ALLCALLS_ERROR = "@@/calls/GET_ALLCALLS_ERROR",
    CALLS_DOWNLOAD_DOCUMENT_REQUEST = "@@/calls/CALLS_DOWNLOAD_DOCUMENT_REQUEST",
    CALLS_DOWNLOAD_DOCUMENT_SUCCESS = "@@/calls/CALLS_DOWNLOAD_DOCUMENT_SUCCESS",
    CALLS_DOWNLOAD_DOCUMENT_ERROR = "@@/calls/CALLS_DOWNLOAD_DOCUMENT_ERROR",
    CALLS_SENTIMENT_REQUEST = "@@/calls/CALLS_SENTIMENT_REQUEST",
    CALLS_SENTIMENT_SUCCESS = "@@/calls/CALLS_SENTIMENT_SUCCESS",
    CALLS_SENTIMENT_ERROR = "@@/calls/CALLS_SENTIMENT_ERROR",
    PUSH_NOTIFICATION_REQUEST = "@@calls/PUSH_NOTIFICATION_REQUEST",
    PUSH_NOTIFICATION_SUCCESS = "@@calls/PUSH_NOTIFICATION_SUCCESS",
    PUSH_NOTIFICATION_ERROR = "@@calls/PUSH_NOTIFICATION_ERROR"
}

export interface CallState {
    // readonly loading: boolean;
    readonly calls: Call[] | null;
    readonly call: Call  | null;
    readonly pagination: Pagination | null;
    readonly allCalls: Call[] | null;
    readonly sentiment: Sentiment | null;
    readonly callloading: boolean
    // readonly errors?: string;
    // readonly content?:any;
    // readonly sort: any;
    // readonly sortBy: any;
    // readonly sortColumn: string;
    // readonly pageable: any;
    // readonly page: any;
    // readonly size: number;
    // readonly totalPages: number;
    // readonly totalElements: number;
    // readonly number: number;
    // readonly numberOfElements: number;
    // readonly first: boolean;
    // readonly last: boolean;
    // readonly empty: boolean;
    // readonly list: []
}
