import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Select from "react-select";
import Flatpickr from "react-flatpickr";
import avatar1 from "../../../../assets/images/users/avatar-1.jpg";
import avatar3 from "../../../../assets/images/users/avatar-3.jpg";
import { Col, Input, Modal, ModalBody, ModalHeader, Row } from "reactstrap";
import { components } from "react-select";
import { toast } from "react-toastify";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { taskAddRequest, tasksListGetRequest } from "store/applications/action";
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { usersListGetRequest } from "store/user/action";
import moment from "moment";
import { userGetRequest } from "store/auth/action";
interface FProps {
    props: any;
    tasksModals: boolean;
    setTasksModals: (event: boolean) => void;
    record: any;
    followupRequired: boolean;
    setFollowupRequired: (event: boolean) => void;
    model: any;
}
const FollowUpTask: React.FC<FProps> = ({
    tasksModals,
    setTasksModals,
    record,
    setFollowupRequired,
    model
}) => {
    const me = useSelector((state: ApplicationState) => state.auth.userProfile)
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const userList = useSelector((state: ApplicationState) => state.user.users);
    const [sortBy, setsortBy] = useState<any>(null);
    const [taskTitle, setTaskTitle] = useState('')
    const [users, setUsers] = useState<any[]>([])
    const [status, setStatus] = useState('INPROGRESS')
    const [priority, setPriority] = useState('LOW')
    const [dueDate, setDueDate] = useState<Date | null>(null)
    const [selectedUser, setSelectedUser] = useState<any>([]);
    const [studentId, setStudentId] = useState('')
    const [applicationId, setApplicationId] = useState(null)
    const [user, setUser] = useState<any>(me.id);
    const [menuIsOpen, setMenuIsOpen] = useState(false);

    const sortbystatus = [
        {
            options: [
                { label: "New", value: "NEW" },
                { label: "Inprogress", value: "INPROGRESS" },
                { label: "Completed", value: "COMPLETED" },
            ],
        },
    ];

    const sortbypriority = [
        {
            options: [
                { label: "Low", value: "LOW" },
                { label: "Medium", value: "MEDIUM" },
                { label: "High", value: "HIGH" },
            ],
        },
    ];

    useEffect(() => {
        if (me?.id) setSelectedUser([{ value: me?.id, label: me?.name }])
    }, [me])

    const toggle = (() => {
        setsortBy(null)
    });
    const handleTodoClicks = () => {
        setsortBy(null)
        setTaskTitle('')
        setTasksModals(false);
        toggle();
    };

    const handleCreateTask = () => {
        const data = {
            id: "",
            title: taskTitle,
            status: status,
            priority: priority,
            dueDate: dueDate,
            studentId: studentId,
            applicationId: applicationId,
            assignedTo: user,
            followup: true
        }
        const handleSuccess = (): void => {
            setFollowupRequired(false)
            toast(`Task added successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            dispatch(tasksListGetRequest(record.id));
        };
        const handleError = (): void => {
        }
        dispatch(taskAddRequest(data, handleSuccess, handleError));
    }

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className="custom-option">
                    <img src={avatar3} alt="" className="img-fluid rounded-circle avatar-xxs mx-2" />
                    <span>{props.data.label}</span>
                </div>
            </components.Option>
        );
    };

    useEffect(() => {
        if (tasksModals) {
            dispatch(userGetRequest())
        }
        let filter = `status=CONFIRMED&pageSize=100`
        dispatch(usersListGetRequest(filter))
        setUser(me.id)
    }, [])

    useEffect(() => {
        const roleValues: any = [];
        userList && userList.map((item: any) => {
            roleValues.push({ value: item.id, label: item.name });
        });
        setUsers(roleValues);
    }, [userList]);

    
    const handleGetList = () => {
        if(model == "students") {
            if (record && record.id) {
                setStudentId(record.id)
                dispatch(tasksListGetRequest(record.id));
            }
        }
        else {
            if (record && record.id) {
                setApplicationId(record.id)
                let studentsId = record?.valuesJson?.student?.id;
                setStudentId(studentsId)
                dispatch(tasksListGetRequest(studentsId, {applicationId: record.id}))
            }
        }     
    }

    useEffect(() => {
        handleGetList()
    }, [record])

    const handleUserSearch = (option: any, name: string) => {
        let queryParams: any = new URLSearchParams({});
        switch (name) {
            case 'searchUser':
                if (option) {
                    queryParams.set('search', option.toString());
                    queryParams.set('status', "CONFIRMED")
                }
                break;
        }
        dispatch(usersListGetRequest(queryParams.toString()));
    }

    const handleInputChange = (inputValue: any) => {
        handleUserSearch(inputValue, "searchUser");
    };

    const handleUserSelect = (sortBy: any) => {
        setUser(sortBy.value);
        setSelectedUser(sortBy);
        setMenuIsOpen(false);
    };

    return (
        <>
            <Modal id="createTask" isOpen={tasksModals} modalClassName="zoomIn" centered tabIndex={-1}>
                <ModalHeader toggle={() => {
                    setTasksModals(false);
                }} className="p-3 bg-primary-subtle"> {"Create Task"} </ModalHeader>
                <ModalBody>
                    <div id="task-error-msg" className="alert alert-danger py-2"></div>
                    <input type="hidden" id="taskid-input" className="form-control" />
                    <div className="mb-3">
                        <>
                            <label htmlFor="task-title-input" className="form-label">Task Title</label>
                            <Input type="text" id="task-title-input" className="form-control" placeholder="Enter task title"
                                name="task"
                                onChange={(e) => setTaskTitle(e.target.value)}
                                defaultValue={''}
                                value={taskTitle}
                            />
                        </>
                    </div>
                    <div className="mb-3 position-relative">
                        <label htmlFor="task-assign-input" className="form-label">Assign To&nbsp;<span>{sortBy?.label}</span></label>
                        <div className="avatar-group d-flex justify-content-center" id="assignee-member">
                            {sortBy ? (
                                <div className='p-1'>
                                    <Link to="#">
                                        <img
                                            src={avatar1} // Use your image source here
                                            width={36}
                                            height={36}
                                            alt=""
                                            className="rounded-circle avatar-xs"
                                        />
                                    </Link>
                                </div>
                            ) : null}
                        </div>
                        <div className="select-element">
                            <Select
                                placeholder="Assign to . . ."
                                value={selectedUser}
                                isMulti={false}
                                onInputChange={handleInputChange}
                                onChange={handleUserSelect}
                                options={users}
                                classNamePrefix="js-example-basic-multiple mb-0"
                                components={{ Option: CustomOption }}
                                isLoading={false}
                                menuIsOpen={menuIsOpen}
                                onMenuOpen={() => setMenuIsOpen(true)}
                                onMenuClose={() => setMenuIsOpen(false)}
                            />
                        </div>
                    </div>
                    <Row className="g-4 mb-3">
                        <Col lg={6}>
                            <label htmlFor="task-status" className="form-label">Status</label>
                            <Input
                                name="status"
                                type="select"
                                className="form-select"
                                id="status-field"
                                onChange={(e) => {
                                    setStatus((e.target.value).toUpperCase())
                                }}
                                value={status}

                            >
                                {sortbystatus.map((item, key) => (
                                    <React.Fragment key={key}>
                                        {item.options.map((item, key) => (<option value={item.value} key={key}>{item.label}</option>))}
                                    </React.Fragment>
                                ))}
                            </Input>
                        </Col>
                        <Col lg={6}>
                            <label htmlFor="priority-field" className="form-label">Priority</label>
                            <Input
                                name="priority"
                                type="select"
                                className="form-select"
                                id="priority-field"
                                onChange={(e) => {
                                    setPriority(e.target.value)
                                }}
                                value={priority}
                            >
                                {sortbypriority.map((sortItem, sortKey) => (
                                    <React.Fragment key={sortKey}>
                                        {sortItem.options.map((optionItem, optionKey) => (
                                            <option value={optionItem.value} key={optionKey}>{optionItem.label}</option>
                                        ))}
                                    </React.Fragment>
                                ))}
                            </Input>
                        </Col>
                    </Row>
                    <div className="mb-4">
                        <label htmlFor="task-duedate-input" className="form-label">Due Date:</label>
                        <Flatpickr
                            name="dueDate"
                            id="date-field"
                            className="form-control"
                            placeholder="Due date"
                            options={{
                                enableTime: true,
                                dateFormat: "Y-m-d H:i",
                            }}
                            onChange={dates => {
                                const selectedDate = dates[0]; // Flatpickr returns an array of selected dates, so we take the first one
                                const parsedDate = moment(selectedDate, 'ddd MMM DD YYYY HH:mm:ss [GMT]ZZ');
                                const formattedDate = selectedDate ? selectedDate.toISOString() : null; // Format the selected date using toISOString
                                setDueDate(parsedDate); // Set the formatted date to state
                            }}
                            value={undefined}
                        />
                    </div>
                    <div className="hstack gap-2 justify-content-end">
                        <button type="button" className="btn btn-ghost-success" onClick={() => {
                            handleTodoClicks();
                            setTasksModals(false);
                        }
                        }><i className="ri-close-fill align-bottom"></i> Close</button>
                        <button type="submit"
                            disabled={!dueDate || !selectedUser || !taskTitle}
                            className="btn btn-primary" onClick={() => {
                                handleCreateTask();
                                handleTodoClicks()
                            }} id="addNewTodo">{"Add Task"}</button>
                    </div>
                </ModalBody>
            </Modal >
        </>
    )
}
export default FollowUpTask;