import { useState } from "react";
import {
    Card,
} from "reactstrap";
import FormBuilder from "../../../Components/Common/FormBuilder/FormBuilder";
import moment from "moment";

const PreviewComponent = ({ EditInputs, fieldType, fieldJson }: any) => {
    const [value, setvalue] = useState<any>()
    const currentDate = moment();

    return (
        <div id="myDivparent">
            <div className="fs-16 fw-semibold">Preview</div>
            <Card className="m-3" id="myDivChild">
                <FormBuilder
                    label={EditInputs.label}
                    type={fieldType}
                    value={value}
                    placeholder={EditInputs.placeholder}
                    className="w-100 h-100 text-start"
                    onChange={(value: any) => {
                        setvalue(value)
                    }}
                    actionButtons={true}
                    defaultValue={currentDate.format('YYYY-MM-DD HH:mm')}
                    Editable={true}
                    options={fieldJson.widget}
                />
            </Card>
        </div>
    );
};

export default PreviewComponent;
