import { useSelector } from 'react-redux';
import { Col } from 'reactstrap'
import { createSelector } from 'reselect';

const TreeView = ({ option, handleExpand, handleSelect, expanded, selectedOption, level = 0 , setIsOpen}: any) => {
    
    const selectDashboardData = createSelector(
        (state: any) => state.Layout,
        (state) => ({
            layoutModeType: state.layoutModeType
        })
    );
    // Inside your component
    const { layoutModeType } = useSelector(selectDashboardData);
    const getIcon = (option: any) => {
        if (option && option.children && option.children.length > 0) {
            return (
                <div>
                    <span className="cursor-pointer items-center justify-center">
                            {expanded[option.value] ? <i className={'ri-subtract-line cursor-pointer fs-15'} /> : <i className={'ri-add-line cursor-pointer fs-15'} />}                           
                    </span>
                </div>
            )
        } 
    }
    return (
        <div key={option.value} className="w-100">
            <button                
                style={{ marginLeft: `${level * 1.25}rem`, width: `calc(100% - ${level * 1.25}rem)`,backgroundColor:layoutModeType == "light" ? "white" : "#495057" }}
                className='border-0'
                onKeyDown={(e) => {
                    if (e.key === 'Enter') {
                      e.preventDefault();
                    }
                  }}   
                  onClick={(e: any) => { e.preventDefault()}}  
            >
                <div className="hstack justify-content-start py-2">                    
                    <Col onClick={(e) => {handleExpand(option.value, e); e.preventDefault()}} className="hstack justify-content-start p-0"  xl={1} md={1} lg={1} sm={1} xs={1} xxl={1}>
                        <div>{getIcon(option)}</div>
                    </Col>
                    <Col  className="hstack justify-content-start p-0" xl={10} md={10} lg={10} sm={10} xs={10} xxl={10} onClick={(e) => {handleSelect(option, e); handleExpand(option.value, e); setIsOpen(false);}}>
                        <span>{option.label}</span>
                    </Col>                    
                </div>
            </button>
            {expanded[option.value] && option.children &&
                option.children.map((subOption: any) => {
                   return <TreeView
                        key={subOption.value}
                        option={subOption}
                        handleExpand={handleExpand}
                        handleSelect={handleSelect}
                        expanded={expanded}
                        selectedOption={selectedOption}
                        level={level + 1}
                        setIsOpen={setIsOpen}
                    />
                })}
        </div>
    )
}

export default TreeView