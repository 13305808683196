import type { Identifier, XYCoord } from 'dnd-core'
import type { FC } from 'react'
import { useRef } from 'react'
import { useDrag, useDrop } from 'react-dnd'
import { Button, Label } from 'reactstrap';

export interface DNDRowProps {
  id: any
  value: any
  index: number
  moveRow: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
  onUpdateClick: (value: any) => void
  onDeleteClick: (value: any) => void
}

interface DragItem {
  index: number
  id: string
  type: string
}

export const DNDRow: FC<DNDRowProps> = ({ id, value, index, moveRow, onUpdateClick, onDeleteClick }) => {
  const ref = useRef<HTMLTableRowElement>(null)
  const [{ handlerId }, drop] = useDrop<
    DragItem,
    void,
    { handlerId: Identifier | null }
  >({
    accept: 'tr',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      }
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return
      }
      const dragIndex = item.index
      const hoverIndex = index

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect()

      // Get vertical middle
      const hoverMiddleY =
        (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2

      // Determine mouse position
      const clientOffset = monitor.getClientOffset()

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return
      }

      // Time to actually perform the action
      moveRow(dragIndex, hoverIndex, isDragging)

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex
    },
  })

  const [{ isDragging }, drag] = useDrag({
    type: 'tr',
    item: () => {
      return { id, index }
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
  })

  const opacity = isDragging ? 0 : 1
  drag(drop(ref))
  return (
    <tr ref={ref} key={index} data-handler-id={handlerId} style={{opacity}} className='table-soft-light'>
        <td className='border-right align-middle' width={'40%'}>
            <div className='d-flex justify-content-between align-items-center gap-2'>
              <div className="d-flex align-items-center justify-content-start">
                  <div className="flex-shrink-0 me-3">
                      <div className="task-handle bg-primary-subtle rounded text-dark px-2 py-1">: :</div>
                  </div>
                  <Label className='cursor-pointer fs-5 text-primary m-0' onClick={onUpdateClick}>{value?.label}</Label>
              </div>
              <div className='d-flex gap-2 align-items-center justify-content-end'>
                <Button
                    onClick={onUpdateClick}
                    size='sm'
                    color="primary"
                    title='Edit'
                    className="btn-primary dts-btn-hover"
                    outline
                  >
                    Edit
                </Button>
                <Button
                    onClick={onDeleteClick}
                    size='sm'
                    color="danger"
                    title='Delete'
                    className="btn-danger dts-btn-hover"
                    outline
                  >
                    Delete
                </Button>
              </div>
            </div>
        </td>
        <td className='border-right align-middle'>
            <div className='d-flex flex-wrap gap-2'>
                {
                  value?.roles?.map((role: any) => <h5 className='m-0'><span className="badge bg-primary-subtle text-primary">{role?.label}</span></h5>)
                }
            </div>
        </td>
        <td className='align-middle'>
            <div className='d-flex flex-wrap gap-2'>
                <h5 className='m-0'>
                    <span className="badge border border-primary text-primary">{value?.snapshot_view?.label || ''}</span>
                </h5>
            </div>
        </td>
    </tr>
  )
}
