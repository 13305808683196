import React from 'react';
import Channel from './Channel';

const Channels = () => {    
    document.title="DTS | Zilter";
    return (
        <React.Fragment>
            <Channel />
        </React.Fragment>
    );
};

export default Channels;