import {StudentActionTypes, Student} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const studentGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: StudentActionTypes.GET_STUDENT_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/students/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: StudentActionTypes.GET_STUDENT_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: StudentActionTypes.GET_STUDENT_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const studentsMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, StudentActionTypes.GET_STUDENTMETADATA_SUCCESS, StudentActionTypes.GET_STUDENTMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

const creategetemailsQuery = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/emails/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getStudentsEmails: AppThunk = (id, filterObject, page, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTS_EMAILS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const searchQuery = creategetemailsQuery(id,filterObject, page);     
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_EMAILS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_EMAILS_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Metadata fetched successfully");
        }
    };
};

export const studentUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.PUT_STUDENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/students/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );        
        const error = {
            204: {
                "message": "Student updated Successfully",
                "success": true
            },
            200: {
                "message": "Student updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, StudentActionTypes.PUT_STUDENT_SUCCESS, StudentActionTypes.PUT_STUDENT_ERROR, error, handleSuccess, handleError);

 
    };
};

export const SendEmail: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.POST_STUDENT_EMAIL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/emails`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
             
        const error = {
            201: {
                "message": "Email sent Successfully",
                "success": true
            },        
            200: {
                "message": "Email sent Successfully",
                "success": true
            }
        }
        await responseHandler(response, dispatch, StudentActionTypes.POST_STUDENT_EMAIL_SUCCESS, StudentActionTypes.POST_STUDENT_EMAIL_ERROR, error, handleSuccess, handleError);

    };
};

export const studentDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.DELETE_STUDENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/students/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        const error = {
            204: {
                "message": "Student deleted Successfully",
                "success": true
            },
            200: {
                "message": "Student deleted Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, StudentActionTypes.DELETE_STUDENT_SUCCESS, StudentActionTypes.DELETE_STUDENT_ERROR, error, handleSuccess, handleError);
    };
};

export const studentListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        try {            
            const response = await fetch(`${DTS_API_STUDENT}/models/get/students?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });
                    
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, StudentActionTypes.GET_STUDENTS_SUCCESS, StudentActionTypes.GET_STUDENTS_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};

export const studentCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: StudentActionTypes.POST_STUDENT_REQUEST
        });
        const response = await fetch(
            `${DTS_API_STUDENT}/models/students`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );        
        const error = {
            208: {
                "message": "Student with this email address already exist",
                "success": false
            },
            201: {
                "message": "Student created Successfully",
                "success": true
            },        
            200: {
                "message": "Student created Successfully",
                "success": true
            }
        }
        await responseHandler(response, dispatch, StudentActionTypes.POST_STUDENT_SUCCESS, StudentActionTypes.POST_STUDENT_ERROR, error, handleSuccess, handleError);
    };
};


const creategetactivityQuery = (id: any,filterObject: any) => {
    let query = `${DTS_API_STUDENT}/activities/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getStudentsActivity: AppThunk = (id, filterObject, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_STUDENTS_ACTIVITY_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const searchQuery = creategetactivityQuery(id,filterObject);         
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_ACTIVITY_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: StudentActionTypes.GET_STUDENTS_ACTIVITY_SUCCESS,
                payload: body_1
            });
            handleSuccess && handleSuccess(body_1);
        }
    };
};

const applicationscreateQueryLink = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/applications/student/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const applicationsListGetRequest: AppThunk = (id,filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.GET_APPLICATIONS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = applicationscreateQueryLink(id, filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.GET_APPLICATIONS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: StudentActionTypes.GET_APPLICATIONS_SUCCESS,
                payload: body_1
            });
        }
    };
};
export const studentExternalApplication: AppThunk = (id,data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        // const { auth: { token } } = getState();
        dispatch({
            type: StudentActionTypes.POST_EXTERNALSTUDENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');  
        headers.append('tenantId', id);  
        const response = await fetch(`${DTS_API_STUDENT}/models/students/forms`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: StudentActionTypes.POST_EXTERNALSTUDENT_ERROR
            });
            handleError && handleError();
        } else {
            dispatch({
                type: StudentActionTypes.POST_EXTERNALSTUDENT_SUCCESS,
                payload: []
            });
            handleSuccess && handleSuccess(body_1);
        }
    };
};


