import FormBuilder from 'Components/Common/FormBuilder/FormBuilder'
import React, { useState, useRef } from 'react'
import Dropzone from 'react-dropzone'
import { Link } from 'react-router-dom'
import { Button, Card, Col, Row } from 'reactstrap'
// import sampleDownloadFile from "./Sampledownloadfile.xlsx";

interface FProps {
    setselectedFiles: (event: any) => void;
    selectedFiles: any;
    SubmitUpload: () => void;
    handleDownloadTemplate: any;
}
const New: React.FC<FProps> = ({ setselectedFiles, selectedFiles, SubmitUpload, handleDownloadTemplate }) => {
    const formatBytes = (bytes: any, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    }

    const handleAcceptedFiles = (files: any) => {
        files.map((file: any) =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size),
            })
        );
        setselectedFiles(files);
    }

    return (
        <div>
            
            <div className='d-flex justify-content-end mt-0'>
                <Link
                    to="#"
                    onClick={()=> handleDownloadTemplate(true)}
                    color="primary"
                    className="fw-medium">
                    {/* <i className="ri-file-download-line label-icon align-middle fs-16 me-2"></i> */}
                    {"Download Template"}
                </Link>
            </div>
            <div className='mt-3'>
                <Dropzone
                    onDrop={(acceptedFiles: any) => {
                        handleAcceptedFiles(acceptedFiles);
                    }}
                >
                    {({ getRootProps, getInputProps }) => (
                        <div className="dropzone dz-clickable">
                            <div
                                className="dz-message needsclick pt-4"
                                {...getRootProps()}
                            >
                                    <div className="mb-3">
                                        <i className="display-4 text-muted ri-upload-cloud-2-fill" />
                                    </div>
                                    <h4>Drop files here or click to upload.</h4>
                                    
                                </div>
                           </div>
                    )}
                </Dropzone>
            </div>
            <div className="list-unstyled mb-0" id="file-previews">
                {selectedFiles.map((f: any, i: any) => {
                    return (
                        <Card
                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                            key={i + "-file"}
                        >
                            <div className="p-2">
                                <Row className="align-items-center">
                                    <Col className="col-auto">
                                        <img
                                            data-dz-thumbnail=""
                                            height="80"
                                            className="avatar-sm rounded bg-light"
                                            alt={f.name}
                                            src={f.preview}
                                        />
                                    </Col>
                                    <Col>
                                        <Link
                                            to="#"
                                            className="text-muted font-weight-bold"
                                        >
                                            {f.name}
                                        </Link>
                                        <p className="mb-0">
                                            <strong>{f.formattedSize}</strong>
                                        </p>
                                    </Col>
                                </Row>
                            </div>
                        </Card>
                    );
                })}
            </div>
            <div className="d-flex align-items-start gap-3 mt-4">

                <button
                    onClick={() => {
                        SubmitUpload()
                    }}
                    type="button"
                    className="btn btn-primary btn-label right ms-auto nexttab"
                    data-nexttab="pills-finish-tab"
                >
                    <i className="ri-save-line label-icon align-middle fs-16 ms-2"></i>
                    Submit
                </button>
            </div>
        </div> 
    )
}

export default New
