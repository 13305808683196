import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Accordion, Label } from "reactstrap";
import { DNDColumn } from "./DNDColumn";

interface ConfigureProps {
    columns: any;
    moveColumns: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onColumnUpdated: (newfilter: any) => void
    t: (key: string) => string;
}

const Configure: React.FC<ConfigureProps> = ({ t, columns, moveColumns, onColumnUpdated }) => {
    const [openDefault, setOpenDefault] = useState('');
    const toggleDefault = (id: any) => {
        if (openDefault !== id) {
            setOpenDefault(id);
        } else {
            setOpenDefault('');
        }
    };
    return (
        <div className="d-flex flex-column gap-3 p-2 h-100">
            <Label className="m-0 fw-bold fs-5">Columns</Label>
            <div className="d-flex flex-column gap-2 overflow-y-scroll pb-5">
                <Accordion className="d-flex flex-column gap-1" id="default-accordion-example" open={openDefault} toggle={toggleDefault}>
                    <DndProvider backend={HTML5Backend}>
                        {
                            columns.map((column: any, valueIndex: number) => {
                                return (
                                    <DNDColumn
                                        accordionId={valueIndex.toString()}
                                        key={column.value}
                                        index={valueIndex}
                                        id={column.value}
                                        column={column}
                                        moveColumns={moveColumns}
                                        toggle={toggleDefault}
                                        onColumnUpdated={onColumnUpdated}
                                    />
                                )
                            })
                        }
                    </DndProvider>
                </Accordion>
            </div>
        </div>
    )
};

export default withTranslation()(Configure);
