import React, { Component, useEffect } from "react";
import { withTranslation } from "react-i18next";
import Details from "Components/Common/RecordDetails/Details";
import { studentDelete, studentGetRequest, studentListGetRequest, studentsMetadataGetRequest, studentUpdateRequest } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { noteAddRequest, notesListGetRequest } from "store/note/action";
import Application from "pages/Students/Applications/Application";
import Deal from "./Deals/Deal";

const Students = (props: any) => {    
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(studentsMetadataGetRequest("students", filters));
    }, [])

    const mainTabList = [
        {
            id: "applications",
            title: "Applications",
            component: Application,
            icon: 'ri-contacts-book-line'
        },
        {
            id: "deals",
            title: "Deals",
            component: Deal,
            icon: 'ri-apps-line'
        }
    ]
    
    return (
        <React.Fragment>
           <Details 
                getListAction={studentListGetRequest}
                deleteAction={studentDelete}
                getDetailsAction={studentGetRequest}
                updateAction={studentUpdateRequest}
                noteAddRequest={noteAddRequest}
                notesListGetRequest={notesListGetRequest}
                model={"students"}
                translater={"student"}
                reducerState={"student"}
                mainTabList={mainTabList}
           />
        </React.Fragment>
    );
};

export default withTranslation()(Students);
