import React, { useState, useRef, useEffect } from 'react'
import axios from 'axios'
import New from './New'
import Uploading from './Uploading'
import { ApplicationState } from 'store'
import { useDispatch, useSelector } from 'react-redux'
import config from "../../../../../config";
import { useParams } from 'react-router'
import { documentListGetRequest } from 'store/documents/action'
const { DTS_API_STUDENT, AWS_API } = config.api;

interface FProps {
    selectedFiles: any;
    setselectedFiles: (event: any) => void;
    setModalFile:(event: boolean) => void;
    setDocumentType: (event: any) => void;
    documentType: any;
    fileInfo: any;
    setFileInfo:  (event: any) => void;
    filterObject: any;
    setData: (event: any) => void;
    setOcrLoading: (event: any) => void;
    setDataPreviewModal:(event: boolean) => void;
    model: any;
    details: any;
    handleGetList:(event: any) => void;
}

const Upload: React.FC<FProps> = ({
    selectedFiles,
    setselectedFiles,
    setModalFile,
    documentType,
    fileInfo,
    setFileInfo,
    filterObject,
    setDataPreviewModal,
    setData,
    setOcrLoading,
    model,
    details,
    setDocumentType}
) => {
    const { id } = useParams();
    const dispatch: any = useDispatch();
    const loading = useSelector((state: ApplicationState) => state.auth.loading)
    const access_token = useSelector((state: ApplicationState) => state.auth?.token)
    const inputRef = useRef<HTMLInputElement>(null)
    const [dragActive, setDragActive] = useState(false)
    const [file, setFile] = useState<File | null>(null)
    const [percentage, setPercentage] = useState(0)
    const [uploading, setUploading] = useState('not_started')
    const [studentId, setStudentId] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);

    const handleGetList = (filters?: any) => {
        if(model == "students") {
            if (id) {
                let newFilters={...filters, studentId: id}
                setStudentId(id)
                dispatch(documentListGetRequest(newFilters));
            }
        }
        else {
            if (details && details.id) {
                setApplicationId(details.id)
                let studentsId = details?.valuesJson?.student ? details?.valuesJson?.student?.id : "";               
                let filterObject = {...filters, studentId:studentsId,  applicationId: id}
                setStudentId(studentsId)
                dispatch(documentListGetRequest(filterObject))
            }
        }     
    }


    useEffect(() => {
        handleGetList()
    }, [details])
    
    const SubmitUpload = async (isTextract: boolean) => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
        setFileInfo(file)
        if (file) {
                setUploading('started');
                const formData = new FormData();
                formData.append('documentType', documentType);
                formData.append('studentId', studentId);
                formData.append('applicationId', applicationId);
                formData.append('file', file);
                setUploading('started');
                const config = {
                    onUploadProgress: function (progressEvent: any) {
                        const percentCompleted = Math.round(
                            (progressEvent.loaded * 100) / progressEvent.total,
                        )
                        setPercentage(percentCompleted)
                    },
                    headers: {
                        'Content-Type': 'multipart/form-data',
                        "token": access_token
                    },
                }
                await axios.post(`${DTS_API_STUDENT}/documents`, formData, config)
                .then((res: any) => {                    
                    if(res == 'File uploaded successfully.') {
                        setPercentage(100)
                        setUploading('completed')
                        setDocumentType("")
                        setModalFile(false) 
                        if(isTextract) {
                            setDataPreviewModal(true)
                            setOcrLoading(true)
                            onRunOCR()
                        } 
                        setselectedFiles([])
                        handleGetList()
                    }
                   
                })
        }
    }
    
    const onRunOCR = async () => {
        const file = selectedFiles && selectedFiles.length ? selectedFiles[0] : null;
		const formData = new FormData();
		formData.append('file', file);	
		try {
		  const response = await fetch(`${AWS_API}/quicksight/upload`, {
			method: 'POST',
			body: formData,
		  });
	
		  if (!response.ok) {
			throw new Error('Failed to upload file.');
		  }
	
		  const data = await response.json();
		  setData(data);          
          setOcrLoading(false)
		} catch (error) {
		  setData({});
		  alert('Error uploading file. Please try again.');
		}
	  };

    let component = null;
    switch(uploading) {
        case 'not_started':
            component = <New model={model} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} SubmitUpload={SubmitUpload} setDocumentType={setDocumentType} documentType={documentType} />
            break;
        case 'started':
            component = <Uploading file={fileInfo} percentage={percentage} />
            break;
    }
    return (
        <div>
            {component}
        </div>
    )
}
export default Upload