export interface universitiesMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface University {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum UniversityActionTypes {
    GET_UNIVERSITYMETADATA_REQUEST = "@@university/GET_UNIVERSITYMETADATA_REQUEST",
    GET_UNIVERSITYMETADATA_SUCCESS = "@@university/GET_UNIVERSITYMETADATA_SUCCESS",
    GET_UNIVERSITYMETADATA_ERROR = "@@university/GET_UNIVERSITYMETADATA_ERROR",
    GET_UNIVERSITY_REQUEST = "@@university/GET_UNIVERSITY_REQUEST",
    GET_UNIVERSITY_SUCCESS = "@@university/GET_UNIVERSITY_SUCCESS",
    GET_UNIVERSITY_ERROR = "@@university/GET_UNIVERSITY_ERROR",
    GET_UNIVERSITIES_REQUEST = "@@university/GET_UNIVERSITIES_REQUEST",
    GET_UNIVERSITIES_SUCCESS = "@@university/GET_UNIVERSITIES_SUCCESS",
    GET_UNIVERSITIES_ERROR = "@@university/GET_UNIVERSITIES_ERROR",
    PUT_UNIVERSITY_REQUEST = "@@university/PUT_UNIVERSITY_REQUEST",
    PUT_UNIVERSITY_SUCCESS = "@@university/PUT_UNIVERSITY_SUCCESS",
    PUT_UNIVERSITY_ERROR = "@@university/PUT_UNIVERSITY_ERROR",
    DELETE_UNIVERSITY_REQUEST = "@@university/DELETE_UNIVERSITY_REQUEST",
    DELETE_UNIVERSITY_SUCCESS = "@@university/DELETE_UNIVERSITY_SUCCESS",
    DELETE_UNIVERSITY_ERROR = "@@university/DELETE_UNIVERSITY_ERROR",
    POST_UNIVERSITY_REQUEST = "@@university/POST_UNIVERSITY_REQUEST",
    POST_UNIVERSITY_SUCCESS = "@@university/POST_UNIVERSITY_SUCCESS",
    POST_UNIVERSITY_ERROR = "@@university/POST_UNIVERSITY_ERROR",
}

export interface UniversityState {
    readonly list: University[] | null;
    readonly details: University | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: universitiesMetadata[];
    readonly loading: boolean;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
}
