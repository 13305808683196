import React, { useEffect, useState } from "react";
import {
    Button,
    Card,
    CardBody,
    Col,
    Container,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    Table,
    TabPane,
    UncontrolledDropdown,
} from "reactstrap";
import { registerPlugin } from "react-filepond";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import FeatherIcon from "feather-icons-react";
import TableContainer from "./TableComponent";
import _ from "lodash";
import BreadCrumb from "Components/Common/BreadCrumb";
import { WithTranslation, withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';
import { ApplicationState } from "store";
import isEqual from 'lodash/isEqual';
import config from "../../config";
import { ToastContainer, toast } from "react-toastify";
import { boolean } from "yup";
import dataFields from "./Fields";
import { formGetRequest, formListGetRequest, formDeleteRequest } from "store/customform/action";
import DeleteModal from "./DeleteModal";
import moment from "moment";
import AccessDenied from "pages/AccessDenied/Denied";
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';

const { link : {ROLES_LEARN_MORE}} = config;

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

interface permissionData {
    permissionId: string,
    value: string;
    item: any;
}

interface RolesProps extends WithTranslation { }

const Roles: React.FC<RolesProps> = (props: any) => {
    const navigate = useNavigate()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 
    const formList = useSelector((state: ApplicationState) => state.customform.list);
    const customformDetails = useSelector((state: ApplicationState) => state.customform.customformDetails);
    const paginationDetails = useSelector((state: ApplicationState) => state.customform.pagination);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const loading = useSelector((state: ApplicationState) => state.customform.loading);
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [create, setCreate] = useState<boolean>(false);
    const [customActiveTab, setcustomActiveTab] = useState<any>("1");
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [search, setsearch] = useState<string>("");
    const [columns, setColumns] = useState<any>([]);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [propertiesJson, setPropertiesJson] = useState<any>([])
    const [propertiesData, setPropertiesData] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10);
    const [record, setrecord] = useState<any>();
    const [isOpenCreate, setIsOpenCreate] = useState<boolean>(false);
    const [isOpenEdit, setIsOpenEdit] = useState<boolean>(false);
    const [pagesList, setPagesList] = useState<any>([]);
    const [sortBy, setSortBy] = useState<any>("")
    const [updateId, setUpdateId] = useState<any>("");
    const [sortOrder, setSortOrder] = useState<string>("")
    const [access, setAccess] = useState<any>([]);
    const [isIconUp, setIsIconUp] = useState(false);
    const [predefinedPermissions, setPredefinedPermissions] = useState([]);
    const [PreAccessPermissions, setPreAccessPermissions]= useState<permissionData[]>([]);
    const [filterObject, setFilterObject] = useState({
        pageSize: 10
    })
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    const toggleCustom = (tab: any) => {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    };

    const onChangePageSize = (value: any) => {
        setPagination({...pagination, pageSize: value})
        setPageSize(value)
        const filters = {...filterObject, pageSize: value}
        setFilterObject(filters)  
        dispatch(formListGetRequest(filters, 0));
    }

    const onClickrow = async (data: any) => {
        setrecord(data);        
    };

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if(userRole === "Owner") {
            setViewAccess(true)
            setCreate(true) 
        }
        else if(subLevelPermissionsList) {      
            let access = subLevelPermissionsList?.forms;
            if(access && access?.length) {
                setAccess(access)
                if(access && access.length) {            
                    access.map((item: any, index: any) => {
                        if(item.name.toLowerCase() == 'view') {
                            item.value == "" || item.value == "none" ? setViewAccess(false)  : setViewAccess(true) 
                       }
                       if(item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false)  : setCreate(true) 
                       }
           
                   })
               }
            }
            else {
                setViewAccess(false)
            }
        }
    }, [subLevelPermissionsList, userProfile])
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(formListGetRequest(filterObject, newPageNo));
    };
          
    const toggleIcon = () => {
        setIsIconUp((prevIsIconUp) => !prevIsIconUp);
    };

    const handleButtonClick = (link: any) => {
        navigator.clipboard.writeText(link).then(() => {
            toast('URL copied to clipboard', { position: 'top-center', hideProgressBar: true, className: 'bg-success text-white' });
           }).catch(err => {
           
        });
    };


    const codecopy = (link: any) => {
        let code = `<iframe src="${link}" width={"100%"} height={'100%'} 
                style={{minHeight: '400px'}}
            />`
            navigator.clipboard.writeText(code).then(() => {
                toast('Code copied to clipboard', { position: 'top-center', hideProgressBar: true, className: 'bg-success text-white' });
            }).catch(err => {
            
            });
    }

    const handleIconClick = (url: any) => {
        window.open(url, '_blank');
    }; 

    useEffect( () => {  
         
        const fetchData = async () => {
            await Promise.all([
                dispatch(formListGetRequest(filterObject))
            ]);
        };   
        fetchData();
        let columns: any = [];
        const fieldsCols: any =  dataFields.map((fields: any, index: any) => {
                if (fields.name == "id")
                    return {
                        Header: _.startCase(fields.name),
                        accessor: fields.name,
                        filterable: true,
                        isSortable: false,
                        disableFilters: true,
                        show: false,
                    };
                else if (fields.name == "name")
                    return {
                        Header: _.startCase(fields.name),
                        accessor: fields.label,
                        isSortable: true,
                        isSorted: true,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {
                            return (
                                <div className="hstack multi-item">
                                <span>{cellProps.row.original.name}</span>
                            </div>
                            );
                        },
                    };
                else if (fields.name == "url")
                    return {
                        Header: _.startCase(fields.name),
                        accessor: fields.label,
                        isSortable: true,
                        isSorted: true,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {
                            return (
                                <div className="hstack gap-2">
                                <Button className="btn-success btn-sm" onClick={() => handleButtonClick(cellProps.row.original.url)}>Copy Link</Button>
                                {/* <Button className="btn-primary btn-sm" onClick={() => codecopy(cellProps.row.original.url)}>Copy Code</Button> */}                    
                            </div>
                            );
                        },
                    };
                    
                else return {
                        Header: _.startCase(fields.name),
                        accessor: fields.name,
                        filterable: true,
                        isSortable: true,
                        disableFilters: true,
                        className: "hoverable",
                        show: true,
                        Cell: (cellProps: any) => {
                            return (
                                <div className="d-flex align-items-center" key={index} onClick={() => setIsOpenEdit(true)}>
                                    <Label
                                        className="hstack py-1 form-label"
                                        htmlFor={cellProps.row.original[fields.name]}
                                    >
                                        {cellProps.row.original[fields.name]}
                                    </Label>
                                </div>
                            );

                        },
                    };
            })
        columns.push.apply(columns, fieldsCols);
        columns.push({
            Header:"Created At",
            accessor: "createdAt",
            id: "createdAt",
            filterable: true,
            isSortable: false,
            disableFilters: true,
            show: true,
            Cell: (cellProps: any) => {
                return  <div className="d-flex align-items-center justify-content-between">                                
                           {cellProps.row.original["createdAt"] ? moment(cellProps.row.original["createdAt"]).format('lll'):
                ''}
                    </div>
            }
        })
        setColumns(columns);
    }, []);

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort}   
          setFilterObject(filters)   
          dispatch(formListGetRequest(filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}  
          setFilterObject(filters)            
          dispatch(formListGetRequest(filters, pagination.page));
        }
      };


    useEffect(() => {        
        if (paginationDetails) {            
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    },[paginationDetails])

     const execute = () => {
        let a = isEqual(formList, propertiesJson);
        return !a
     }

    useEffect(() => {
        const parsedData: any = formList && formList.length ? formList.map((item: any) => {
            const valuesJson = JSON.parse(item.valuesJson);
            let values = { ...valuesJson, ...item,name: item.name, url: item.url}
            return values
        }) : []
        let properties = parsedData && parsedData.length ? parsedData : []
        setPropertiesData(properties)
        setPropertiesJson(formList)
    }, [execute()])

    
    const onDelete = () => {
        const handleSuccess = () => {
            dispatch(formListGetRequest())
            setisDeleteOpen(false)
        }
        const handleFailure = () => {
            setisDeleteOpen(false)

        }
        dispatch(formDeleteRequest(record.id, handleSuccess, handleFailure))  
    }

    const onCreate = () => {
        navigate('./custom-form-builder')
    }

    const handleChange = (value: any) => {
        setsearch(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters)
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(formListGetRequest(filters))
        }, 2000);
        setTypingTimeout(newTimeout);
    };

    const handleKeyDown = (event: any) => {
        if (event.key === 'Enter') {
          const filters = {...filterObject}
          setFilterObject(filters)
          dispatch(formListGetRequest(filters))
        }
      };
    return (
        <div>
        { !authLoading ?
            viewAccess ? 
        <React.Fragment>
            <ToastContainer />
            {isDeleteOpen && <DeleteModal 
                show={isDeleteOpen}
                onCloseClick={() => setisDeleteOpen(false)}
                onDelete={onDelete}
                record={record} 
                props={props}
            />}
            <div>
                <Card>
                    <CardBody>
                        <Row>
                            <Col>
                                <div className="search-box">
                                    <Input
                                        type="text"
                                        size={14}
                                        className="search"
                                        onKeyDown={handleKeyDown}
                                        placeholder={props.t("student.search_for")}
                                        onChange={(e) => handleChange(e.target.value)}
                                        value={search}
                                    />
                                    { search == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
                            
                                </div>
                            </Col>
                            <Col>
                                <div className='hstack gap-2 justify-content-end'>
                                    <div className='hstack gap-2 mb-2'>  
                                    {
                                            create ? 
                                            <Button
                                                onClick={() => onCreate()}
                                                color="primary"
                                                className="btn-label">
                                                    <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("applications.create_entry")}
                                            </Button>
                                        :         
                                            <Button
                                                // onClick={() => setCreateOpen(true)}
                                                color="primary"
                                                disabled
                                                className="btn-label">
                                                    <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("applications.create_entry")}
                                            </Button>} 
                                    </div>
                                </div>
                            </Col>  
                        </Row>     
                        <div className="students-table">
                            <TableContainer
                                columns={columns}
                                data={propertiesData || []}
                                customPageSize={pageSize}
                                setrecord={setrecord}
                                setisDeleteOpen={setisDeleteOpen}
                                divClass="table-responsive"
                                tableClass="align-middle table-nowrap"
                                theadClass="table-light text-muted"
                                thClass="border-bottom-1 table-soft-primary"
                                SearchPlaceholder='Search...'
                                isPageSizeChange={true}
                                isBordered={true}
                                pageChanged={pageChanged}
                                pagination={pagination}
                                setPagination={setPagination}
                                pagesList={pagesList}
                                onChangePageSize={onChangePageSize}
                                pageSize={pageSize}
                                isIconUp={isIconUp}
                                handleSort={handleSort}
                                sortBy={sortBy}
                                sortOrder={sortOrder}
                                props={props}
                                loading={loading}
                                onClickrow={onClickrow}
                                access={access}
                                handleIconClick={handleIconClick}
                            />
                        </div>
                    </CardBody>
                </Card>
            </div>   
        </React.Fragment>
        : <AccessDenied />
        : <ListingLoader 
            columns={columns} 
            tableClass="align-middle table-nowrap"
            theadClass="table-light text-muted"
            thClass="border-bottom-1 table-soft-primary"
       /> }
       </div>
    );
};
export default withTranslation()(Roles);
