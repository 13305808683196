import {CallActionTypes, CallState} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { actions } from "react-table";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const callGetRequest: AppThunk = (studentId, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: CallActionTypes.GET_CALL_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/calls/${studentId}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: CallActionTypes.GET_CALL_ERROR,
                    payload: body
                });
                // handleError(body);
            } else {
                dispatch({
                    type: CallActionTypes.GET_CALL_SUCCESS,
                    payload: body
                });
                // handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

// const createQueryLink = (filterObject: any, page: any) => {
//     const keys = Object.keys(filterObject);
//     const values = Object.values(filterObject);
//     let query = `${DTS_API_STUDENT}/calls`;
//     for (let i = 0; i < keys.length; i++) {
//         if (values[i]) query += `${keys[i]}=${values[i]}&`;
//     }
//     return query;
// };
export const callsListGetRequest: AppThunk = (studentID, queryParams) => {
   
    return async (dispatch: Dispatch, getState): Promise<void> => { 
        const { auth: { token } } = getState();
        dispatch({
            type: CallActionTypes.GET_CALLS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_STUDENT}/calls/${studentID}?${queryParams ? queryParams : ''}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CallActionTypes.GET_CALLS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: CallActionTypes.GET_CALLS_SUCCESS,
                payload: body_1
            });
        }
    };
};


// const createQueryLink = (id: any, filterObject: any, page: any) => {
//     const keys = filterObject ? Object.keys(filterObject) : [] ;
//     const values = filterObject ? Object.values(filterObject) : [];
//     let query = `${DTS_API_STUDENT}/calls/${id}&`;
//     for (let i = 0; i < keys.length; i++) {
//         if (values[i]) query += `${keys[i]}=${values[i]}&`;
//     }
//     return query;
// };
// export const callsListGetRequest: AppThunk = (studentID, filterObject, page) => {
   
//     return async (dispatch: Dispatch, getState): Promise<void> => { 
//         const { auth: { token } } = getState();
//         dispatch({
//             type: CallActionTypes.GET_CALLS_REQUEST
//         });  
//         const headers = new Headers();
//         headers.append('Content-Type', 'application/json');
//         if (token) {
//             headers.append('token', token);
//         }       
//         let searchQuery = createQueryLink(studentID, filterObject, page);  
//         const response = await fetch(searchQuery, {
//             method: "GET",
//             headers: headers
//         });
    
//         const body = await response.json();
//         const {response: response_1, body: body_1} = {response, body};
//         if (!response_1.ok) {
//             dispatch({
//                 type: CallActionTypes.GET_CALLS_ERROR,
//                 payload: body_1
//             });
//         } else {
//             dispatch({
//                 type: CallActionTypes.GET_CALLS_SUCCESS,
//                 payload: body_1
//             });
//         }
//     };
// };
export const allCallsListGetRequest: AppThunk = () => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: CallActionTypes.GET_ALLCALLS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_STUDENT}/calls`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CallActionTypes.GET_ALLCALLS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: CallActionTypes.GET_ALLCALLS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const callDelete: AppThunk = (callsId, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallActionTypes.DELETE_CALL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/calls/${callsId}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(callsId)
        });
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: CallActionTypes.DELETE_CALL_ERROR,
            });
            handleError();
        } else {
            dispatch({
                type: CallActionTypes.DELETE_CALL_SUCCESS,
            });
            handleSuccess();
        }
    };
};

export const callAddRequest: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallActionTypes.POST_CALL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/calls`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: CallActionTypes.POST_CALL_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CallActionTypes.POST_CALL_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const callsDocumentDownload: AppThunk = (query, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallActionTypes.CALLS_DOWNLOAD_DOCUMENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/calls?${query}`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response.blob();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CallActionTypes.CALLS_DOWNLOAD_DOCUMENT_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: CallActionTypes.CALLS_DOWNLOAD_DOCUMENT_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CallActionTypes.CALLS_DOWNLOAD_DOCUMENT_ERROR
            });
            handleSuccess();
        }
    };
};

export const callsSentimentJSON: AppThunk = (query, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallActionTypes.CALLS_SENTIMENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/calls?${query}`, {
            method: "GET",
            headers: headers,
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: CallActionTypes.CALLS_SENTIMENT_ERROR
                });
                handleError && handleError();
            } else {
                dispatch({
                    type: CallActionTypes.CALLS_SENTIMENT_SUCCESS,
                    payload: body_1
                });
                handleSuccess && handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: CallActionTypes.CALLS_SENTIMENT_ERROR
            });
            handleError && handleError();
        }
    };
};

export const pushNotification: ActionCreator<
    ThunkAction<void, ApplicationState, null, Action<string>>
> = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: CallActionTypes.PUSH_NOTIFICATION_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/calls/push-notification`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: CallActionTypes.PUSH_NOTIFICATION_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: CallActionTypes.PUSH_NOTIFICATION_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};