import {
    Button,
    Card,
    CardBody,
    Col,
    Input,
    Label,
    Row,
} from "reactstrap";
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';
import { withTranslation } from "react-i18next";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { noteAddRequest, notesListGetRequest } from "store/note/action";
import { toast } from "react-toastify";
import Select from "react-select";
import { userGetRequest } from "store/auth/action";
import FollowUpTask from "./Modals/FollowUpTask";
import Comments from "./Modals/Comments";

const noteType = [
    {
        options: [
            { label: "Counselling Session Summary", value: "COUNSELLING SESSION SUMMARY" },
            { label: "Follow-up call", value: "FOLLOW-UP CALL" },
            { label: "Incoming Call", value: "INCOMING CALL" },
            { label: "Attempt to book", value: "ATTEMPT TO BOOK" },
            { label: "Proof of Funds Details", value: "PROOF OF FUNDS DETAILS" },
            { label: "Course Notes", value: "COURSE NOTES" },
            { label: "Finance Notes", value: "FINANCE NOTES" },
            { label: "Others", value: "OTHERS" }
        ],
    },
];
const Notes = (props: any) => {
    let { id } = useParams();
    const { model, details} = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [selectedAddNoteType, setSelectedAddNoteType] = useState(null);
    const [addShortNoteValue, setAddShortNoteValue] = useState("");
    const [newNote, setNewNote] = useState("");
    const [tasksModals, setTasksModals] = useState<boolean>(false);
    const [followupRequired, setFollowupRequired] = useState(false)
    const [transcribing, setTranscribing] = useState("parent")
    const [studentId, setStudentId] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [isAddNoteButtonDisabled, setIsAddNoteButtonDisabled] = useState<boolean>(false);

    const {
        transcript,
        listening,
        resetTranscript,
        browserSupportsSpeechRecognition
    } = useSpeechRecognition({ transcribing: transcribing === "parent" });

    useEffect(() => {
        if (id) {
            dispatch(userGetRequest(id))
        }
    }, [])

    const handleGetList = (filterObject?: any) => {
        if(model == "students") {
            if (id) {
                setStudentId(id)
                dispatch(notesListGetRequest(id, filterObject));
            }
        }
        else {
            if (details && details.id) {
                setApplicationId(details.id)
                let studentsId = details && details?.valuesJson && details?.valuesJson?.student?.id ? details?.valuesJson?.student?.id : "";
                setStudentId(studentsId)
                let filters = {...filterObject, applicationId: id}
                dispatch(notesListGetRequest(studentsId, filters))
            }
        }     
    }

    useEffect(() => {
        handleGetList()   
    }, [details])


    useEffect(() => {
        setNewNote(transcript);
    }, [transcript])

    if (!browserSupportsSpeechRecognition) {
        return <span>Browser doesn't support speech recognition.</span>;
    }

    const startListening = () => {
        SpeechRecognition.startListening({ continuous: true });
    };

    const stopListening = () => {
        SpeechRecognition.stopListening();
    };

    const handleAddNoteTypeChange = (selectedOption: any) => {
        setSelectedAddNoteType(selectedOption);
        setAddShortNoteValue(selectedOption.label)
        // You can also update the formData here if needed
    };
    
    const handleNewNoteChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
        setNewNote(event.target.value);
    };

    const handleReset = () => {
        setNewNote("");
        setSelectedAddNoteType(null)
        setIsAddNoteButtonDisabled(false);
        resetTranscript();
        setAddShortNoteValue('')
    };

    const handleAddNote = () => {
        stopListening();
        setIsAddNoteButtonDisabled(true);
        const data =
        {
            shortNote: addShortNoteValue,
            studentId: studentId,
            applicationId: applicationId,
            parentId: "",
            note: newNote
        }
        const handleSuccess = (body: any): void => {
            createFollowUp();
            handleReset();
            handleGetList()
            if (followupRequired) {
                setTasksModals(true);
            } else {
                setTasksModals(false);
            }
        };

        const handleError = (body: any): void => {
        };

        dispatch(noteAddRequest(data, handleSuccess, handleError));
    };

    const handleFollowupCheck = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFollowupRequired(event.target.checked);
    };

    const createFollowUp = () => {
        setTasksModals(followupRequired)
    }

    return (
        <React.Fragment>
            <FollowUpTask
                followupRequired={followupRequired}
                setFollowupRequired={setFollowupRequired}
                record={details}
                props={props}
                tasksModals={tasksModals}
                setTasksModals={setTasksModals}
                model={model}
            />
            {/* Your existing JSX code */}
            <Card>
                <CardBody>
                    <>
                        <form className="mt-1">
                            <Row className="g-3">
                                <Col xs={12} >
                                    <textarea className="form-control bg-light border-light" id="exampleFormControlTextarea1" value={newNote}
                                        onChange={handleNewNoteChange} rows={3} placeholder={props.t("applications.add_note")}></textarea>
                                </Col>
                                <Col xs={12} className="mb-1 text-start">
                                    {/* <Dictaphone /> */}
                                    <div className='d-flex justify-content-end gap-2'>
                                        <div className="form-check mb-0 align-self-center">
                                            <Input className="form-check-input" type="checkbox" onChange={handleFollowupCheck} id="formCheck6" />
                                            <Label className="form-check-label" for="formCheck6">
                                                Follow-up required ?
                                            </Label>
                                        </div>
                                        <div style={{ minWidth: '250px' }}>
                                            <Select
                                                placeholder="Select Note Type"
                                                classNamePrefix="js-example-data-array"
                                                isLoading={false}
                                                options={noteType}
                                                value={selectedAddNoteType}
                                                onChange={handleAddNoteTypeChange}
                                                menuPlacement="auto" // You can also try "bottom" or "top"
                                                menuPosition="fixed"
                                            />
                                        </div>
                                        {listening ? (
                                            <>
                                                <Button color="danger" className="ms-2 btn-icon" outline type="button" onClick={stopListening}> <i className="ri-mic-off-line"></i> </Button>
                                            </>
                                        ) : (
                                            <>
                                                <Button color="primary" className="ms-2 btn-icon" outline type="button" onClick={startListening}> <i className="ri-mic-line"></i> </Button>
                                            </>
                                        )}
                                         <Button color="primary" className="ms-2 btn btn-primary"
                                            disabled={isAddNoteButtonDisabled || (!newNote || !selectedAddNoteType) && (!transcript || !selectedAddNoteType)}
                                            onClick={handleAddNote}>{props.t("applications.add_note")}</Button>
                                    </div>
                                </Col>
                            </Row>
                        </form>
                    </>
                    <Comments record={details} noteType={noteType} transcribing={transcribing} setTranscribing={setTranscribing} notesListGetRequest={notesListGetRequest} noteAddRequest={noteAddRequest} model={model} />
                </CardBody>
            </Card>
        </React.Fragment>
    );
};

export default withTranslation()(Notes);
