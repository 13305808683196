export interface viewsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface View {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum ViewActionTypes {
    GET_VIEWMETADATA_REQUEST = "@@view/GET_VIEWMETADATA_REQUEST",
    GET_VIEWMETADATA_SUCCESS = "@@view/GET_VIEWMETADATA_SUCCESS",
    GET_VIEWMETADATA_ERROR = "@@view/GET_VIEWMETADATA_ERROR",
    GET_VIEW_REQUEST = "@@view/GET_VIEW_REQUEST",
    GET_VIEW_SUCCESS = "@@view/GET_VIEW_SUCCESS",
    GET_VIEW_ERROR = "@@view/GET_VIEW_ERROR",
    GET_VIEWS_REQUEST = "@@view/GET_VIEWS_REQUEST",
    GET_VIEWS_SUCCESS = "@@view/GET_VIEWS_SUCCESS",
    GET_VIEWS_ERROR = "@@view/GET_VIEWS_ERROR",
    PUT_VIEW_REQUEST = "@@view/PUT_VIEW_REQUEST",
    PUT_VIEW_SUCCESS = "@@view/PUT_VIEW_SUCCESS",
    PUT_VIEW_ERROR = "@@view/PUT_VIEW_ERROR",
    DELETE_VIEW_REQUEST = "@@view/DELETE_VIEW_REQUEST",
    DELETE_VIEW_SUCCESS = "@@view/DELETE_VIEW_SUCCESS",
    DELETE_VIEW_ERROR = "@@view/DELETE_VIEW_ERROR",
    POST_VIEW_REQUEST = "@@view/POST_VIEW_REQUEST",
    POST_VIEW_SUCCESS = "@@view/POST_VIEW_SUCCESS",
    POST_VIEW_ERROR = "@@view/POST_VIEW_ERROR",  
    POST_FILTER_SUCCESS = "@@view/POST_FILTER_SUCCESS", 
    GET_FILTER_REQUEST = "@@view/GET_FILTER_REQUEST",
    GET_FILTER_SUCCESS = "@@view/GET_FILTER_SUCCESS",
    POST_STORE_VIEW_SUCCESS = "@@view/POST_STORE_VIEW_SUCCESS",
    GET_STORE_VIEW_REQUEST = "@@view/GET_STORE_VIEW_REQUEST",
    GET_STORE_VIEW_SUCCESS = "@@view/GET_STORE_VIEW_SUCCESS",
}

export interface ViewState {
    readonly views: View[] | null;
    readonly view: View | null;
    readonly pagination: Pagination | null;
    readonly loading: boolean;
    readonly storeFilters: any;
    readonly storeView: any;
}
