import React, { useEffect, useRef, useState } from 'react';
import cloneDeep from 'lodash/cloneDeep';
import Select, { SelectProps } from 'react-dropdown-select';
import { Button, ButtonGroup, Input, Label } from "reactstrap";
import { getDateRange } from 'helpers/generateDateRange';

// Define the type for the options used in the Select component
interface Option {
    label: string;
    value: string;
    disabled?: boolean;
}

// Props for the SelectDropdown component
interface SelectDropdownProps {
    title: string;
    options: Option[];
    disabled?: boolean;
    className?: string;
    placeholder?: string;
    property?: any;
    value?: Option;
    isSearchable?: boolean;
    optionsLoading?: boolean;
    multi?: boolean;
    onFocus?: (property: any) => void;
    onChange?: (value: any, highValue: any, valueOption: any) => void;
    onClearFilter?: (property: any) => void;
}

// Props for the custom content renderer
interface ContentRendererProps {
    props: SelectProps<Option>;
    state: {
        values: Option[];
        search: string;
    };
}

// Props for the custom dropdown renderer
interface DropdownRendererProps {
    props: SelectProps<Option>;
    state: {
        values: Option[];
        search: string;
    };
    methods: {
        areAllSelected: () => boolean;
        clearAll: () => void;
        selectAll: (options: Option[]) => void;
        setSearch: (event: React.ChangeEvent<HTMLInputElement>) => void;
        addItem: (option: Option) => void;
        isSelected: (option: Option) => boolean;
    };
}

// Functional component that renders the custom dropdown with actions
const SelectDropdown: React.FC<SelectDropdownProps> = ({
    title, options, placeholder, isSearchable, value: initialValue, onFocus, onChange, optionsLoading, multi, ...rest
}) => {
    const [selectedValue, setSelectedValue] = useState<Option | null>(null);
    const prevValuesRef: any = useRef();

    // Initialize selected values from props if provided
    useEffect(() => {
        if (initialValue) {
            setSelectedValue(initialValue);
        } else {
            setSelectedValue(null)
        }
    }, [initialValue]);

    // Call onChange callback when selectedValues state changes
    useEffect(() => {
        // Only update if values has changed
        if (JSON.stringify(selectedValue) !== JSON.stringify(prevValuesRef.current)) {
            if (onChange) {
                const selectedDate = getDateRange(selectedValue?.value || '')
                if(selectedDate?.length && selectedDate[0] && selectedValue) {
                    onChange(selectedDate[0], selectedDate[1], selectedValue);
                } else {
                    onChange(null, null, null);
                }
            }
            // Store a deep copy to avoid mutating references
            prevValuesRef.current = cloneDeep(selectedValue);
        }
    }, [selectedValue]);

    // Handle selection and deselection of options
    const handleSelection = (value: Option) => {
        setSelectedValue(value);
    };

    // Custom renderer for the button content inside the dropdown
    const renderContent = ({ state }: ContentRendererProps) => (
        <ButtonGroup>
            <Button
                size='sm'
                onFocus={onFocus}
                color="primary"
                className={`d-flex w-100 btn-soft-primary ${selectedValue ? "rounded-end-0" : ""} btn-custom`}
            >
                <div className="fs-16 fw-semibold text-truncate">
                    {placeholder}
                </div>
                <i className="ri-arrow-down-s-line label-icon align-middle fs-16 fw-semibold ms-2"></i>
            </Button>
        </ButtonGroup>
    );

    // Custom renderer for the dropdown menu
    const renderDropdown = ({ props, state, methods }: DropdownRendererProps) => {
        const searchPattern = new RegExp(state.search, 'i');
        const searchField = props.searchBy as keyof Option;
        const labelField = props.labelField as keyof Option;
        const valueField = props.valueField as keyof Option;

        return (
            <div className='dropdown-content'>
                {isSearchable && (
                    <div className="form-icon left p-2">
                        <Input
                            type="text"
                            className="form-control form-control-icon fs-16"
                            placeholder="Search..."
                            value={state.search}
                            onChange={methods.setSearch}
                        />
                        <i className="fs-16 ri-search-line text-muted"></i>
                    </div>
                )}
                {optionsLoading ? <p className="d-flex align-items-center justify-content-center p-2 text-center text-muted">Loading...</p> : <ul className="options-list">
                    {props.options
                        .filter((item) =>
                            searchPattern.test(item[searchField] as string || item[labelField] as string || '')
                        )
                        .map((option) => {
                            return (
                                <li
                                    key={option[valueField] as string}
                                    onClick={() => !option.disabled ? handleSelection(option) : null}
                                    className="d-flex p-0 cursor-pointer"
                                >
                                    <Button
                                        color="primary"
                                        className="d-flex align-items-center btn btn-ghost-primary waves-effect waves-light py-2 w-100 rounded-0"
                                    >
                                        <Label className="cursor-pointer text-truncate w-100 mb-0 text-left pt-1">
                                            {option[labelField] as string}
                                        </Label>
                                    </Button>
                                </li>
                            );
                        })}
                </ul>}
            </div>
        );
    };

    return (
        <div className='d-flex dts-select-dropdown'>
            <Select
                className="react-dropdown-select p-0 border-0 d-flex"
                multi={multi}
                closeOnSelect={!multi}
                contentRenderer={renderContent}
                dropdownRenderer={renderDropdown}
                options={options}
                values={selectedValue ? [selectedValue] : []}
                dropdownHandle={false}
                onChange={(e) => console.log(e)}
                {...rest}
            />
            {selectedValue && (
                <Button
                    size='sm'
                    onClick={() => setSelectedValue(null)}
                    color="danger"
                    className="btn-icon-custom btn-soft-danger rounded-start-0"
                >
                    <i className="ri-close-line label-icon align-middle fs-5 fw-semibold"></i>
                </Button>
            )}
        </div>
    );
};

export default SelectDropdown;
