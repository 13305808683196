import {UniversityActionTypes, University} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const universityGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: UniversityActionTypes.GET_UNIVERSITY_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/institutes/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, UniversityActionTypes.GET_UNIVERSITY_SUCCESS, UniversityActionTypes.GET_UNIVERSITY_ERROR, null, null, null, "GET");
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const universitiesMetadataGetRequest: AppThunk = (model,filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: UniversityActionTypes.GET_UNIVERSITYMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        if (model) {
            headers.append('Properties-Model', model);
        }  
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, UniversityActionTypes.GET_UNIVERSITYMETADATA_SUCCESS, UniversityActionTypes.GET_UNIVERSITYMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const universityUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UniversityActionTypes.PUT_UNIVERSITY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/institutes/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                                
        const error = {
            204: {
                "message": "Institute updated Successfully",
                "success": true
            },
            200: {
                "message": "Institute updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, UniversityActionTypes.PUT_UNIVERSITY_SUCCESS, UniversityActionTypes.PUT_UNIVERSITY_ERROR, error, handleSuccess, handleError);
    
    };
};

export const universityDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: UniversityActionTypes.DELETE_UNIVERSITY_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/institutes/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {            
            const error = {
                204: {
                    "message": "Institute deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Institute deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, UniversityActionTypes.DELETE_UNIVERSITY_SUCCESS, UniversityActionTypes.DELETE_UNIVERSITY_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: UniversityActionTypes.DELETE_UNIVERSITY_ERROR
            });
            handleSuccess();
        }
    };
};

export const universityListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: UniversityActionTypes.GET_UNIVERSITIES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        try {         
            const response = await fetch(`${DTS_API_STUDENT}/models/get/institutes?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {            
                if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                    await responseHandler(response, dispatch, UniversityActionTypes.GET_UNIVERSITIES_SUCCESS, UniversityActionTypes.GET_UNIVERSITIES_ERROR, null, null, null, "GET");
                
                } 
            }
        }
        catch (error) {
        }
    };
};

export const universityCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: UniversityActionTypes.POST_UNIVERSITY_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/models/institutes`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );            
            const error = {
                208: {
                    "message": "Institute already exist",
                    "success": false
                },
                201: {
                    "message": "Institute created Successfully",
                    "success": true
                },       
                200: {
                    "message": "Institute created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, UniversityActionTypes.POST_UNIVERSITY_SUCCESS, UniversityActionTypes.POST_UNIVERSITY_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: UniversityActionTypes.POST_UNIVERSITY_ERROR, payload: error });
            handleError();
        }
    };
};



