import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { Badge, Button, Col, Input, Label, Modal, ModalBody ,ModalFooter,ModalHeader, Row} from "reactstrap";


const ExportModal = ({ show, onCloseClick, data} : any) => {
    return (
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
             <ModalBody className="py-3 px-5">
                <div className="mt-2 text-center">
                    <i className="ri-file-text-line display-5 text-success"></i>
                    <div className="mt-4 pt-2 fs-15 mx-4 mx-sm-5">
                        <h4>Are you sure ?</h4>
                        <p className="text-muted mx-4 mb-0">
                            Are you sure you want to export CSV file?
                        </p>
                        </div>
                    </div>
                    <div className="d-flex gap-2 justify-content-center mt-4 mb-2">
                    <button
                        type="button"
                        className="btn w-sm btn-light"
                        data-bs-dismiss="modal"
                        onClick={onCloseClick}
                    >
                        Close
                    </button>
                    <CSVLink
                        data={data}
                        filename="data.csv"
                        type="button"
                        onClick={onCloseClick}
                        className="btn w-sm btn-success "
                        id="delete-record"
                    >
                     Export
                    </CSVLink>
                </div>
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
    };

export default ExportModal;