import React, { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { setAuthorization } from "../helpers/api_helper";
import { useDispatch, useSelector } from "react-redux";

import { useProfile } from "../Components/Hooks/UserHooks";

import { signoutRequest, userGetRequest } from "../store/auth/action";
import { ApplicationState } from "store";

const AuthProtected = (props : any) =>{
  const dispatch : any = useDispatch();
  const auth = useSelector((state: ApplicationState) => state.auth);
  const { token, loading, userProfile, isUserLoggedin} = auth;
  
  useEffect(() => {
    dispatch(userGetRequest());
  }, []);


  if (!token) {
    return (
      <Navigate to={{ pathname: "/signin"}} />
    );
  }

  return <>{props.children}</>;
};


export default AuthProtected;
