import React, { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Input, Label, Offcanvas, OffcanvasBody, OffcanvasHeader } from "reactstrap";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";

interface ProcessFlowUpdateDrawerProps {
    isOpen: boolean;
    processFlow: any;
    toggle: (isClosed: boolean) => void;
    onSubmit: (newProcessFlow: any) => void;
    processFlows: any[];
    t: (key: string) => string;
}

const ProcessFlowUpdateDrawer = ({ isOpen, processFlow, toggle, processFlows, onSubmit, t }: ProcessFlowUpdateDrawerProps) => {

    // Local state management
    const [processFlowName, setProcessFlowName] = useState<string>("");
    const [errorMessage, setErrorMessage] = useState<string>('');

    // Set initial state when processFlow data is provided
    useEffect(() => {
        if (processFlow) {
            setProcessFlowName(processFlow.label);
            setErrorMessage("");
        }
    }, [processFlow]);

    // Handle processFlow name input change
    const handleProcessFlowNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const name = e.target.value;
        setProcessFlowName(name);

        // Check for duplicate processFlow names
        if(name !== processFlow.label) {
            const processFlowExists = processFlows.some((existingProcessFlow) => existingProcessFlow.value === name);
            setErrorMessage(processFlowExists ? t('properties.process_flow.common.process_flow.update.name_error') : "");
        }
    };

    // Handle processFlow update
    const handleUpdateProcessFlow = () => {
        const newProcessFlow = {
            ...processFlow,
            label: processFlowName,
        };
        onSubmit(newProcessFlow)
        toggle(false);
    };

    return (
        <Offcanvas
            direction="end"
            isOpen={isOpen}
            id="processFlowUpdate"
            backdrop={false}
            toggle={() => toggle(false)}
        >
            <ToastContainer />
            <OffcanvasHeader
                className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary p-3"
                id="processFlowUpdateHeader"
                toggle={() => toggle(false)}
            >
                <span className="m-0 me-2 text-white">{t('properties.process_flow.common.process_flow.update.header')}</span>
            </OffcanvasHeader>
            <OffcanvasBody className="bg-light" id="processFlowUpdateBody">
                <div className="d-flex flex-column gap-2 p-3">
                    {/* Status Name Input */}
                    <div className="form-group mb-3">
                        <Label className="form-label fw-bold fs-6">{t('properties.process_flow.common.process_flow.update.process_flow_label')}:</Label>
                        <Input
                            type="text"
                            placeholder={t('properties.process_flow.common.process_flow.update.process_flow_placeholder')}
                            onChange={handleProcessFlowNameChange}
                            value={processFlowName}
                        />
                        {errorMessage && <p className="form-text text-danger">{errorMessage}</p>}
                    </div>
                </div>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-info bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <div className="hstack gap-2">
                    <Link to="#" className="text-danger fw-bold w-auto" onClick={() => toggle(false)}>
                        {t('properties.process_flow.common.process_flow.update.btn_cancel')}
                    </Link>
                </div>
                <Button
                    color="primary"
                    className="btn btn-primary w-auto"
                    disabled={!processFlowName || !!errorMessage}
                    onClick={handleUpdateProcessFlow}
                >
                    {t('properties.process_flow.common.process_flow.update.btn_update')}
                </Button>
            </div>
        </Offcanvas>
    );
};

export default withTranslation()(ProcessFlowUpdateDrawer);
