import React from "react";
import { withTranslation } from "react-i18next";
import { Table } from "reactstrap";
interface ReportProps {
    t: (key: string) => string;
}

const Report: React.FC<ReportProps> = ({t}) => {
    return (
        <div className="table-responsive">
            <Table className="align-middle table-nowrap my-2">
                <thead className='table-primary'>
                    <tr>
                        <th className='text-center table-soft-primary text-primary align-middle' scope="col">Customer</th>
                        <th className='text-center table-soft-primary text-primary align-middle' scope="col">Date</th>
                        <th className='text-center table-soft-primary text-primary align-middle' scope="col">Invoice</th>
                    </tr>
                </thead>
                <tbody>
                    <tr className='table-soft-light'>
                        <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                        <td className='align-middle text-dark'>October 15, 2021</td>
                        <td className='align-middle text-dark'>$2,300</td>
                    </tr>
                    <tr className='table-soft-light'>
                        <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                        <td className='align-middle text-dark'>October 15, 2021</td>
                        <td className='align-middle text-dark'>$2,300</td>
                    </tr>
                    <tr className='table-soft-light'>
                        <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                        <td className='align-middle text-dark'>October 15, 2021</td>
                        <td className='align-middle text-dark'>$2,300</td>
                    </tr>
                    <tr className='table-soft-light'>
                        <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                        <td className='align-middle text-dark'>October 15, 2021</td>
                        <td className='align-middle text-dark'>$2,300</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    );
};

export default withTranslation()(Report);
