import React, { useCallback, useEffect, useState } from "react";
import { withTranslation } from 'react-i18next';
import { Button, Table } from "reactstrap";
import Report from "./Report";
import ControlPanel from "./MainPanel/ControlPanel/ControlPanel";
import DataPanel from "./MainPanel/DataPanel/DataPanel";

const Create: React.FC = (props: any) => {
    const [columns, setColumns]: any = useState([])
    const [filters, setFilters]: any = useState([])
    const [dataSources, setDataSources] = useState([])

    useEffect(() => {
        console.log("filters>>>>", filters)
        console.log("columns>>>>", columns)
        console.log("dataSources>>>>", dataSources)
    }, [filters, columns, dataSources]);

    const onAddColumns = (property: any, dataSource: any) => {
        setColumns((preColumns: any) => [...preColumns,
            {
                key: property.key,
                label: property.label,
                type: property.type,
                optionLabel: property.optionLabel,
                options: property.values,
                model: property.model,
                dataSource
            }])
    }
    
    const onAddFilters = (property: any, dataSource: any) => {
        setFilters((preFilters: any) => [...preFilters, {
            key: property.key,
            label: property.label,
            type: property.type,
            optionLabel: property.optionLabel,
            options: property.values,
            model: property.model,
            dataSource
        }])
    }
    // Function to handle moving statuses in the list
    const moveColumns = useCallback((dragIndex: number, hoverIndex: number) => {
        setColumns((prevColumns: any) => {
            const updatedColumns = [...prevColumns];
            const [draggedStatus] = updatedColumns.splice(dragIndex, 1);
            updatedColumns.splice(hoverIndex, 0, draggedStatus);
            return updatedColumns;
        });
    }, []);

    // Update or add filter
    const onFilterUpdated = (newFilter: any) => {
        const updatedFilters = filters.map((filter: any) => {
            if (newFilter.key === filter.key) {
                return {
                    ...filter,
                    ...newFilter
                };
            }
            return filter;
        });
        setFilters(updatedFilters)
    }

    // Update or add column
    const onColumnUpdated = (newColumn: any) => {
        const updatedColumns = columns.map((column: any) => {
            if (newColumn.key === column.key) {
                return {
                    ...column,
                    ...newColumn
                };
            }
            return column;
        });
        setColumns(updatedColumns)
    }
    return (
        <React.Fragment>
            <div className="position-fixed top-0 bottom-0 w-100 h-100 bg-white" style={{zIndex: 1003}}>
                <div className="d-flex justify-content-between bg-primary p-2">
                    <div>
                        <Button
                            color="primary"
                            className="btn-label">
                                <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                                {props.t('reports.action_bar.btn_back')}
                        </Button>
                    </div>
                    <div className="d-flex justify-content-center">
                        <Button
                            color="light"
                            className="btn-label" outline>
                                <i className="ri-file-excel-2-line label-icon align-middle fs-16"></i>
                                {props.t('reports.action_bar.btn_save_report')}
                        </Button>
                    </div>
                </div>
                <div className="d-flex gap-2 h-100">
                    <div className="d-flex h-100 bg-white">
                        <DataPanel
                            onAddColumns={onAddColumns}
                            onAddFilters={onAddFilters}
                            dataSources={dataSources}
                            setDataSources={setDataSources}
                        />
                        <ControlPanel
                            columns={columns}
                            filters={filters}
                            dataSources={dataSources}
                            moveColumns={moveColumns}
                            onFilterUpdated={onFilterUpdated}
                            onColumnUpdated={onColumnUpdated}
                        />
                    </div>
                    {
                        dataSources?.length ? columns?.length ?
                            <Report /> : 
                            <div className="w-100 h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">Add at least one column to display the report.</div> :
                        <div className="w-100 h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">No Data Sources Selected</div>
                    }
                </div>
            </div>
        </React.Fragment>
    );
};
export default withTranslation()(Create);
