import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";

import {
  Form,
  FormFeedback,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Col,
  Button
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Flatpickr from "react-flatpickr";

//redux
import { useSelector, useDispatch } from "react-redux";
import { createGoogleCalendarEvent, getGoogleCalendarData, getGoogleCalendarEvents } from "store/calendarSync/action";
import { ApplicationState } from "store";
import moment from "moment";
import DtsEmailsInputs from "Components/Common/FormBuilder/DtsEmailsInputs";
import { studentGetRequest } from "store/student/action";

const BYDAY: any = { "SU": 0, "MO": 1, "TU": 2, "WE": 3, "TH": 4, "FR": 5, "SA": 6 }
export interface SuggestionOption {
  readonly value: string;
  readonly label: string;
}

interface PostEvent {
  summary: string;
  location: string;
  description: string;
  eventDate: Date | null;
  startDateTime: Date | null;
  endDateTime: Date | null;
  attendees: string[];
}

// Function to check if the required fields are empty
const checkObjectEmpty = (obj: PostEvent) => {
  // Destructure to exclude 'description'
  const { description, ...rest } = obj;

  // Check if any of the remaining fields are empty, null, or undefined
  return Object.values(rest).some(value => value === null || value === undefined || value === '');
};

interface FProps {
  props: any;
  meetingsModals: boolean;
  setMeetingsModals: (event: boolean) => void;
  info: any;
  model: string;
}
const MeetingsModal: React.FC<FProps> = ({
  props,
  meetingsModals,
  setMeetingsModals,
  info,
  model
}) => {
  const {id} = useParams();
  var date = new Date();
  var d = date.getDate();
  var m = date.getMonth();
  var y = date.getFullYear();
  const dispatch: any = useDispatch();
  const studentDetails = useSelector((state: ApplicationState) => state.student.details);
  const googleConnect = useSelector((state: ApplicationState) => state.calendarSync.data);
  const [event, setEvent] = useState<any>({});
  const [modal, setModal] = useState<boolean>(false);
  const [selectedNewDay, setSelectedNewDay] = useState(moment().format())
  const [isEdit, setIsEdit] = useState<boolean>(false);
  const [isEditButton, setIsEditButton] = useState<boolean>(true);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteEvent, setDeleteEvent] = useState<string>('');
  const [eventName, setEventName] = useState<string>("");
  const [accountDetails, setAccountDetails] = useState<any>();
  const [newEvent, setNewEvent] = useState<string>('');
  const [newStartTime, setNewStartTime] = useState(moment().add(30, 'minutes').format());
  const [newEndTime, setNewEndTime] = useState(moment(newStartTime).add(60, 'minutes').format());
  const [newLocation, setNewLocation] = useState<any>('');
  const [newDescription, setNewDescription] = useState<any>('');
  const [emails, setEmails] = useState<any[]>([]);
  const [dateStartTime, setDateStartTime] = useState(moment().add(30, 'minutes').format());
  const [dateEndTime, setDateEndTime] = useState(moment(dateStartTime).add(60, 'minutes').format());
  const [disabled,setdisabled] = useState(true)
  const [emailData, setEmailData] = useState<any>({
    to: info.email || info.student.email
  })
  const [postEvent, setPostEvent] = useState<PostEvent>({
    summary: "",
    location: "",
    description: "",
    eventDate: selectedNewDay,
    startDateTime: dateStartTime,
    endDateTime: dateEndTime,
    attendees: [info.email || info.student.email]
  })

  useEffect(() => {
    if( model == "students") {
        if (info.email) {
            setEmailData((prevState: any) => ({
                ...prevState,
                to: info.email
            }));
            setPostEvent(prevState => ({
                ...prevState,
                attendees: [info.email]
            }));
        }
    }
    else {
        if (info.student.email) {
            setEmailData((prevState: any) => ({
                ...prevState,
                to: info.student.email
            }));
            setPostEvent(prevState => ({
                ...prevState,
                attendees: [info.student.email]
            }));
        }
    }
}, [info]);

  const events: any = [];
  const isJson = (str: any) => {
    try {
        let options = JSON.parse(str);
        return options
    } catch (e) {
        return false;
    }
}

useEffect(() => {
  dispatch(studentGetRequest(id))
 },[id])


useEffect(() => {
  const selectedDate = moment(new Date());
  const currentTime = moment();

  // Determine the nearest upcoming 00 or 30-minute mark
  const roundedMinutes = Math.ceil(currentTime.minute() / 30) * 30;
  if (roundedMinutes === 60) {
    currentTime.add(1, 'hour').startOf('hour'); // Move to the next hour
    currentTime.set({ minute: 0 });
  } else {
    currentTime.set({ minute: roundedMinutes });
  }

  // Combine the selected date with the adjusted time
  const combinedDateTime = selectedDate
    .set({
      hour: currentTime.hour(),
      minute: currentTime.minute(),
      second: currentTime.second(),
    })
    .format();

  // Update the states with the calculated times
  setSelectedNewDay(new Date(combinedDateTime));
  setDateStartTime(moment(combinedDateTime).format());
  setDateEndTime(moment(combinedDateTime).add(30, 'minutes').format());
  setNewStartTime(new Date(moment(combinedDateTime).format()));
  setNewEndTime(new Date(moment(combinedDateTime).add(30, 'minutes').format()));
  toggle();
}, []);

  useEffect(() => {
    dispatch(getGoogleCalendarData())
    setAccountDetails(googleConnect)
  }, [])

  const toggle = () => {
    if (modal) {
      setModal(false);
      setEvent(null);
      setIsEdit(false);
      setIsEditButton(true);
      clearNewMeeting();
    } else {
      setModal(true);
    }
  };
  /**
   * Handling date click on calendar
   */
  const handleCreateEvent = () => {
    const email = googleConnect[0]?.email;
    const handleSuccess = () => {
      clearNewMeeting();
      dispatch(getGoogleCalendarEvents(email))
      toggle();
    }
    dispatch(createGoogleCalendarEvent(email, postEvent, handleSuccess))
  }

  const handleDateChange = (dateType: string, date: any) => {
    // Create a moment object for the selected date
    let selectedDate = moment(date);
    let currentTime = moment();
  
    let combinedDateTime: any;
  
    // Update the postEvent state based on the dateType
    switch (dateType) {
      case 'date':
        combinedDateTime = selectedDate
          .set({
            hour: currentTime.hour(),
            minute: currentTime.minute(),
            second: currentTime.second(),
          })
          .format();
  
        setPostEvent((prevObject) => ({
          ...prevObject,
          eventDate: combinedDateTime, // Set the event date
          startDateTime: combinedDateTime, // Set the start date-time
          endDateTime: moment(combinedDateTime).add(30, 'minutes').format(), // Set the end date-time (30 minutes later)
        }));
        break;
  
      case 'startTime':
        combinedDateTime = moment(postEvent.eventDate) // Use the existing event date
          .set({
            hour: selectedDate.hour(), // Set hour from selected start time
            minute: selectedDate.minute(), // Set minute from selected start time
            second: selectedDate.second(), // Set second from selected start time
          })
          .format();
          setNewStartTime(new Date(combinedDateTime));
          setNewEndTime(new Date((moment(combinedDateTime).add(30, 'minutes').format())));
        setPostEvent((prevObject) => ({
          ...prevObject,
          startDateTime: combinedDateTime, // Update only the start date-time
          endDateTime: moment(combinedDateTime).add(30, 'minutes').format(), // Set the end date-time (30 minutes later)
        }));
        break;
  
      case 'endTime':
        combinedDateTime = moment(postEvent.startDateTime) // Use the existing start date-time
          .set({
            hour: selectedDate.hour(), // Set hour from selected end time
            minute: selectedDate.minute(), // Set minute from selected end time
            second: selectedDate.second(), // Set second from selected end time
          })
          .format();
  
        setPostEvent((prevObject) => ({
          ...prevObject,
          endDateTime: combinedDateTime, // Update only the end date-time
        }));
        break;
  
      default:
        break;
    }
  };

  const clearNewMeeting = () => {
    setPostEvent({
      summary: "",
      location: "",
      description: "",
      eventDate: selectedNewDay,
      startDateTime: dateStartTime,
      endDateTime: dateEndTime,
      attendees: [info?.email || info?.student?.email],
    })
    setEmailData({
      to: info?.email || info?.student?.email
    })
    setNewEvent('');
    setNewLocation('');
    setNewDescription('');
  }
  const str_dt = function formatDate(date: any) {
    var monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];
    var d = new Date(date),
      month = "" + monthNames[d.getMonth()],
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;
    return [day + " " + month, year].join(",");
  };

  // events validation
  const validation: any = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      id: (events && events.id) || "",
      title: (events && events.title) || "",
      category: (event && event.category) || "",
      location: (events && events.location) || "",
      description: (events && events.description) || "",
      defaultDate: (event && event.defaultDate) || [],
      eventDate: (events && events.eventDate) || [],
      start: (events && events.start) || "",
      end: (events && events.end) || ''
    },

    validationSchema: Yup.object({
      title: Yup.string().required("Please Enter Your Event Name"),
      category: Yup.string().required("Please Select Your Category"),
      location: Yup.string().required("Please Enter Your Location"),
      description: Yup.string().required("Please Enter Your Description"),
      eventDate: Yup.date().required('Event date is required'),
      start: Yup.date().required('Start Time is required'),
      end: Yup.date().required('End Time is required'),
      defaultDate: Yup.array().of(Yup.date()).required('Date range is required').min(2, 'Select at least two dates'),
    }),
    onSubmit: (values) => {
      var updatedDay: any = "";
      if (selectedNewDay) {
        updatedDay = new Date(selectedNewDay[1]);
        updatedDay.setDate(updatedDay.getDate() + 1);
      }

      if (isEdit) {
        const updateEvent = {
          id: event.id,
          title: values.title,
          className: values.category,
          start: selectedNewDay ? selectedNewDay[0] : event.start,
          end: selectedNewDay ? updatedDay : event.end,
          location: values.location,
          description: values.description,
        };
        // update event
        // dispatch(onUpdateEvent(updateEvent));
        validation.resetForm();
      } else {
        const newEvent = {
          id: Math.floor(Math.random() * 100),
          title: values["title"],
          start: selectedNewDay[0],
          end: updatedDay,
          className: values["category"],
          location: values["location"],
          description: values["description"],
        };
        // save new event
        // dispatch(onAddNewEvent(newEvent));
        validation.resetForm();
      }

      // setSelectedDay(null);
      setSelectedNewDay(null);
      toggle();
    },
  });

  const submitOtherEvent = () => {

    document.getElementById("form-event")?.classList.remove("view-event");

    document
      .getElementById("event-title")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-category")?.classList.replace("d-none", "d-block");
    (document.getElementById("event-start-date")?.parentNode as HTMLElement).classList.remove("d-none");
    document
      .getElementById("event-start-date")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-location")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-description")?.classList.replace("d-none", "d-block");
    document
      .getElementById("event-start-date-tag")?.classList.replace("d-block", "d-none");
    document
      .getElementById("event-location-tag")?.classList.replace("d-block", "d-none");
    document
      .getElementById("event-description-tag")?.classList.replace("d-block", "d-none");

    setIsEditButton(true);
  };

  /**
   * On category darg event
   */
  const onDrag = (event: any) => {
    event.preventDefault();
  };

  /**
   * On calendar drop event
   */
  const onDrop = (event: any) => {
    const date = event["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );

    const draggedEl = event.draggedEl;
    const draggedElclass = draggedEl.className;
    if (
      draggedEl.classList.contains("external-event") &&
      draggedElclass.indexOf("fc-event-draggable") === -1
    ) {
      const modifiedData = {
        id: Math.floor(Math.random() * 1000),
        title: draggedEl.innerText,
        start: modifiedDate,
        className: draggedEl.className,
      };
      // dispatch(onAddNewEvent(modifiedData));
    }
  };
  const handleSelectChange = (newValue: any, actionMeta: any) => {
    setEmails(newValue);
  };
  const filterSuggestions = (inputValue: string) => {
    return emails.filter((i) =>
      i.label.toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const promiseOptions = (inputValue: string) =>
    new Promise<SuggestionOption[]>((resolve) => {
      setTimeout(() => {
        resolve(filterSuggestions(inputValue));
      }, 1000);
    });
 
  document.title = "Calendar | Zilter";
  return (
    <React.Fragment>
      <div className="">

            <Modal isOpen={meetingsModals} id="event-modal" centered>
              <ModalHeader toggle={() => {
            setMeetingsModals(false);
          }} tag="h5" className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary">
                <span className='text-light'>{!!isEdit ? event.title : "Add Event"}</span>
              </ModalHeader>
              <ModalBody>
                <Form
                  className={!!isEdit ? "needs-validation view-event" : "needs-validation"}
                  name="event-form"
                  id="form-event"
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <Row className="event-form">
                    <Col xs={12}>
                    <div className="mb-3">
                        <Label className="form-label">Event Name</Label>
                        <Input
                          className={!!isEdit ? "d-none" : "d-block"}
                          placeholder="Enter event name"
                          type="text"
                          name="title"
                          id="event-title"
                          onChange={(e) => {
                            setNewEvent(e.target.value)
                            setPostEvent((prevObject) => ({
                              ...prevObject,
                              summary: e.target.value
                          }))
                            // validation.handleChange()
                          }
                          }
                          onBlur={validation.handleBlur}
                          value={postEvent.summary || ""} />
                        {validation.touched.title && validation.errors.title ? (
                          <FormFeedback type="invalid" className="d-block">{!postEvent.summary ? validation.errors.title : null}</FormFeedback>)
                          : null}
                      </div>
                    </Col>

                    <Col xs={12}>
                    <div className="mb-3">
                      <Label>Event Date</Label>
                      <div className={!!isEdit ? "input-group d-none" : "input-group"}>
                        <Flatpickr
                        // defaultValue={selectedNewDay}
                          className="form-control"
                          id="event-start-date"
                          name="defaultDate"
                          placeholder="Select Date"
                          value={selectedNewDay}
                          options={{
                            // mode: "range",
                            dateFormat: "d M Y",
                            // minDate: new Date(),
                          }}
                          onChange={(date) => { handleDateChange("date", moment(date[0]))
                          }}
                        />
                        <span className="input-group-text">
                          <i className="ri-calendar-event-line"></i>
                        </span>
                      </div>
                      {validation.touched.eventDate && validation.errors.eventDate ? (
                        <FormFeedback type="invalid" className="d-block">{!selectedNewDay ? validation.errors.eventDate : null} </FormFeedback>
                      ) : null}
                    </div>
                  </Col>
                  <Col xs={6}>
                      <div className="mb-3">
                        <Label>Start Time</Label>
                        <div className="input-group">
                          <Flatpickr className="form-control"
                            name="start"
                            value={newStartTime}
                            onChange={(date) => {handleDateChange("startTime", moment(date[0]))
                              validation.setFieldValue("start", date[0]);
                            }}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "h:i K",
                            }} />
                          <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
                        </div>
                        {validation.touched.start && validation.errors.start ? (
                          <FormFeedback type="invalid" className="d-block">{!newStartTime ? validation.errors.start : null} </FormFeedback>
                        ) : null}
                      </div>
                    </Col>

                    <Col xs={6}>
                      <div className="mb-3">
                        <Label>End Time</Label>
                        <div className="input-group">
                          <Flatpickr className="form-control input-group"
                            name="end"
                            value={newEndTime}
                            onChange={(date) => {handleDateChange("endTime", moment(date[0]))
                              validation.setFieldValue("end", date[0])
                            }}
                            options={{
                              enableTime: true,
                              noCalendar: true,
                              dateFormat: "h:i K",
                            }} />
                          <span className="input-group-text"> <i className="ri-calendar-event-line"></i> </span>
                        </div>
                        {validation.touched.end && validation.errors.end ? (
                          <FormFeedback type="invalid" className="d-block">{!newEndTime ? validation.errors.end : null} </FormFeedback>
                        ) : null}
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-2">
                        <Label htmlFor="exampleInputrounded" className="form-label">
                          Invitee Email
                        </Label>
                        <DtsEmailsInputs placeholder={"Invitee email"} defaultValue={`${emailData.to}`} setdisabled={setdisabled} onChange={(values: any) => {
                          setPostEvent((prevObject) => ({
                            ...prevObject,
                            attendees: [...values]
                        }))
                          setEmailData({ ...emailData, to: values })}} />
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label htmlFor="event-location">Location</Label>
                        <div>
                          <Input
                            type="text"
                            className={!!isEdit ? "d-none" : "d-block"}
                            name="location"
                            id="event-location"
                            placeholder="Event location"
                            onChange={(e) => {
                              setNewLocation(e.target.value)
                              setPostEvent((prevObject) => ({
                                ...prevObject,
                                location: e.target.value
                            }))
                              // validation.handleChange()
                            }
                            }
                            onBlur={validation.handleBlur}
                            value={postEvent.location || ""} />
                          {validation.touched.location && validation.errors.location ? (
                            <FormFeedback type="invalid" className="d-block">{!postEvent.location ? validation.errors.location : null}</FormFeedback>
                          ) : null}
                        </div>
                      </div>
                    </Col>
                    <Col xs={12}>
                      <div className="mb-3">
                        <Label className="form-label">Description</Label>
                        <textarea
                          className={!!isEdit ? "form-control d-none" : "form-control d-block"}
                          id="event-description"
                          name="description"
                          placeholder="Enter a description"
                          rows={3}
                          onChange={(e) => {
                            setNewDescription(e.target.value)
                            setPostEvent((prevObject) => ({
                              ...prevObject,
                              description: e.target.value
                          }))
                          }}
                          onBlur={validation.handleBlur}
                          value={postEvent.description || ""}></textarea>
                        {/* {validation.touched.description && validation.errors.description ? (
                          <FormFeedback type="invalid" className="d-block">{!newDescription ? validation.errors.description : null}</FormFeedback>
                        ) : null} */}
                      </div>
                    </Col>
                  </Row>
                  <div className="hstack gap-2 justify-content-end">
                    {!!isEdit && (
                      <button
                        type="button"
                        className="btn btn-soft-danger"
                        id="btn-delete-event"
                        onClick={() => {
                          toggle();
                          setDeleteModal(true);
                        }}
                      >
                        <i className="ri-close-line align-bottom"></i> Delete
                      </button>
                    )}
                    {isEditButton &&
                      <button type="button" disabled={checkObjectEmpty(postEvent)} onClick={handleCreateEvent} className="btn btn-success" id="btn-save-event">
                        {!!isEdit ? "Edit Event" : "Add Event"}

                      </button>}
                  </div>
                </Form>
              </ModalBody>
            </Modal>
          {/* </Col>
        </Row> */}
      </div>
    </React.Fragment>
  );
};
// MeetingsModal.propTypes = {
//   events: PropTypes.any,
//   categories: PropTypes.array,
//   className: PropTypes.string,
//   onGetEvents: PropTypes.func,
//   onAddNewEvent: PropTypes.func,
//   onUpdateEvent: PropTypes.func,
//   onDeleteEvent: PropTypes.func,
//   onGetCategories: PropTypes.func,
// };

export default MeetingsModal;