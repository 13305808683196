import React from "react";
import { withTranslation } from 'react-i18next';
import { Button, Card, CardBody, Input, Table } from "reactstrap";

const Roles: React.FC = (props: any) => {
    return (
        <React.Fragment>
            <Card>
                <CardBody className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                        <div className="form-icon">
                            <Input type="email" className="form-control form-control-icon" placeholder="example@gmail.com" />
                            <i className="ri-mail-unread-line"></i>
                        </div>
                        <div>
                            <Button
                                color="primary"
                                className="btn-label">
                                    <i className=" ri-file-excel-2-line label-icon align-middle fs-16 me-2"></i>
                                    {props.t('reports.create_report')}
                            </Button>
                        </div>
                    </div>
                    <div className="table-responsive">
                        <Table className="align-middle table-nowrap mb-0">
                            <thead className='table-primary'>
                                <tr>
                                    <th className='text-center table-soft-primary text-primary align-middle' scope="col">Customer</th>
                                    <th className='text-center table-soft-primary text-primary align-middle' scope="col">Date</th>
                                    <th className='text-center table-soft-primary text-primary align-middle' scope="col">Invoice</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className='table-soft-light'>
                                    <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                                    <td className='align-middle text-dark'>October 15, 2021</td>
                                    <td className='align-middle text-dark'>$2,300</td>
                                </tr>
                                <tr className='table-soft-light'>
                                    <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                                    <td className='align-middle text-dark'>October 15, 2021</td>
                                    <td className='align-middle text-dark'>$2,300</td>
                                </tr>
                                <tr className='table-soft-light'>
                                    <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                                    <td className='align-middle text-dark'>October 15, 2021</td>
                                    <td className='align-middle text-dark'>$2,300</td>
                                </tr>
                                <tr className='table-soft-light'>
                                    <td className='align-middle text-dark fw-semibold'>Bobby Davis</td>
                                    <td className='align-middle text-dark'>October 15, 2021</td>
                                    <td className='align-middle text-dark'>$2,300</td>
                                </tr>
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    );
};
export default withTranslation()(Roles);
