import { Reducer } from "redux";
import { ViewActionTypes, ViewState } from "./types";
import { isJson } from "utils";


export const initialState: ViewState =
{
    view: null,
    views: null,
    pagination: null,
    loading: false,
    storeFilters: null,    
    storeView: null
}
const reducer: Reducer<ViewState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ViewActionTypes.GET_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.GET_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                view: action.payload
            };
        }
        case ViewActionTypes.GET_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload, view: null};
        }  
        case ViewActionTypes.POST_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.POST_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ViewActionTypes.POST_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case ViewActionTypes.GET_VIEWS_REQUEST: {
            return { ...state, loading: true, list:[] };
        }
        case ViewActionTypes.GET_VIEWS_SUCCESS: {
            return {
                ...state,
                loading: false,
                views: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ViewActionTypes.GET_VIEWS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list:[] };
        }
        case ViewActionTypes.PUT_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.PUT_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case ViewActionTypes.PUT_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ViewActionTypes.DELETE_VIEW_REQUEST: {
            return { ...state, loading: true };
        }
        case ViewActionTypes.DELETE_VIEW_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case ViewActionTypes.DELETE_VIEW_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }    
        case ViewActionTypes.POST_FILTER_SUCCESS: {   
            let filterData = localStorage?.getItem('storeFilters')       
            let filters: any = filterData && isJson(filterData) ? JSON.parse(filterData) : {}             
            localStorage.setItem('storeFilters', JSON.stringify({...filters, ...action.payload}))
            return { ...state, loading: false, storeFilters: action.payload };
        } 
        case ViewActionTypes.GET_FILTER_REQUEST: {    
            let localFilters = localStorage?.getItem('storeFilters')       
            let filters: any = localFilters && isJson(localFilters) ? JSON.parse(localFilters) : null        
            return { ...state, loading: true, storeFilters: filters };
        }  
        case ViewActionTypes.GET_FILTER_SUCCESS: {     
            return { ...state, loading: false };
        }           
        case ViewActionTypes.POST_STORE_VIEW_SUCCESS: {   
            let viewData = localStorage?.getItem('storeView')       
            let views: any = viewData && isJson(viewData) ? JSON.parse(viewData) : {}        
            localStorage.setItem('storeView', JSON.stringify({...views, ...action.payload}))
            return { ...state, loading: false };
        }  
        case ViewActionTypes.GET_STORE_VIEW_REQUEST: {    
            let localView = localStorage?.getItem('storeView')       
            let View: any = localView && isJson(localView) ? JSON.parse(localView) : null        
            return { ...state, loading: true, storeView: View };
        }  
        case ViewActionTypes.GET_STORE_VIEW_SUCCESS: {     
            return { ...state, loading: false };
        }     
        default: {
            return state;
        }
    }
};

export { reducer as ViewReducer };
