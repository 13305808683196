import BreadCrumb from 'Components/Common/BreadCrumb';
import React, { useEffect, useState } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import {
    Button,
    Col,
    Container,
    Nav,
    NavItem,
    Offcanvas,
    OffcanvasBody,
    OffcanvasHeader,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Label,
} from 'reactstrap';
import avtarImage2 from '../../assets/images/users/avatar-1.jpg';
import avtarImage3 from '../../assets/images/users/user-dummy-img.jpg';
import classnames from 'classnames';
import { Link } from 'react-router-dom';
import TableContainer from './TableComponent';


const Access = () => {

    const [activeArrowTab, setactiveArrowTab] = useState<number>(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState<number[]>([1]);
    const toggleArrowTab = (tab: number) => {
        if (activeArrowTab !== tab) {
            const modifiedSteps = [...passedarrowSteps, tab];
            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    };

    const CreateRole = () => {
        // Implement your logic for creating a role
    };
    const columns = [
        {
            Header:"Email",
            accessor: "email",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        },
        {
            Header:"Status",
            accessor: "status",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        }
    ]

    const calendarcolumns = [
        {
            Header:"Name",
            accessor: "name",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        },
        {
            Header:"Status",
            accessor: "status",
            filterable: true,
            isSortable:false,
            disableFilters: true,
            show: true
        }
    ]

    return (
        <React.Fragment>
            <div className="step-arrow-nav mt-4 mb-4">
                <Row className="w-100 vstack gap-3 justify-content-start">
                    <Col lg={12} className="vstack justify-content-between">
                        <h4>Permissions</h4>
                        <div className='vstack gap-1'>
                            <p>Permissions manage how users can work with features and tools.</p>       
                            <div className='px-2'>
                                <TableContainer
                                    columns={columns}
                                    data={[]}
                                    divClass="table-responsive table-card mb-1"
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    SearchPlaceholder='Search...'
                                    isPageSizeChange={true}
                                    isBordered={true}
                                    title={"Super Admins can manage all users, tools, and settings."}
                                    subtitle={"Users in this Permission Set can access everything except some subscription features. They need a paid seat for full access to Sales Hub and Service Hub. "}
                                />
                            </div>                    
                            
                        </div>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Access);