import React, { useEffect, useState } from "react"
import loaderlogo from "../../assets/images/dtsimg/svg/zilter_logo_white.svg"
import { Button } from "reactstrap";
import ArrangeFields from "./ArrangeFields";

const Arranger = ({metadata, onChange, fields} : any) => {
    const [isopen, setOpen] = useState<boolean>(false)
    return (
        <React.Fragment>            
            <ArrangeFields
                show={isopen}
                onCloseClick={() => setOpen(false)}
                dataFields={metadata}
                onChange={onChange}
                fields={fields}
            />
            <div>
                <span className="cursor-pointer"
                    onClick={() => setOpen(true)}>
                        <i className="ri-settings-2-fill label-icon align-middle fs-16 me-2 text-primary"></i>
                </span>
            </div>
        </React.Fragment>
    )
}

export default Arranger;