export interface CustomForm {
    list: any;
    name: string;
    last_name: string;
    image_url: string;
    phone_number: string;
    email: string;
    website: string;
    company_name:string;
    no_of_employees: string;
}
export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    first: boolean,
    last: boolean
  }

export enum CustomFormActionTypes {
    GET_CUSTOMFORMS_REQUEST = "@@customform/GET_CUSTOMFORMS_REQUEST",
    GET_CUSTOMFORMS_SUCCESS = "@@customform/GET_CUSTOMFORMS_SUCCESS",
    GET_CUSTOMFORMS_ERROR = "@@customform/GET_CUSTOMFORMS_ERROR",
    GET_CUSTOMFORM_REQUEST = "@@customform/GET_CUSTOMFORM_REQUEST",
    GET_CUSTOMFORM_SUCCESS = "@@customform/GET_CUSTOMFORM_SUCCESS",
    GET_CUSTOMFORM_ERROR = "@@customform/GET_CUSTOMFORM_ERROR",
    PUT_CUSTOMFORM_REQUEST = "@@customform/PUT_CUSTOMFORM_REQUEST",
    PUT_CUSTOMFORM_SUCCESS = "@@customform/PUT_CUSTOMFORM_SUCCESS",
    PUT_CUSTOMFORM_ERROR = "@@customform/PUT_CUSTOMFORM_ERROR",
    POST_CUSTOMFORM_REQUEST = "@@customform/POST_CUSTOMFORM_REQUEST",
    POST_CUSTOMFORM_SUCCESS = "@@customform/POST_CUSTOMFORM_SUCCESS",
    POST_CUSTOMFORM_ERROR = "@@customform/POST_CUSTOMFORM_ERROR",
    DELETE_CUSTOMFORM_REQUEST = "@@customform/DELETE_CUSTOMFORM_REQUEST",
    DELETE_CUSTOMFORM_SUCCESS = "@@customform/DELETE_CUSTOMFORM_SUCCESS",
    DELETE_CUSTOMFORM_ERROR = "@@customform/DELETE_CUSTOMFORM_ERROR",
    GET_METADATA_REQUEST = "@@customform/GET_METADATA_REQUEST",
    GET_METADATA_SUCCESS = "@@customform/GET_METADATA_SUCCESS",
    GET_METADATA_ERROR = "@@customform/GET_METADATA_ERROR"
}

export interface CustomFormState {
    readonly loading: boolean;
    readonly errors?: string;
    readonly response?: any;
    readonly token?: string;
    readonly permissionsdata?: any;
    readonly list?: any;
    readonly customformDetails?: any;
    readonly pagination: Pagination | null;
    readonly metadata: any;
    readonly formloading: any;
}
