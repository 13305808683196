import React, { useEffect, useState } from 'react';
import { Button, Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import Finance from 'pages/ChannelDetails/Finanace/Finance';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from 'store';
import StudentList from './StudentsList'
import { useParams } from 'react-router-dom';
import { ResetInvoceData } from 'store/auth/action';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { channelGetRequest, channelMetadataGetRequest, channelUpdateRequest, invoicesStudentsList } from 'store/channels/action';
import { isJson } from 'utils';

const Channel = (props: any) => {
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const ChannelDetail = useSelector((state: ApplicationState) => state.channels.details)
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const students = useSelector((state: ApplicationState) => state.channels.invoicesstudents)
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const [isStudentListopen, setIsStudentListopen] = useState(false);
    const [mainList, setMainList] = useState<any>([]);
    const [selectedlist, setSelectedList] = useState<any>([]);
    const [disabled, setdisabled] = useState<any>(true)
    const [create, setCreate] = useState<boolean>(false);

    const tabList = [{
        title: 'Finance',
        component: Finance
    }]

    const handleselect = (obj: any, value: boolean) => {
        if (value === true) {
            let newList = selectedlist
            newList.push(obj)
            setSelectedList(newList)
            setdisabled(!newList.length)
        }
        else {
            let newList = selectedlist.filter((item: any) => item.id !== obj.id);
            setSelectedList(newList)
            setdisabled(!newList.length)
        }

    }

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setCreate(true)
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.invoices;
            if (access && access?.length) {
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }

                    })
                }
            }
        }
    }, [subLevelPermissionsList, userProfile])

    const createStudents = () => {
        dispatch(ResetInvoceData())
    }

    useEffect(() => {
        dispatch(invoicesStudentsList(id))
    },[]) 

    useEffect(() => {
        const parsedData: any = students && students.length ? students.map((item: any) => {
            const valuesJson = item.valuesJson && isJson(item.valuesJson) ? JSON.parse(item.valuesJson): item.valuesJson;
            let values = { ...valuesJson, ...item, id: item.id, createdAt: item.createdAt }
            return values
        }) : []
        let studentsList = parsedData && parsedData.length && parsedData.reverse()
        setMainList(studentsList)
    }, [students])

    const createInvoice = () => {
        if (create) return <Button
            onClick={() => { setIsStudentListopen(true); createStudents() }}
            color="primary"
            size='sm'
            className="btn-label py-2">
            <i className="ri-article-line label-icon align-middle fs-16 me-2"></i>
            {props.t("channel.create_invoice")}
        </Button>
        else return <Button
            color="primary"
            size='sm'
            disabled
            className="btn-label py-2">
            <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
            {props.t("channel.create_invoice")}
        </Button>
    }
    
    return (
        <React.Fragment>
            <StudentList
                show={isStudentListopen}
                onCloseClick={() => setIsStudentListopen(false)}
                mainList={mainList}
                selectedlist={selectedlist}
                setSelectedList={setSelectedList}
                handleselect={handleselect}
                ChannelDetail={ChannelDetail}
                disabled={disabled}
            />
            <div className="page-content">
                <Container fluid>
                    <Section
                        detailsGetAction={channelGetRequest}
                        metaDatagetRequest={channelMetadataGetRequest}
                        updateAction={channelUpdateRequest}
                        model={"channels"}
                        reducerState={"channels"}
                        translater={"channel"}
                        label={"Channels"}
                        tabList={tabList}
                        createInvoice={createInvoice}
                    />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Channel);