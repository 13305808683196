import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { courseCreateRequest, courseDelete, courseGetRequest, courseListGetRequest, coursesMetadataGetRequest } from 'store/course/action';

const Course = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <Listing 
            viewId={viewId}
            isCreateModal={isCreateModal}
            setCreateOpen={setCreateOpen}
            getListAction={courseListGetRequest}
            deleteAction={courseDelete}
            detailsGetAction={courseGetRequest}
            metaDatagetRequest={coursesMetadataGetRequest}
            createAction={courseCreateRequest}
            model={"courses"}
            reducerState={"courses"}
            translater={"courses"}
        />
    );
};
export default withTranslation()(Course);


