import React from 'react';
import { Container, Row } from 'reactstrap';
import BreadCrumb from '../../../Components/Common/BreadCrumb';
import FormBuilder from './FormBuilder';

const CustomForms = () => {

    document.title = "DTS | Zilter";

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <BreadCrumb title="Custom Forms" pageTitle="Custom Forms" />
                    <Row>
                        <FormBuilder />
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

export default CustomForms;