import React, { useState, useRef, useEffect } from 'react';

const OTPInput = ({ setInputs, inputs, props, t }: any) => {
    const [otp, setOTP] = useState(['', '', '', '', '', '']);
    const [error, setError] = useState<boolean>(false);
    const inputRefs = useRef<(HTMLInputElement | null)[]>([]);

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>, index: number) => {
        const newOTP = [...otp];
        if (e.target.value.length > 1) return; // Prevent entering more than one character
        newOTP[index] = e.target.value;
        setOTP(newOTP);
        setInputs({ ...inputs, otp: newOTP.join('') })
        if (index < otp.length - 1 && e.target.value.length === 1 && inputRefs.current[index + 1]) {
            inputRefs.current[index + 1]?.focus();
        }
    };
    
  const handleBackspace = (e: React.KeyboardEvent<HTMLInputElement>, index: number) => {
    if (e.key === 'Backspace' && index > 0 && !otp[index]) {
      inputRefs.current[index - 1]?.focus();
      let otpArray = inputs.otp.split(""); // Convert string to array of characters
        while (otpArray.length > 0) {
            let removedChar = otpArray.shift(); // Remove the first character
            setInputs({ ...inputs, otp: otpArray})
        }
    }
  };

    const hasEmptyString = () => {
        return otp.some(item => item.trim() === ''); // Using trim() to handle strings with only whitespace
    }
    const hasallEmptyString = () => {
        return !otp.some(item => item.trim() !== '');// Using trim() to handle strings with only whitespace
    }

    useEffect(() => {
        !hasallEmptyString() ? hasEmptyString() ? setError(true) : setError(false) : setError(false)
    }, [otp])
    return (
        <div className='text-center'>
            {otp.map((digit, index) => (
                <input
                    key={index}
                    type="number"
                    className='fs-20 border-0 border-bottom border-2 border-info bg-transparent text-center'
                    maxLength={1}
                    value={digit}
                    onChange={(e) => handleInputChange(e, index)}
                    onKeyDown={(e) => handleBackspace(e, index)}
                    style={{ width: '2rem', marginRight: '1rem' }}
                    ref={(input) => {
                        inputRefs.current[index] = input;
                    }}
                />
            ))}
            <div>
                {error ? <div className="text-danger fs-14 fw-medium text-center">{t("signup.your_code_should_be_composed_of_6_numbers")}</div> : <div className="text-light fs-14 fw-medium text-center">{t("signup.your_code_should_be_composed_of_4_numbers")}</div>}
            </div>
        </div>
    );
};

export default OTPInput;