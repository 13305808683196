import React from "react";
import { Container, Row } from "reactstrap";
import UsersList from "./UsersList";

const index = () => {
  document.title = "DTS | Zilter";

  return (
    <>
      <div className="page-content">
        <Container fluid>
          <Row>
            <UsersList />
          </Row>
        </Container>
      </div>
    </>
  );
};

export default index;
