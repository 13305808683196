import { useEffect, useState } from "react";
import { Form, Button, Col } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { toast } from "react-toastify";
import { studentExternalApplication } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const DTSFullForm = ({ dataFields, btn_text, data, md, lg, sm, xl, xs, BtnClassName,tenantId, isUpdate }: any) => {
	const filterFields: any = [];
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 

	dataFields &&
		dataFields.length &&
		dataFields.map((field: any) => {
			let item = field ? field : {};
			if (field) return filterFields.push(item);
		});

	const [formValues, setFormValues] = useState<any>({});
	const [formerror, setError] = useState<any>([]);
	

	const onChange = (option: any, key: any) => {
		if (typeof option == "object") {
			setFormValues({ ...formValues, [key]: option.value });
		} else setFormValues({ ...formValues, [key]: option });
	};

	useEffect(() => {
		if (data) {
			setFormValues(data);
		}
	}, [data]);

	return (
		<Form action="" className="d-flex flex-column justify-content-center h-100">
			<div className="live-preview hstack flex-wrap">
				{filterFields && filterFields.length
					? filterFields.map((field: any) => {
						if(field.hidden) return
						return (
							<Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} className="align-self-start">
								<FormBuilder
									placeholder={field.placeholder}
									name={field.key}
									onChange={(e: any) => onChange(e, field.key)}
									options={(field.values && field.values.reverse()) || []}
									label={field.label}
									type={field.type}
									value={formValues[field.key]}
									defaultValue={formValues[field.key]}
									isEditState={true}
									actionButtons={isUpdate}
									Editable={true}
									rest={field}
									invalid={true}
									formValues={formValues}
									dataFields={filterFields}
									setError={setError}
									formerror={formerror}
									isTextract={true}
								/>
							</Col>
						);
					})
					: null}
			</div>
			<div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
				<Button
					className={BtnClassName ? `btn btn-success ${BtnClassName}` : "btn btn-success w-100"}
					disabled={true}
				>
					{btn_text}
				</Button>
			</div>
		</Form>
	);

};

export default DTSFullForm;
