import {Reducer} from "redux";
import {WhatsappActionTypes, WhatsappState} from "./types";
export const initialState: WhatsappState = {
    whatsapps:[],
    errors: null,
    loading: false,
    pagination: null,
};

const reducer: Reducer<WhatsappState> = (state: any = initialState, action: any) => {
    switch (action.type) {          
        case WhatsappActionTypes.GET_WHATSAPPS_REQUEST: {
            return {...state, loading: true};
        }
        case WhatsappActionTypes.GET_WHATSAPPS_SUCCESS: {
            return {
                ...state,
                loading: false,
                errors: null,
                whatsapps: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case WhatsappActionTypes.GET_WHATSAPPS_ERROR: {
            return {...state, loading: false, whatsapps: [], errors: action.payload};
        }   
        default: {
            return state;
        }
    }
};

export {reducer as WhatsappReducer};
