import { Reducer } from "redux";
import { NoteActionTypes, NoteState } from "./types";


export const initialState: NoteState =
{

    notes: [],
    note: null,
    pagination: null
}
const reducer: Reducer<NoteState> = (state: any = initialState, action: any) => {
    switch (action.type) {

        case NoteActionTypes.GET_NOTE_REQUEST: {
            return { ...state, loading: true };
        }
        case NoteActionTypes.GET_NOTE_SUCCESS: {
            return {
                ...state,
                loading: false,
                note: action.payload
            };
        }
        case NoteActionTypes.GET_NOTE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case NoteActionTypes.GET_NOTES_REQUEST: {
            return { ...state, loading: true };
        }
        case NoteActionTypes.GET_NOTES_SUCCESS: {
            return {
                ...state,
                loading: false,
                notes: action.payload.content
            };
        }
        case NoteActionTypes.GET_NOTES_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case NoteActionTypes.DELETE_NOTE_REQUEST: {
            return { ...state, loading: true };
        }
        case NoteActionTypes.DELETE_NOTE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case NoteActionTypes.DELETE_NOTE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        default:
            return state; // Return the current state for unrecognized actions

    }
};

export { reducer as noteReducer };
