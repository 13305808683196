import React, { useEffect } from "react"
import loaderlogo from "../../assets/images/dtsimg/svg/zilter_logo_white.svg"

const DtsSpinners = ({ setLoading }: any) => {

    return (
        <React.Fragment>
            <div className='dtsloader'>
                <div className="loader">
                    {/* <img src={loaderlogo} height={45}/>                     */}
                    <i className="ri-refresh-line text-light" />
                </div>
            </div>
        </React.Fragment>
    )
}

export default DtsSpinners;