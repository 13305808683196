import { toast } from "react-toastify";
import config from "./config";
const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT


export const levelhandle = (levelvalue: any) => {
	let level = ""
	switch (levelvalue) {
		case 1: level = 'Undergraduate';
			break
		case 2: level = 'Postgraduate';
			break
		case 3: level = 'Foundation';
			break
		case 4: level = 'Research';
			break
		default: level = 'NA';
			break
	}
	return level;
}

const errorGlobal: any = {
	500: {
		"message": "Server error, please try again later",
		"success": false
	},
	502: {
		"message": "Server error, please try again later",
		"success": false
	},
	400: {
		"message": "Invalid request, please check your input",
		"success": false
	},
	401: {
		"message": "You need to log in to access this page",
		"success": false
	},
	403: {
		"message": "Access denied, you don't have permission",
		"success": false
	},
	408: {
		"message": "Request timed out, please try again",
		"success": false
	},
	503: {
		"message": "Server is currently unavailable",
		"success": false
	},
	504: {
		"message": "Server timed out, please try again",
		"success": false
	},
	201: {
		"message": "Created Successfully",
		"success": true
	},
	200: {
		"message": "Request Successful",
		"success": true
	},
	204: {
		"message": "Deleted Successfully",
		"success": true
	},
	208: {
		"message": "You are trying to add duplicate Entry",
		"success": false
	}
}

export const responseHandler = async (response: any, dispatch: any, typeSuccess: any, typeError: any, error?: any, handleSuccess?: any, handleError?: any, method?: any) => {
	if (response?.status) {
		const httpResponse: any = error && error[response?.status]
			? error[response?.status]
			: errorGlobal[response?.status];
		if (httpResponse?.success) {
			try {
				const body = await response.json();
				dispatch({
					type: typeSuccess,
					payload: body
				});
				handleSuccess && handleSuccess(body)
			} catch {
				dispatch({
					type: typeSuccess,
					payload: null
				});
				handleSuccess && handleSuccess()
			}
		} else {
			dispatch({
				type: typeError,
				payload: httpResponse?.message || 'An unexpected error occurred, please try again later'
			});
			handleError && handleError()
		}
		let bgColor = httpResponse?.success ? "bg-success" : "bg-danger";
		if (!method || method !== "GET") {
			const message = () => toast(`${httpResponse?.message || 'An unexpected error occurred, please try again later'}`, { position: "top-center", hideProgressBar: true, className: `${bgColor} text-white` });
			message();
		}
	} else {
		dispatch({
			type: typeError,
			payload: 'An unexpected error occurred, please try again later'
		});
		handleError && handleError()
		if (!method || method !== "GET") {
			const message = () => toast(`An unexpected error occurred, please try again later`, { position: "top-center", hideProgressBar: true, className: 'bg-danger text-white' });
			message();
		}
	}
}

export const isJson = (str: any) => {
	try {
		let options = JSON.parse(str);
		return options
	} catch (e) {
		//Error
		//JSON is not okay
		return false;
	}
}

export const evaluateConditionalLogic = (conditional: any, formValues: any) => {
	let isPresent = null;
	if (conditional?.when && conditional?.condition && conditional?.values && conditional?.property) {
		if(conditional?.property?.valueJson?.type !== 'select') return
		const values = conditional?.values.map((item: any) => item.value);
		switch (conditional?.condition) {
			case 'EQ':
			case 'contains_exactly':
			case 'IN':
				isPresent = values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when].includes(item));
				if (isPresent) return conditional.show
				break
			case 'NEQ':
			case 'is_none_of':
				isPresent = formValues?.[conditional?.when] ? values.length && values?.some((item: any) => formValues?.[conditional?.when]?.includes(item)) : true;
				if (!isPresent) return conditional.show
				break
			case 'LIKE':
			case 'contains':
				isPresent = formValues?.[conditional?.when] && values.length && values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when]?.some((formValue: any) => formValue?.includes(item)));
				if (isPresent) return conditional?.show
				break
			case 'does_not_contain':
				isPresent = values?.some((item: any) => formValues?.[conditional?.when] && formValues?.[conditional?.when].some((formValue: any) => formValue?.includes(item)));
				if (!isPresent) return conditional?.show
				break
			case 'is_empty':
				isPresent = !formValues?.[conditional?.when];
				if (isPresent) return conditional?.show
				break
			case 'is_not_empty':
				isPresent = formValues?.[conditional?.when];
				if (isPresent) return conditional?.show
				break
			case 'BETWEEN':
				if(formValues?.[conditional?.when]) {
					isPresent = formValues?.[conditional?.when] >= parseFloat(conditional?.value) && formValues?.[conditional?.when] <= parseFloat(conditional?.highValue);
				}
				if (isPresent && conditional?.show) return isPresent
		}
		// if(conditional?.show) return isPresent 
		// else return !isPresent
	}
};


export const createQueryLink = (filterObject: any, page?: any) => {
	const keys = Object.keys(filterObject);
	const values = Object.values(filterObject);
	let query = `${DTS_API_STUDENT}/properties?`;
	if (page) query += `page=${page}&`;
	for (let i = 0; i < keys.length; i++) {
		if (values[i]) query += `${keys[i]}=${values[i]}&`;
	}
	return query;
};


// Select value handler
export const handleSelectValue = (value: any, options: any) => {
	if (options && options.length) {
		const foundItem = options.find((item: any) => item.value === value);
		if (foundItem) {
			return foundItem.label;
		}
	}
	return null; // or any default value you prefer
}


export const checkTheyOwn = (obj: any, id: any) => {
	if (obj && typeof obj === 'object' && Object.values(obj).includes(id)) {
		return true
	} else if (obj && typeof obj === 'object') {	
		for (const key in obj) {					
			if (typeof obj?.[key] === 'object') {
				let arr =  obj?.[key] ? Object.values(obj?.[key]) : [];
				let istrue = arr.some((value: any) => (typeof value != 'object' && value === id))
				if(istrue) return true	
				if (checkTheyOwn(obj[key], id)) return true;
			  } else if (checkTheyOwn(obj[key], id)) return true;
		}
	} else if (obj && obj === id) {
		return true
	}
	else return false
}

export const convertCurrency = (
    amount: number | string | undefined | null,  // Allow amount to be undefined or null
    fromCurrency: string,
    toCurrency: string,
    parsedRates: any
): number => {
    // Handle cases where amount is null or undefined
    if (amount === null || amount === undefined) {
        throw new Error(`The provided amount is undefined or null.`);
    }

    // Convert amount to number if it's a string
    const numericAmount = typeof amount === 'number' ? amount : Number(amount);

    // Check if the conversion was successful
    if (isNaN(numericAmount)) {
        throw new Error(`The provided amount "${amount}" is not a valid number.`);
    }

    if (fromCurrency === toCurrency) {
        return numericAmount;
    }

    const fromRate = parsedRates[fromCurrency];
    const toRate = parsedRates[toCurrency];
    if (!fromRate || !toRate) {
        throw new Error(`Conversion rate not available for ${fromCurrency} or ${toCurrency}`);
    }
	else {
		const amountInBaseCurrency = numericAmount / fromRate;
		const convertedAmount = amountInBaseCurrency * toRate;
		return convertedAmount;
	}

};

// objectUtils.ts
export interface ObjectDetails {
	length: number;
	emptyCount: number;
	nullCount: number;
	undefinedCount: number;
  }
  
  export const calculateObjectDetails = (data: Record<string, any>): ObjectDetails => {
	let length = Object.keys(data).length;
	let emptyCount = 0;
	let nullCount = 0;
	let undefinedCount = 0;
  
	for (const key in data) {
	  const value = data[key];
  
	  if (value === null) {
		nullCount += 1;
		undefinedCount += 1; // In JavaScript, null and undefined are often treated similarly
	  } else if (value === undefined) {
		undefinedCount += 1;
	  } else if (
		value === "" || // Empty string
		(Array.isArray(value) && value.length === 0) || // Empty array
		(typeof value === "object" && Object.keys(value).length === 0) // Empty object
	  ) {
		emptyCount += 1;
	  }
	}
  
	return {
	  length,
	  emptyCount,
	  nullCount,
	  undefinedCount,
	};
  };
  

