import {SubagentActionTypes, Subagent} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT;
const DTS_API_DOMAIN = api.DTS_API_DOMAIN;

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const subagentGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: SubagentActionTypes.GET_SUBAGENT_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/subagents/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, SubagentActionTypes.GET_SUBAGENT_SUCCESS, SubagentActionTypes.GET_SUBAGENT_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const subagentMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: SubagentActionTypes.GET_SUBAGENTMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        if (model) {
            headers.append('Properties-Model', model);
        } 
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, SubagentActionTypes.GET_SUBAGENTMETADATA_SUCCESS, SubagentActionTypes.GET_SUBAGENTMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const subagentUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: SubagentActionTypes.PUT_SUBAGENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/subagents/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                                     
        const error = {
            204: {
                "message": "Subagent updated Successfully",
                "success": true
            },
            200: {
                "message": "Subagent updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, SubagentActionTypes.PUT_SUBAGENT_SUCCESS, SubagentActionTypes.PUT_SUBAGENT_ERROR, error, handleSuccess, handleError);
    
    };
};

export const subagentDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: SubagentActionTypes.DELETE_SUBAGENT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/subagents/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Subagent deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Subagent deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, SubagentActionTypes.DELETE_SUBAGENT_SUCCESS, SubagentActionTypes.DELETE_SUBAGENT_ERROR, error, handleSuccess, handleError);
       
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: SubagentActionTypes.DELETE_SUBAGENT_ERROR
            });
            handleSuccess();
        }
    };
};

const subagentusercreateQueryLink = (id: any, filterObject: any, page: any) => {
    let query = `${DTS_API_DOMAIN}/users/subagents/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const subagentUserListGetRequest: AppThunk = (id, filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: SubagentActionTypes.GET_SUBAGENTS_USERS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = subagentusercreateQueryLink(id, filterObject,pageno);   
        try {          
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });   
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, SubagentActionTypes.GET_SUBAGENTS_USERS_SUCCESS, SubagentActionTypes.GET_SUBAGENTS_USERS_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};

export const subagentCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: SubagentActionTypes.POST_SUBAGENT_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/models/subagents`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Subagent already exist",
                    "success": false
                },
                201: {
                    "message": "Subagent created Successfully",
                    "success": true
                },       
                200: {
                    "message": "Subagent created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, SubagentActionTypes.POST_SUBAGENT_SUCCESS, SubagentActionTypes.POST_SUBAGENT_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: SubagentActionTypes.POST_SUBAGENT_ERROR, payload: error });
            handleError();
        }
    };
};

export const invoicesStudentsList: AppThunk = (subagentId , handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: SubagentActionTypes.GET_INVOICES_STUDENTS_LIST_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/applications/invoices/${subagentId}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: SubagentActionTypes.GET_INVOICES_STUDENTS_LIST_ERROR,
                    payload: body
                });
            } else {
                dispatch({
                    type: SubagentActionTypes.GET_INVOICES_STUDENTS_LIST_SUCCESS,
                    payload: body
                });
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const subagentListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: SubagentActionTypes.GET_SUBAGENTS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        try {           
            const response = await fetch(`${DTS_API_STUDENT}/models/get/subagents?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });         
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, SubagentActionTypes.GET_SUBAGENTS_SUCCESS, SubagentActionTypes.GET_SUBAGENTS_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};

export const userUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: SubagentActionTypes.PUT_USER_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_DOMAIN}/users/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
                                       
        const error = {
            204: {
                "message": "Invite sent successfully",
                "success": true
            },
            200: {
                "message": "Invite sent successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, SubagentActionTypes.PUT_USER_SUCCESS, SubagentActionTypes.PUT_USER_ERROR, error, handleSuccess, handleError);
    
    };
};




