import {ApplicationsActionTypes, Applications} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const applicationGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ApplicationsActionTypes.GET_APPLICATION_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/applications/${id}`, {
                method: "GET",
                headers: headers
            });
           
            await responseHandler(response, dispatch, ApplicationsActionTypes.GET_APPLICATION_SUCCESS, ApplicationsActionTypes.GET_APPLICATION_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};


export const applicationsMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_APPLICATIONSMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        } 
        const searchQuery = createQueryLink(filterObject);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.GET_APPLICATIONSMETADATA_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: ApplicationsActionTypes.GET_APPLICATIONSMETADATA_SUCCESS,
                payload: body_1
            });
        }
    };
};

const creategetemailsQuery = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/emails/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getApplicationsEmails: AppThunk = (id, filterObject, page, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const searchQuery = creategetemailsQuery(id,filterObject, page);         
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_ERROR,
                payload: body_1
            });
            handleError && handleError(body_1);
        } else {
            dispatch({
                type: ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_SUCCESS,
                payload: body_1
            });
            handleSuccess && handleSuccess("Metadata fetched successfully");
        }
    };
};

const creategetnotesQuery = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/emails/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const getApplicationsnotes: AppThunk = (id, filterObject, page, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const searchQuery = creategetnotesQuery(id,filterObject, page);         
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_ERROR,
                payload: body_1
            });
            // handleError(body_1);
        } else {
            dispatch({
                type: ApplicationsActionTypes.GET_APPLICATIONS_EMAILS_SUCCESS,
                payload: body_1
            });
            // handleSuccess("Metadata fetched successfully");
        }
    };
};

export const applicationUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.PUT_APPLICATIONS_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/applications/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
                
        const error = {
            204: {
                "message": "Application updated Successfully",
                "success": true
            },
            200: {
                "message": "Application updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, ApplicationsActionTypes.PUT_APPLICATIONS_SUCCESS, ApplicationsActionTypes.PUT_APPLICATIONS_ERROR, error, handleSuccess, handleError);

 
    };
};

export const SendEmail: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.POST_APPLICATIONS_EMAIL_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/emails`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.POST_APPLICATIONS_EMAIL_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: ApplicationsActionTypes.POST_APPLICATIONS_EMAIL_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const applicationDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.DELETE_APPLICATIONS_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/applications/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const body = await response.json();
            const {response: response_1, body: body_1} = {response, body};
            if (!response_1.ok) {
                dispatch({
                    type: ApplicationsActionTypes.DELETE_APPLICATIONS_ERROR
                });
                handleError();
            } else {
                dispatch({
                    type: ApplicationsActionTypes.DELETE_APPLICATIONS_SUCCESS,
                    payload: []
                });
                handleSuccess(body_1);
            }
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: ApplicationsActionTypes.DELETE_APPLICATIONS_ERROR
            });
            handleSuccess();
        }
    };
};

export const applicationsListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_APPLICATIONS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        try {            
            const response = await fetch(`${DTS_API_STUDENT}/models/get/applications?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, ApplicationsActionTypes.GET_APPLICATIONS_SUCCESS, ApplicationsActionTypes.GET_APPLICATIONS_ERROR, null, null, null, "GET");
            
            } 
        }
        catch (error) {
        }
    };
};

export const applicationCreateRequest: AppThunk = (studentId, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: ApplicationsActionTypes.POST_APPLICATIONS_REQUEST
        });
        const response = await fetch(
            `${DTS_API_STUDENT}/models/applications/${studentId}`,
            {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "token": token
                },
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.POST_APPLICATIONS_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: ApplicationsActionTypes.POST_APPLICATIONS_SUCCESS,
                payload: []
            });
            handleSuccess(body_1);
        }
    };
};

export const noteGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ApplicationsActionTypes.GET_NOTE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/notes/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ApplicationsActionTypes.GET_NOTE_ERROR,
                    payload: body
                });
                // handleError(body);
            } else {
                dispatch({
                    type: ApplicationsActionTypes.GET_NOTE_SUCCESS,
                    payload: body
                });
                // handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

const createNotesQueryLink = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/notes/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};

export const notesListGetRequest: AppThunk = (id, filterObject, page) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_NOTES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const searchQuery = createNotesQueryLink(id,filterObject,page);           
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.GET_NOTES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: ApplicationsActionTypes.GET_NOTES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const notesListGetRequestParams: AppThunk = (id, queryParams, page) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_NOTES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }          
        const response = await fetch(`${DTS_API_STUDENT}/notes/${id}?`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.GET_NOTES_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: ApplicationsActionTypes.GET_NOTES_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const noteDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();

        dispatch({
            type: ApplicationsActionTypes.DELETE_NOTE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/notes/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.DELETE_NOTE_ERROR,
                payload: body_1
            });
            handleError(body_1);
        } else {
            dispatch({
                type: ApplicationsActionTypes.DELETE_NOTE_SUCCESS,
                payload: body_1
            });
            handleSuccess(body_1);
        }
    };
};

export const noteAddRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.POST_NOTE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/notes`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.POST_NOTE_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: ApplicationsActionTypes.POST_NOTE_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};


export const taskGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ApplicationsActionTypes.GET_TASK_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/tasks/${id}`, {
                method: "GET",
                headers: headers
            });
            const body = await response.json();
            if (!response.ok) {
                dispatch({
                    type: ApplicationsActionTypes.GET_TASK_ERROR,
                    payload: body
                });
                // handleError(body);
            } else {
                dispatch({
                    type: ApplicationsActionTypes.GET_TASK_SUCCESS,
                    payload: body
                });
                // handleSuccess(body);
            }
        } catch (error) {
            console.error("Error parsing JSON:", error);
            // Handle parsing error here
        }
    };
};

const createTaskQueryLink = (id: any,filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/tasks/${id}?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        // if(keys.length) query += `search=`;
        for (let i = 0; i < keys.length; i++) {
           if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};
export const tasksListGetRequest: AppThunk = (id,filterObject,page) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_TASKS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const searchQuery = createTaskQueryLink(id,filterObject,page);             
        const response = await fetch(searchQuery, {
           method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.GET_TASKS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: ApplicationsActionTypes.GET_TASKS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const tasksListGetRequestParams: AppThunk = (id, queryParams) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.GET_TASKS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }         
        const response = await fetch(`${DTS_API_STUDENT}/tasks/${queryParams}`, {
            method: "GET",
            headers: headers
        });
    
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.GET_TASKS_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: ApplicationsActionTypes.GET_TASKS_SUCCESS,
                payload: body_1
            });
        }
    };
};

export const taskDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.DELETE_TASK_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/tasks/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.DELETE_TASK_ERROR,
            });
            handleError();
        } else {
            dispatch({
                type: ApplicationsActionTypes.DELETE_TASK_SUCCESS,
            });
            handleSuccess();
        }
    };
};

export const taskAddRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ApplicationsActionTypes.POST_TASK_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(`${DTS_API_STUDENT}/tasks`,
            {
                method: "POST",
                headers: headers,
                body: JSON.stringify(data)
            }
        );
        // const body = await response.json();
        const {response: response_1} = {response};
        if (!response_1.ok) {
            dispatch({
                type: ApplicationsActionTypes.POST_TASK_ERROR
            });
            handleError();
        } else {
            dispatch({
                type: ApplicationsActionTypes.POST_TASK_SUCCESS,
                payload: []
            });
            handleSuccess();
        }
    };
};

