import React, { useState } from "react";
import {
    Card,
    CardBody,
    CardHeader,
    Col,
    Container,
    Form,
    Input,
    Label,
    Nav,
    NavItem,
    NavLink,
    Row,
    TabContent,
    TabPane,
    Progress,
    Button,
    Spinner,
} from "reactstrap";

import classnames from "classnames";
import { Link, useNavigate } from "react-router-dom";
import envelope from "../../../assets/images/dtsimg/svg/send_email-cropped.svg";
import OTPInput from "./otp";
import { WithTranslation } from 'react-i18next';
import { useSelector } from "react-redux";
import { ApplicationState } from "store";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { setResponse, signupRequest } from "store/auth/action";
import { resendOtp } from "store/user/action";
import MicrosoftLogin from "react-microsoft-login";
import Email from "pages/StudentsDetails/Email";

interface SignUpState {
    email: string;
    otp: string;
    full_name: string;
    phone_number: string;
    company_name: string;
    company_website: string;
    password: string;
    people_count: string;
    countrycode: any;
}

interface Step2Props {
    next: () => void;
    back: () => void;
    inputs: SignUpState;
    setInputs: React.Dispatch<React.SetStateAction<SignUpState>>;
    onCreate: () => void;    
    t: (key: string) => string;
    verifyOtp: () => void;
    setIsEditEmail: (state: boolean) => void;
    loading: boolean;
}

const Step2: React.FC<Step2Props> = ({ t, next, back, inputs, setInputs, onCreate, verifyOtp, loading, setIsEditEmail }: Step2Props) => {
    const auth = useSelector((state: ApplicationState) => state.auth);
    const navigate = useNavigate();
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const resendOTP = (): void => {
        const data = {
            "attributes": [
                {
                    "key": "email",
                    "value": inputs.email,
                }
            ]
        }
        const handleSuccess = (body: any): void => {
            const message = () => toast(`OTP resent successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
            dispatch(setResponse(body));
            // toggleArrowTab(activeArrowTab + 1)
        }
        const handleError = (body: any): void => {
            const message = () => toast(`Please enter valid email`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        dispatch(resendOtp(data, handleSuccess, handleError));
    }
    const authHandler = (err: any, data: any, msalInstance: any) => {
        if (err) {
            // Handle authentication error
            console.error('Authentication error:', err);
            return;
          }
        
          // Extract necessary information from the response
          const { access_token, id_token, expires_in } = data;
        
          // Decode the id_token to get user information
          const decodedToken = parseJwt(id_token);
        
          // Extract user information from the decoded token
          const { email, name, preferred_username } = decodedToken;
        
          // Perform signup or any other actions with the extracted user information
          // Example:
          // signUpUser(email, name, preferred_username);
        
    };
    const parseJwt = (token: any) => {
        try {
          return JSON.parse(atob(token.split('.')[1]));
        } catch (e) {
          return null;
        }
      };
    return (
        <div>
            <Row className="hstack gap-3 flex-column justify-content-center">
                <Col lg={12} className="d-flex flex-column justify-content-center">
                    <p className="hstack justify-content-center">
                        <img className="text-info" src={envelope} alt="" height={150} width={200} />
                    </p>
                    <div className="hstack fs-32 mt-4 fw-bold justify-content-center">
                        <h1 className="fw-bold">{t("signup.check_your_email")}</h1>
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="hstack fs-14 fw-nomal justify-content-center">
                        {t("signup.please_enter_verification_code_we_send_to")}:
                    </div>
                    <div className="hstack fs-18 mb-3 fw-bold justify-content-center">
                        {inputs.email}
                    </div>
                </Col>
                <Col lg={4}>
                    <div className="hstack fs-17 mb-1 fw-nomal justify-content-center">
                        {t("signup.verification_code")}:
                    </div>
                    <div className="hstack justify-content-center">
                        <OTPInput
                            setInputs={setInputs}
                            inputs={inputs}
                            t={t}
                        />
                        {/* {inputs.otp !=="" && /[a-z]/.test(inputs.password) ? <p></p> : null} */}
                    </div>
                </Col>
                <Col lg={4}>
                    <button type="button" className="w-100 btn btn-primary btn-icon waves-effect waves-light" onClick={() => { verifyOtp(); }} disabled={!inputs.otp}>
                        {loading ? <span className="d-flex align-items-center">
                            <span className="flex-grow-1 me-2">
                                Loading...
                            </span>
                            <Spinner size="sm" type="grow" className="flex-shrink-0" role="status"> Loading... </Spinner>
                        </span> : t("signup.next")}
                    </button>{" "}
                </Col>
                <Col lg={4}>
                    <p>{t("signup.didnt_get_the_email")}
                    {/* <MicrosoftLogin 
                            clientId={'9dce3457-ea35-4f69-9c96-aecbda169c12'}
                            authCallback={authHandler}
                            redirectUri={'http://localhost:3000'}
                            graphScopes={['openid', 'profile', 'email', 'code']} children={undefined}/> */}
                        <Button color="success" onClick={() => resendOTP()} className="btn btn-ghost-success waves-effect waves-light p-0">&nbsp;{t("signup.resend")}</Button>&nbsp;or&nbsp;
                        <Button color="success" onClick={() => { back(); setIsEditEmail(true) }} className="btn btn-ghost-success waves-effect waves-light p-0">&nbsp;{t("signup.edit_your_email_address")}</Button>
                        {/* <Button onClick={} */}
                    </p>
                </Col>
            </Row>
        </div>
    );
};

export default Step2;
