import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import DashboardComponent from "../../Components/Common/Dashboard/Dashboard"
import { Card, CardHeader, Col } from 'reactstrap';
import { BarLabelChart } from 'pages/Charts/ECharts/ECharts';
import KPIs from './Charts/KPIs';
import { BasicBarChart } from './Charts/Charts';
import GenerativeQA from '../../Components/Common/Dashboard/Dashboard'

const AIDashboard = (props: any) => {   
    return (
       <GenerativeQA />
    );
};
export default withTranslation()(AIDashboard);


