import { useEffect, useState } from "react";
import {
    Label,
    Input,
    FormFeedback,
} from "reactstrap";
import { components } from "react-select";
import AsyncCreatableSelect from "react-select/async-creatable";
import Select, { StylesConfig } from 'react-select';

export interface SuggestionOption {
    readonly value: string;
    readonly label: string;
}

const DtsEmailSelectInput: React.FC<any> = ({ placeholder, defaultValue, setdisabled , onChange, disabled}: any) => {
    const [errormsg, setErrormsg] = useState<any>(undefined);
    const [emailsArray, setEmailsArray] = useState<any>([]);
    const handleTonullerror = () => {
        setErrormsg('Please enter atleast one receiver')
        return true;
    }

    const validateInput = (value: any) => {
        const isValid = value && value.length ? value.some((email: any) => {
            if (email.value === "" || !/^\w+([\.+-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email.value)) {
                let error: any = `Please enter a valid email for the email address ${email.value}`;
                setErrormsg(error);
                return true;
            }
            return false;
        }): placeholder == "To" ? <>{handleTonullerror()}</> : false;
        if (!isValid) {
            setErrormsg(undefined);
        }      
    }
    useEffect(() => {
        if (errormsg) {
            if (typeof setdisabled === 'function') {
                setdisabled(true)
            }
            const inputdiv: any = document.getElementById(`${placeholder}feedback`);
            if (inputdiv) inputdiv.classList.add('d-block');
        }
        else {
            if (typeof setdisabled === 'function') {
                setdisabled(false)
            }
            const inputdiv: any = document.getElementById(`${placeholder}feedback`);
            if (inputdiv) inputdiv.classList.remove('d-block');
        }

    }, [errormsg])

    const CustomOption = (props: any) => {
        return (
            <components.Option {...props}>
                <div className='hstack justify-content-between'>
                    {props.children}
                    {props.isSelected && <i className="ri-check-line fs-20" />}
                </div>
            </components.Option>
        );
    };

    const customStyles = {
        control: (provided: any, state: any) => ({
            ...provided,
            color: 'auto',
            fontWeight: 900,
            border: state.isFocused ? '0.5px solid #2A5F8D' : 'auto',
            borderRadius: 8,
            // backgroundColor: 'white',
            boxShadow: 'none',
            '&:hover': {
                border: '0.5px solid #2A5F8D',
            },
        }),
        // menu: (provided: any) => ({
        //     ...provided,
        //     backgroundColor: 'white',
        //     borderRadius: 8,
        //     //   boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.1)',
        //     //   border: '1px solid #ccc',
        // }),
        // // Customize the style of the option in the dropdown menu
        // option: (provided: any, state: any) => ({
        //     ...provided,
        //     backgroundColor: 'white', // Custom background color for selected option
        //     color: 'black', // Custom text color for selected option
            
        // }),
        
        multiValue: (styles: any) => {
            return {
              ...styles,
              backgroundColor: "#2A5F8D",
            };
          },
          multiValueLabel: (styles: any) => ({
            ...styles,
            color: "white",
          }),
          multiValueRemove: (styles: any) => ({
            ...styles,
            color:"white",
          }),
    }

    useEffect(() => {
        if (defaultValue && defaultValue != "") {
            let email = { label: defaultValue, value: defaultValue }
            setEmailsArray([...emailsArray, email])
        }
    }, [])
    const handleSelectChange = (newValue: any, actionMeta: any) => {
        if(actionMeta.action == 'create-option') {
            validateInput(newValue);
            let newEmails = [...emailsArray, actionMeta.option]
            setEmailsArray(newEmails);
            const emails = newEmails.map((item: any) => {return item.value});
            onChange(emails)

        }
        else if(actionMeta.action == "remove-value") {
            const elementToRemove = actionMeta.removedValue;
            const updatedEmails = emailsArray.filter((email: any) => email !== elementToRemove);
            setEmailsArray(updatedEmails);
            const emails = updatedEmails.map((item: any) => {return item.value});
            onChange(emails)
        }
    };
    return (
        <div className="w-100 select-element">
            <AsyncCreatableSelect
                isDisabled={disabled}
                styles={customStyles}
                defaultOptions
                components={{ Option: CustomOption }}
                className='form-select-sm p-0'
                onChange={handleSelectChange}
                defaultValue={defaultValue ? {value:defaultValue,label:defaultValue}: null}
                isMulti={true}
                isClearable={true}
                options={emailsArray}
                placeholder={placeholder}
            />
            {<FormFeedback id={`${placeholder}feedback`}>{errormsg}</FormFeedback>}
        </div>
    );
};

export default DtsEmailSelectInput;
