import React from 'react';
import { Container } from 'reactstrap';
import { withTranslation } from 'react-i18next';
import Section from 'Components/Common/Details/Section';
import { companiesMetadataGetRequest, companyGetRequest, companyUpdateRequest } from 'store/companies/action';
import Banks from './Banks/Banks';


const Details = (props: any) => {
    const tabList = [{
        title: 'Banks',
        component: Banks
    }]
    return (
        <React.Fragment>
            <div className="page-content">                
                <Container fluid>       
                <Section 
                    detailsGetAction={companyGetRequest}
                    metaDatagetRequest={companiesMetadataGetRequest}
                    updateAction={companyUpdateRequest}
                    model={"companies"}
                    reducerState={"companies"}
                    translater={"company"}
                    label={"Company"}
                    tabList={tabList}
                />
                </Container>
            </div>
        </React.Fragment>
    );
};

export default withTranslation()(Details);