import React, { useEffect, useState } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Card,
    CardBody,
    Modal,
    ModalBody,
    Button,
    ModalHeader,
    ListGroup,
    ListGroupItem,
    Label,
    Input,
    Badge,
} from "reactstrap";
import StarRatingInput from 'react-star-ratings';
import { applicationCreateRequest, applicationUpdateRequest } from "store/applications/action";
import { propertyGetRequest } from 'store/properties/action';
import { ThunkAction, ThunkDispatch } from "@reduxjs/toolkit";
import Select from "react-select";
import { Action } from "redux";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import SimpleBar from "simplebar-react";
import { ApplicationState } from "store";
import { applicationsCommissions } from "store/channels/action";
import { recommendApplicationList } from "store/recommendation/action";
import { useParams } from "react-router";
import { getCreateStatus } from "helpers/workflowStatusHelper";
import { isJson } from "utils";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";

const STARCOLORS = ["#ff4545", "#ff4545", "#ffa534", "#ffe234", "#b7dd29", "#57e32c"]

const PROPERTY_ID = '04f3764a-a37f-4634-970d-27e845bfca60';

// Utility function to check if a string is a valid JSON and parse it
const parseJson = (str: string) => {
    try {
        return JSON.parse(str);
    } catch (e) {
        return null;
    }
};

const ChannelWiseDetailsAddEntry = ({ props, show, onCloseClick, dataFields, record,studentId , fetchAppliedCourses}: any) => {
    const {id} = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const channelList = useSelector((state: ApplicationState) => state.channels.applicationsChannel);
    const property = useSelector((state: ApplicationState) => state.properties.property);

    const [formMetaData, setFormMetaData] = useState<any>([]);    
    const [formdata, setformdata] = useState<any>({});
    const [financeList, setfinanceList]= useState<any>({});
    const [processFlows, setProcessFlows] = useState<any[]>([]);
    const [selectedProcessFlow, setSelectedProcessFlow] = useState<any>(null);
    const [selectedChannel, setSelectedChannel] = useState<any>(null);
    // Fetch the property data when the component mounts
    useEffect(() => {
        dispatch(propertyGetRequest(PROPERTY_ID));
    }, [dispatch]);

    // Parse and set lifecycle and statuses data when property data is available
    useEffect(() => {
        if (property?.valuesJson) {
            const parsedData = parseJson(property.valuesJson);
            const processFlows = parsedData?.process_flows || [];
            setProcessFlows(processFlows);
        }
    }, [property]);
    
    const CreateApplication = () => {
        const { status } = getCreateStatus(selectedProcessFlow);
        let formValues= {channels: selectedChannel , process_flow: selectedProcessFlow.value};
        const handleSuccess = (body: any) => {
            fetchAppliedCourses()
            dispatch(recommendApplicationList(id))
            onCloseClick()            
        }
        const handleError = () => {

        }
        dispatch(applicationUpdateRequest(record.id, formValues, handleSuccess, () => { }))
    }
    
    useEffect(() => {
        let newstatusMetaData: any = []
        dataFields && dataFields.map((field: any) => {
            let item = field.valuesJson  && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {}
            if(item?.quickCreate) return newstatusMetaData.push(field)
        })
        let addEntryFields = newstatusMetaData.filter((obj: any) => JSON.parse(obj.valuesJson).key !== 'student' && JSON.parse(obj.valuesJson).key !== 'course_name');
        setFormMetaData(addEntryFields)
    }, [dataFields])

    useEffect(() => {
        let prefilleddata: any = []
       if(record && record.length) {
        prefilleddata = {
            student: studentId,
            course_name: record.id
        }
    }
        // let addEntryFields = newstatusMetaData.filter((obj: any) => JSON.parse(obj.valuesJson).key !== 'student');
        setformdata(prefilleddata)
    }, [record])

    useEffect(() => {     
    }, [record.id])

    useEffect(() => {
        const parsedData: any = channelList && channelList.length ? channelList.map((item: any) => {
            const valuesJson = item.valuesJson ? item.valuesJson : {};
            let values = { ...valuesJson, channel_id: item.id, createdAt: item.createdAt,averageRating: item.averageRating, commission: item.commission,commissionPercentage: item.commissionPercentage, taxIncluded: item.taxIncluded, ...item }
            return values
        }) : []
        let channels = parsedData && parsedData.length && parsedData.reverse() 
        setfinanceList(channels)
    }, [channelList])
    const onClose = () => {
        setSelectedChannel(null)
        setSelectedProcessFlow(null)
        onCloseClick(!show)
    }
    return (
          <Offcanvas
          direction="end"
          isOpen={show}
          id="offcanvasExample"
          toggle={onClose}
          backdrop={false}
      >
          <OffcanvasHeader className="bg-light" toggle={() => { onClose() }}>
              {props.t("student.create_application")}
          </OffcanvasHeader>
          <OffcanvasBody>
              <Card>
                <CardBody className="p-0">
                    <SimpleBar style={{ maxHeight: "800px" }}>
                        <div className="d-flex flex-column gap-4">
                            <div className="d-flex flex-column gap-1">
                                 <FormBuilder                             
                                    placeholder={props.t('properties.process_flow.application.select_process_flow_placeholder')}
                                    onChange={(option : any) => setSelectedProcessFlow(option)}
                                    options={processFlows}
                                    label={"Select Process Flow"}
                                    type={"select"}
                                    value={selectedProcessFlow}
                                    className="w-100 h-100 text-start"
                                />
                            </div>
                            <div className="d-flex flex-column gap-1">
                                <Label>Select Channel Partner</Label>
                                <ListGroup className="mb-1">
                                    {
                                    financeList && financeList.length ? financeList.map((item: any) => {
                                        return <ListGroupItem>
                                        <div className="d-flex align-items-center">
                                            <div className="d-flex justify-content-between w-100">
                                                <div className="d-flex align-items-center">
                                                    <div className="form-check mb-2">
                                                        <Input
                                                            className="form-check-input"
                                                            type="radio"
                                                            name="flexRadioDefault"
                                                            onChange={() => setSelectedChannel(item)}
                                                        />
                                                    </div>
                                                    <div className="flex-shrink-0 avatar-xs">
                                                        <div className="avatar-title bg-danger-subtle text-danger rounded">
                                                            <i className="ri-community-fill"></i>
                                                        </div>
                                                    </div>
                                                    <div className="ms-2">
                                                        <h6 className="d-flex fs-14 mb-0 flex-wrap">{item.name}</h6>
                                                        <StarRatingInput
                                                            rating={parseFloat(item.averageRating) || 0}
                                                            starRatedColor={STARCOLORS[parseFloat(item.averageRating) || 0]}
                                                            numberOfStars={5}
                                                            name='rating'
                                                            starDimension="10"
                                                            starSpacing="2px"
                                                        />
                                                    </div>
                                                </div>
                                                <div>
                                                    <Badge>£{item.commission}</Badge>
                                                </div>
                                            </div>
                                            {/* <div className="flex-shrink-0 w-25">
                                                <Button
                                                    color="secondary"
                                                    className="btn-sm"
                                                    style={{width: 'fit-content', minWidth: '80px'}}
                                                    outline={true}                                        
                                                    onClick={() =>CreateApplication(item)}
                                                >
                                                £{item.commission}
                                                </Button>
                                            </div> */}
                                        </div>
                                        </ListGroupItem>
                                }) : <div>No Data to show for Channels</div>}                          
                                </ListGroup>
                            </div>
                        </div>
                    </SimpleBar>
                </CardBody>
              </Card>
            </OffcanvasBody>
            <div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Button
                    color="light"
                    onClick={() => onClose()}
                >
                    Cancel
                </Button>
                <Button
                    color="primary"
                    onClick={() => CreateApplication()}
                    disabled={!selectedChannel || !selectedProcessFlow}
                >
                    Create
                </Button>
            </div>
      </Offcanvas>
    );
};

export default ChannelWiseDetailsAddEntry;