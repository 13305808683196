const dataFields = [
      {
        "name": "name",
        "label": "Name",
        "type": "text",
        "value": ""
      },
      {
        "name": "url",
        "label": "URL",
        "type": "text",
        "value": ""
      }
]

export default dataFields;