export interface Whatsapp {
    id: string,
    fromMobile: string,
    toMobile: string,
    fromId: string,
    toId: string,
    message: string,
    whatsAppJson: string,
}

export interface Pagination {
    page: number,
    size: number,
    pages: number,
    elements: number,
    first: boolean,
    last: boolean,
    offset: number
}

export enum WhatsappActionTypes {
    GET_WHATSAPPS_REQUEST = "@@whatsapp/GET_WHATSAPPS_REQUEST",
    GET_WHATSAPPS_SUCCESS = "@@whatsapp/GET_WHATSAPPS_SUCCESS",
    GET_WHATSAPPS_ERROR = "@@whatsapp/GET_WHATSAPPS_ERROR"
}

export interface WhatsappState {
    readonly whatsapps: Whatsapp[];
    readonly loading: boolean;
    readonly errors: string | null;
    readonly pagination: Pagination | null;
}
