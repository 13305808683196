import React from 'react';
import { Form } from '@formio/react';
import { Container } from 'reactstrap';
import { useSelector } from "react-redux";
import { ApplicationState } from 'store';

const FormRender = () => { 
    const formData = useSelector((state: ApplicationState) => state.form.formData);
return (
    <React.Fragment>
        <div className="page-content">
            <Container fluid>                           
                <Form form={formData} />
            </Container>
        </div>
    </React.Fragment>
);
}

export default FormRender;