import { useEffect, useState } from "react";
import { Form, Button, Col } from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { toast } from "react-toastify";
import { studentExternalApplication } from "store/student/action";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";

const DTSFullForm = ({ dataFields, btn_text, data, md, lg, sm, xl, xs, BtnClassName,tenantId, isUpdate }: any) => {
	const filterFields: any = [];
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch(); 

	dataFields &&
		dataFields.length &&
		dataFields.map((field: any) => {
			let item = field ? field : {};
			if (field) return filterFields.push(item);
		});

	const [formValues, setFormValues] = useState<any>({});
	const [fieldValues, setFieldsValues] = useState<any>({});
	const [formerror, setError] = useState<any>([]);


	const onChange = (option: any, key: any) => {
		if (typeof option == "object") {
			setFormValues({ ...formValues, [key]: option.value });
		} else setFormValues({ ...formValues, [key]: option });
	};

	const btnAction = (formValues: any) => {
        const handleSuccess = (body: any) => {
            const message = () => toast(`Entry Added Successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message();
        }
        const handleError = () => {

        }
        dispatch(studentExternalApplication(tenantId,formValues, handleSuccess ,handleError))
    }

	
	// const onSaveClick = () => {
	// 	btnAction(formValues, "single")
	// }

	const allMandatoryFieldsFilled = () => {
		for (const field of filterFields) {
			if (
				field &&
				field.validate &&
				field.validate.required &&
				!formValues[field.key]
			) {
				return false;
			}
		}
		return true;
	};

	useEffect(() => {
		if (data) {
			setFormValues(data);
			setFieldsValues(data)
		}
	}, [data]);
	const formdisabled = Object.keys(formerror).length === 0;
	return (
		<Form action="" className="d-flex flex-column justify-content-center h-100">
			<div className="live-preview hstack flex-wrap">
				{filterFields && filterFields.length
					? filterFields.map((field: any) => {
						if(field.hidden) return
						return (
							<Col xl={xl} lg={lg} md={md} sm={sm} xs={xs} className="align-self-start">
								<FormBuilder
									placeholder={field.placeholder}
									name={field.key}
									onChange={(e: any) => onChange(e, field.key)}
									options={(field.values && field.values.reverse()) || []}
									label={field.label}
									type={field.type}
									value={formValues[field.key]}
									defaultValue={formValues[field.key]}
									resetValue={fieldValues[field.key]}
									isEditState={true}
									actionButtons={isUpdate}
									Editable={true}
									rest={field}
									invalid={true}
									formValues={formValues}
									dataFields={filterFields}
									setError={setError}
									formerror={formerror}
									isTextract={true}
								/>
							</Col>
						);
					})
					: null}
			</div>
			<div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-center hstack gap-2">
				<Button
					className={BtnClassName ? `btn btn-success ${BtnClassName}` : "btn btn-success w-100"}
					disabled={true}
					// onClick={() => btnAction(formValues)}
				>
					{btn_text}
				</Button>
			</div>
		</Form>
	);

};

export default DTSFullForm;
