import { useEffect, useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Card, Modal, ModalHeader, ModalBody, Input, Label, Row } from "reactstrap";
import { GroupGetByModelRequest, propertyListGetRequest, propertyUpdateRequest } from '../../../store/properties/action';
import { useDispatch, useSelector } from "react-redux";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import Roles from "../roles/Roles";
import { ApplicationState } from "store";

const ManageAccess = ({ props, id, record, model, show, onCloseClick, setpermissionInputs, setEditInputs, pagination, EditInputs, permissionInputs, filterObject }: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const optionList = useSelector((state: ApplicationState) => state.properties.groups);
    const [activeArrowTab, setactiveArrowTab] = useState(1);
    const [passedarrowSteps, setPassedarrowSteps] = useState([1]);
    const [groupoptions, setgroupOptions] = useState([]);
    const [PermissionId, setPermissionId] = useState<any>("view_edit")

    const toggleArrowTab = (tab: any) => {
        if (activeArrowTab !== tab) {
            var modifiedSteps = [...passedarrowSteps, tab];

            if (tab >= 1 && tab <= 3) {
                setactiveArrowTab(tab);
                setPassedarrowSteps(modifiedSteps);
            }
        }
    }

    const onApply = (permissions: any) => {
        const RolePermissions = {
            "permissions": PermissionId,
            "item": permissions
        }
        const data = {
            "permissionsJson": JSON.stringify(RolePermissions),
            "valuesJson": JSON.stringify(EditInputs),
            "model": EditInputs.objectType,
            "groupId": EditInputs.groupId,
        }
        const handleSuccess = () => {
            dispatch(propertyListGetRequest(EditInputs.objectType, filterObject, pagination.page))          
            toggleArrowTab(1)
            onCloseClick()
            setpermissionInputs({})
            setEditInputs({})
        }
        const handleFailure = () => {

        }
        dispatch(propertyUpdateRequest(id, data, handleSuccess, handleFailure))
    }

    useEffect(() => {
        dispatch(GroupGetByModelRequest(model))
    }, [])

    const onChange = (value: any) => {
        setPermissionId(value)
    }

    const options = [
        { label: 'Private to super admins only', value: 'Owner' },
        { label: 'Allow everyone to access and edit', value: 'view_edit' },
        { label: 'Allow everyone view', value: 'view' },
        { label: 'Assign to roles', value: 'role_based' }
    ];

    useEffect(() => {
        let options: any = optionList && optionList.length && optionList.map((item: any) => {
            return { label: item.groupTitle, value: item.id }
        })
        setgroupOptions(options)
    }, [optionList])

    useEffect(() => {
        if (EditInputs && EditInputs.groupId) {
            groupoptions && groupoptions.length && groupoptions.map((field: any) => {
                if (field.label === EditInputs.groupId) {
                    setEditInputs({ ...EditInputs, groupId: field.value })
                }
            })
        }
    }, [EditInputs])

    useEffect(() => {
        if (permissionInputs && permissionInputs.permissions) {
            setPermissionId(permissionInputs.permissions)
        }
    }, [permissionInputs])

    return (
        <Modal backdrop={"static"} id="myModal" isOpen={show} toggle={() => { onCloseClick(); }} centered>
            <ModalHeader className="bg-primary bg-gradient p-3 offcanvas-header-dark" toggle={() => { onCloseClick(); }}>
                <span className="text-light">{props.t("properties.manage_access")} - {record?.original?.label}</span>
            </ModalHeader>
            <ModalBody className='text-center p-3'>
                <div className="mt-1 text-left">
                    <p>{props.t("properties.manage_access_helper_text")}</p>
                    <h5>{props.t("properties.property_access")}</h5>
                    <div className="vstack gap-1 ms-3">
                        {options && options.length ? options.map((item: any) => {
                            return <div>
                                <Input
                                    className={"form-check-input"}
                                    type={"radio"}
                                    id={item.value}
                                    checked={PermissionId ? item.value == PermissionId : item.value == "view_edit"}
                                    onChange={(e) => onChange(item.value)}
                                />
                                <Label className="form-check-label" >
                                    {item.label}
                                </Label>
                            </div>
                        }) : null}
                        {
                            PermissionId == "role_based" ?
                                <Card>
                                    <Roles onCloseClick={onCloseClick} onApply={onApply} props={props} permissionInputs={permissionInputs} record={record} />
                                </Card>
                                : null
                        }
                        {
                            PermissionId !== "role_based" ?
                                <Row>
                                    <div className="hstack gap-2 justify-content-center">
                                        <Button className="btn btn-primary" onClick={() => onApply([])} disabled={!PermissionId}>
                                            {props.t("properties.save")}
                                        </Button>
                                        <Button color="danger" onClick={() => onCloseClick()} outline>
                                            {props.t("properties.cancel")}
                                        </Button>
                                    </div>
                                </Row>
                                : null
                        }
                    </div>
                </div>
            </ModalBody>
        </Modal>
    );
};

export default withTranslation()(ManageAccess);
