import { Reducer } from "redux";
import { StudentActionTypes, StudentState } from "./types";


export const initialState: StudentState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    emails:[],
    activities:[],
    activitiesPagination: [],
    applicationlist: [],
    Applicationpagination: {},
    metaLoading: false,
    loading: false,
    dataLoading: false
}
const reducer: Reducer<StudentState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case StudentActionTypes.GET_STUDENTMETADATA_REQUEST: {
            return { ...state, metaLoading: true };
        }
        case StudentActionTypes.GET_STUDENTMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case StudentActionTypes.GET_STUDENTMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload };
        }

        case StudentActionTypes.GET_STUDENTS_EMAILS_REQUEST: {
            return { ...state, loading: true, list:[]  };
        }
        case StudentActionTypes.GET_STUDENTS_EMAILS_SUCCESS: {
            return {
                ...state,
                loading: false,
                emails: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case StudentActionTypes.GET_STUDENTS_EMAILS_ERROR: {
            return { ...state, loading: false, errors: action.payload, list:[]  };
        }

        
        case StudentActionTypes.GET_STUDENTS_ACTIVITY_REQUEST: {
            return { ...state, loading: true };
        }
        case StudentActionTypes.GET_STUDENTS_ACTIVITY_SUCCESS: {
            return {
                ...state,
                loading: false,
                activities: action.payload.content,
                activitiesPagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case StudentActionTypes.GET_STUDENTS_ACTIVITY_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case StudentActionTypes.GET_STUDENT_REQUEST: {
            return { ...state, loading: true };
        }
        case StudentActionTypes.GET_STUDENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case StudentActionTypes.GET_STUDENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }    
            // StudentActionTypes.POST_STUDENT_EMAIL_REQUEST
            case StudentActionTypes.POST_EXTERNALSTUDENT_REQUEST: {
                return { ...state, loading: true };
            }
            case StudentActionTypes.POST_EXTERNALSTUDENT_SUCCESS: {
                return {
                    ...state,
                    loading: false,
                };
            }
            case StudentActionTypes.POST_EXTERNALSTUDENT_ERROR: {
                return { ...state, loading: false, errors: action.payload };
            }
        case StudentActionTypes.POST_STUDENT_REQUEST: {
            return { ...state, loading: true };
        }
        case StudentActionTypes.POST_STUDENT_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case StudentActionTypes.POST_STUDENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

              
        case StudentActionTypes.POST_STUDENT_EMAIL_REQUEST: {
            return { ...state, loading: true };
        }
        case StudentActionTypes.POST_STUDENT_EMAIL_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case StudentActionTypes.POST_STUDENT_EMAIL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case StudentActionTypes.GET_STUDENTS_REQUEST: {
            return { ...state, dataLoading: true };
        }
        case StudentActionTypes.GET_STUDENTS_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case StudentActionTypes.GET_STUDENTS_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload };
        }
        case StudentActionTypes.PUT_STUDENT_REQUEST: {
            return { ...state, loading: true };
        }
        case StudentActionTypes.PUT_STUDENT_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case StudentActionTypes.PUT_STUDENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case StudentActionTypes.DELETE_STUDENT_REQUEST: {
            return { ...state, loading: true };
        }
        case StudentActionTypes.DELETE_STUDENT_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case StudentActionTypes.DELETE_STUDENT_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        
        case StudentActionTypes.GET_APPLICATIONS_REQUEST: {
            return { ...state, loading: true };
        }
        case StudentActionTypes.GET_APPLICATIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                applicationlist: action.payload.content,
                Applicationpagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case StudentActionTypes.GET_APPLICATIONS_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case StudentActionTypes.GET_FILTER_STUDENTS_REQUEST: {
            return { ...state, dataLoading: true };
        }
        case StudentActionTypes.GET_FILTER_STUDENTS_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                students: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case StudentActionTypes.GET_FILTER_STUDENTS_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload };
        }
        
        default: {
            return state;
        }
    }
};

export { reducer as studentReducer };
