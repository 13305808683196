import { Reducer } from "redux";
import { CourseActionTypes, CourseState } from "./types";


export const initialState: CourseState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: null,
    dataLoading: false
}
const reducer: Reducer<CourseState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case CourseActionTypes.GET_COURSEMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: [] };
        }
        case CourseActionTypes.GET_COURSEMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case CourseActionTypes.GET_COURSEMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: [] };
        }
        case CourseActionTypes.GET_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.GET_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case CourseActionTypes.GET_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        case CourseActionTypes.POST_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.POST_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case CourseActionTypes.POST_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case CourseActionTypes.GET_COURSES_REQUEST: {
            return { ...state, dataLoading: true,list:[]  };
        }
        case CourseActionTypes.GET_COURSES_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case CourseActionTypes.GET_COURSES_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload ,list:[] };
        }
        case CourseActionTypes.PUT_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.PUT_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case CourseActionTypes.PUT_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case CourseActionTypes.DELETE_COURSE_REQUEST: {
            return { ...state, loading: true };
        }
        case CourseActionTypes.DELETE_COURSE_SUCCESS: {
            return {
                ...state,
                loading: false,
                content: action.payload
            };
        }
        case CourseActionTypes.DELETE_COURSE_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
        
        case CourseActionTypes.GET_FILTER_COURSES_REQUEST: {
            return { ...state, dataLoading: true };
        }
        case CourseActionTypes.GET_FILTER_COURSES_SUCCESS: {
            return {
                ...state,
                dataLoading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case CourseActionTypes.GET_FILTER_COURSES_ERROR: {
            return { ...state, dataLoading: false, errors: action.payload };
        }      
        default: {
            return state;
        }
    }
};

export { reducer as CourseReducer };
