import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Button } from 'reactstrap';
import _ from 'lodash';
import TableContainer from '../TableComponent';
import { withTranslation } from 'react-i18next';
import { ToastContainer, toast } from 'react-toastify';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { propertyArchivedListGetRequest, GroupGetByModelRequest, groupCreateRequest, groupDelete, groupUpdateRequest } from 'store/properties/action';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import { usersListGetRequest } from 'store/user/action';
import AddEntry from './AddEntry';
import DeleteModal from './DeleteModal';
import UpdateEntry from './UpdateEntry';
import ListingLoader from 'Components/Common/FormBuilder/DtsTablePlaceholder';
import AccessDenied from 'pages/AccessDenied/Denied';
import { checkTheyOwn } from 'utils';

const Groups = (props: any) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const groups = useSelector((state: ApplicationState) => state.properties.groups);
    const paginationDetails = useSelector((state: ApplicationState) => state.properties.groupspagination);
    const loading = useSelector((state: ApplicationState) => state.properties.groupsloading);
    const authLoading = useSelector((state: ApplicationState) => state.auth.meloading);
    const userProfile = useSelector((state: ApplicationState) => state.auth.userProfile);
    const subLevelPermissionsList = useSelector((state: ApplicationState) => state.auth.subLevelPermissionsList);
    const [isEntryOpen, setisEntryOpen] = useState(false)
    const [isEditOpen, setisEditOpen] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(10)
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);
    const [columns, setColumns] = useState<any>([])
    const [propertiesData, setPropertiesData] = useState<any>([])
    const [viewAccess, setViewAccess] = useState<boolean>(false);
    const [access, setAccess] = useState<any>([]);
    const [create, setCreate] = useState<boolean>(false);
    const [edit, setEdit] = useState<any>(null);
    const [record, setrecord] = useState<any>({});
    const [filterObject, setFilterObject] = useState<any>({});
    const [arrowNavTab, setarrowNavTab] = useState<any>("1");
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")
    const [pagesList, setPagesList] = useState<any>([]);
    const [propertiesJson, setPropertiesJson] = useState<any>([])
    const [isDeleteOpen, setisDeleteOpen] = useState<any>(false)
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            totalPages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false,
            totalElements: 0
        }
    )

    useEffect(() => {
        let userRole = userProfile?.role?.title;
        if (userRole === "Owner") {
            setViewAccess(true)
            setCreate(true)
            setEdit("all")
        }
        else if (subLevelPermissionsList) {
            let access = subLevelPermissionsList?.sections;
            if (access && access?.length) {
                setAccess(access)
                setViewAccess(true)
                if (access && access.length) {
                    access.map((item: any, index: any) => {
                        if (item.name.toLowerCase() == 'create') {
                            item.value == "" || item.value == "none" ? setCreate(false) : setCreate(true)
                        }
                        if (item.name.toLowerCase() == 'edit') {
                            item.value == "" || item.value == "none" ? setEdit(null) : setEdit(item.value)
                        }
                    })
                }
            }
            else {
                setViewAccess(false)
                setEdit(null)
            }
        }
    }, [subLevelPermissionsList, userProfile])

    useEffect(() => {
        if (groups && groups.length) {
            let fields = groups[0]
            const keys = Object.keys(fields)
            const columns: any = []
            if (keys && keys.length) {
                keys.map((field, index) => {
                    if (field == 'groupTitle') return columns[0] = {
                        Header: "Name",
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        isSortable: true,
                        show: true,
                        Cell: (cellProps: any) => {
                            let they_own = checkTheyOwn(cellProps.row.original, userProfile?.id)
                            return <div className="d-flex align-items-center justify-content-between">
                                <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >

                                        {cellProps.row.original[field]}
                                    </div>
                                </div>
                                {
                                    field == "groupTitle" ? <div className={"btn_preview"}>
                                        <div className={"hstack gap-2"}>
                                        {
                                            edit && (edit?.toLowerCase() =="all" || (edit?.toLowerCase() == "they_own" && they_own)) ? 
                                        
                                                <Button
                                                    size='sm'
                                                    color="dark"
                                                    outline={true}
                                                    className={""}
                                                    onClick={() => { setisEditOpen(true); setrecord(cellProps.row.original); }} >
                                                    {props.t("properties.edit")}
                                                </Button>
                                                : null}
                                        </div>
                                    </div>
                                        : null
                                }
                            </div>

                        }
                    }
                    else if (field == 'model') return columns[1] = {
                        Header: "Model",
                        accessor: field,
                        filterable: true,
                        isSortable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {
                            return <div className="d-flex align-items-center justify-content-between">
                                <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >
                                        {cellProps.row.original[field].charAt(0).toUpperCase() + cellProps.row.original[field].slice(1)}
                                    </div>
                                </div>
                            </div>

                        }
                    }
                })
                setColumns(columns)
            }
        }
    }, [groups, edit]);

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
            // Reverse the sorting order if the same column is clicked again
            setSortBy(columnName);
            setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
            let sort = sortOrder === 'asc' ? 'desc' : 'asc'
            let filters = { ...filterObject, sortBy: columnName, sortOrder: sort }
            setFilterObject(filters)
            dispatch(GroupGetByModelRequest(props.model, filters, pagination.page));
        } else {
            // Set the new column to sort by and default to ascending order
            setSortBy(columnName);
            setSortOrder('asc');
            let filters = { ...filterObject, sortBy: columnName, sortOrder: 'asc' }
            setFilterObject(filters)
            dispatch(GroupGetByModelRequest(props.model, filters, pagination.page));
        }
    };

    useEffect(() => {
        dispatch(usersListGetRequest())
    }, []);


    const execute = () => {
        let a = isEqual(groups, propertiesJson);
        return !a
    }

    useEffect(() => {
        const properties: any = groups && groups.length ? groups.map((item: any) => {
            return item
        }) : [];
        let groupList = properties;
        setPropertiesData(groupList)
        setPropertiesJson(groups)
    }, [execute()])


    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(GroupGetByModelRequest(props.model, filterObject, newPageNo));
    };

    const onChangePageSize = (value: any) => {
        setPagination({ ...pagination, pageSize: value })
        setPageSize(value);
        let filters = { ...filterObject, pageSize: value }
        setFilterObject(filters)
        dispatch(GroupGetByModelRequest(props.model, filters, 0));
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    }, [paginationDetails])

    useEffect(() => {
        dispatch(GroupGetByModelRequest(props.model));
        dispatch(propertyArchivedListGetRequest(props.model));
    }, [props.model])

    const onSave = (groupName: string) => {
        const data = {
            "groupTitle": groupName,
            "model": props.model
        }
        const handleSuccess = () => {
            let filters = { ...filterObject, pageSize: pageSize }
            dispatch(GroupGetByModelRequest(props.model, filters, 0))
            setisEntryOpen(false)
        }
        const handleFailure = () => {

        }
        dispatch(groupCreateRequest(data, handleSuccess, handleFailure))
    }

    const onUpdate = (groupName: string) => {
        const data = {
            "groupTitle": groupName,
            "model": props.model
        }
        const handleSuccess = () => {
            let filters = { ...filterObject, pageSize: pageSize }
            dispatch(GroupGetByModelRequest(props.model, filters, pagination.page))
            setisEditOpen(false)
        }
        dispatch(groupUpdateRequest(record.id, data, handleSuccess, () => { }))
    }

    const onDelete = () => {
        const handleSuccess = () => {
            let filters = { ...filterObject, pageSize: pageSize }
            dispatch(GroupGetByModelRequest(props.model, filters, pagination.page))
            setisDeleteOpen(false)
        }
        const handleFailure = () => {
            setisDeleteOpen(false)
            let filters = { ...filterObject, pageSize: pageSize }
            dispatch(GroupGetByModelRequest(props.model, filters, pagination.page))
        }
        dispatch(groupDelete(record.id, handleSuccess, handleFailure))
    }

    const handleChange = (value: any) => {
        setsearchvalue(value);
        const filters = { ...filterObject, search: value, pageSize: pageSize }
        setFilterObject(filters);
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {
            dispatch(GroupGetByModelRequest(props.model, filters, pagination.page))
        }, 2000);

        setTypingTimeout(newTimeout);
    };

    document.title = "Properties | Zilter";
    return (
        <div> {!authLoading ?
            viewAccess ?
                <React.Fragment>
                    <ToastContainer />
                    {isEntryOpen && <AddEntry
                        show={isEntryOpen}
                        onCloseClick={() => setisEntryOpen(false)}
                        onSave={onSave}
                        props={props}
                        model={props.model}
                    />}
                    {isEditOpen && <UpdateEntry
                        show={isEditOpen}
                        onCloseClick={() => setisEditOpen(false)}
                        onUpdate={onUpdate}
                        record={record}
                        props={props}
                    />}
                    {isDeleteOpen && <DeleteModal
                        show={isDeleteOpen}
                        onCloseClick={() => setisDeleteOpen(false)}
                        onDelete={onDelete}
                        record={record}
                        props={props}
                    />}
                    <Card>
                        <CardBody className='border border-dashed border-end-0 border-start-0 vstack gap-2'>
                            <Row className='hstack gap-2 justify-content-start m-0'>
                                <Col className="p-0 m-0" >
                                    <div className='hstack gap-2 justify-content-start'>
                                        <div className="search-box">
                                            <Input
                                                type="text"
                                                size={14}
                                                className="search"
                                                placeholder="Search for..."
                                                onChange={(e) => handleChange(e.target.value)}
                                                value={searchvalue}
                                            />
                                            {searchvalue == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}

                                        </div>
                                    </div>
                                </Col>
                                <Col className="p-0 m-0" >
                                    <div className='hstack gap-2 justify-content-end'>
                                        {
                                            create ?
                                                <Button
                                                    onClick={() => setisEntryOpen(true)}
                                                    color="primary"
                                                    className="btn-label">
                                                    <i className="ri-user-add-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t("properties.create_groups")}
                                                </Button>
                                                :
                                                <Button
                                                    color="primary"
                                                    size='sm'
                                                    disabled
                                                    className="btn-label">
                                                    <i className="ri-admin-fill label-icon align-middle fs-16 me-2"></i>
                                                    {props.t('roles.create_role')}
                                                </Button>}

                                    </div>
                                </Col>
                            </Row>
                            <Row className='students-table'>
                                <TableContainer
                                    columns={columns}
                                    data={propertiesData || []}
                                    customPageSize={pageSize}
                                    tableClass="align-middle table-nowrap"
                                    theadClass="table-light text-muted"
                                    thClass="border-bottom-1 table-soft-primary"
                                    isPageSizeChange={true}
                                    pageChanged={pageChanged}
                                    pagination={pagination}
                                    pagesList={pagesList}
                                    onChangePageSize={onChangePageSize}
                                    setisDeleteOpen={setisDeleteOpen}
                                    setrecord={setrecord}
                                    pageSize={pageSize}
                                    handleSort={handleSort}
                                    sortBy={sortBy}
                                    sortOrder={sortOrder}
                                    props={props}
                                    loading={loading}
                                    access={access}
                                />
                            </Row>
                        </CardBody>
                    </Card>
                </React.Fragment> :
                <AccessDenied />
            : <ListingLoader
                columns={columns}
                tableClass="align-middle table-nowrap"
                theadClass="table-light text-muted"
                thClass="border-bottom-1 table-soft-primary"
            />}
        </div>
    );
};

export default withTranslation()(Groups);


