import _ from 'lodash';
import { withTranslation } from 'react-i18next';
import Listing from 'Components/Common/Views/Listing';
import { universitiesMetadataGetRequest, universityCreateRequest, universityDelete, universityGetRequest, universityListGetRequest } from 'store/university/action';

const University = (props: any) => {
    const { viewId, isCreateModal, setCreateOpen } = props;    
    return (
        <Listing 
            viewId={viewId}
            isCreateModal={isCreateModal}
            setCreateOpen={setCreateOpen}
            getListAction={universityListGetRequest}
            deleteAction={universityDelete}
            detailsGetAction={universityGetRequest}
            metaDatagetRequest={universitiesMetadataGetRequest}
            createAction={universityCreateRequest}
            model={"institutes"}
            reducerState={"universities"}
            translater={"institute"}
        />
    );
};
export default withTranslation()(University);


