const multikeys = [
    {key: "mobile", keys: ["phone number", "phone no", "contact no", "contact number", "contact", "mobile number", "mobile no", "mobile", "cell phone", "phone"]},
    {key: "date_of_birth", keys: ["date of birth", "birth date", "birthday", "dob", "fecha de nacimiento", "date de naissance"]},
    {key: "email", keys: ["email id", "email address", "email", "email id", "contact email", "alternate email address"]},
    {key: "gender", keys: ["sex", "gender"]},
    {key: "city", keys: ["city", "city of residence", "residence", "town", "village"]},
    {key: "nationality", keys: ["nationality", "country_of_origin", "citizenship", "nationalite", "nacionalidad"]},
    {key: "zip_code", keys: ["zip code", "postal code", "pin code", "postcode"]},
    {key: "marital_status", keys: ["marital status", "relationship status", "marital_status"]},
    {key: "first_name", keys: ["name", "first name", "given names", "prenoms"]},
    {key: "last_name", keys: ["last name", "surname", "nom", "nombre", "nome"]},
    {key: "twitter", keys: ["twitter"]},
    {key: "fax", keys: ["fax"]},
    {key: "name", keys: ["full name", "name"]},
    {key: "passport_number", keys: ["passport number", "passport"]},
    {key: "disability", keys: ["disability", "disability status"]},
    {key: "dependent", keys: ["dependent", "dependents", "no of dependents"]},
    {key: "previous_visa_refusal_details", keys: ["previous visa refusal details", "visa refusal history", "visa refusal"]},
    {key: "fee_budget", keys: ["fee budget", "budget for fees", "financial budget"]},
    {key: "referee_1", keys: ["referee 1", "reference 1", "referee"]},
    {key: "referee_2", keys: ["referee 2", "reference 2"]},
    {key: "entrance_test", keys: ["entrance test", "admission test", "entry test"]},
    {key: "entrance_test_details", keys: ["entrance test details", "admission test details"]},
    {key: "post_graduate_masters_details", keys: ["post graduate details", "masters details", "postgraduate details"]},
    {key: "ineligible_reason", keys: ["ineligible reason", "reason for ineligibility"]},
    {key: "country_preferences", keys: ["country preferences", "preferred countries", "country of preference"]},
    {key: "higher_secondary_school_grade_12_a_levels_details", keys: ["higher secondary school details", "grade 12 details", "a levels details", "12th grade details"]},
    {key: "12th_english_score", keys: ["12th English score", "grade 12 English score"]},
    {key: "self_family_funding_amount", keys: ["self funding amount", "family funding amount", "funding amount"]},
    {key: "10th_english_score", keys: ["10th English score", "grade 10 English score"]},
    {key: "help_with_loans", keys: ["help with loans", "loan assistance"]},
    {key: "english_test", keys: ["english test", "language proficiency test", "English proficiency test"]},
    {key: "marital_status", keys: ["marital status", "relationship status"]},
    {key: "passport_number", keys: ["passport number", "passport"]},
    {key: "next_of_kin", keys: ["next of kin", "emergency contact", "kin"]},
    {key: "course_level", keys: ["course level", "level of course"]},
    {key: "address_line_1", keys: ["address line 1", "street address", "address"]},
    {key: "telecaller", keys: ["telecaller", "call center agent", "telemarketing agent"]},
    {key: "processing_officer", keys: ["processing officer", "case officer", "application officer"]},
    {key: "subagent", keys: ["subagent", "associated agent"]},
    {key: "criminal_conviction", keys: ["criminal conviction", "conviction details", "criminal record"]},
    {key: "last_name", keys: ["last name", "surname", "family name"]},
    {key: "disability_", keys: ["disability", "disability status"]},
    {key: "country_of_origin", keys: ["country of origin", "place of origin", "nationality"]},
    {key: "source_of_funding", keys: ["source of funding", "funding source"]},
    {key: "institute_preference", keys: ["institute preference", "preferred institutes", "preferred colleges"]},
    {key: "source", keys: ["source", "lead source", "referral source"]},
    {key: "counseller", keys: ["counseller", "counselor", "adviser"]},
    {key: "english_test_details", keys: ["english test details", "language test details"]},
    {key: "course_intake", keys: ["course intake", "intake period", "intake details"]},
    {key: "counselling_summary", keys: ["counselling summary", "summary of counselling", "counseling summary"]},
    {key: "bachelors_degree_details", keys: ["bachelors degree details", "undergraduate degree details"]},

];

export default multikeys;