import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Label, Button, ButtonGroup, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, ListGroup, ListGroupItem } from "reactstrap";
import { Action } from "redux";
import { ApplicationState } from "store";
import { propertyListGetRequest } from "store/properties/action";
import DataSourceDrawer from "./DataSourceDrawer";

const ICONS: any = {
    "textfield": "ri-input-method-line",
    "textarea": "ri-file-text-line",
    "phone": "ri-input-method-line",
    "email": "ri-input-method-line",
    "checkbox": "ri-checkbox-line",
    "selectboxes": "ri-checkbox-multiple-line",
    "datetime": "ri-calendar-line",
    "select": "ri-list-check",
    "datasetselect": "ri-database-2-line",
    "radio": "ri-radio-button-line",
    "number": "ri-hashtag",
    "file": "ri-file-upload-line"
}

const isJson = (str: any) => {
    try {
        let options = JSON.parse(str);
        return options
    } catch (e) {
        return false;
    }
}

interface DataPanelProps {
    onAddColumns: (property: any, dataSource: any) => void;
    onAddFilters: (property: any, dataSource: any) => void;
    setDataSources: (property: any) => void;
    dataSources: any;
    t: (key: string) => string;
}

const DataPanel: React.FC<DataPanelProps> = ({t, onAddColumns, onAddFilters, dataSources, setDataSources }) => {
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const propertiesList = useSelector((state: ApplicationState) => state.properties.list); 
    
    const [dataSourceToggle, setDataSourceToggle] = useState<boolean>(false);
    const [toggleData, setToggleData] = useState(true);
    const [selectedDataSources, setSelectedDataSources]: any = useState([])
    const [selectedDataSource, setSelectedDataSource]: any = useState(null)
    const [dataSourceCount, setDataSourceCount] = useState(null)

    const onDataSource = (primary: any, secondary: any) => {
        const selectedDataSource: any = {
            primary,
            secondary
        }
        let dataSources: any = [{...primary, primary: true}]
        if(secondary?.length) {
            dataSources = [...dataSources, ...secondary]
        }
        setDataSources(dataSources)
        setSelectedDataSource(dataSources[0])
        setDataSourceCount(dataSources?.length)
        setSelectedDataSources(selectedDataSource)
        dispatch(propertyListGetRequest(dataSources[0].value, null, 0, 500))
    }

    const onSelectDataSource = (dataSource: any) => {
        dispatch(propertyListGetRequest(dataSource.value, null, 0, 500))
        setSelectedDataSource(dataSource)
    }
    console.log("propertiesList>>>", propertiesList)
    if(toggleData) {
        return (
            <div className="d-flex flex-column h-100 bg-white border-end">
                <div className="d-flex h-100  flex-column" style={{width: "350px"}}>
                    <div className="d-flex justify-content-between p-4 align-items-center">
                        <h5 className="m-0">{t('reports.data_bar.heading')}</h5>
                        <Button color="primary" className="btn-icon btn-ghost-primary" onClick={() => setToggleData(false)}>
                            <i className="cursor-pointer ri-indent-decrease fs-4"></i>
                        </Button>
                    </div>
                    {dataSourceCount ? <div className="d-flex flex-column align-items-center justify-content-center gap-2 border-bottom border-dark-subtle pb-4">
                        <div className="d-flex gap-1">
                            <Label className="fw-semibold fs-5 text-muted">{dataSourceCount} {dataSourceCount <= 1 ? t('reports.data_bar.count_label') : t('reports.data_bar.count_label_p')}</Label>
                            <Button size="sm" color="primary" className="btn-icon btn-soft-primary" onClick={() => setDataSourceToggle(true)}> <i className=" ri-edit-2-fill" /> </Button>
                        </div>
                        <ButtonGroup>
                            <UncontrolledDropdown>
                                <DropdownToggle tag="button" className="btn btn-soft-primary">
                                    {selectedDataSource?.label} {selectedDataSource.primary ? '(Primary)' : ''} <i className="mdi mdi-chevron-down"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    {
                                        dataSources.map((dataSource: any) => <DropdownItem onClick={() => onSelectDataSource(dataSource)}>{dataSource.label} {dataSource.primary ? '(Primary)' : ''}</DropdownItem>)
                                    }
                                </DropdownMenu>
                            </UncontrolledDropdown>
                        </ButtonGroup>
                    </div> : <div className="d-flex justify-content-center border-bottom border-dark-subtle pb-4">
                        <Button
                            color="primary"
                            className="btn-label"
                            onClick={() => setDataSourceToggle(true)}
                        >
                                <i className="ri-database-2-line label-icon align-middle fs-16"></i>
                                {t('reports.data_bar.btn_add_data_sources')}
                        </Button>
                    </div>}
                    <div className="d-flex gap-2 flex-column h-100 overflow-x-hidden overflow-y-scroll pb-5">
                        <ListGroup flush>
                        {
                            propertiesList?.map((propertyJson: any, propertyJsonIndex: number) => {
                                const property = propertyJson.valuesJson  && isJson(propertyJson.valuesJson) ? JSON.parse(propertyJson.valuesJson) : {}
                                return (
                                    <ListGroupItem className="cursor-pointer d-flex list-group-item-action justify-content-between border-0">
                                        <div className="cursor-pointer d-flex list-group-item-action gap-2">
                                            <i className={`${ICONS[property.type]} text-muted`}></i>
                                            <Label className="cursor-pointer m-0 text-muted">{property.label}</Label>
                                        </div>
                                        <ButtonGroup>
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" className="px-1 py-0 btn btn-ghost-primary">
                                                    <i className="ri-more-2-fill"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => onAddColumns(property, selectedDataSource)}>Add to Column</DropdownItem>
                                                    <DropdownItem onClick={() => onAddFilters(property, selectedDataSource)}>Add to Filters</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </ButtonGroup>
                                    </ListGroupItem>
                                )
                            })
                        }
                        </ListGroup>
                    </div>
                </div>
                <DataSourceDrawer isOpen={dataSourceToggle} toggle={setDataSourceToggle} onDataSource={onDataSource} initData={selectedDataSources} />
            </div>
        );
    } else {
        return (
            <div className="d-flex flex-column bg-white border-end">
                <div className="d-flex justify-content-between p-2 py-4 align-items-center">
                    <Button color="primary" className="btn-icon btn-ghost-primary" onClick={() => setToggleData(true)}>
                        <i className="cursor-pointer ri-indent-increase fs-4"></i>
                    </Button>
                </div>
            </div>
        )
    }
};

export default withTranslation()(DataPanel);
