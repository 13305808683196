import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Label, Row } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './optiontablecomponent';
import dataFields from './tablefields.json';
import FeatherIcon from "feather-icons-react";
import Select from "react-select";
import { Link } from 'react-router-dom';
import FormBuilder from "../../../Components/Common/FormBuilder/FormBuilder";
import DtsDataSelectInput from "./DtsDataSelectInput";
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { ApplicationState } from 'store';
import { useDispatch, useSelector } from 'react-redux';
import { ModelListGetRequest } from 'store/auth/action';

const DropdownSelectPreview = ({ setEditInputs, EditInputs, setFieldJson, fieldJson, fieldType }: any) => {
    const modelList = useSelector((state: ApplicationState) => state.auth.models)
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const [columns, setColumns] = useState<any>([])
    const [counter, setCounter] = useState<any>(1)
    const [selectedsortOption, setselectedsortOption] = useState<any>({ label: 'Custom', value: 'custom' })
    const [model, setModal] = useState<any>({});
    const [type, setType] = useState<any>({});
    const [optionLabel, SetLabel] = useState<any>({});
    const [returnValue, SetReturnValue] = useState<any>({ label: 'Field value', value: 'value' });
    const [rows, setRows] = useState<any>([
        {
            "id": 1,
            "label": "",
            "value": "",
            "in_forms": true
        }
    ])
    const [pageSize, setPageSize] = useState<number>(30)
    const [isMultiDeleteButton, setIsMultiDeleteButton] = useState(false);
    const [Selectedcount, setselectedcount] = useState<number>(0)
    const [rest, setrest] = useState();


    useEffect(() => {
        const columns: any = dataFields && dataFields.fields ? dataFields.fields.map((fields, index) => {
            if (fields.name == 'id') return {
                Header: _.startCase(fields.name),
                accessor: fields.name,
                filterable: true,
                isSortable: false,
                disableFilters: true,
                show: false,
            }
            else if (fields.type == 'text') return {
                Header: _.startCase(fields.name),
                accessor: fields.name,
                filterable: true,
                disableFilters: true,
                show: true,
                Cell: (cellProps: any) => {
                    return <Input placeholder={fields.placeholder} value={cellProps.row.original[fields.name]} name={fields.name} onChange={(e) => handleCellValueChange(cellProps.row.original, e.target.name, e.target.value)} />
                }
            }
            else if (fields.type == 'radio') return {
                Header: _.startCase(fields.name),
                accessor: fields.name,
                filterable: true,
                isSortable: true,
                disableFilters: true,
                show: true,
                className: 'text-center',
                Cell: (cellProps: any) => {
                    return <div className="form-check form-switch form-check-right align-items-center d-flex mt-1">
                        <Input className="form-check-input" name={fields.name} type="checkbox" role="switch" defaultChecked={cellProps.row.original.in_forms} id="flexSwitchCheckRightDisabled" onChange={(e) => handleCellValueChange(cellProps.row.original, e.target.name, e.target.value)} />
                    </div>
                }
            }
        }) : []
        setColumns(columns)
        dispatch(ModelListGetRequest())
    }, []);


    const handleCellValueChange = (cellprops: any, field: any, value: any) => {
        if (field == "label") {
            setRows((prevState: any) =>
                prevState.map((item: any) =>
                    item.id === cellprops.id ? { ...item, [field]: value, value: value.toLowerCase().replace(/ /g, "_") } : item
                )
            );
        }
        else {
            setRows((prevState: any) =>
                prevState.map((item: any) =>
                    item.id === cellprops.id ? { ...item, [field]: value } : item
                )
            );
        }

    };


    const addoption = () => {
        setCounter(counter + 1)
        let updatedrows = [...rows]
        const row = {
            "id": counter + 1,
            "label": "",
            "value": "",
            "in_forms": true
        }
        updatedrows.push(row)
        setRows(updatedrows);
    }

    const deleteAllOption = () => {
        setCounter(1)
        const row = {
            "id": 1,
            "label": "",
            "value": "",
            "in_forms": true
        }
        setRows([row]);
    }

    useEffect(() => {
        let options = rows.sort((a: any, b: any) => a.id - b.id);
        setRows(options);
        setEditInputs({ ...EditInputs, values: options })
        let newfield = { ...fieldJson, values: options }
        setFieldJson(newfield)
    }, [rows]);
    document.title = "DTS | Zilter";

    const objectTypeOption = modelList && modelList.length ?
        modelList.map((item: any) => {
            return { label: item.charAt(0).toUpperCase() + item.slice(1), value: item.toLowerCase() }
        }) : []

    const ReturnOption = [
        { label: 'Field label', value: 'label' },
        { label: 'Field value', value: 'value' }
    ]

    const handleselected = (option: any, name: string,) => {
        switch (name) {
            case 'model': setModal(option)
                setEditInputs({ ...EditInputs, model: option.value.toLowerCase() })
                setFieldJson({ ...fieldJson, model: option.value.toLowerCase() })
                setrest({ ...fieldJson, model: option.value.toLowerCase() })
                break
            case 'type': setType(option)
                setEditInputs({ ...EditInputs, dropdownType: option.value })
                setFieldJson({ ...fieldJson, dropdownType: option.value })
                setrest({ ...fieldJson, dropdownType: option.value })
                break
            case 'optionLabel': SetLabel(option)
                setEditInputs({ ...EditInputs, optionLabel: option.value })
                setFieldJson({ ...fieldJson, optionLabel: option.value })
                setrest({ ...fieldJson, optionLabel: option.value })
                break
            case 'returnValue': SetReturnValue(option)
                setEditInputs({ ...EditInputs, returnValue: option.value })
                setFieldJson({ ...fieldJson, returnValue: option.value })
                setrest({ ...fieldJson, returnValue: option.value })
                break
        }
    }

    useEffect(() => {
        if (EditInputs && EditInputs.model) {
            let option = { label: EditInputs.model.charAt(0).toUpperCase() + EditInputs.model.slice(1), value: EditInputs.model }
            setModal(option)
            let optionLabel = { label: EditInputs.optionLabel.charAt(0).toUpperCase() + EditInputs.optionLabel.slice(1), value: EditInputs.optionLabel }
            SetLabel(optionLabel)
        }
    }, [EditInputs]);

    return (
        <React.Fragment>
            <Row className='mt-1'>
                <Col>
                    <Label className='form-label'>Module</Label>
                    <Select
                        placeholder={'Select Module'}
                        className="fs-16 fw-medium"
                        value={model}
                        onChange={(option: any) => handleselected(option, 'model')}
                        options={objectTypeOption}
                        name="choices-single-default"
                    ></Select>
                </Col>
                <Col>
                    <DtsDataSelectInput
                        placeholder={'Select Field name'}
                        onChange={(option: any) => handleselected(option, 'optionLabel')}
                        label={'Field name'}
                        value={optionLabel.value}
                        defaultValue={optionLabel.value}
                        rest={fieldJson}
                        disabled={!fieldJson.model}
                        model={model}
                    />
                </Col>
            </Row>
            <Row>
                <Label className='form-label mt-2'>Field Return Value</Label>
                <Select
                    // defaultOptions
                    placeholder={'Select field return value'}
                    className="fs-16 fw-medium"
                    value={returnValue}
                    onChange={(option: any) => handleselected(option, 'returnValue')}
                    options={ReturnOption}
                    name="choices-single-default"
                ></Select>
            </Row>
            {
                type.value == "custom" ? <Row className='mt-1'>
                    <Col>
                        <Card>
                            <CardBody className="border border-dashed border-end-0 border-start-0">
                                <div className={"students-table"}>
                                    <TableContainer
                                        columns={columns}
                                        data={rows.sort((a: any, b: any) => b.id - a.id) || []}
                                        customPageSize={pageSize}
                                        divClass="table-responsive table-card mb-1"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light text-muted"
                                        isPageSizeChange={false}
                                        isBordered={true}
                                        setIsMultiDeleteButton={setIsMultiDeleteButton}
                                        setselectedcount={setselectedcount}

                                    />
                                </div>
                                <div className="hstack gap-3 px-3">
                                    <Link
                                        to="#"
                                        className="fw-bold text-info w-auto"
                                        onClick={() => addoption()}
                                    >
                                        <FeatherIcon icon="plus" className="mb-1 fw-bold text-info display-2" style={{ width: 14, height: 14 }} />
                                        Add option
                                    </Link>
                                    <Link
                                        to="#"
                                        className="fw-bold text-info w-auto"
                                        onClick={() => deleteAllOption()}
                                    >
                                        <FeatherIcon icon="trash-2" className="mb-1 fw-bold text-info display-2" style={{ width: 14, height: 14 }} />
                                        Clear all
                                    </Link>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                    : null
            }
            <Row>
                <Col>
                    <div id="myDivparent">
                        <div className="fs-16 fw-semibold">Preview</div>
                        <Card className="m-3 vstack" id="myDivChild">
                            <FormBuilder
                                placeholder={EditInputs.placeholder}
                                onChange={(option: any) => setselectedsortOption(option)}
                                options={rows.reverse()}
                                label={EditInputs.label}
                                type={fieldType}
                                value={selectedsortOption}
                                className="w-100 h-100 text-start"
                                rest={fieldJson}
                            />
                        </Card>
                    </div>
                </Col>
            </Row>
        </React.Fragment>
    );
};
export default DropdownSelectPreview;
