import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import Configure from "./Configure";
import Filters from "./Filters";

interface DataPanelProps {
    columns: any;
    filters: any;
    dataSources: any;
    moveColumns: (dragIndex: number, hoverIndex: number, isDragging: boolean) => void
    onFilterUpdated: (newfilter: any) => void
    onColumnUpdated: (newfilter: any) => void
    t: (key: string) => string;
}

const ControlPanel: React.FC<DataPanelProps> = ({ t, filters, columns, dataSources, moveColumns, onFilterUpdated, onColumnUpdated }) => {
    const [selectedTab, setSelectedTab] = useState("1")
    return (
        <div className="d-flex flex-column bg-light border-primary-subtle border-end">
            <div className="d-flex h-100  flex-column" style={{width: "300px"}}>
                <Nav tabs>
                    <NavItem className="w-50">
                        <NavLink
                            className={selectedTab === "1" ? "active cursor-pointer border-start-0" : "cursor-pointer"}
                            onClick={() => setSelectedTab("1")}
                        >
                            Configure
                        </NavLink>
                    </NavItem>
                    <NavItem className="w-50">
                        <NavLink
                            className={selectedTab === "2" ? "active cursor-pointer border-end-0" : "cursor-pointer"}
                            onClick={() => setSelectedTab("2")}
                        >
                            Filters
                        </NavLink>
                    </NavItem>
                </Nav>
                <TabContent activeTab={selectedTab} className="overflow-y-scroll">
                    <TabPane tabId="1" className="h-100">
                        {
                            dataSources?.length ? <Configure columns={columns} moveColumns={moveColumns} onColumnUpdated={onColumnUpdated} /> :
                            <div className="h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">No Data Sources Selected</div>
                        }
                    </TabPane>
                    <TabPane tabId="2" className="h-100">
                        {
                            dataSources?.length ? <Filters filters={filters} onFilterUpdated={onFilterUpdated} /> :
                            <div className="h-100 d-flex align-items-center justify-content-center fs-6 fw-semibold">No Data Sources Selected</div>
                        }
                    </TabPane>
                </TabContent>
            </div>
        </div>
    )
};

export default withTranslation()(ControlPanel);
