import {FormActionTypes} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const createForm: AppThunk = (data) => {
    return async (dispatch: Dispatch): Promise<void> => {        
        localStorage.clear();
        dispatch({
            type: FormActionTypes.SET_FORM_DATA,
            payload: data
        });
    };
};
