import {SourceActionTypes, Source} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;

const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const sourceGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: SourceActionTypes.GET_SOURCE_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/sources/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, SourceActionTypes.GET_SOURCE_SUCCESS, SourceActionTypes.GET_SOURCE_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const sourcesMetadataGetRequest: AppThunk = (model, filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: SourceActionTypes.GET_SOURCEMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        if (model) {
            headers.append('Properties-Model', model);
        }  
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, SourceActionTypes.GET_SOURCEMETADATA_SUCCESS, SourceActionTypes.GET_SOURCEMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const sourceUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: SourceActionTypes.PUT_SOURCE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/sources/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );                                 
        const error = {
            204: {
                "message": "Source updated Successfully",
                "success": true
            },
            200: {
                "message": "Source updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, SourceActionTypes.PUT_SOURCE_SUCCESS, SourceActionTypes.PUT_SOURCE_ERROR, error, handleSuccess, handleError);
    
    };
};

export const sourceDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: SourceActionTypes.DELETE_SOURCE_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/sources/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Source deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Source deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, SourceActionTypes.DELETE_SOURCE_SUCCESS, SourceActionTypes.DELETE_SOURCE_ERROR, error, handleSuccess, handleError);
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: SourceActionTypes.DELETE_SOURCE_ERROR
            });
            handleSuccess();
        }
    };
};

export const sourceListGetRequest: AppThunk = (filters,pageno, pageSize, signal) => {
    let requestbody = filters ? filters : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: SourceActionTypes.GET_SOURCES_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        try {           
            const response = await fetch(`${DTS_API_STUDENT}/models/get/sources?page=${pageno?pageno:0}&pageSize=${pageSize?pageSize:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });                    
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, SourceActionTypes.GET_SOURCES_SUCCESS, SourceActionTypes.GET_SOURCES_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};

export const sourceCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    let parent = data.parent ? data.parent : null
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: SourceActionTypes.POST_SOURCE_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/sources?parent=${parent}`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Source already exist",
                    "success": false
                },
                201: {
                    "message": "Source created Successfully",
                    "success": true
                },        
                200: {
                    "message": "Source created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, SourceActionTypes.POST_SOURCE_SUCCESS, SourceActionTypes.POST_SOURCE_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: SourceActionTypes.POST_SOURCE_ERROR, payload: error });
            handleError();
        }
    };
};

///sources/hierarchy



const sourceshierarchycreateQueryLink = (filterObject: any, page: any) => {
    let query = `${DTS_API_STUDENT}/sources?`;
    if(filterObject && typeof filterObject === 'object') {      
        const keys = Object.keys(filterObject);
        const values = Object.values(filterObject);      
        if (page) query += `page=${page}&`;
        for (let i = 0; i < keys.length; i++) {
            if(values[i]) query += `${keys[i]}=${values[i]}&`;
        }
        return query;
    }
    else return query;
};
export const SourceHierachyGetRequest: AppThunk = (filterObject,pageno) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: SourceActionTypes.GET_SOURCES_HIERARCHY_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const searchQuery = sourceshierarchycreateQueryLink(filterObject,pageno);             
        const response = await fetch(searchQuery, {
            method: "GET",
            headers: headers
        });
        const body = await response.json();
        const {response: response_1, body: body_1} = {response, body};
        if (!response_1.ok) {
            dispatch({
                type: SourceActionTypes.GET_SOURCES_HIERARCHY_ERROR,
                payload: body_1
            });
        } else {
            dispatch({
                type: SourceActionTypes.GET_SOURCES_HIERARCHY_SUCCESS,
                payload: body_1
            });
        }
    };
};



