export interface subagentsMetadata {
  id: string,
  fieldName: string,
  dataType: string,
  options: string,
  createdBy: string,
  createdAt: string,
  updatedAt: string,
  deletedAt: string | null,
  model: string;
  valuesJson: string;
  permissionsJson: string;
}

export interface notes   {
    id: string,
    shortNote: string,
    detailedNote: string,
    createdBy: string,
    createdAt: string,
    replies: any,
    updatedAt: string,
    deletedAt: string
}
export interface Subagent {
  createdAt : string,
  createdBy : string,
  deletedAt:string,
  id : string,
  updatedAt : string,
  valuesJson:string
  }

  export interface Pagination {
    size: number,
    page: number,
    pages: number,
    total: number,
    elements: number,
    last: boolean
  }

export enum SubagentActionTypes {
    GET_SUBAGENTMETADATA_REQUEST = "@@subagent/GET_SUBAGENTMETADATA_REQUEST",
    GET_SUBAGENTMETADATA_SUCCESS = "@@subagent/GET_SUBAGENTMETADATA_SUCCESS",
    GET_SUBAGENTMETADATA_ERROR = "@@subagent/GET_SUBAGENTMETADATA_ERROR",
    GET_SUBAGENT_REQUEST = "@@subagent/GET_SUBAGENT_REQUEST",
    GET_SUBAGENT_SUCCESS = "@@subagent/GET_SUBAGENT_SUCCESS",
    GET_SUBAGENT_ERROR = "@@subagent/GET_SUBAGENT_ERROR",
    GET_SUBAGENTS_REQUEST = "@@subagent/GET_SUBAGENTS_REQUEST",
    GET_SUBAGENTS_SUCCESS = "@@subagent/GET_SUBAGENTS_SUCCESS",
    GET_SUBAGENTS_ERROR = "@@subagent/GET_SUBAGENTS_ERROR",
    GET_SUBAGENTS_USERS_REQUEST = "@@subagent/GET_SUBAGENTS_USERS_REQUEST",
    GET_SUBAGENTS_USERS_SUCCESS = "@@subagent/GET_SUBAGENTS_USERS_SUCCESS",
    GET_SUBAGENTS_USERS_ERROR = "@@subagent/GET_SUBAGENTS_USERS_ERROR",
    PUT_SUBAGENT_REQUEST = "@@subagent/PUT_SUBAGENT_REQUEST",
    PUT_SUBAGENT_SUCCESS = "@@subagent/PUT_SUBAGENT_SUCCESS",
    PUT_SUBAGENT_ERROR = "@@subagent/PUT_SUBAGENT_ERROR",
    DELETE_SUBAGENT_REQUEST = "@@subagent/DELETE_SUBAGENT_REQUEST",
    DELETE_SUBAGENT_SUCCESS = "@@subagent/DELETE_SUBAGENT_SUCCESS",
    DELETE_SUBAGENT_ERROR = "@@subagent/DELETE_SUBAGENT_ERROR",
    POST_SUBAGENT_REQUEST = "@@subagent/POST_SUBAGENT_REQUEST",
    POST_SUBAGENT_SUCCESS = "@@subagent/POST_SUBAGENT_SUCCESS",
    POST_SUBAGENT_ERROR = "@@subagent/POST_SUBAGENT_ERROR",    
    GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST = "@@subagent/GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST",
    GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS = "@@subagent/GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS",
    GET_APPLICATIONS_COMMISSIONS_LIST_ERROR = "@@subagent/GET_APPLICATIONS_COMMISSIONS_LIST_ERROR",
    GET_INVOICES_STUDENTS_LIST_REQUEST = "@@subagent/GET_INVOICES_STUDENTS_LIST_REQUEST",
    GET_INVOICES_STUDENTS_LIST_SUCCESS = "@@subagent/GET_INVOICES_STUDENTS_LIST_SUCCESS",
    GET_INVOICES_STUDENTS_LIST_ERROR = "@@subagent/GET_INVOICES_STUDENTS_LIST_ERROR",
    PUT_USER_REQUEST = "@@user/PUT_USER_REQUEST",
    PUT_USER_SUCCESS = "@@user/PUT_USER_SUCCESS",
    PUT_USER_ERROR = "@@user/PUT_USER_ERROR"
}

export interface SubagentState {
    readonly list: Subagent[] | null;
    readonly details: Subagent | null;
    readonly pagination: Pagination | null;
    readonly metaDataFields: subagentsMetadata[];
    readonly invoicesstudents: any;
    readonly loading: boolean;
    readonly subagentusers: any;
    readonly dataLoading: boolean;
    readonly metaLoading: boolean;
    readonly usersLoading: boolean;
}
