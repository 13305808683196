import {Reducer} from "redux";
import {RoleActionTypes, RoleState} from "./types";


export const initialState: RoleState = { 
    errors: undefined,
    loading: false,
    response:{},
    token: undefined,
    pagination: null,
    roleDetails:{}
};

const reducer: Reducer<RoleState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case RoleActionTypes.GET_ROLES_REQUEST: {
            return {...state, loading: true};
        }
        case RoleActionTypes.GET_ROLES_SUCCESS: {
            return {
                ...state,
                loading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case RoleActionTypes.GET_ROLES_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case RoleActionTypes.GET_ROLE_REQUEST: {
            return {...state, loading: true};
        }
        case RoleActionTypes.GET_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                roleDetails: action.payload
            };
        }
        case RoleActionTypes.GET_ROLE_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case RoleActionTypes.GET_PERMISSIONS_REQUEST: {
            return {...state, loading: true};
        }
        case RoleActionTypes.GET_PERMISSIONS_SUCCESS: {
            return {
                ...state,
                loading: false,
                permissionsdata: action.payload
            };
        }
        case RoleActionTypes.GET_PERMISSIONS_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        case RoleActionTypes.PUT_ROLE_REQUEST: {
            return {...state, loading: true};
        }
        case RoleActionTypes.PUT_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }
        case RoleActionTypes.PUT_ROLE_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }        
        case RoleActionTypes.POST_ROLE_REQUEST: {
            return {...state, loading: true};
        }
        case RoleActionTypes.POST_ROLE_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }
        case RoleActionTypes.POST_PERMISSION_REQUEST: {
            return {...state, loading: true};
        }
        case RoleActionTypes.POST_PERMISSION_SUCCESS: {
            return {
                ...state,
                loading: false,
                data: action.payload
            };
        }
        case RoleActionTypes.POST_PERMISSION_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }        
        case RoleActionTypes.DELETE_ROLE_REQUEST: {
            return {...state, loading: true};
        }
        case RoleActionTypes.DELETE_ROLE_SUCCESS: {
            return {...state, loading: false, data: action.payload};
        }
        case RoleActionTypes.DELETE_ROLE_ERROR: {
            return {...state, loading: false, errors: action.payload};
        }
        default: {
            return state;
        }
    }
};

export {reducer as RoleReducer};
