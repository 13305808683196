import {ProductActionTypes, Product} from "./types";

import {ActionCreator, Action, Dispatch} from "redux";
import {ThunkAction} from "redux-thunk";

import {ApplicationState} from "../index";
import config from "../../config";
import { createQueryLink, responseHandler } from "utils";

const { api } = config;
const DTS_API_STUDENT = api.DTS_API_STUDENT

export type AppThunk = ActionCreator<ThunkAction<void, ApplicationState, null, Action<string>>>;

export const productGetRequest: AppThunk = (id, handleError, handleSuccess) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        dispatch({
            type: ProductActionTypes.GET_PRODUCT_REQUEST
        });
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/products/${id}`, {
                method: "GET",
                headers: headers
            });
            await responseHandler(response, dispatch, ProductActionTypes.GET_PRODUCT_SUCCESS, ProductActionTypes.GET_PRODUCT_ERROR, null, null, null, "GET"); 
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const productsMetadataGetRequest: AppThunk = (filterObject) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ProductActionTypes.GET_PRODUCTMETADATA_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }  
        headers.append('Properties-Model', "products");  
        const searchQuery =   createQueryLink(filterObject)    
        try {  
            const response = await fetch(searchQuery, {
                method: "GET",
                headers: headers
            });    
            await responseHandler(response, dispatch, ProductActionTypes.GET_PRODUCTMETADATA_SUCCESS, ProductActionTypes.GET_PRODUCTMETADATA_ERROR, null, null, null, "GET");
           
        } catch (error) {
            console.error("Error parsing JSON:", error);
        }
    };
};

export const productUpdateRequest: AppThunk = (id, data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ProductActionTypes.PUT_PRODUCT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }   
        const response = await fetch(
            `${DTS_API_STUDENT}/models/products/${id}`,
            {
                method: "PUT",
                headers: headers,
                body: JSON.stringify(data)
            }
        );      
                              
        const error = {
            204: {
                "message": "Invoice updated Successfully",
                "success": true
            },
            200: {
                "message": "Invoice updated Successfully",
                "success": true
            }
        }  
        await responseHandler(response, dispatch, ProductActionTypes.PUT_PRODUCT_SUCCESS, ProductActionTypes.PUT_PRODUCT_ERROR, error, handleSuccess, handleError);
    
    };
};

export const productDelete: AppThunk = (id, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState): Promise<void> => {
        const { auth: { token } } = getState();
        dispatch({
            type: ProductActionTypes.DELETE_PRODUCT_REQUEST
        });
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        } 
        const response = await fetch(`${DTS_API_STUDENT}/models/products/${id}`, {
            method: "DELETE",
            headers: headers,
            body: JSON.stringify(id)
        });
        try {
            const error = {
                204: {
                    "message": "Product deleted Successfully",
                    "success": true
                },
                200: {
                    "message": "Product deleted Successfully",
                    "success": true
                }
            }  
            await responseHandler(response, dispatch, ProductActionTypes.DELETE_PRODUCT_SUCCESS, ProductActionTypes.DELETE_PRODUCT_ERROR, error, handleSuccess, handleError);      
        } catch (error) {
            console.error("Error parsing response body:", error);
            dispatch({
                type: ProductActionTypes.DELETE_PRODUCT_ERROR
            });
            handleSuccess();
        }
    };
};

export const productCreateRequest: AppThunk = (data, handleSuccess, handleError) => {
    return async (dispatch: Dispatch, getState: any): Promise<void> => {   
        const { auth: { token } } = getState();   
        dispatch({
            type: ProductActionTypes.POST_PRODUCT_REQUEST
        });
        try {
            const response = await fetch(
                `${DTS_API_STUDENT}/models/products`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        "token": token
                    },
                    body: JSON.stringify(data)
                }
            );
            const error = {
                208: {
                    "message": "Product already exist",
                    "success": false
                },
                201: {
                    "message": "Product created Successfully",
                    "success": true
                },        
                200: {
                    "message": "Product created Successfully",
                    "success": true
                }
            }
            await responseHandler(response, dispatch, ProductActionTypes.POST_PRODUCT_SUCCESS, ProductActionTypes.POST_PRODUCT_ERROR, error, handleSuccess, handleError);

        } catch (error) {
            dispatch({ type: ProductActionTypes.POST_PRODUCT_ERROR, payload: error });
            handleError();
        }
    };
};

export const productListGetRequest: AppThunk = (data, page, size, signal) => {
    let requestbody = data ? data : {}
    return async (dispatch: Dispatch, getState): Promise<void> => {        
        const { auth: { token } } = getState();
        dispatch({
            type: ProductActionTypes.GET_PRODUCTS_REQUEST
        });  
        const headers = new Headers();
        headers.append('Content-Type', 'application/json');
        if (token) {
            headers.append('token', token);
        }
        try {
            const response = await fetch(`${DTS_API_STUDENT}/models/get/products?page=${page?page:0}&pageSize=${size?size:10}`, {
                method: "POST",
                headers: headers,
                body: JSON.stringify(requestbody),
                signal
            }).catch((error) => {
                return false;
            });                                
            if(typeof response === 'object' && !Array.isArray(response) && response !== null) {           
                await responseHandler(response, dispatch, ProductActionTypes.GET_PRODUCTS_SUCCESS, ProductActionTypes.GET_PRODUCTS_ERROR, null, null, null, "GET");
            } 
        }
        catch (error) {
        }
    };
};



