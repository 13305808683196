import React, { useEffect, useState, useCallback } from 'react';
import { Button, Col, DropdownItem, DropdownMenu, DropdownToggle, Modal, ModalBody, ModalHeader, Row, UncontrolledDropdown } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { ToastContainer, toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { ApplicationState } from 'store';
import Paginator from 'Components/Common/Paginator';
import { documentListGetRequest, documentDelete, documentDownload } from 'store/documents/action';
import { withTranslation } from 'react-i18next';
import DeleteModal from './DeleteModal';
import Upload from './Upload/Upload';
import moment from 'moment';
import { applicationGetRequest } from 'store/applications/action';
import DataPreviewModal from './DataPreviewModal';
import { studentsMetadataGetRequest } from 'store/student/action';
import DtsTablePlaceholder from 'Components/Common/FormBuilder/DtsTablePlaceholder';

const Documents = (props: any) => {
    document.title = "Documents | Zilter";
    const { id } = useParams()
    const {model, details} = props;
    const fileList = useSelector((state: ApplicationState) => state.document.list);
    const dataLoading  = useSelector((state: ApplicationState) => state.document.dataLoading);
    const paginationDetails = useSelector((state: ApplicationState) => state.document.pagination);
    const metadata = useSelector((state: ApplicationState) => state.student.metaDataFields);
    const [studentsmetaData, setstudentsmetaData] = useState<any>([]);
    const [data, setData] = useState(0)
    const dispatch: any = useDispatch();
    const [selectedFiles, setselectedFiles] = useState<any>([]);
    const [fileInfo, setFileInfo] = useState<any>({});
    const [fileDetailsOpen, setFileDetailsOpen] = useState(false)
    const [filterObject, setFilterObject] = useState<any>({});
    const [documentType, setDocumentType] = useState<any>("");
    const [isDeleteModal, setDeleteModal] = useState<boolean>(false);
    const [pageSize, setPageSize] = useState<number>(10)
    const [modalFile, setModalFile] = useState<boolean>(false);
    const [dataPreviewModal, setDataPreviewModal] = useState<boolean>(false);
    const [OcrLoading, setOcrLoading] = useState<boolean>(false);
    const [studentId, setStudentId] = useState<any>(null);
    const [applicationId, setApplicationId] = useState<any>(null);
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            pages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )

    const fileToggle = useCallback(() => {
        if (modalFile) {
            setModalFile(false);
        } else {
            setModalFile(true);
        }
    }, [modalFile]);

    const handleGetList = (filters?: any) => {
        if(model == "students") {
            if (id) {
                let newFilters={...filters, studentId: id}
                setStudentId(id)
                dispatch(documentListGetRequest(newFilters));
            }
        }
        else {
            if (details && details.id) {
                setApplicationId(details.id)
                let studentsId = details?.valuesJson?.student ? details?.valuesJson?.student?.id : "";               
                let filterObject = {...filters, applicationId: id, studentId:studentsId}
                setStudentId(studentsId)
                dispatch(documentListGetRequest(filterObject))
            }
        }     
    }

    useEffect(() => {
        handleGetList()
    }, [details])

    useEffect(() => {
        setstudentsmetaData(metadata)
    }, [metadata])

    // Add File
    const handleFileClicks = () => {
        setModalFile(!modalFile);
        fileToggle();
    };

    // Delete File
    const onClickFileDelete = (file: any) => {
        const handleSuccess = () =>{
            setDeleteModal(false)
            handleGetList()
        }
        const handleFailure = () =>{
            
        }
        dispatch(documentDelete(fileInfo.documentKey, handleSuccess, handleFailure))
    };

    const onClickFileDownload = (file: any) => {
        const handleSuccess = async (body: any) =>{
            setDeleteModal(false)

            try {        
                // Create a Blob from the response data
                const fileBlob = await body.blob();
        
                // Create a temporary URL for the Blob
                const url = window.URL.createObjectURL(fileBlob);
        
                // Create a temporary <a> element to trigger the download
                const tempLink = document.createElement("a");
                tempLink.href = url;
                tempLink.setAttribute("download", file.documentName); // Set the desired filename for the downloaded file
        
                // Append the <a> element to the body and click it to trigger the download
                document.body.appendChild(tempLink);
                tempLink.click();
        
                // Clean up the temporary elements and URL
                document.body.removeChild(tempLink);
                window.URL.revokeObjectURL(url);
            } catch (error) {
                console.error("Error downloading file:", error);
            }
        }
        const handleFailure = () =>{
            
        }
        dispatch(documentDownload(file.documentKey, handleSuccess, handleFailure))
    }

    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                newPageNo = 0;
                setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                newPageNo = lastPage - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal - 1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        handleGetList({...filterObject, page: newPageNo})
    };

    useEffect(() => {
        let element: any = document.getElementById('documenttab');
        if (element) {
            fileDetailsOpen ? element.classList.add("email-detail-show") : element.classList.remove("email-detail-show")
        }
    }, [fileDetailsOpen])

    const onChangePageSize = (event: any) => {
        let value = Number(event.target.value)
        setPageSize(Number(event.target.value));
        const filters = { ...filterObject, pageSize: value, page:0 }
        setFilterObject(filters)
        handleGetList(filters)
    }
   
    const handleIcon = (type: any) => {
        const extensions : any = {
            "video": ["mp4", "mov", "avi", "mkv", "wmv", "flv", "webm", "3gp"],
            "image": ["jpg", "jpeg", "png", "gif", "bmp", "tiff", "svg", "webp"],
            "pdf": ["pdf"],
            "document": ["doc", "docx", "txt", "rtf", "odt", "pages"],
            "spreadsheet": ["xls", "xlsx", "csv", "ods", "numbers"],
            "presentation": ["ppt", "pptx", "key", "odp"],
            "archive": ["zip", "rar", "7z", "tar", "gz"],
            "executable": ["exe", "bat", "sh", "app"]
        };
        
        for (let category in extensions) {
            if (type && extensions[category] && extensions[category].includes(type.toLowerCase())) {
                // Return corresponding component based on category
                switch (category) {
                    case "video":
                    return <i className=' ri-video-fil text-success'></i>;
                    case "image":
                    return <i className='ri-image-2-fill text-success'></i>;
                    case "pdf":
                    return <i className='ri-file-pdf-fill text-danger'></i>;
                    case "document":
                    return <i className='ri-file-text-fill text-primary'></i>;
                    case "spreadsheet":
                    return <i className=' ri-file-excel-fill text-success'></i>;
                    case "presentation":
                    return <i className='ri-file-ppt-2-fill text-warning'></i>;
                    case "archive":
                    return <i className='ri-folder-zip-fill text-light'></i>;
                    // case "executable":
                    // return <i className='ri-file-pdf-line'></i>;
                    default:
                    return <i className='ri-file-pdf-line text-danger'></i>;
                }
            }
        }
        // If no match is found, return null
        return null;
    }

    useEffect(() => {
        if (paginationDetails) {
            setPagination(paginationDetails);
        }
    }, [paginationDetails])

    useEffect(() => {
        const filters = { pageSize: 100 }
        dispatch(studentsMetadataGetRequest("students", filters));
    }, [])

    return (
        <React.Fragment>
            <ToastContainer closeButton={false} />
            <DeleteModal
                show={isDeleteModal}
                onDelete={onClickFileDelete}
                onCloseClick={() => setDeleteModal(false)}
                record={fileInfo}
                props={props}
            />
            <DataPreviewModal
                show={dataPreviewModal}
                onDelete={onClickFileDelete}
                onCloseClick={() => setDataPreviewModal(false)}
                data={data}
                props={props}
                dataFields={studentsmetaData}
                OcrLoading={OcrLoading}
            />
            <div>
                <div className="chat-wrapper d-lg-flex gap-1 mx-n4 p-1">
                    <div className="file-manager-content w-100 p-3 py-0">
                        <div className="mx-n3 px-4 file-manager-content-scroll overflow-x-hidden overflow-y-auto">
                            <div>
                                <div className="d-flex align-items-center mb-3">
                                    <h5 className="flex-grow-1 fs-17 mb-0" id="filetype-title">Recent File</h5>
                                    <div className="flex-shrink-0">

                                        <Button
                                            onClick={() => handleFileClicks()}
                                            color="primary"
                                            className="btn-label">
                                                <i className="ri-file-upload-fill label-icon align-middle fs-16 me-2"></i>
                                                Upload file
                                        </Button>
                                    </div>
                                </div>
                                <div className="">
                                {!dataLoading ? 
                                    <table className="table align-middle table-nowrap mb-0">
                                        <thead className="table-active">
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">File Size</th>
                                                <th scope="col">Uploaded Date</th>
                                                <th scope="col" className="text-center">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody id="file-list">

                                            {fileList && fileList.length ? fileList.map((item: any, key: any) => {
                                                let file_extension = item.documentName.split(".")[1]
                                                return <tr key={key}>
                                                    <td>
                                                        <input className="form-control filelist-id" type="hidden" value="1" id="filelist-1" />
                                                        <div className="d-flex align-items-center cursor-pointer" onClick={() => { setFileInfo(item); setFileDetailsOpen(true) }}>
                                                            <div className="flex-shrink-0 fs-17 me-2 filelist-icon">
                                                                {handleIcon(item.documentExtension)}
                                                            </div>
                                                            <div className="flex-grow-1 filelist-name">{item.documentName}</div>
                                                            <div className="d-none filelist-type"> {item.documentType} </div>
                                                        </div>
                                                    </td>
                                                    <td className="filelist-size">{item.documentSize}</td>
                                                    <td className="filelist-create">{moment(item.createdAt).format('lll')}</td>
                                                   <td>
                                                        <div className="d-flex gap-3 justify-content-center">
                                                            <UncontrolledDropdown dir='start'>
                                                                <DropdownToggle tag="button" className="btn btn-soft-primary btn-sm dropdown" id="dropdownMenuButton">
                                                                    <i className="ri-more-fill align-bottom" />
                                                                </DropdownToggle>
                                                                <DropdownMenu className="dropdown-menu-end" style={{zIndex: 9999, position: 'absolute',overflowY: 'auto', maxHeight: 200}}>
                                                                    <DropdownItem className="viewfile-list" onClick={() => { setFileInfo(item); setFileDetailsOpen(true) }}>
                                                                        <button className="btn btn-sm btn-soft-info remove-list fs-12"
                                                                                >
                                                                                    <i className="ri-eye-fill"></i>
                                                                        </button>{" "}{props.t("student.view")}                                                                 
                                                                    </DropdownItem>
                                                                    <DropdownItem divider />
                                                                    <DropdownItem className="remove-list" onClick={() => {setFileInfo(item); setDeleteModal(true)}}>
                                                                        <button className="btn btn-sm btn-soft-danger remove-list"
                                                                                    >
                                                                            <i className="ri-delete-bin-fill"></i>                                                                       
                                                                        </button>{" "}{props.t("student.delete")}  
                                                                    </DropdownItem>
                                                                </DropdownMenu>
                                                            </UncontrolledDropdown>
                                                        </div>
                                                    </td>
                                                </tr>})
                                                :
                                                <div className='p-2'><h5>No documents Uploaded yet</h5></div>
                                                }
                                        </tbody>
                                    </table>
                                    : <DtsTablePlaceholder                                     
                                    rows={5} cols={5}                                     
                                    tableClass="align-middle table-nowrap"
                                    thClass="border-bottom-1 table-soft-primary"
                                />}
                                </div>

                                <Row className="align-items-center mt-2 g-3 text-center text-sm-start">
                                    <div className="col-sm">
                                        <div className="text-muted">{props.t("applications.showing")}<span className="fw-semibold ms-1">{" "}{fileList?.length}{" "}</span>{props.t("applications.of")}{" "}<span className="fw-semibold">{pagination && pagination.elements ? pagination.elements : 0}</span> {props.t("applications.results")}
                                        </div>
                                    </div>
                                    <div className="col-sm-auto">
                                        <Paginator total={pagination && pagination.elements ? pagination.elements : 0}
                                            currentPage={pagination?.page}
                                            pageChanged={(e: any) => pageChanged(e)}
                                            pageSize={pageSize}
                                            isPageSizeChange={true}
                                            onChangeInSelect={(e: any) => onChangePageSize(e)}
                                            pagination={pagination}
                                            props={props}
                                        />
                                    </div>
                                </Row>
                            </div>
                        </div>
                    </div>
                    <div className="email-detail-content email-detail-content-scroll h-100">
                        <div className="p-4 d-flex flex-column h-100">
                            <div className="pb-4 border-bottom border-bottom-dashed">
                                <Row>
                                    <Col className="col">
                                        <div className="">
                                            <button type="button" className="btn btn-soft-danger btn-icon btn-sm fs-16 close-btn-email" onClick={() => setFileDetailsOpen(false)}>
                                                <i className="ri-close-fill align-bottom"></i>
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <SimpleBar className="mx-n3 pt-3 px-3 email-detail-content-scroll">
                                <div className="d-flex h-100 flex-column">
                                    <div className="d-flex align-items-center pb-3 border-bottom border-bottom-dashed mb-3 gap-2">
                                        <h5 className="flex-grow-1 fw-semibold mb-0">File Preview</h5>
                                        {/* <div>
                                            <button type="button" className="btn btn-ghost-primary btn-icon btn-sm fs-16 favourite-btn">
                                                <i className="ri-star-fill align-bottom"></i>
                                            </button>
                                        </div> */}
                                    </div>
                                    <div className="pb-3 border-bottom border-bottom-dashed mb-3">
                                        <div className="file-details-box bg-light p-3 text-center rounded-3 border border-light mb-3">
                                            <div className="display-4 file-icon">
                                            {
                                                fileInfo?.documentName ? handleIcon(fileInfo.documentExtension): null
                                            }
                                            </div>
                                        </div>
                                        <button type="button" className="btn btn-icon btn-sm btn-ghost-success float-end fs-16"></button>
                                        <h5 className="fs-16 mb-1 file-name">{fileInfo.documentName}</h5>
                                        <p className="text-muted mb-0 fs-12"><span className="file-size">{fileInfo.documentSize}</span>, <span className="create-date">{fileInfo.createDate}</span></p>
                                    </div>
                                    <div>
                                        <h5 className="fs-12 text-uppercase text-muted mb-3">File Description :</h5>
                                        <div className="table-responsive">
                                            <table className="table table-borderless table-nowrap table-sm">
                                                <tbody>
                                                    <tr>
                                                        <th scope="row" style={{ width: "35%" }}>File Name :</th>
                                                        <td className="file-name">{fileInfo.documentName}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">File Type :</th>
                                                        <td className="file-type">{fileInfo.documentExtension}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Size :</th>
                                                        <td className="file-size">{fileInfo.documentSize}</td>
                                                    </tr>
                                                    <tr>
                                                        <th scope="row">Created :</th>                                                        
                                                        <td className="create-date">{moment(fileInfo?.createdAt).format('lll')}</td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                    <div className="mt-auto border-top border-top-dashed py-3">
                                        <div className="hstack gap-2">
                                            <button type="button" className="btn btn-soft-primary w-100" onClick={() => onClickFileDownload(fileInfo)}><i className="ri-download-2-line align-bottom me-1"></i> Download</button>
                                            <button type="button" className="btn btn-soft-danger w-100 remove-file-overview" onClick={() => {setDeleteModal(true); setFileDetailsOpen(false)}}><i className="ri-close-fill align-bottom me-1"></i> Delete</button>
                                        </div>
                                    </div>
                                </div>
                            </SimpleBar>
                        </div>
                    </div>
                </div>
            </div>
            <Modal backdrop="static" id="createFileModal" isOpen={modalFile}  toggle={() => { fileToggle(); }}  modalClassName="zoomIn" centered tabIndex={1}>
                <ModalHeader  toggle={() => { fileToggle(); }}  className="p-3 bg-success-subtle">{"Upload File"}</ModalHeader>
                <ModalBody>
                    <Upload model={props.model} details={props.details} handleGetList={handleGetList} setOcrLoading={setOcrLoading} setData={setData} setDataPreviewModal={setDataPreviewModal} filterObject={filterObject} selectedFiles={selectedFiles} setselectedFiles={setselectedFiles} setModalFile={setModalFile} setDocumentType={setDocumentType} documentType={documentType} fileInfo={fileInfo} setFileInfo={setFileInfo} />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

export default withTranslation()(Documents);

