import { Reducer } from "redux";
import { ChannelActionTypes, ChannelState } from "./types";


export const initialState: ChannelState =
{
    metaDataFields: [],
    list: null,
    details: null,
    pagination: null,
    loading: false,
    dataLoading: false,
    applicationsChannel: [],
    invoicesstudents: [],
    metaLoading: false
}
const reducer: Reducer<ChannelState> = (state: any = initialState, action: any) => {
    switch (action.type) {
        case ChannelActionTypes.GET_CHANNELMETADATA_REQUEST: {
            return { ...state, metaLoading: true,  metaDataFields: [] };
        }
        case ChannelActionTypes.GET_CHANNELMETADATA_SUCCESS: {
            return {
                ...state,
                metaLoading: false,
                metaDataFields: action.payload.content
            };
        }
        case ChannelActionTypes.GET_CHANNELMETADATA_ERROR: {
            return { ...state, metaLoading: false, errors: action.payload,  metaDataFields: [] };
        }
        case ChannelActionTypes.GET_CHANNEL_REQUEST: {
            return { ...state, loading: true };
        }
        case ChannelActionTypes.GET_CHANNEL_SUCCESS: {
            return {
                ...state,
                loading: false,
                details: action.payload
            };
        }
        case ChannelActionTypes.GET_CHANNEL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }  
        
        
        case ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_REQUEST: {
            return { ...state, loading: true };
        }
        case ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                applicationsChannel: action.payload.content
            };
        }
        case ChannelActionTypes.GET_APPLICATIONS_COMMISSIONS_LIST_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 

        case ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_REQUEST: {
            return { ...state, loading: true };
        }
        case ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_SUCCESS: {
            return {
                ...state,
                loading: false,
                invoicesstudents: action.payload.content
            };
        }
        case ChannelActionTypes.GET_INVOICES_STUDENTS_LIST_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        } 

        case ChannelActionTypes.POST_CHANNEL_REQUEST: {
            return { ...state, loading: true };
        }
        case ChannelActionTypes.POST_CHANNEL_SUCCESS: {
            return {
                ...state,
                loading: false,
            };
        }
        case ChannelActionTypes.POST_CHANNEL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }

        case ChannelActionTypes.GET_CHANNELS_REQUEST: {
            return { ...state, dataloading: true,list:[] };
        }
        case ChannelActionTypes.GET_CHANNELS_SUCCESS: {
            return {
                ...state,
                dataloading: false,
                list: action.payload.content,
                pagination: {
                    page: action.payload.number,
                    size: action.payload.size,
                    pages: action.payload.totalPages,
                    elements: action.payload.totalElements,
                    first: action.payload.first,
                    last: action.payload.last,
                    offset: action.payload.pageable.offset
                }
            };
        }
        case ChannelActionTypes.GET_CHANNELS_ERROR: {
            return { ...state, dataloading: false, errors: action.payload,list:[]  };
        }
        case ChannelActionTypes.PUT_CHANNEL_REQUEST: {
            return { ...state, loading: true };
        }
        case ChannelActionTypes.PUT_CHANNEL_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case ChannelActionTypes.PUT_CHANNEL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }
        case ChannelActionTypes.DELETE_CHANNEL_REQUEST: {
            return { ...state, loading: true };
        }
        case ChannelActionTypes.DELETE_CHANNEL_SUCCESS: {
            return {
                ...state,
                loading: false
            };
        }
        case ChannelActionTypes.DELETE_CHANNEL_ERROR: {
            return { ...state, loading: false, errors: action.payload };
        }        
        default: {
            return state;
        }
    }
};

export { reducer as ChannelReducer };
