import { Button, Modal, ModalBody, ModalHeader } from "reactstrap";
import Upload from "./Upload/Upload";
import { useState } from "react";

const ImportModal = ({ model, show, onCloseClick, downloadBulkTemplate, props }: any) => {
    const [selectedFiles, setSelectedFiles] = useState();
    const [fileInfo, setFileInfo] = useState();
    return ( 
        <Modal backdrop="static" isOpen={show} toggle={onCloseClick} centered={true}>
            <ModalHeader className="p-3 offcanvas-header-dark d-flex flex-row justify-content-between align-items-center bg-primary" toggle={onCloseClick}>
                <span className='text-light'>{props.t("views.import_file")}</span>
            </ModalHeader>
            <ModalBody className='text-center p-5'>
                <Upload model={model} onCloseClick={onCloseClick}  selectedFiles={selectedFiles} setSelectedFiles={setSelectedFiles} fileInfo={fileInfo} setFileInfo={setFileInfo} downloadBulkTemplate={downloadBulkTemplate} />                
            </ModalBody>
        </Modal>
    ) as unknown as JSX.Element;
};

export default ImportModal;