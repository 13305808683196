export interface Calendar {
    data: any,
    loading: boolean,
    error: string,
    events: [],
    userData: any
}

export enum CalendarActionTypes {

    POST_GOOGLECALENDARSYNC_REQUEST = "@@calendar/POST_GOOGLECALENDARSYNC_REQUEST",
    POST_GOOGLECALENDARSYNC_SUCCESS = "@@calendar/POST_GOOGLECALENDARSYNC_SUCCESS",
    POST_GOOGLECALENDARSYNC_ERROR = "@@calendar/POST_GOOGLECALENDARSYNC_ERROR",
    GET_GOOGLECALENDARSYNC_REQUEST = "@@calendar/GET_GOOGLECALENDARSYNC_REQUEST",
    GET_GOOGLECALENDARSYNC_SUCCESS = "@@calendar/GET_GOOGLECALENDARSYNC_SUCCESS",
    GET_GOOGLECALENDARSYNC_ERROR = "@@calendar/GET_GOOGLECALENDARSYNC_ERROR",
    DELETE_GOOGLECALENDARSYNC_REQUEST = "@@calendar/DELETE_GOOGLECALENDARSYNC_REQUEST",
    DELETE_GOOGLECALENDARSYNC_SUCCESS = "@@calendar/DELETE_GOOGLECALENDARSYNC_SUCCESS",
    DELETE_GOOGLECALENDARSYNC_ERROR = "@@calendar/DELETE_GOOGLECALENDARSYNC_ERROR",
    GET_GOOGLECALENDAREVENTS_REQUEST = "@@calendar/GET_GOOGLECALENDAREVENTS_REQUEST",
    GET_GOOGLECALENDAREVENTS_SUCCESS = "@@calendar/GET_GOOGLECALENDAREVENTS_SUCCESS",
    GET_GOOGLECALENDAREVENTS_ERROR = "@@calendar/GET_GOOGLECALENDAREVENTS_ERROR",
    POST_CREATEGOOGLEEVENT_SUCCESS = "@@calendar/POST_CREATEGOOGLEEVENT_SUCCESS",
    POST_CREATEGOOGLEEVENT_REQUEST = "@@calendar/POST_CREATEGOOGLEEVENT_REQUEST",
    POST_CREATEGOOGLEEVENT_ERROR = "@@calendar/POST_CREATEGOOGLEEVENT_ERROR",
    DELETE_GOOGLECALENDAREVENT_SUCCESS = "@@calendar/DELETE_GOOGLECALENDAREVENT_SUCCESS",
    DELETE_GOOGLECALENDAREVENT_REQUEST = "@@calendar/DELETE_GOOGLECALENDAREVENT_REQUEST",
    DELETE_GOOGLECALENDAREVENT_ERROR = "@@calendar/DELETE_GOOGLECALENDAREVENT_ERROR",
    SIGNUP_MICROSOFT_REQUEST = '@@calendar/SIGNUP_MICROSOFT_REQUEST',
    SIGNUP_MICROSOFT_SUCCESS = '@@calendar/SIGNUP_MICROSOFT_SUCCESS',
    SIGNUP_MICROSOFT_ERROR = '@@calendar/SIGNUP_MICROSOFT_ERROR',

}

export interface CalendarState {
    readonly data?: any;
    readonly loading: boolean;
    readonly error: string | null;
    readonly events: [] | null;
    readonly token: string;
    readonly userData: any;
}