import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Input, Row, Button } from 'reactstrap';
import _ from 'lodash';
import TableContainer from './TableComponent';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { Action, ThunkDispatch } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';
import { propertyArchivedListGetRequest, propertyListGetRequest, propertyRestoreRequest} from 'store/properties/action';
import { ApplicationState } from 'store';
import isEqual from 'lodash/isEqual';
import RestoreModal from './innerComponents/RestoreModal';
import moment from 'moment';

const Properties = (props : any) => {
    const { model } = props;
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();   
    const archivedPropertyList = useSelector((state: ApplicationState) => state.properties.achivedlist);   
    const paginationDetails = useSelector((state: ApplicationState) => state.properties.achivedpagination); 
    const loading = useSelector((state: ApplicationState) => state.properties.archivedloading); 
    const [Archivedcolumns, setArchivedColumns] = useState<any>([])
    const [record, setrecord] = useState<any>({});
    const [filterObject, setFilterObject] =  useState<any>({pageSize:10});
    const [searchArchivedData, setArchivedsearchData] = useState<any>([])
    const [pageSize, setPageSize] = useState<number>(10)
    const [typingTimeout, setTypingTimeout] = useState<NodeJS.Timeout | null>(null);  
    const [searchvalue, setsearchvalue] = useState<string>("");
    const [isArchivedOpen, setIsArchivedOpen] = useState<boolean>(false);
    const [isDeleteOpen, setisDeleteOpen] = useState<boolean>(false);
    const [isRestore, setisRestoreOpen] = useState<boolean>(false);
    const [sortBy, setSortBy] = useState<any>("")
    const [sortOrder, setSortOrder] = useState<string>("")  
    const [pagesList, setPagesList] = useState<any>([]);
    const [propertiesArchivedJson, setArchivedPropertiesJson] = useState<any>([])
    const [pagination, setPagination] = useState<any>(
        {
            size: 10,
            page: 0,
            totalPages: 0,
            total: 0,
            elements: 0,
            first: true,
            last: false
        }
    )
    const fieldtype = [
        {
            label: "Text input",
            options: [
                { label: "Single-line text", value: "textfield" },
                { label: "Multi-line text", value: "textarea" },
                { label: "Phone number", value: "phone" },
                { label: "Email", value: "email" },
            ]
        },
        {
            label: "Choosing options",
            options: [
                { label: "Single checkbox", value: "checkbox" },
                { label: "Multiple checkboxes", value: "selectboxes" },
                { label: "Date picker", value: "datetime" },
                { label: "Dropdown select", value: "select" }, 
                { label: "Suggest dropdown", value: "datasetselect" },                 
                { label: "Radio select", value: "radio" }
            ]
        },
        {
            label: "values",
            options: [
                { label: "Number", value: "number" }
            ]
        },
        {
            label: "other",
            options: [
                { label: "File", value: "file" },
            ]
        }
    ];

    const renderField = (value: any) => {        
        let fieldlabel =""
          let a = fieldtype.map((field: any) => {
            field && field.options.length && field.options.map((item: any) => {
                if(item.value == value) fieldlabel = item.label;
            })
        })
        return fieldlabel
    }

    const handleSort = (columnName: any) => {
        if (sortBy === columnName) {
          // Reverse the sorting order if the same column is clicked again
          setSortBy(columnName);
          setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc');
          let sort = sortOrder === 'asc' ? 'desc' : 'asc'
          let filters = {...filterObject, sortBy: columnName,sortOrder: sort} 
          setFilterObject(filters)         
          dispatch(propertyArchivedListGetRequest(model,filters, pagination.page));
        } else {
          // Set the new column to sort by and default to ascending order
          setSortBy(columnName);
          setSortOrder('asc');
          let filters = {...filterObject, sortBy: columnName, sortOrder: 'asc'}      
          setFilterObject(filters)        
          dispatch(propertyArchivedListGetRequest(model,filters, pagination.page));
        }
      };

    useEffect(() => {
        if(archivedPropertyList && archivedPropertyList.length) {
            let fields =  JSON.parse(archivedPropertyList[0].valuesJson);  
            const keys = Object.keys(fields)
            keys.push('createdBy') 
            const columns: any = []
            if(keys && keys.length) {
                keys.map((field,index) =>{
                    if(field == 'label')  return columns[1] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        isSortable: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return  <div className="d-flex align-items-center justify-content-between">                                
                            <div className="hstack flex-grow-1  gap-2">
                                    <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                   
                                    {cellProps.row.original[field]}
                                </div>
                            </div>
                            {
                                field == "label" ? 
                                <Button 
                                size='sm'
                                color="dark"
                                outline={true}
                                className={"btn_preview"}   
                                onClick={() => {setisRestoreOpen(true); setrecord(cellProps.row); onClickrow(cellProps.row);}} >
                                   {props.t("properties.restore")}
                                </Button> 
                                    : null
                                }
                    </div>                        
                            
                        }
                    }
                    else if(field == 'type')  return columns[2] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {   
                            return <div className="d-flex align-items-center justify-content-between">                                
                                        <div className="hstack flex-grow-1  gap-2">
                                                <div className="flex-shrink-0 text-decoration-none hstack flex-grow-1 gap-2" >                                  
                                                {renderField(cellProps.row.original[field])}
                                            </div>
                                        </div>
                                </div>                               
                        }
                    }
                    else if(field == 'createdBy' )  return columns[3] = {
                        Header: _.startCase(field),
                        accessor: field,
                        filterable: true,
                        disableFilters: true,
                        show: true,
                        Cell: (cellProps: any) => {        
                            return <span className="fw-semibold fs-14">{cellProps.row.original.createdBy?.name}</span>                               
                        }
                    }                   
                })
                columns.push({
                    Header:'Archived Date',
                    accessor: 'deletedAt',
                    filterable: true,
                    disableFilters: true,
                    show: true,
                    Cell: (cellProps: any) => {        
                        return <span className="fw-semibold fs-14">{cellProps.row.original["deletedAt"] ? moment(cellProps.row.original["deletedAt"]).format('lll'):null}</span>                               
                    }
                })
                setArchivedColumns(columns)
            }           
        }
    }, [archivedPropertyList]); 
 
    const onClickrow = (data: any) => {
        setrecord(data.original);
    }

    const Archivedexecute = () => {
        let a= isEqual(archivedPropertyList, propertiesArchivedJson);
        return !a
     }

    useEffect(() => {
        const parsedArchivedData: any = archivedPropertyList && archivedPropertyList.length ? archivedPropertyList.map((item: any) => {
            const valuesJson = JSON.parse(item.valuesJson);
            let created = item.createdBy && item.createdBy.name
            let values = { ...valuesJson, ...item, deletedAt:item.deletedAt}
            return values
        }) : []
        let properties = parsedArchivedData && parsedArchivedData.length ? parsedArchivedData : [];
        setArchivedsearchData(properties)
        setArchivedPropertiesJson(archivedPropertyList)
    }, [Archivedexecute()])

    
    const pageChanged = (pageVal: any) => {
        const { pages, page } = pagination;
        const lastPage = pages;
        let newPageNo = 0;
        switch (pageVal) {
            case 'prev':
                if (page >= 1) {
                    newPageNo = page - 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'next':
                if (page < lastPage) {
                    newPageNo = page + 1;
                    setPagination({ ...pagination, page: newPageNo });
                }
                break;
            case 'first':
                    newPageNo = 0;
                    setPagination({ ...pagination, page: 0 });
                break;
            case 'last':
                    newPageNo = lastPage - 1;
                    setPagination({ ...pagination, page: newPageNo });
                break;
            default:
                newPageNo = pageVal-1;
                setPagination({ ...pagination, page: newPageNo });
                break;
        }
        dispatch(propertyArchivedListGetRequest(model,filterObject, newPageNo));
    };
    
    const onChangePageSize = (value: any) => {
        setPagination({...pagination, pageSize: value})
        setPageSize(value);
        let filters= {...filterObject, pageSize: value}
        setFilterObject(filters)
        dispatch(propertyArchivedListGetRequest(model,filters, 0));
    }   

    const onRestore = () => {
        const handleSuccess = () => {
            const message = () => toast(`${record.label} is restored successfully`, { position: "top-center", hideProgressBar: true, className: 'bg-success text-white' });
            message()
            dispatch(propertyListGetRequest(model, filterObject))
            dispatch(propertyArchivedListGetRequest(model));
            setisRestoreOpen(false)
        }
        const handleFailure = () => {
            setisRestoreOpen(false)

        }
        dispatch(propertyRestoreRequest(model,record.id, handleSuccess, handleFailure))  
    }
    
    const handleChange = (value: any) => {
        setsearchvalue(value);
        const filters = {...filterObject, search: value}
        setFilterObject(filters);            
        if (typingTimeout) {
            clearTimeout(typingTimeout);
        }
        const newTimeout = setTimeout(() => {            
            dispatch(propertyArchivedListGetRequest(model,filters));  
        }, 2000);

        setTypingTimeout(newTimeout);
    };

    useEffect(() => {        
        if (paginationDetails) {            
            setPagination(paginationDetails);
            setPagesList(Array.from({ length: paginationDetails.pages }, (_, index) => index))
        }
    },[paginationDetails])

    useEffect(() => {        
        dispatch(propertyArchivedListGetRequest(model)); 
    },[model])

    document.title="Properties | Zilter";
    return (
        <React.Fragment>
            <RestoreModal 
                show={isRestore}
                onCloseClick={() => setisRestoreOpen(false)}
                onRestore={onRestore}
                record={record} 
                props={props}
            />
            <Card>
                <CardBody>
                    <Row className='hstack'>                                
                        <Col>
                            <div className="search-box">                                
                                <Input
                                type="text"
                                size={14}
                                className="search"
                                placeholder="Search for..."
                                onChange={(e) => handleChange(e.target.value)}
                                value={searchvalue}
                            />
                            { searchvalue == "" ? <i className="ri-search-line search-icon"></i> : <i className=" ri-close-circle-fill search-icon cursor-pointer" onClick={() => handleChange("")}></i>}
                    
                            </div>  
                        </Col>
                    </Row>
                    <Row className={"students-table mt-1"}>
                                    <TableContainer
                                        columns={Archivedcolumns}
                                        data={searchArchivedData || []}
                                        customPageSize={pageSize}
                                        divClass="table-responsive"
                                        tableClass="align-middle table-nowrap"
                                        theadClass="table-light text-muted"
                                        thClass="border-bottom-1 table-soft-primary"
                                        isPageSizeChange={true}                                           
                                        pageChanged={pageChanged}
                                        pagination={paginationDetails}
                                        pagesList={pagesList}
                                        onChangePageSize={onChangePageSize}
                                        pageSize={pageSize}       
                                        setIsArchivedOpen={setIsArchivedOpen} 
                                        setisDeleteOpen={setisDeleteOpen}
                                        archive={true}      
                                        handleSort={handleSort}
                                        sortBy={sortBy}
                                        sortOrder={sortOrder}     
                                        props={props} 
                                        loading={loading}  
                                    />
                    </Row>
                </CardBody>
            </Card>       
        </React.Fragment>
    );
};
export default withTranslation()(Properties);


