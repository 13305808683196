import React, { useEffect } from "react";
import {
    Offcanvas,
    OffcanvasHeader,
    OffcanvasBody,
    Col,
    Card,
    CardBody,
    Button,
    AccordionItem,
    AccordionHeader,
    AccordionBody,
    UncontrolledAccordion,
} from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { ApplicationState } from "store";
import InvoiceInfo from './InvoiceInfo';
import { useParams } from "react-router-dom";
import { Action, ThunkDispatch } from "@reduxjs/toolkit";
import { propertyListGetRequest } from "store/properties/action";
import { invoiceGetRequest } from "store/invoices/action";
import { isJson } from "utils";
interface PreviewModalProps {
    show ?: boolean;
    open ?: string;
    onCloseClick : () => void;
    ontoggle:  (e: any) => void;
    setrecord:  (e: any) => void;
    record: {};
    metaData: any;
    data: any;
  }
  
  const PreviewModal: React.FC<PreviewModalProps> = ({ metaData, show, onCloseClick, record ,ontoggle, open, setrecord, data}: any) => {    
    const { id } = useParams()
    const dispatch: ThunkDispatch<any, null, Action<string>> = useDispatch();
    const invoiceDetails: any = useSelector((state: ApplicationState) => state.invoices.details);
  
    let info =  invoiceDetails && invoiceDetails.valuesJson  && isJson(invoiceDetails.valuesJson) ? JSON.parse(invoiceDetails.valuesJson) : {}
    useEffect(() => {
        setrecord(invoiceDetails)
        dispatch(invoiceGetRequest(id))
        const filters = {pageSize: 100}
        dispatch(propertyListGetRequest("invoices",filters));
    }, [])
   return ( <>
         {
                <Offcanvas
                    direction="end"
                    isOpen={show}
                    id="offcanvasExample"
                    toggle={onCloseClick}
                >
                    <OffcanvasHeader className="bg-light" toggle={onCloseClick}>
                        {record?.channel_name}
                    </OffcanvasHeader>
                    <form action="" className="d-flex flex-column justify-content-end h-100">
                        <OffcanvasBody> 
                        <Col xxl={12} md={12} lg={12}>
                            <Card id="contact-view-detail">
                                <CardBody className="text-center">
                                    <div className="text-center">
                                        <h5 className="mt-4 mb-1">{info.channel_name}</h5>
                                        <p className="text-muted">{info.email}</p>
                                        </div>
                                </CardBody>
                            </Card>
                            </Col>
                            <div>
                                <UncontrolledAccordion toggle={()=>{}} defaultOpen={["about"]}>
                                    <AccordionItem>
                                        <AccordionHeader targetId="about">
                                            About this Invoice
                                        </AccordionHeader>
                                        <AccordionBody accordionId="about">
                                            <InvoiceInfo record={info} data={data} metaData={metaData} onCloseClick={onCloseClick}/>
                                        </AccordionBody>
                                    </AccordionItem>
                                </UncontrolledAccordion>
                            </div>
                        </OffcanvasBody>
                        <div className="offcanvas-footer border-top p-3 text-center hstack gap-2">
                            <Button
                                className="btn btn-light w-100"
                                onClick={() => onCloseClick()}
                            >
                                Cancel
                            </Button>
                        </div>
                    </form>
                </Offcanvas>
            }
        </>
    );
};

export default PreviewModal;
