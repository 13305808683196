import React, { useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Container, Row, Form, Input, Label, FormFeedback } from 'reactstrap';
import ParticlesAuth from './ParticlesAuth';
import logoLight from "../../assets/images/dtsimg/svg/zilter_logo_blue.svg";
import { withTranslation } from 'react-i18next';
import FeatherIcon from 'feather-icons-react';
import { useDispatch, useSelector } from "react-redux";
import { resetPassword } from "store/auth/action";
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { Action } from 'redux';

interface Inputs {
    password: string;
    confirm_password: string;
}

interface ResetPasswordSuccessProps { 
    t: (key: string) => string;
}

const ResetPasswordSuccess: React.FC<ResetPasswordSuccessProps> = ({t}) => {
    document.title = "Create New Password | Zilter";    
    const navigate = useNavigate()
    const onRedirect = () => {
        navigate("/password/forgot")
    }
    return (
        <div className="auth-page-content">
            <Container>
                <Row className="justify-content-center">
                    <Col md={8} lg={6} xl={5}>
                        <Card className="mt-4">
                            <CardBody className="p-4">
                                <Row>
                                    <Col lg={12}>
                                        <div className="text-center mt-sm-5 mb-4 text-white-50">
                                            <div>
                                                <Link to="/#" className="d-inline-block auth-logo">
                                                    <img src={logoLight} alt="" height="50" />
                                                </Link>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                                <div className="text-center mt-2">
                                    <FeatherIcon icon="x-circle" className="icon-xxl icon-dual-danger" />
                                    <h5 className="text-danger fw-bold fs-28 mb-2 mt-4">Sorry, your password reset link is expired!</h5>
                                </div>
                                <div className="p-2 mb-2">
                                    <Form>
                                        <div className="mt-4 text-center">
                                            <Button color="secondary" className="w-auto" onClick={() => onRedirect()}>Request for new link</Button>
                                        </div>
                                    </Form>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </div>
    );
};

export default withTranslation()(ResetPasswordSuccess);