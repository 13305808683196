import React, { useEffect, useState } from "react";
import {
    Card,
    CardBody,
    Form,
    Button,
} from "reactstrap";
import FormBuilder from "Components/Common/FormBuilder/FormBuilder";
import { isJson } from "utils";
import { getAvailableStatuses } from "helpers/workflowStatusHelper";

const QuickFormComponent = ({ dataFields, btnAction, btn_text, APAId , data, btndisabled}: any) => {
    const filterFields: any = [];
   
    dataFields && dataFields.length && dataFields.map((field: any) => {           
        let item = field.valuesJson && isJson(field.valuesJson) ? JSON.parse(field.valuesJson) : {...field.valuesJson}   
        if (field.valuesJson) return filterFields.push(item)
    })
    const [formValues, setFormValues] = useState<any>({});
    const [disabled, setdisabled] = useState<any>(false);
    const [formerror, setError] = useState<any>([]);

    const onChange = (option: any, key: any, field: any) => {  
        if(field.type == "selectboxes" || field.isMulti) {   
            setFormValues({ ...formValues, [key]: option })
        }     
        else if(key == 'parent') {
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else if(key == 'source') {
            setFormValues({ ...formValues, [key]: option?.value || option, subagent: null })
        }
        else if(key == 'subagent') {
            setFormValues({ ...formValues, [key]: option?.value || option, source: "c017ce92-15ae-47b1-9ab1-197895ce94d6" })
        }
        else if (typeof option == 'object') {            
            setFormValues({ ...formValues, [key]: option?.value || option })
        }
        else {
            setFormValues({ ...formValues, [key]: option })
        }
    };
    const allMandatoryFieldsFilled = () => {
        for (const field of filterFields) {
            if (field && field.validate && field.validate.required && !formValues[field.key]) {
                return false;
            }
        }
        return true;
    };
    useEffect(() => {   
        if(data?.valuesJson) {
            let values = data?.valuesJson && isJson(data?.valuesJson)  ? JSON.parse(data?.valuesJson) : {...data?.valuesJson}
            setFormValues(values)
        }
        else if(data?.id) {
            setFormValues(data)
        }
        else {
            let newData: any = {}
            filterFields && filterFields.length && filterFields.map((item: any, key: any) => {
                const resetvalue = item?.ConditionalDefaultValue?.isDefaultValue ? item?.ConditionalDefaultValue?.values.value || item?.ConditionalDefaultValue?.values || null : null;
                newData[item.key]= resetvalue;
            })
            setFormValues(newData)
        }
    }, [data])
    const formdisabled = Object.keys(formerror).length === 0;
    return (
        <Form action="" className="d-flex flex-column justify-content-end h-100">
            <div className="live-preview vstack gap-2">
                {filterFields && filterFields.length ? filterFields.map((field: any) => {  
                    let statusoptions = null; 
                    if(field?.process_flows && field.key == 'student_status'){
                        const { statuses, status } = getAvailableStatuses(field?.process_flows, formValues[field.key]);
                        statusoptions= statuses
                    }
                    if(field.quickCreate) return <FormBuilder
                        placeholder={field.placeholder}
                        name={field.key}
                        onChange={(e: any) => onChange(e, field.key, field)}
                        options={statusoptions || field.values && field.values.reverse() || []}
                        label={field.label}
                        type={field.type}
                        value={formValues[field.key]}
                        isEditState={true}
                        actionButtons={false}
                        defaultValue={formValues[field.key]}
                        Editable={true}
                        rest={field}
                        invalid={true}
                        formValues={formValues}
                        dataFields={filterFields}
                        setdisabled={setdisabled}
                        setError={setError}
                        formerror={formerror}
                        resetValue={formValues[field.key]}
                    />
                }): null}
            </div>
            <div className="offcanvas-footer bg-opacity-10 border-top p-3 justify-content-between hstack gap-2">
                <Button
                    className="btn btn-success w-100"
                    disabled={!allMandatoryFieldsFilled() || disabled || !formdisabled || btndisabled}
                    onClick={() => btnAction(formValues)}
                >
                    {btn_text}
                </Button>
            </div>
        </Form>
    );
};

export default QuickFormComponent;

